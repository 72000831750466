import { useState, useContext } from "react";
import Container from "react-bootstrap/Container";
import ListaTransfEstoque from "../../../Components/Estoque/Transferencias/ListaTransfEstoque";
import "./styles.scss";
import IEstoque from "../../../Components/Estoque/Interface/IStock";
import { FormTransferenciaEstoque } from "../../../Components/Estoque/Transferencias/CadTransferencias/transferencia";
import { ThemeContext } from "../../../context/theme-context";

export interface IListaTransferenciaEstoque {
  title: string;
  OnAddNewCli: (e: boolean) => void;
  onEditItem: (item: IEstoque) => void;
  OnAddNewEstoque: (e: boolean) => void;
}

export interface IFormTransferenciaEstoque {
  title: string;
  OnAddNewEstoque: (e: boolean) => void;
  estoque?: IEstoque;
  editando: boolean;
}

export default function CadTransfEstoque() {
  const { collapsed } = useContext(ThemeContext);
  const [cad, setCad] = useState<boolean>(false);
  const [toEditTransferencia, setToEditTransferencia] = useState<IEstoque>();
  const [editando, setEditando] = useState<boolean>(false);

  const handleNew = (e: any) => {
    setEditando(false);
    setCad(e);
  };

  const handleEdit = (props: IEstoque) => {
    setToEditTransferencia(props);
    setCad(true);
    setEditando(true);
  };

  return (
    <>
      {!cad ? (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <ListaTransfEstoque
            title="Transferência de Estoque"
            onEditItem={(item: IEstoque) => handleEdit(item)}
            OnAddNewCli={(e: boolean) => handleNew(e)}
            OnAddNewEstoque={(e: boolean) => handleNew(e)}
          />
        </Container>
      ) : (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <FormTransferenciaEstoque
            title={
              toEditTransferencia
                ? "Editar Transferência de Estoque"
                : "Novo Transferência de Estoque"
            }
            OnAddNewEstoque={(e: boolean) => {
              handleNew(e);
              setToEditTransferencia(undefined);
            }}
            estoque={toEditTransferencia}
            editando={editando}
          />
        </Container>
      )}
    </>
  );
}
