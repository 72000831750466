import { addDays } from "date-fns";
import moment from "moment";

const timeZoneOffset: number = new Date().getTimezoneOffset() * 60000;

class DateUtils {
  static toDate(value: string): Date {
    if (value.substr(value.length - 1, 1) === "Z")
      return new Date(moment(value).toDate().valueOf() + timeZoneOffset);
    else return moment(value).toDate();
  }

  static startOfDay(value: Date = new Date()): Date {
    return moment(value).startOf("day").toDate();
  }

  static endOfDay(value: Date = new Date()): Date {
    return moment(value).endOf("day").toDate();
  }

  static lastDay(value: Date = new Date()): Date {
    return moment(value).subtract("day", 1).toDate();
  }

  static nextDay(value: Date = new Date()): Date {
    return moment(value).add("day", 1).toDate();
  }

  static startOfWeek(value: Date = new Date()): Date {
    return moment(value).startOf("week").toDate();
  }

  static endOfWeek(value: Date = new Date()): Date {
    return moment(value).endOf("week").toDate();
  }

  static lastWeek(value: Date = new Date()): Date {
    return moment(value).subtract("week", 1).toDate();
  }

  static nextWeek(value: Date = new Date()): Date {
    return moment(value).add("week", 1).toDate();
  }

  static startOfMonth(value: Date = new Date()): Date {
    return moment(value).startOf("month").toDate();
  }

  static endOfMonth(value: Date = new Date()): Date {
    return moment(value).endOf("month").toDate();
  }

  static lastMonth(value: Date = new Date()): Date {
    return moment(value).subtract("month", 1).toDate();
  }

  static nextMonth(value: Date = new Date()): Date {
    return moment(value).add("month", 1).toDate();
  }

  static startOfYear(value: Date = new Date()): Date {
    return moment(value).startOf("year").toDate();
  }

  static endOfYear(value: Date = new Date()): Date {
    return moment(value).endOf("year").toDate();
  }

  static lastYear(value: Date = new Date()): Date {
    return moment(value).subtract("year", 1).toDate();
  }

  static nextYear(value: Date = new Date()): Date {
    return moment(value).add("year", 1).toDate();
  }
}

export default DateUtils;
