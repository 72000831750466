import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import "./styles.scss";

const Loading = () => {
    return (
        <div className="fundo">
            <form>
                <div>
                    <Spinner animation="border" role="status" className='spinner' variant="primary">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
                <div>
                    <span className='label'> Aguarde</span>
                </div>
            </form>
            
        </div>
    )
  }
  export default Loading