import { Fragment, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../../../Loading/Loading";
import { NoData } from "../../../../noData";
import { AppDispatch, useAppSelector } from "../../../../../redux/store";
import { useDispatch } from "react-redux";
import { Btn } from "../../../../data/Btn";
import { TreePDV } from "./Tree";
import ModalRevenue from "./Modal";
import { IChartAccount } from "../../../../../redux/features/chartAccount/interface";
export default function RevenueModal({
  onEditItem,
  OnAddNewChartAccount,
  setKeyActived,
}: any) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const dispatch = useDispatch<AppDispatch>();
  const { revenue } = useAppSelector(
    ({ chartAccountReducer }) => chartAccountReducer.value
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [openModalCadPDV, setModalCadPDV] = useState<boolean>(false);
  const [listPDV, setListPDV] = useState<any[]>([]);
  const [listEditeItem, setListEditeItem] = useState<
    IChartAccount | undefined
  >();

  const handleEdit = (item: any) => {
    setListEditeItem(item);
    setModalCadPDV(true);
  };

  return (
    <>
      <ModalRevenue
        show={openModalCadPDV}
        setShow={setModalCadPDV}
        setLoading={setLoading}
        listPDV={listPDV}
        setListPDV={setListPDV}
        listEditeItem={listEditeItem}
        setListEditeItem={setListEditeItem}
        setKeyActived={setKeyActived}
      />
      <div className="mb-2 mt-4">
        <Btn
          text={"Nova categoria das receitas"}
          type="button"
          variante="primary"
          icon="plus"
          onClick={() => setModalCadPDV(true)}
        />
      </div>
      <div className="mt-4">
        {revenue?.length > 0 ? (
          revenue?.map((tree: any) => (
            <Fragment key={tree.id}>
              <TreePDV
                revenue={tree}
                OnAddNewCategorie={OnAddNewChartAccount}
                handleEdit={handleEdit}
              />
            </Fragment>
          ))
        ) : (
          <NoData />
        )}
      </div>
      {loading && <Loading />}
    </>
  );
}
