import * as S from "./styles";

interface IProps {
  state?: any;
  setState?: any;
  autoComplete?: "on" | "off";
  type: "string" | "number" | "date" | "text" | "email";
  placeholder?: string;
  maxLength?: number;
  label?: string;
  step?: string;
  min?: string;
  max?: string;
  disabled?: boolean;
  isInvalid?: boolean;
  size?: "sm" | "lg";
  onBlur?: (e: any) => void;
  onChenge?: (e: any) => void;
  as?: any;
  rows?: number;
  minWidth?: string | undefined;
}

export function InputDefault({
  state,
  setState,
  label,
  type,
  autoComplete,
  placeholder,
  maxLength,
  step,
  min,
  max,
  disabled,
  isInvalid,
  size,
  onBlur,
  onChenge,
  as,
  rows,
  minWidth,
}: IProps) {
  return (
    <label>
      <div className="label">{label}</div>
      <S.Input minWidth={minWidth}>
        <S.FormInput
          as={as}
          type={type}
          autoComplete={autoComplete}
          value={state}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={setState ? (e: any) => setState(e.target.value) : onChenge}
          step={step}
          min={min}
          max={max}
          disabled={disabled}
          isInvalid={isInvalid}
          onBlur={onBlur}
          rows={rows}
        />
      </S.Input>
    </label>
  );
}
