export interface ISelect {
  value: number;
  label: string;
}

export const objType: ISelect[] = [
  {
    label: "Inventário",
    value: 2,
  },
  {
    label: "Contagem",
    value: 1,
  },
];

export const objStatus: ISelect[] = [
  {
    label: "Contando",
    value: 1,
  },
  {
    label: "Finalizado",
    value: 2,
  },
  {
    label: "Estornado",
    value: 3,
  },
];

export const objectPeriod: ISelect[] = [
  {
    value: 1,
    label: "Todos",
  },
  {
    value: 2,
    label: "Data de cadastro",
  },
];
