import styles from "./styles.module.scss";
import { useContext, useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Format from "../../../../Services/Format";
import { reverseStr } from "../../../../Services/reverseString";

import { ExtratoContext } from "../context/extrato-bancario-context";
import tableStyleDefault from "../../../../Services/tableStyleDefault";
import tableStyleDefaultBolder from "../../../../Services/tableStyleDefaultBolder";

export default function ListExtratoBancario(props: any) {
  const { listExtratoBancario, banckAccountId, listBank, startDate, endDate } =
    useContext(ExtratoContext);

  const [banckName, setBanckName] = useState<string>("");
  const [banckAgency, setBanckAgency] = useState<string>("");
  const [banckAgencyDigit, setBanckAgencyDigit] = useState<string>("");
  const [banckAccount, setBanckAccount] = useState<string>("");
  const [banckAccountDigit, setBanckAccountDigit] = useState<string>("");
  const [banckBalance, setBanckBalance] = useState<number>(0);
  const [totalCredito, setTotalCredito] = useState<number>(0);
  const [totalDebito, setTotalDebito] = useState<number>(0);

  let amount = banckBalance;

  const getBalance = () => {
    const amountCredito: any = [];
    const amountDebito: any = [];

    for (let index = 0; index < listExtratoBancario.length; index++) {
      if (
        listExtratoBancario[index].relationships?.paymentType?.entry_type === 1
      ) {
        amountCredito.push({
          amount: listExtratoBancario[index].amount,
        });
      } else {
        amountDebito.push({
          amount: listExtratoBancario[index].amount,
        });
      }
    }

    if (amountCredito.length === 1) {
      setTotalCredito(amountCredito[0].amount);
    } else {
      setTotalCredito(
        amountCredito.reduce((a: any, b: any) => a + b.amount, 0)
      );
    }

    if (amountDebito.length === 1) {
      setTotalDebito(amountDebito[0].amount);
    } else {
      setTotalDebito(amountDebito.reduce((a: any, b: any) => a + b.amount, 0));
    }
  };

  const columns = [
    {
      name: "Data",
      center: true,
      width: "px",
      maxWidth: "120px",
      selector: (row: any) => Format.date(row?.created_at, "DD/MM/YYYY"),
    },
    {
      name: "Número",
      maxWidth: "80px",
      center: true,
      selector: (row: any) => row?.document,
    },

    {
      name: "Descrição",
      maxWidth: "220px",
      selector: (row: any) => row?.relationships?.paymentType?.description,
    },

    {
      name: "Tipo",
      maxWidth: "120px",
      center: true,
      selector: (row: any) =>
        row?.relationships?.paymentType?.entry_type === 1
          ? "Receita"
          : "Despesa",
    },

    {
      name: "Status",
      maxWidth: "100px",
      center: true,
      selector: (row: any) => {
        if (row.status === 2) {
          return <>Fechado</>;
        } else if (row.status === 1) {
          return <>Aberto</>;
        }
      },
    },

    {
      name: "Crédito",
      maxWidth: "130px",
      center: true,
      selector: (row: any) =>
        row?.relationships?.paymentType?.entry_type === 1
          ? "R$ " + row?.amount.toFixed(2)
          : "-",
    },

    {
      name: "Débito",
      maxWidth: "130px",
      center: true,
      selector: (row: any) =>
        row?.relationships?.paymentType?.entry_type === 0
          ? "R$ " + row?.amount.toFixed(2)
          : "-",
    },

    {
      name: "Saldo",
      maxWidth: "130px",
      center: true,
      selector: (row: any) =>
        row?.relationships?.paymentType?.entry_type === 1
          ? "R$ " + (amount += row.amount).toFixed(2)
          : "R$ " + (amount -= row.amount).toFixed(2),
    },
  ];

  const listTotal: any = [
    {
      total: "Total",
      date: new Date(),
      credito: totalCredito.toFixed(2),
      debito: totalDebito.toFixed(2),
      saldo: totalCredito - totalDebito,
    },
  ];

  const columnsTotal = [
    {
      center: true,
      maxWidth: "120px",
      selector: (row: any) => Format.date(row?.date, "DD/MM/YYYY"),
    },
    {
      minWidth: "400px",
      center: true,
      selector: (row: any) => row.total,
    },
    {
      maxWidth: "130px",
      center: true,
      selector: (row: any) => "R$ " + row.credito,
    },
    {
      maxWidth: "130px",
      center: true,
      selector: (row: any) => "R$ " + row.debito,
    },
    {
      maxWidth: "136px",
      center: true,
      selector: (row: any) => "R$ " + (row.saldo + banckBalance).toFixed(2),
    },
  ];

  const columnsFinal = [
    {
      center: true,
      maxWidth: "680px",
      selector: () => "Resultado geral",
    },
    {
      maxWidth: "130px",
      center: true,
      selector: () => "R$ " + totalCredito.toFixed(2),
    },
    {
      maxWidth: "130px",
      center: true,
      selector: () => "R$ " + totalDebito.toFixed(2),
    },
    {
      maxWidth: "136px",
      center: true,
      selector: () => "R$ " + (totalCredito - totalDebito).toFixed(2),
    },
  ];

  useEffect(() => {
    getBalance();
    setBanckName(
      listBank.find((option: any) => option.id === banckAccountId).bank
    );
    setBanckAgency(
      listBank.find((option: any) => option.id === banckAccountId).agency
    );
    setBanckAgencyDigit(
      listBank.find((option: any) => option.id === banckAccountId).agency_digit
    );
    setBanckAccount(
      listBank.find((option: any) => option.id === banckAccountId).account
    );
    setBanckAccountDigit(
      listBank.find((option: any) => option.id === banckAccountId).account_digit
    );
    setBanckBalance(
      listBank.find((option: any) => option.id === banckAccountId).balance
    );
  }, [listBank, listExtratoBancario]);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title className={styles.title}>Extrato Bancário</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className={styles.containerBanck}>
          {banckName ? (
            <div className={styles.bankName}>
              {banckName} -
              <span>
                Agência: {banckAgency}-{banckAgencyDigit}
              </span>
              <span>
                Conta: {banckAccount}-{banckAccountDigit}
              </span>
            </div>
          ) : (
            <div>Não possui banco cadastrado</div>
          )}
          <div>
            Período: {reverseStr(startDate)} até {reverseStr(endDate)}
          </div>
          <div>Saldo Inicial: R$ {banckBalance.toFixed(2)}</div>
        </div>
        <div className="mx-4 my-4">
          {listExtratoBancario.length > 0 ? (
            <>
              <div className={styles.containerTable}>
                <div className={styles.tableZ}>
                  <DataTable
                    className={styles.dataTable}
                    columns={columns}
                    data={listExtratoBancario || []}
                    striped
                    customStyles={tableStyleDefault}
                  />
                </div>
                <div className={styles.table}>
                  <DataTable
                    className={styles.dataTable}
                    columns={columnsTotal}
                    data={listTotal || []}
                    striped
                    customStyles={tableStyleDefaultBolder}
                  />
                </div>
              </div>
              <div className={styles.tableFinal}>
                <DataTable
                  className={styles.dataTable}
                  columns={columnsFinal}
                  data={listTotal || []}
                  striped
                  customStyles={tableStyleDefaultBolder}
                />
              </div>
            </>
          ) : (
            <Card.Text className="text-center">
              Nenhum registro encontrado para sua pesquisa.
            </Card.Text>
          )}
        </div>
      </Modal.Body>
    </>
  );
}
