import styled from "styled-components";

export const Content = styled.div`
  border: 1px solid #bfbfbf;
  cursor: pointer;

  .grid-item {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 1rem;

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr;
    }

    .img {
      border: 1px solid #bfbfbf;
      width: 180px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: italic;
    }
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    ul {
      list-style: none;
    }

    ul {
      @media screen and (max-width: 1200px) {
        padding: 1rem;
      }
      .status-active {
        color: #00b300;
        font-weight: bold;
      }

      .status-inactived {
        color: #ff4d4d;
        font-weight: bold;
      }

      .name {
        font-size: 1.2rem;
        font-weight: bolder;
        text-transform: uppercase;
        color: #404040;
      }

      li {
        .cnpj-phone {
          display: grid;
          grid-template-columns: 2fr 1fr;
          margin-bottom: 0.3rem;
        }

        .items {
          display: flex;
          flex-direction: column;
          font-size: 0.75rem;

          .title {
            font-weight: bolder;
            color: #595959;
          }
        }
      }
    }
  }
`;
