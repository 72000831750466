import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IStore } from "../inventary/interface";
import { getCstsAsync, getStateAsync, getStoreAsync } from "./services";
import { ICsts, IState } from "./interface";

interface InitialState {
  value: storeState;
}

interface storeState {
  fetchError: string;
  loading: boolean;
  stores: IStore[];
  state: IState[];
  csts: ICsts[];
}

const initialState: InitialState = {
  value: {
    loading: false,
    fetchError: "",
    stores: [],
    state: [],
    csts: [],
  } as storeState,
};

export const store = createSlice({
  name: "store",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(getStoreAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getStoreAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IStore[]>) => {
        state.value.loading = false;
        state.value.stores = action.payload;
      }
    );
    builder.addCase(
      getStoreAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );

    builder.addCase(getCstsAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getCstsAsync.fulfilled,
      (state: InitialState, action: PayloadAction<ICsts[]>) => {
        state.value.loading = false;
        state.value.csts = action.payload;
      }
    );
    builder.addCase(
      getCstsAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );
  },
});

export const {} = store.actions;

export default store.reducer;
