import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IChartAccount } from "./interface";
import { getChartAccountAsync } from "./services";
import { getEntryType } from "./utils";

interface InitialState {
  value: chartAccountState;
}

interface StateForm {
  state: "error" | "loading" | "send" | "default";
  message?: string;
}

interface chartAccountState {
  loading: boolean;
  fetchError: string;
  revenue: IChartAccount[];
  expenxe: IChartAccount[];
  stateForm: StateForm;
  keyTab: string;
}

const initialState: InitialState = {
  value: {
    loading: false,
    fetchError: "",
    revenue: [],
    expenxe: [],
    keyTab: "",
    stateForm: { state: "default" },
  } as chartAccountState,
};

export const chartAccount = createSlice({
  name: "chartAccount",
  initialState,
  reducers: {
    setKeyTab: (state: InitialState, action) => {
      state.value.keyTab = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(getChartAccountAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getChartAccountAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IChartAccount[]>) => {
        state.value.loading = false;
        state.value.revenue = getEntryType(action.payload, 1);
        state.value.expenxe = getEntryType(action.payload, 0);
      }
    );
    builder.addCase(
      getChartAccountAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );
  },
});

export const { setKeyTab } = chartAccount.actions;

export default chartAccount.reducer;
