import { useState, useContext } from "react";
import Container from "react-bootstrap/Container";
import ListaMovimEstoque from "../../../Components/Estoque/Movimentacoes/ListaMovimEstoque";
import "./styles.scss";
import IEstoque from "../../../Components/Estoque/Interface/IStock";
import { FormMovimentacaoEstoque } from "../../../Components/Estoque/Movimentacoes/CadMovimentacao/movimentacao";
import { ThemeContext } from "../../../context/theme-context";
import { IStockQuote } from "../../../Components/Estoque/Orcamento/interface/IStockQuote";

export interface IListaMovimEstoque {
  title: string;
  OnAddNewCli: (e: boolean) => void;
  onEditItem: (item: IEstoque) => void;
  OnAddNewEstoqueMovimentacao: (e: boolean) => void;
  setBudgetSelected?: any;
}

export interface IFormMovimentacaoEstoque {
  title: string;
  OnAddNewEstoqueMovimentacao: (e: boolean) => void;
  estoque?: IEstoque;
  editando: boolean;
  budgetSelected?: IStockQuote;
}

export default function CadMovimEstoque() {
  const { collapsed } = useContext(ThemeContext);
  const [budgetSelected, setBudgetSelected] = useState<any>();

  const [cad, setCad] = useState<boolean>(false);
  const [toEditMovimentacao, setToEditMovimentacao] = useState<IEstoque>();
  const [editando, setEditando] = useState<boolean>(false);

  const handleNew = (e: boolean) => {
    setEditando(false);
    setCad(e);
  };

  const handleEdit = (props: IEstoque) => {
    setToEditMovimentacao(props);
    setCad(true);
    setEditando(true);
  };

  return (
    <>
      {!cad ? (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <ListaMovimEstoque
            title="Movimentações"
            onEditItem={(item: IEstoque) => handleEdit(item)}
            OnAddNewCli={(e: boolean) => handleNew(e)}
            OnAddNewEstoqueMovimentacao={(e: boolean) => handleNew(e)}
            setBudgetSelected={setBudgetSelected}
          />
        </Container>
      ) : (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <FormMovimentacaoEstoque
            title={
              toEditMovimentacao
                ? "Editar Movimentação de Estoque"
                : "Nova Movimentação de Estoque"
            }
            OnAddNewEstoqueMovimentacao={(e: boolean) => {
              handleNew(e);
              setToEditMovimentacao(undefined);
              setBudgetSelected(undefined);
            }}
            estoque={toEditMovimentacao}
            editando={editando}
            budgetSelected={budgetSelected}
          />
        </Container>
      )}
    </>
  );
}
