import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaEdit } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import tableStyleDefault from "../../../../Services/tableStyleDefault";
import DateUtils from "../../../../Services/DateUtils";
import Format from "../../../../Services/Format";
import ActiveInactiv from "../../../data/activeInactive";
import CustomLoader from "../../../data/customLoader";
import { AppDispatch, useAppSelector } from "../../../../redux/store";
import LayoutFilter from "../../../data/LayoutFilter";
import ModalCad from "./ModalCad";
import { useDispatch } from "react-redux";
import {
  getBankAccountAsync,
  getExternalBanksAsync,
} from "../../../../redux/features/bankAccount/services";
import { paginationOptions } from "../../../data/PagineteInfo";

export default function ConsultaContasBancarias(props: any) {
  const dispatch = useDispatch<AppDispatch>();

  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const { loading, bankAccount } = useAppSelector(
    ({ externalBankReducer }) => externalBankReducer.value
  );

  const [openModalCadPDV, setModalCadPDV] = useState<boolean>(false);
  const [listEditeItem, setListEditeItem] = useState<any>();

  const handleEdit = (item: any) => {
    setListEditeItem(item);
    setModalCadPDV(true);
  };

  const columns: any = [
    {
      name: "Descrição",
      sortable: true,
      grow: 0.3,
      selector: (row: any) => row?.description?.toLowerCase(),
    },
    {
      name: "Banco",
      grow: 0.2,
      selector: (row: any) => row?.bank?.toLowerCase(),
    },
    {
      name: "Agência",
      center: true,
      grow: 0.1,
      selector: (row: any) => row?.agency,
    },
    {
      name: "Conta",
      center: true,
      grow: 0.1,
      selector: (row: any) => row?.account,
    },
    {
      name: "Saldo Real",
      center: true,
      sortable: true,
      grow: 0.1,
      selector: (row: any) =>
        `${row?.real_balance?.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })}`,
    },
    {
      name: "Saldo Previsto",
      center: true,
      sortable: true,
      grow: 0.1,
      selector: (row: any) =>
        `${row?.balance?.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })}`,
    },
    {
      name: "Data Criação",
      center: true,
      grow: 0.1,
      selector: (row: any) =>
        Format.date(DateUtils.toDate(row?.created_at), "DD/MM/YYYY"),
    },
    {
      name: "Status",
      center: true,
      grow: 0.1,
      selector: (row: any) =>
        row.is_active ? (
          <ActiveInactiv status={row?.is_active} text="Ativo" />
        ) : (
          <ActiveInactiv status={row?.is_active} text="Inativo" />
        ),
    },
    {
      name: "Ação",
      center: true,
      grow: 0.1,
      cell: (row: any) => (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">Editar</Tooltip>}
        >
          <span className="d-inline-block">
            <Button
              onClick={() => handleEdit(row)}
              variant="contained"
              color="primary"
            >
              <FaEdit size={18} color="8e8d8d" />
            </Button>
          </span>
        </OverlayTrigger>
      ),
      button: true,
    },
  ];

  useEffect(() => {
    dispatch(getBankAccountAsync(storeMe.storeActive.id));
    dispatch(getExternalBanksAsync());
  }, [dispatch]);

  return (
    <>
      <LayoutFilter title={props.Title} onClickAdd={() => setModalCadPDV(true)}>
        <div className="mt-3" />
        <DataTable
          columns={columns}
          data={bankAccount || []}
          striped
          pagination
          paginationDefaultPage={1}
          paginationPerPage={25}
          customStyles={tableStyleDefault}
          paginationComponentOptions={paginationOptions}
          progressPending={loading}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhuma informação foi encontrada."
        />
      </LayoutFilter>

      <ModalCad
        show={openModalCadPDV}
        setShow={setModalCadPDV}
        listEditeItem={listEditeItem}
        setListEditeItem={setListEditeItem}
      />

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
