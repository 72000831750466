import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 1rem 0rem;

  @media only screen and (max-width: 1100px) {
    .max-screen {
      display: none;
    }
  }

  @media only screen and (min-width: 1100px) {
    .max-screen {
      display: grid;

      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 1.2rem;

      .value-label {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .flex {
          display: flex;
        }
      }

      .initial-value {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      .final_value {
        input {
          width: 110px;
        }
      }
    }

    .min-screen {
      display: none;
    }

    label > .label {
      text-align: right;
      margin-right: 0.5rem;
    }
  }

  .label {
    font-size: 11px;
    font-weight: 500;
    text-align: left;
  }

  label {
    display: grid;
    grid-template-columns: 1fr 3fr;
    text-align: left;

    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;
      .label {
        display: none;
      }
    }
  }

  .wrapper-date {
    display: grid;
    grid-template-columns: 1fr;
  }
`;
