import globals from "../Globals.json";
import api from "./api";

export const createPaymentType = async (objChartPaymentType: any) => {
  if (objChartPaymentType.uuid) {
    return api
      .put(
        `${globals.api.paymentTypes}/${objChartPaymentType.uuid}`,
        objChartPaymentType
      )
      .then((res: any) => {
        if (res.status === 201 || res.status === 200) {
          const objCallback = {
            data: res.data.data,
            status: true,
          };
          return objCallback;
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  } else {
    return api
      .post(`${globals.api.paymentTypes}`, objChartPaymentType)
      .then((res: any) => {
        if (res.status === 201 || res.status === 200) {
          const objCallback = {
            data: res.data.data,
            status: true,
          };
          return objCallback;
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }
};

export const getPaymentType = () => {
  return api.get(`${globals.api.paymentTypes}?paginate=false`);
};
