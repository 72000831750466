// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__ULgQ5 {
  display: grid;
  gap: 5px;
  padding: 17px;
  box-shadow: 1px 1px 1px -1px rgba(164, 166, 163, 0.9803921569);
  border-radius: 5px;
  font-size: 12px;
  background-color: white;
  box-shadow: 0px 0px 17px -10px rgb(122, 122, 122);
}

.styles_titleGroup__pR73P {
  display: flex;
  justify-content: space-between;
}

.styles_btnGourp__kXIjZ {
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/Components/reactHookForm/LayoutForm/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,aAAA;EACA,8DAAA;EACA,kBAAA;EACA,eAAA;EACA,uBAAA;EAIA,iDAAA;AAAF;;AAGA;EACE,aAAA;EACA,8BAAA;AAAF;;AAGA;EACE,aAAA;EACA,8BAAA;AAAF","sourcesContent":[".container {\n  display: grid;\n  gap: 5px;\n  padding: 17px;\n  box-shadow: 1px 1px 1px -1px #a4a6a3fa;\n  border-radius: 5px;\n  font-size: 12px;\n  background-color: white;\n\n  -webkit-box-shadow: 0px 0px 17px -10px rgba(122, 122, 122, 1);\n  -moz-box-shadow: 0px 0px 17px -10px rgba(122, 122, 122, 1);\n  box-shadow: 0px 0px 17px -10px rgba(122, 122, 122, 1);\n}\n\n.titleGroup {\n  display: flex;\n  justify-content: space-between;\n}\n\n.btnGourp {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__ULgQ5`,
	"titleGroup": `styles_titleGroup__pR73P`,
	"btnGourp": `styles_btnGourp__kXIjZ`
};
export default ___CSS_LOADER_EXPORT___;
