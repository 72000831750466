import { useContext, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./styles.css";
import styles from "../FormProduto/styles.module.scss";
import ReactSelect from "../../../data/reactSelect";
import GetEndPoint from "../../../../Services/GetEndPoint";
import { ProdutoContext } from "../../../../context/product";
import OptionsReactSelect from "../../../../interfaces/OptionsReactSelect";
import Layout from "../../../data/Layout";
import { useAppSelector } from "../../../../redux/store";
import * as S from "../styled";
import { InputDefault } from "../../../data/inputDefault";

export default function InfoFiscais() {
  const {
    taxProfile,
    setTaxProfile,
    stateOrigin,
    setStateOrigin,
    stateDestiny,
    setStateDestiny,
    types,
    setTypes,
    origins,
    setOrigins,
    ncm,
    setNcm,
    cest,
    setCest,
    cfop,
    setCfop,
    customer,
    setCustomer,
    icms,
    setIcms,
    ipi,
    setIpi,
    cstReasons,
    setCstReasons,
    pis,
    setPis,
    weight,
    setWeight,
    aliquot,
    setAliquotIcms,
    mva,
    setMva,
    reduction,
    setReduction,
    icmsAliquotDeferral,
    setIcmsAliquotDeferral,
    icmsCredit,
    setIcmsCredit,
    aliquotIpi,
    setAliquotIpi,
    aliquotPis,
    setAliquotPis,
    aliquotCofins,
    setAliquotCofins,
    validNcm,
    validCfop,
    validIcms,
    validAliquot,
    validAliquotIpi,
    validIpi,
    validPis,
    validAliquotPis,
    validOrigins,
    validType,
    category,
    setCofins,
    cofins,
    setValidCofins,
    validCofins,
    setXdPrinters,
    setSelfService,
    setXdFollowItem,
  } = useContext(ProdutoContext);

  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [taxProfileList, setTaxProfileList] = useState<OptionsReactSelect[]>(
    []
  );
  const [taxProfileListCompleted, setTaxProfileListCompleted] = useState<any[]>(
    []
  );
  const [cate, setListCategory] = useState<OptionsReactSelect[]>([]);

  const [categoryListCompleted, setCategoryListCompleted] = useState<any[]>([]);
  const [statesList, setStatesList] = useState<OptionsReactSelect[]>([]);
  const [invoiceTypesList, setInvoiceTypesList] = useState<
    OptionsReactSelect[]
  >([]);
  const [cstOriginsList, setCstOriginsList] = useState<OptionsReactSelect[]>(
    []
  );
  const [ncmList, setNcmList] = useState<OptionsReactSelect[]>([]);
  const [cestList, setCestList] = useState<OptionsReactSelect[]>([]);
  const [cfopList, setCfopList] = useState<OptionsReactSelect[]>([]);
  const [icmsCustomerTypesList, setIcmsCustomerTypesList] = useState<
    OptionsReactSelect[]
  >([]);
  const [icmsList, setIcmsList] = useState<OptionsReactSelect[]>([]);
  const [ipiList, setIpiList] = useState<OptionsReactSelect[]>([]);
  const [pisList, setPisList] = useState<OptionsReactSelect[]>([]);
  const [cstReasonsList, setCstReasonsList] = useState<OptionsReactSelect[]>(
    []
  );

  const completeTaxProfileInformationCategory = () => {
    let taxProfileCategory = categoryListCompleted.find(
      (item: any) => item.id === category
    );

    taxProfileCategory && setXdPrinters(taxProfileCategory?.xd_printers);
    taxProfileCategory && setXdFollowItem(taxProfileCategory?.xd_follow_item);
    taxProfileCategory && setSelfService(taxProfileCategory?.self_service);
    let selectedTaxProfileCat = taxProfileListCompleted.find(
      (item: any) => item.id === taxProfileCategory?.tax_profile_id
    );

    setIfoFiscais(selectedTaxProfileCat, taxProfileCategory?.tax_profile_id);

    if (!category || category === 0) {
      clearInfoFicais();
    }
  };

  const completeTaxProfileInformationTax = () => {
    if (taxProfile) {
      let selectedTaxProfile = taxProfileListCompleted.find(
        (item: any) => item.id === taxProfile
      );

      setIfoFiscais(selectedTaxProfile);

      if (!taxProfile || taxProfile === 0) {
        clearInfoFicais();
      }

      !taxProfile && completeTaxProfileInformationCategory();
    } else {
      clearInfoFicais();
    }
  };

  const setIfoFiscais = (list: any, id?: number) => {
    id && setTaxProfile(id);
    setCest(list?.relationships?.cest?.id);
    setTypes(list?.relationships?.tax_invoice_type?.id);
    setOrigins(list?.relationships?.cst_origin?.id);
    setIcms(list?.relationships?.cst_icms?.id);
    setNcm(list?.relationships?.ncm?.id);
    setCfop(list?.relationships?.cfop?.id);
    setStateOrigin(list?.relationships?.icms_origin_state?.id);
    setStateDestiny(list?.relationships?.icms_destiny_state?.id);
    setCustomer(list?.relationships?.icms_customer_type?.id);
    setIpi(list?.relationships?.cst_ipi?.id);
    setCstReasons(list?.relationships?.cst_ipi_reason?.id);
    setCofins(list?.relationships?.cst_cofins?.id);
    setPis(list?.relationships?.cst_pis?.id);
    setWeight(list?.tax_weight?.toString());
    setAliquotIcms(list?.icms_aliquot?.toString());
    setMva(list?.icms_mva?.toString());
    setReduction(list?.icms_base_calc_reduction?.toString());
    setAliquotIpi(list?.ipi_aliquot?.toString());
    setAliquotPis(list?.pis_aliquot?.toString());
    setAliquotCofins(list?.cofins_aliquot?.toString());
    setIcmsAliquotDeferral(list?.icms_aliquot_deferral?.toString());
    setIcmsCredit(list?.icms_credit?.toString());
  };

  const clearInfoFicais = () => {
    setCest(undefined);
    setTypes(undefined);
    setOrigins(undefined);
    setIcms(undefined);
    setNcm(undefined);
    setCfop(undefined);
    setStateOrigin(undefined);
    setStateDestiny(undefined);
    setCustomer(undefined);
    setIpi(undefined);
    setCstReasons(undefined);
    setPis(undefined);
    setCofins(undefined);
    setWeight("");
    setAliquotIcms("");
    setMva("");
    setReduction("");
    setAliquotIpi("");
    setAliquotPis("");
    setAliquotCofins("");
    setIcmsAliquotDeferral("");
    setIcmsCredit("");
    setXdPrinters("0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0");
    setXdFollowItem(false);
    setSelfService(false);
  };

  useEffect(() => {
    GetEndPoint.categories(setListCategory, setCategoryListCompleted);
    GetEndPoint.taxProfile(setTaxProfileList, setTaxProfileListCompleted);
    GetEndPoint.state(setStatesList);
    GetEndPoint.invoiceTypes(setInvoiceTypesList);
    GetEndPoint.cstOrigin(setCstOriginsList);
    GetEndPoint.cests(setCestList);
    GetEndPoint.cfop(setCfopList);
    GetEndPoint.icmsCustomerTypes(setIcmsCustomerTypesList);
    GetEndPoint.csts(setIcmsList, setIpiList, setPisList);
    GetEndPoint.ncms(setNcmList);
    GetEndPoint.cstReasons(setCstReasonsList);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      completeTaxProfileInformationCategory();
    }, 500);
  }, [category]);

  useEffect(() => {
    setTimeout(() => {
      completeTaxProfileInformationTax();
    }, 500);
  }, [taxProfile]);

  let newTaxProfileList = taxProfileList.filter(
    (item) => item.store_id === storeMe.storeActive.id
  );

  return (
    <Layout title="Informações Fiscais">
      <Row>
        <p>
          {" "}
          Selecione um perfil fiscal para preencher automaticamente todas as
          informações fiscais do produto.
        </p>
      </Row>
      <S.Wrapper>
        <ReactSelect
          label="Perfil fiscal"
          placeholder="Perfil fiscal"
          isClearable
          options={newTaxProfileList}
          state={taxProfile}
          setState={setTaxProfile}
        />
      </S.Wrapper>
      <div className={styles.line} />
      <S.Wrapper>
        <InputDefault
          label="Peso"
          placeholder="0,00 Kg"
          type="number"
          autoComplete="off"
          step="0.01"
          min="0"
          state={weight}
          setState={setWeight}
        />

        <ReactSelect
          label="Tipo"
          placeholder="Tipo"
          isClearable
          options={invoiceTypesList}
          state={types}
          setState={setTypes}
          isInvalid={validType}
        />

        <ReactSelect
          label="Origem"
          placeholder="Selecione a origem"
          isClearable
          options={cstOriginsList}
          state={origins}
          setState={setOrigins}
          isInvalid={validOrigins}
        />

        <ReactSelect
          label="NCM"
          placeholder="Nomenclatura Comum do Mercosul"
          isClearable
          options={ncmList}
          state={ncm}
          setState={setNcm}
          isInvalid={validNcm}
        />

        <ReactSelect
          label="CEST"
          placeholder="Especificador da Substituição Tributária"
          isClearable
          options={cestList}
          state={cest}
          setState={setCest}
        />

        <ReactSelect
          label="CFOP"
          placeholder="Fiscal de Operações e Prestação"
          isClearable
          options={cfopList}
          state={cfop}
          setState={setCfop}
          isInvalid={validCfop}
        />
      </S.Wrapper>

      <div className={styles.line} />

      <Row className="mb-2">
        <Col className="spaceBetween">
          <h4>ICMS</h4>
        </Col>
      </Row>
      <S.Wrapper>
        <ReactSelect
          label="Origem"
          placeholder="UF"
          isClearable
          options={statesList}
          state={stateOrigin}
          setState={setStateOrigin}
        />

        <ReactSelect
          label="Destino"
          placeholder="UF"
          isClearable
          options={statesList}
          state={stateDestiny}
          setState={setStateDestiny}
        />

        <ReactSelect
          label="Tipo de cliente"
          placeholder="Selecione o cliente"
          isClearable
          options={icmsCustomerTypesList}
          state={customer}
          setState={setCustomer}
        />

        <ReactSelect
          label="Sit. tributária"
          placeholder="Selecione a situação tributária"
          isClearable
          options={icmsList}
          state={icms}
          setState={setIcms}
          isInvalid={validIcms}
        />

        <InputDefault
          label="Alíquota"
          placeholder="0,00"
          type="number"
          autoComplete="off"
          step="0.01"
          min="0"
          state={aliquot}
          setState={setAliquotIcms}
          isInvalid={validAliquot}
        />

        <InputDefault
          label="MVA"
          placeholder="0,00"
          type="number"
          autoComplete="off"
          step="0.01"
          min="0"
          state={mva}
          setState={setMva}
        />

        <InputDefault
          label="Diferimento"
          placeholder="0,00"
          type="number"
          autoComplete="off"
          step="0.01"
          min="0"
          state={icmsAliquotDeferral}
          setState={setIcmsAliquotDeferral}
        />

        <InputDefault
          label="Cálc. de crédito"
          placeholder="0,00"
          type="number"
          autoComplete="off"
          step="0.01"
          min="0"
          state={icmsCredit}
          setState={setIcmsCredit}
        />

        <InputDefault
          label="Red. de calculo"
          placeholder="0,00"
          type="number"
          autoComplete="off"
          step="0.01"
          min="0"
          state={reduction}
          setState={setReduction}
        />
      </S.Wrapper>

      <div className={styles.line} />

      <Row className="mb-2">
        <Col className="spaceBetween">
          <h4>IPI</h4>
        </Col>
      </Row>

      <S.Wrapper>
        <ReactSelect
          label="Sit.o tributária"
          placeholder="Situação tributária"
          isClearable
          options={ipiList}
          state={ipi}
          setState={setIpi}
          isInvalid={validIpi}
        />

        <InputDefault
          label="Aliquota IPI"
          placeholder="0,00"
          type="number"
          autoComplete="off"
          step="0.01"
          min="0"
          state={aliquotIpi}
          setState={setAliquotIpi}
          isInvalid={validAliquotIpi}
        />

        <ReactSelect
          label="Enq. de IPI"
          placeholder="Enquadramento de ipi"
          isClearable
          options={cstReasonsList}
          state={cstReasons}
          setState={setCstReasons}
        />
      </S.Wrapper>

      <div className={styles.line} />

      <Row className="mb-2">
        <Col className="spaceBetween">
          <h4>PIS e COFINS</h4>
        </Col>
      </Row>

      <S.Wrapper>
        <ReactSelect
          label="Sit. tributária"
          placeholder="Situação tributária"
          isClearable
          options={pisList}
          state={pis}
          setState={setPis}
          isInvalid={validPis}
        />

        <InputDefault
          label="Aliq. PIS"
          placeholder="0,00"
          type="number"
          autoComplete="off"
          step="0.01"
          min="0"
          state={aliquotPis}
          setState={setAliquotPis}
          isInvalid={validAliquotPis}
        />

        <ReactSelect
          label="Sit. tributária"
          placeholder="Situação tributária"
          isClearable
          options={pisList}
          state={cofins}
          setState={setCofins}
          isInvalid={validCofins}
        />

        <InputDefault
          label="Aliq. COFINS"
          placeholder="0,00"
          type="number"
          autoComplete="off"
          step="0.01"
          min="0"
          state={aliquotCofins}
          setState={setAliquotCofins}
        />
      </S.Wrapper>
    </Layout>
  );
}
