import React, { useState, useEffect, useContext } from "react";
import styles from "./styles.module.scss";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Format from "../../../../Services/Format";
import { getReceiver } from "../../../../Services/people-service";
import { getBankAccounts } from "../../../../Services/bank-accounts";
import { getPaymentType } from "../../../../Services/payment-type";
import { ToastContainer } from "react-toastify";
import { notify, notifyError } from "../../../notify/notify-component";
import Loading from "../../../Loading/Loading";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";
import { getChartAccount } from "../../../../Services/chart-accounts-service";
import { createFinancialTransaction } from "../../../../Services/financial-transaction";
import { StoreContext } from "../../../../context/store-context";
import { useAppSelector } from "../../../../redux/store";
import {
  chargebackFinancialLauchTransactionAsync,
  getfinancialTransactionAsync,
} from "../../../../redux/features/financialTransaction/services";

export default function CadUndo(props: any) {
  const store = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [loading, setLoading] = useState<boolean>(false);
  const [amountInstallments, setAmountInstallments] = useState<number>(0);
  const [paymentTypeId, setPaymentTypeId] = useState<number>(0);
  const [banckAccountId, setBanckaccountId] = useState<number>(0);
  const [chartAccountId, setChartAccountId] = useState<number>(0);
  const [note, setNote] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [receiver, setReceiver] = useState<number>(0);
  const [document, setDocument] = useState<string>("");
  const [amountValue, setAmountValue] = useState<number>(0);
  const [pendingValue, setPendingValue] = useState<number>(0);
  const [categories, setCategories] = useState<number>(0);

  const [paymentDate, setPaymentDate] = useState<string>(
    Format.date(new Date(), "YYYY-MM-DD")
  );
  const [dueDate, setDueDate] = useState<string>(
    Format.date(new Date(), "YYYY-MM-DD")
  );

  const [listReceiver, setListReceiver] = useState<Array<any>>([]);
  const [listBankaccount, setListBankAccount] = useState<Array<any>>([]);
  const [listPaymentType, setListPaymentType] = useState<Array<any>>([]);
  const [listChartAccount, setListChartAccount] = useState<Array<any>>([]);

  const [numberInstallments, setNumberInstallments] = useState<number>(0);
  const [totalInstallments, setTotalInstallments] = useState<any[]>([]);
  const [number, setNumber] = useState<number | undefined>();
  const [discount, setDiscount] = useState<number | undefined>();
  const [interest, setInterest] = useState<number | undefined>();

  const fillReceiver = async () => {
    await getReceiver().then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

      let list = data.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setListReceiver(list);
    });
  };

  const fillBankAccounts = async () => {
    await getBankAccounts().then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

      let list = data.map((item: any) => ({
        value: item.id,
        label: item.description,
      }));
      setListBankAccount(list);
    });
  };

  const fillChartAccount = async () => {
    await getChartAccount().then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

      const filterRevenue = data.filter(
        (revenue: any) => revenue.entry_type === props.EntryType
      );

      let list = filterRevenue.map((item: any) => ({
        value: item.id,
        label: item.description,
      }));
      setListChartAccount(list);
    });
  };

  const fillPaymentType = async () => {
    await getPaymentType().then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

      const filterPaymentType = data.filter(
        (paymentType: any) => paymentType.entry_type === props.EntryType
      );

      let list = filterPaymentType.map((item: any) => ({
        value: item.id,
        label: item.description,
      }));
      setListPaymentType(list);
    });
  };

  async function handleSubmit() {
    setLoading(true);
    try {
      props
        .dispatch(
          chargebackFinancialLauchTransactionAsync(props.undoTransacao?.uuid)
        )
        .then((result: any) => {
          if (
            chargebackFinancialLauchTransactionAsync.fulfilled.match(result)
          ) {
            props.dispatch(getfinancialTransactionAsync(""));
            props.onHide();
          } else {
            notifyError("Erro no lançamento.");
          }
        });
    } catch (error) {
      notifyError("Erro no Estorno");
    }
    setLoading(false);
  }

  useEffect(() => {
    fillReceiver();
    fillPaymentType();
    fillBankAccounts();
    fillChartAccount();

    if (props.undoTransacao) {
      setDescription(props.undoTransacao?.description);
      setReceiver(props.undoTransacao?.receiver_people_id);
      setDocument(props.undoTransacao?.document_description);
      setAmountValue(props.undoTransacao?.amount);
      setPendingValue(props.undoTransacao?.pending_amount);
      setPaymentTypeId(props.undoTransacao?.payment_type_id);
      setBanckaccountId(props.undoTransacao?.bank_account_id);
      setDueDate(props.undoTransacao?.due_date);
      setChartAccountId(props.undoTransacao?.chart_account_id);
      setNote(props.undoTransacao?.note);
      setNumber(props.undoTransacao?.installment_number);
      setAmountInstallments(props.undoTransacao?.amount);
      setCategories(props.undoTransacao?.financial_transaction_category_id);

      setInterest(props.undoTransacao.interest);
      setDiscount(props.undoTransacao.discount);
    }
  }, [props.undoTransacao]);

  return (
    <>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton className={`${styles.modalHeader}`}>
          <Modal.Title className="fs-3">{props.Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "12px" }}>
          <Form className={styles.form}>
            <Row>
              <Col md="12" className="mb-1 d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Descrição :{" "}
                </label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    disabled={true}
                    size="sm"
                    autoComplete="off"
                    type="text"
                    name="description"
                    placeholder="Descrição"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md="12" className="mb-1 d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Favorecido :{" "}
                </label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    disabled={true}
                    size="sm"
                    autoComplete="off"
                    type="text"
                    value={
                      receiver && listReceiver?.length > 0
                        ? listReceiver?.filter(
                            (option: any) => option.value === receiver
                          )?.[0]?.label
                        : ""
                    }
                  />
                </Col>
              </Col>
            </Row>

            <Row style={{ marginLeft: "4.1rem" }}>
              <Col md="5" className="mb-1 d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Número :{" "}
                </label>

                <Form.Control
                  className="fs-7"
                  disabled={true}
                  size="sm"
                  autoComplete="off"
                  type="text"
                  name="numero doc."
                  placeholder="REC-000"
                  min="0"
                  onChange={(e) => setDocument(e.target.value)}
                  value={document}
                />
              </Col>
              {number && (
                <Col md="5" className="mb-1 d-flex align-items-center">
                  <label
                    className="px-1"
                    style={{ width: "150px", textAlign: "right" }}
                  >
                    NºParcela :
                  </label>

                  <Form.Control
                    className="fs-7"
                    disabled={true}
                    size="sm"
                    autoComplete="off"
                    type="text"
                    name="numero parcela."
                    placeholder="REC-000"
                    min="0"
                    value={number}
                  />
                </Col>
              )}
            </Row>

            <Row>
              <Col md="7" className="mb-1 d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Total :{" "}
                </label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    type="number"
                    step="0.1"
                    placeholder="0,00"
                    min="0"
                    disabled={true}
                    size="sm"
                    value={amountValue}
                  />
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md="7" className="mb-1  d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Pendente :{" "}
                </label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    type="number"
                    step="0.1"
                    placeholder="0,00"
                    min="0"
                    disabled={true}
                    size="sm"
                    value={pendingValue}
                  />
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md="7" className="mb-1 d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Valor :{" "}
                </label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    disabled={true}
                    size="sm"
                    autoComplete="off"
                    type="number"
                    name="tax"
                    step="0.1"
                    placeholder="0,00"
                    min="0"
                    onChange={(e) =>
                      setAmountInstallments(Number.parseFloat(e.target.value))
                    }
                    value={amountInstallments}
                  />
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md="7" className="mb-1 d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Juros :{" "}
                </label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    disabled={true}
                    size="sm"
                    autoComplete="off"
                    type="number"
                    name="interest"
                    min="0.0"
                    placeholder="0.0%"
                    step="0.1"
                    onChange={(e) => setInterest(parseFloat(e.target.value))}
                    value={interest}
                  />
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md="7" className="mb-1 d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Desconto :{" "}
                </label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    disabled={true}
                    size="sm"
                    autoComplete="off"
                    type="number"
                    name="discount"
                    min="0.0"
                    placeholder="0.0%"
                    step="0.1"
                    onChange={(e) => setDiscount(parseFloat(e.target.value))}
                    value={discount}
                  />
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md="12" className="mb-1 d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  {props.Label} :{" "}
                </label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    disabled={true}
                    size="sm"
                    autoComplete="off"
                    type="text"
                    name={props.Label}
                    placeholder={props.Placeholder}
                    min="0"
                    value={
                      paymentTypeId && listPaymentType?.length > 0
                        ? listPaymentType.filter(
                            (option: any) => option.value === paymentTypeId
                          )?.[0]?.label
                        : ""
                    }
                  />
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md="12" className="mb-1 d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Conta :{" "}
                </label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    disabled={true}
                    size="sm"
                    autoComplete="off"
                    type="text"
                    name="Conta"
                    placeholder="Conta"
                    min="0"
                    value={
                      banckAccountId && listBankaccount?.length > 0
                        ? listBankaccount.filter(
                            (option: any) => option.value === banckAccountId
                          )?.[0]?.label
                        : ""
                    }
                  />
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md="12" className="mb-1 d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Plano de Contas :{" "}
                </label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    disabled={true}
                    size="sm"
                    autoComplete="off"
                    type="text"
                    name="Plano de Conta"
                    placeholder="Plano de Conta"
                    value={
                      chartAccountId && listChartAccount?.length > 0
                        ? listChartAccount?.filter((option: any) =>
                            option.value === chartAccountId
                              ? option.value === chartAccountId
                              : [0]
                          )?.[0]?.label
                        : ""
                    }
                  />
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md="12" className="mb-1  d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Data de Recebimentoto :{" "}
                </label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    disabled={true}
                    size="sm"
                    type="date"
                    name="data vencimento"
                    placeholder="dd/mm/aa"
                    value={paymentDate}
                    onChange={(e) => setPaymentDate(e.target.value)}
                  />
                </Col>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="mb-1  d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Data de Vencimento :{" "}
                </label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    disabled={true}
                    size="sm"
                    type="date"
                    name="data vencimento"
                    placeholder="dd/mm/aa"
                    value={dueDate}
                    onChange={(e) => setDueDate(e.target.value)}
                  />
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md="12" className="mb-1  d-flex align-items-center">
                <Form.Label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                  disabled={true}
                >
                  Observação :{" "}
                </Form.Label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    as="textarea"
                    rows={2}
                    disabled={true}
                    value={note}
                    onChange={(e: any) => setNote(e.target.value)}
                  />
                </Col>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer
          className={`${styles.footer} d-flex justify-content-center gap-20 py-4`}
        >
          <Button variant="primary" onClick={props.onHide} size="sm">
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSubmit} size="sm">
            Estornar
          </Button>
        </Modal.Footer>
      </Modal>

      {loading && <Loading />}

      <ToastContainer
        position="top-center"
        autoClose={300}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
}
