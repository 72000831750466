import { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Format from "../../../../Services/Format";
import { FaEye } from "react-icons/fa";
import { RiArrowGoBackFill } from "react-icons/ri";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../../Services/api";
import Globals from "../../../../Globals.json";
import DateUtils from "../../../../Services/DateUtils";
import { IListaMovimEstoque } from "../../../../Pages/Cadastro/CadMovimEstoque";
import IEstoque from "../../Interface/IStock";
import LayoutFilter from "../../../data/LayoutFilter";
import ReactSelect from "../../../data/reactSelect";
import { InputDefault } from "../../../data/inputDefault";
import LayoutTable from "../../../data/LayoutTable";
import GetEndPoint from "../../../../Services/GetEndPoint";
import { sortByCreated } from "../../../../Services/sortByCreated";
import tableStyleDefault from "../../../../Services/tableStyleDefault";
import { notify, notifyError } from "../../../notify/notify-component";
import { format, parse } from "date-fns";
import { getStockableProducts } from "../../../../Services/product-service";
import { Modal } from "react-bootstrap";
import { TableRanking } from "../../../../Services/TableRanking";
import { useAppSelector } from "../../../../redux/store";
import { IStore } from "../../../../redux/features/userMe/interface";
import { NoData } from "../../../noData";
import CustomLoader from "../../../data/customLoader";
import ActiveInactiv from "../../../data/activeInactive";
import * as S from "./styled";

export default function ConsultaMovimentacoes({
  title,
  OnAddNewCli,
  onEditItem,
  setBudgetSelected,
}: IListaMovimEstoque) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [movement, setMovement] = useState<Array<any>>([]);

  const [storeDestinationId, setStoreDestinationId] = useState<number>();
  const [budgetId, setBudgetId] = useState<number>();
  const [listStores, setListStores] = useState<Array<any>>([]);
  const [type_date, setTypeDate] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const [vendor_id, setVendorId] = useState<string>("");
  const [movementType, setMovementType] = useState<string>("");
  const [product, setProduct] = useState<string>("");
  const [stock, setStock] = useState<number>(0);
  const [operationType, setOperationType] = useState<string>("");
  const [startDate, setStartDate] = useState<string>(
    Format.firstDate("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(
    Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD")
  );
  const [movementStartDate, setMovementStartDate] = useState<string>(
    Format.firstDate("YYYY-MM-DD")
  );
  const [movementEndDate, setMovementEndDate] = useState<string>(
    Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD")
  );
  const [origin, setOrigin] = useState<string>("");
  const [lastUuidMoviment, setLastUuidMoviment] = useState<string>("");
  const [status, setStatus] = useState<number>(0);

  const [listVendor, setListVendor] = useState<Array<any>>([]);
  const [listMovementType, setListMovementType] = useState<Array<any>>([]);
  const [listProduct, setListProduct] = useState<Array<any>>([]);
  const [listStock, setListStock] = useState<Array<any>>([]);
  const [budgetStock, setBudgeStock] = useState<Array<any>>([]);
  const [listOperationType, setListOperationType] = useState<Array<any>>([]);

  const [validDateIni, setValidDateIni] = useState<boolean>(false);
  const [validDateEnd, setValidDateEnd] = useState<boolean>(false);
  const [modalChargeBack, setModalChargeBack] = useState<boolean>(false);
  const [chargeBackPaid, setChargeBackPaid] = useState<boolean>(false);
  const [modalQuotes, setModalQuotes] = useState<boolean>(false);
  const [orderDate, setOrderDate] = useState<boolean>(false);
  const [orderAmount, setOrderAmount] = useState<boolean>(false);
  const [orderDocument, setOrderDocument] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const oOperationType = [
    {
      id: "entrada",
      value: "Entrada",
    },
    {
      id: "saida",
      value: "Saída",
    },
  ];

  const oOSituationStatus = [
    {
      value: 0,
      label: "Todos",
    },
    {
      value: 1,
      label: "Realizado",
    },
    {
      value: 2,
      label: "Estornado",
    },
  ];

  const listdate = [
    { value: 0, label: "Todos" },
    { value: 1, label: "Data da Movimentação" },
  ];

  const handleEdit = (row: IEstoque) => {
    onEditItem(row);
  };

  const isLater = (start: string, end: string) => {
    return start > end;
  };

  const handleDateStart = async (date: string) => {
    if (!date) return;
    if (date.length !== 10) {
      setValidDateIni(false);
      return;
    }
    if (type_date === 2) {
      setStartDate(date);
    } else {
      setMovementStartDate(date);
    }
    let dateValid = isLater(date, endDate);
    setValidDateIni(dateValid);
    if (dateValid) {
      return;
    }
  };

  const handleDateEnd = async (date: string) => {
    setEndDate(date);
    setMovementEndDate(date);
  };

  const clearFields = () => {
    setStoreDestinationId(0);
    setProduct("");
    setType("");
    setOrigin("");
    setOperationType("");
    setStock(0);
    setVendorId("");
    setMovementType("");
    setStatus(0);
    setStartDate(Format.firstDate("YYYY-MM-DD"));
    setEndDate(Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD"));
    setMovementStartDate(Format.firstDate("YYYY-MM-DD"));
    setMovementEndDate(Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD"));
    setTypeDate(0);
    getMovement();
  };

  const createFilter = (filterList: Array<string>): string => {
    let filter = "";
    for (let i = 0; i < filterList.length; i++) {
      if (i >= 1) {
        filter += "&";
      }
      filter += `filter${filterList[i]}`;
    }
    return filter;
  };

  const handleSearchList = () => {
    if (validDateIni || validDateEnd) return;
    let filterList = [];
    if (type !== "") filterList.push(`[type]=${type}`);
    if (stock) filterList.push(`[stock_id]=${stock}`);
    if (vendor_id) filterList.push(`[vendor_id]=${vendor_id}`);
    if (movementType)
      filterList.push(`[stock_movement_type_id]=${movementType}`);
    if (operationType) filterList.push(`[operation_type]=${operationType}`);
    if (origin !== "") filterList.push(`[origin]=${origin}`);
    if (status !== 0) filterList.push(`[situation_id]=${status}`);
    if (type_date === 1) {
      filterList.push(`[movement_dt_starts_at]=${movementStartDate}`);
      filterList.push(`[movement_dt_ends_at]=${movementEndDate}`);
    }
    if (type_date === 2) {
      filterList.push(`[starts_at]=${startDate}`);
      filterList.push(`[ends_at]=${endDate}`);
    }
    if (filterList.length > 0) {
      const filter = createFilter(filterList);
      if (filter !== "") {
        getMovement(filter);
      }
      return;
    }
    getMovement();
  };

  const ColumnBtnClassification = (name: string, handleClassification: any) => {
    return (
      <OverlayTrigger
        overlay={<Tooltip id="tooltip-disabled">Classificar</Tooltip>}
      >
        <span onClick={handleClassification} style={{ cursor: "pointer" }}>
          {name}
        </span>
      </OverlayTrigger>
    );
  };

  const handleOrderDate = () => {
    setOrderDate(!orderDate);
    if (orderDate) {
      const des = "paginate=false&orderBy=created_at&order=desc";
      getMovement(des);
    } else {
      const asc = "paginate=false&orderBy=created_at&order=asc";
      getMovement(asc);
    }
  };

  const columns = [
    {
      name: "Código",
      center: true,
      grow: 0.3,
      sortable: true,
      selector: (row: any) => row?.id,
    },
    {
      name: "Documento",
      center: true,
      grow: 0.3,
      sortable: true,
      selector: (row: any) => row?.document_stock?.toLowerCase() ?? "---",
    },
    {
      name: "Tipo de Movimentação",
      sortable: true,
      selector: (row: any) =>
        row?.relationships?.stock_movement_type?.description?.toLowerCase(),
    },

    {
      name: "Operação",
      center: true,
      grow: 0.3,
      selector: (row: any) => row?.relationships?.stock_movement_type?.type,
    },
    {
      name: "Valor",
      center: true,
      sortable: true,
      grow: 0.3,
      selector: (row: any) =>
        Format.currency(row.relationships.financial_transaction?.amount ?? 0),
    },
    {
      name: "Data e Hora",
      center: true,
      //sortable: true,
      grow: 0.4,
      selector: (row: any) => {
        if (row.created_at) {
          const formattedDateString = Format.date(
            row.created_at,
            "DD/MM/YYYY HH:mm:ss"
            //"DD/MM/YYYY "
          );

          const dataHoraLocal = parse(
            formattedDateString,
            "dd/MM/yyyy HH:mm:ss",
            //"dd/MM/yyyy",
            new Date()
          );

          const formattedDate = format(dataHoraLocal, "dd/MM/yy - HH:mm:ss");
          return formattedDate;
        } else {
          return "Não há dados";
        }
      },
    },
    {
      name: "Status",
      center: true,
      grow: 0.3,
      selector: (row: any) => {
        if (row.relationships.situation?.description === "Realizado") {
          return (
            <ActiveInactiv
              status={
                row.relationships.stock_quote_situation?.description ===
                "Realizado"
                  ? true
                  : false
              }
              text="Realizado"
              color="#319628"
            />
          );
        } else {
          return (
            <ActiveInactiv
              status={
                row.relationships.stock_quote_situation?.description ===
                "Estornado"
                  ? true
                  : false
              }
              color="#e04b46"
              text="Estornado"
            />
          );
        }
      },
    },

    {
      name: "Ação",
      center: true,
      grow: 0.3,
      cell: (row: any) => (
        <>
          {row.uuid === lastUuidMoviment && (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">
                  {row.relationships.situation.id === 2
                    ? "Já estornado"
                    : "Estornar"}
                </Tooltip>
              }
            >
              <span className="d-inline-block ">
                <Button
                  className="border-0"
                  onClick={() =>
                    handleChargeBackConfirmation(
                      row?.relationships?.financial_transaction?.relationships
                        ?.entries
                    )
                  }
                  variant="contained"
                  color="primary"
                  disabled={row.relationships.situation.id === 2}
                >
                  <RiArrowGoBackFill size={18} color="8e8d8d" />
                </Button>
              </span>
            </OverlayTrigger>
          )}

          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">Visualizar</Tooltip>}
          >
            <span className="d-inline-block">
              <Button
                onClick={() => handleEdit(row)}
                variant="contained"
                color="primary"
              >
                <FaEye size={18} color="8e8d8d" />
              </Button>
            </span>
          </OverlayTrigger>
        </>
      ),
      button: true,
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Registros por página",
    rangeSeparatorText: "de",
  };

  const handleAdd = () => {
    OnAddNewCli(true);
  };
  const onClickAddAlternative = () => {
    setModalQuotes(true);
  };

  const fillOperationType = async () => {
    let list = oOperationType.map((item: any) => ({
      value: item.id,
      label: item.value,
    }));
    setListOperationType(list);
  };

  const filterProducts = (data: IEstoque[], productId: number): IEstoque[] => {
    return data.filter((item) =>
      item.relationships.products.some(
        (product) => product.product_id === productId
      )
    );
  };

  const handleChargeBackConfirmation = (entries: any) => {
    if (entries?.length > 0) {
      setChargeBackPaid(true);
      setModalChargeBack(true);
    } else {
      setModalChargeBack(true);
    }
  };

  const handleChargeBackAsyncBoth = async () => {
    setLoading(true);
    try {
      await api.put(
        Globals.api.movements + "/" + lastUuidMoviment + "/chargeback"
      );

      notify("Movimentação Estornada.");

      getMovement();
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      notifyError(`Erro ao estornar ${error}`);
      setLoading(false);
    }
    setModalChargeBack(false);
  };

  const handleChargeBackAsyncOnlyStock = async () => {
    setLoading(true);
    try {
      const responseMov = await api.put(
        Globals.api.movements + "/" + lastUuidMoviment + "/chargeback"
      );

      const dataFinancial =
        responseMov?.data?.data?.relationships?.financial_transaction;
      const oModel = {
        ...dataFinancial,
        status: 1,
      };
      await api.put(
        `${Globals.api.financialTransaction}/${dataFinancial.uuid}`,
        oModel
      );

      notify("Movimentação Estornada.");

      getMovement();
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      notifyError(`Erro ao estornar ${error}`);
      setLoading(false);
    }
    setModalChargeBack(false);
  };

  const handleSelectedBudget = () => {
    const selected = budgetStock?.find((item: any) => item.id === budgetId);
    setBudgetSelected(selected);
    OnAddNewCli(true);
  };

  const handleCancelModalBudget = () => {
    setModalQuotes(false);
    setBudgetId(0);
  };

  const getMovement = (filter?: string) => {
    const uri =
      filter !== undefined
        ? `?${filter}&store_id=${storeMe.storeActive.id}`
        : `?paginate=false&orderBy=created_at&order=desc&store_id=${storeMe.storeActive.id}`;

    setLoading(true);
    api
      .get(Globals.api.movementsStocks + `${uri}`)
      .then((response) => {
        const data = response.data.data;
        const filterData = data.filter(
          (item: any) =>
            item?.relationships?.stock_movement_type?.description?.toLowerCase() !==
            "entrada por cadastro"
        );

        if (product) {
          let newDate = filterProducts(filterData, parseInt(product));
          setMovement(newDate);
        } else {
          let lastedCreatedArray = filterData;
          let lastedItem = lastedCreatedArray[0];
          setLastUuidMoviment(lastedItem.uuid);
          setMovement(filterData);
        }
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  async function handleSelectStock(id: string) {
    setStock(parseInt(id));
  }

  const fillProducts = async () => {
    setLoading(true);
    await getStockableProducts().then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);
      const products = data.filter(
        (item: any) => item.stock_local_id === stock
      );

      setListProduct(products);

      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 300);
    });
  };

  const onClickOpenFilter = () => {
    setOpenFilter(!openFilter);
  };

  useEffect(() => {
    if (storeDestinationId) {
      GetEndPoint.stocks(setListStock, storeDestinationId);
      setType("");
      setOrigin("");
      setOperationType("");
      setStock(0);
      setVendorId("");
      setMovementType("");
      setStatus(0);
      setStartDate(Format.firstDate("YYYY-MM-DD"));
      setEndDate(Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD"));
      setMovementStartDate(Format.firstDate("YYYY-MM-DD"));
      setMovementEndDate(Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD"));
      setTypeDate(0);
      getMovement();
    }
  }, [storeDestinationId]);

  useEffect(() => {
    getMovement();

    GetEndPoint.movimentTypes(setListMovementType);
    GetEndPoint.vendor(setListVendor);
    GetEndPoint.store(setListStores);
    GetEndPoint.budgetCompleted(setBudgeStock);

    fillOperationType();
  }, []);

  useEffect(() => {
    fillProducts();
  }, [stock]);

  useEffect(() => {
    TableRanking.byStockFinancialAmmount(orderAmount, setMovement, movement);
  }, [orderAmount]);

  useEffect(() => {
    TableRanking.byStockFinancialId(orderDocument, setMovement, movement);
  }, [orderDocument]);

  let newListVendor = listVendor.filter(
    (item) => item.store_id === storeMe.storeActive.id
  );

  let newBudgeStock = budgetStock.filter(
    (item) => item.store_id === storeMe.storeActive.id
  );

  return (
    <>
      <LayoutFilter
        title={title}
        onClickAdd={handleAdd}
        onClickAddAlternative={onClickAddAlternative}
        altBtnText="Orçamento"
        onClickClear={clearFields}
        onClickSearch={handleSearchList}
        onClickOpenFilter={onClickOpenFilter}
        openFilter={openFilter}
      >
        <S.Wrapper>
          {openFilter && (
            <div className="min-screen">
              <ReactSelect
                label="Loja"
                state={storeDestinationId}
                placeholder="Loja"
                options={
                  storeMe &&
                  storeMe?.user?.relationships?.stores?.map((item: IStore) => ({
                    value: item.id,
                    label: item.trading_name || item.company_name,
                  }))
                }
                setState={setStoreDestinationId}
              />

              <ReactSelect
                label="Loc. Estoque"
                state={stock}
                onChange={(e: any) => handleSelectStock(e?.value)}
                placeholder="Loc. Estoque"
                options={listStock}
                isDisabled={!storeDestinationId}
              />

              <ReactSelect
                label="Fornecedor"
                placeholder="Fornecedor"
                state={vendor_id}
                setState={setVendorId}
                options={newListVendor}
              />

              <ReactSelect
                label="Operação"
                placeholder="Operação"
                state={operationType}
                setState={setOperationType}
                options={listOperationType}
              />

              <ReactSelect
                label="Tip. Mov."
                placeholder="Tip. Movimentação"
                state={movementType}
                setState={setMovementType}
                options={listMovementType}
              />

              <ReactSelect
                label="Produto"
                placeholder="Produto"
                state={product}
                setState={setProduct}
                options={listProduct.map((item: any) => ({
                  value: item.uuid,
                  label: item.purchase_name,
                  unit: item.relationships?.sale_measure_unit?.name,
                }))}
              />

              <ReactSelect
                label="Status"
                state={status}
                setState={setStatus}
                options={oOSituationStatus}
              />

              <ReactSelect
                label="Período"
                state={type_date}
                options={listdate}
                isInvalid={false}
                setState={setTypeDate}
              />

              <InputDefault
                label="Dé"
                type="date"
                disabled={type_date === 0}
                state={type_date === 2 ? startDate : movementStartDate}
                onChenge={(e: any) => handleDateStart(e.target.value)}
                isInvalid={validDateIni}
              />

              <InputDefault
                label="Até"
                type="date"
                disabled={type_date === 0}
                state={type_date === 2 ? endDate : movementEndDate}
                onChenge={(e: any) => handleDateEnd(e.target.value)}
              />
            </div>
          )}

          <div className="max-screen">
            <ReactSelect
              label="Loja"
              state={storeDestinationId}
              options={
                storeMe &&
                storeMe?.user?.relationships?.stores?.map((item: IStore) => ({
                  value: item.id,
                  label: item.trading_name || item.company_name,
                }))
              }
              setState={setStoreDestinationId}
            />

            <ReactSelect
              label="Loc. Estoque"
              state={stock}
              onChange={(e: any) => handleSelectStock(e?.value)}
              options={listStock}
              isDisabled={!storeDestinationId}
            />

            <ReactSelect
              label="Fornecedor"
              state={vendor_id}
              setState={setVendorId}
              options={newListVendor}
            />

            <ReactSelect
              label="Operação"
              state={operationType}
              setState={setOperationType}
              options={listOperationType}
            />

            <ReactSelect
              label="Tip. Mov."
              state={movementType}
              setState={setMovementType}
              options={listMovementType}
            />

            <ReactSelect
              label="Produto"
              state={product}
              setState={setProduct}
              options={listProduct.map((item: any) => ({
                value: item.uuid,
                label: item.purchase_name,
                unit: item.relationships?.sale_measure_unit?.name,
              }))}
            />

            <ReactSelect
              label="Status"
              state={status}
              setState={setStatus}
              options={oOSituationStatus}
            />

            <ReactSelect
              label="Período"
              state={type_date}
              options={listdate}
              isInvalid={false}
              setState={setTypeDate}
            />

            <InputDefault
              label="Dé"
              type="date"
              disabled={type_date === 0}
              state={type_date === 2 ? startDate : movementStartDate}
              onChenge={(e: any) => handleDateStart(e.target.value)}
              isInvalid={validDateIni}
            />

            <InputDefault
              label="Até"
              type="date"
              disabled={type_date === 0}
              state={type_date === 2 ? endDate : movementEndDate}
              onChenge={(e: any) => handleDateEnd(e.target.value)}
            />
          </div>
        </S.Wrapper>
      </LayoutFilter>

      <LayoutTable>
        <DataTable
          columns={columns}
          data={movement || []}
          striped
          pagination
          paginationDefaultPage={1}
          paginationPerPage={25}
          customStyles={tableStyleDefault}
          paginationComponentOptions={paginationOptions}
          progressPending={loading}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhuma informação foi encontrada."
        />
      </LayoutTable>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Modal show={modalChargeBack} onHide={() => setModalChargeBack(false)}>
        <Modal.Header closeButton style={{ backgroundColor: "#ced0d4f4" }}>
          <h3 style={{ fontSize: "18px" }}>Confirmação</h3>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#f7f7f8f4" }}>
          {chargeBackPaid ? (
            <p>
              Movimentação financeira atrelada a esta movimentação já foi
              liquidada. O estorno da movimentação será realizado, no entanto, é
              necessário realizar manualmente o estorno da transação financeira.
            </p>
          ) : (
            <p>
              Deseja cancelar a movimentação financeira atrelada a esta
              movimentação?
            </p>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: "#f7f7f8f4",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="secondary"
            onClick={() => setModalChargeBack(false)}
            size="sm"
          >
            Cancelar
          </Button>
          <div style={{ display: "flex", gap: "10px" }}>
            {!chargeBackPaid && (
              <Button
                variant="danger"
                onClick={handleChargeBackAsyncOnlyStock}
                size="sm"
                style={{ padding: "4px 20px" }}
              >
                Não
              </Button>
            )}
            <Button
              variant="success"
              onClick={handleChargeBackAsyncBoth}
              size="sm"
              style={{ padding: "4px 20px" }}
            >
              Sim
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={modalQuotes} onHide={handleCancelModalBudget}>
        <Modal.Header closeButton style={{ backgroundColor: "#ced0d4f4" }}>
          <h3 style={{ fontSize: "18px" }}>Utilizar Orçamento</h3>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#f7f7f8f4" }}>
          <p>
            Selecione um orçamento para ser utilizado como base para a criação
            de uma movimentação de estoque:
          </p>
          <S.Wrapper>
            <ReactSelect
              label="Orçamento"
              state={budgetId}
              placeholder="Orçamento"
              options={sortByCreated(newBudgeStock).map((item: any) => ({
                value: item.id,
                label: item?.description,
              }))}
              setState={setBudgetId}
            />
          </S.Wrapper>

          <span style={{ fontSize: "10px" }}>
            As informações base para uma movimentação de estoque restantes serão
            preenchidas a seguir.
          </span>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: "#f7f7f8f4",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="secondary"
            onClick={handleCancelModalBudget}
            size="sm"
          >
            Cancelar
          </Button>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              variant="success"
              onClick={handleSelectedBudget}
              size="sm"
              style={{ padding: "4px 20px" }}
            >
              Avançar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
