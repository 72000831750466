import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IBankAccount, IExternalBank } from "./interface";
import { getBankAccountAsync, getExternalBanksAsync } from "./services";
import { sortByCreated } from "../../../Services/sortByCreated";

interface InitialState {
  value: BankAccountState;
}

interface StateForm {
  state: "error" | "loading" | "send" | "default";
  message?: string;
}

interface BankAccountState {
  loading: boolean;
  fetchError: string;
  stateForm: StateForm;
  bankAccount: IBankAccount[];
  externalBanks: IExternalBank[];
}

const initialState: InitialState = {
  value: {
    loading: false,
    fetchError: "",
    bankAccount: [],
    externalBanks: [],
    stateForm: { state: "default" },
  } as BankAccountState,
};

export const bankAccount = createSlice({
  name: "bankAccount",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(getBankAccountAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getBankAccountAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IBankAccount[]>) => {
        state.value.loading = false;
        state.value.bankAccount = sortByCreated(action.payload);
      }
    );
    builder.addCase(
      getBankAccountAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );

    builder.addCase(getExternalBanksAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getExternalBanksAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IExternalBank[]>) => {
        state.value.loading = false;
        state.value.externalBanks = action.payload;
      }
    );
    builder.addCase(
      getExternalBanksAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );
  },
});

export const {} = bankAccount.actions;

export default bankAccount.reducer;
