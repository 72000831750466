import { Button, ButtonGroup, Col } from "react-bootstrap";
import * as S from "./styles";
import { Dispatch, SetStateAction } from "react";

interface IProps {
  gender: string;
  setGender: Dispatch<SetStateAction<string>>;
}

export function BtnGroupGender({ gender, setGender }: IProps) {
  return (
    <S.RowGroup>
      <Col md="4">
        <ButtonGroup>
          <Button
            onClick={() => setGender("m")}
            variant={gender === "m" ? "primary" : "light"}
            active={gender === "m" ? true : false}
          >
            Masculino
          </Button>
          <Button
            onClick={() => setGender("f")}
            variant={gender === "f" ? "primary" : "light"}
            active={gender === "f" ? true : false}
          >
            Feminino
          </Button>
        </ButtonGroup>
      </Col>
    </S.RowGroup>
  );
}
