import { useState, useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import { IStore } from "../../../Components/Configuracao/interface/store";
import { FormStore } from "../../../Components/Dashboard/Representative/Store/FormStore";
import { ThemeContext } from "../../../context/theme-context";
import GetEndPoint from "../../../Services/GetEndPoint";
import globals from "../../../Globals.json";
import { ListStore } from "../../../Components/Configuracao/Store/ListStore";

export interface IListStore {
  onEditItem: (item: IStore) => void;
}

export interface IFormStore {
  title: string;
  OnAddNewStore: (e: boolean) => void;
  store?: any;
  editando: boolean;
  selectOptions?: any;
}

export interface ISelectOptions {}

export default function CadStore() {
  const { collapsed } = useContext(ThemeContext);
  const [cad, setCad] = useState<boolean>(false);
  const [toEditStore, setToEditStore] = useState<{}>({});
  const [editando, setEditando] = useState<boolean>(false);

  const handleNew = (e: boolean) => {
    setCad(e);
    setEditando(false);
  };

  const handleEdit = (props: IStore) => {
    setToEditStore(props);
    setCad(true);
    setEditando(true);
  };

  return (
    <>
      {!cad ? (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <ListStore onEditItem={(item: IStore) => handleEdit(item)} />
        </Container>
      ) : (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <FormStore
            title={"Editar Loja"}
            OnAddNewStore={(efinancial: boolean) => {
              handleNew(efinancial);
            }}
            store={toEditStore}
            editando={editando}
          />
        </Container>
      )}
    </>
  );
}
