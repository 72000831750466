import api from "../../Services/api";
import { LoginResponse, SignInData } from "./interfaces";
import globals from "./../../Globals.json";

export class LoginApi {
  private static instance: LoginApi;

  public static getInstance(): LoginApi {
    if (!LoginApi.instance) {
      LoginApi.instance = new LoginApi();
    }
    return LoginApi.instance;
  }

  async auth(data: SignInData) {
    try {
      const response = await api.post<LoginResponse>(globals.api.login, data);

      if ((response as any)?.response?.data.error) {
        throw (response as any).response.data.error;
      }

      api.defaults.headers[
        "Authorization"
      ] = `Bearer ${response.data.access_token}`;

      localStorage.setItem("@token", response.data.access_token);

      localStorage.setItem("user", JSON.stringify(response.data.user));

      localStorage.setItem(
        "acitvedStore",
        JSON.stringify(response.data.user.relationships.stores[0])
      );

      return response.data as LoginResponse;
    } catch (err: any) {
      if (err?.response.data.error) {
        throw err.response.data.error;
      }

      throw err;
    }
  }
}
