import { Col, Form, Row } from "react-bootstrap";
import styles from "./styles.module.scss";
import { Btn } from "../Btn";

interface ILayoutFilter {
  children: any;
  title: string;
  onClickAdd?: () => void;
  onClickClear?: () => void;
  onClickSearch?: any;
  onClickAddAlternative?: () => void;
  altBtnText?: string;
  textAdd?: string;
  onClickOpenFilter?: any;
  openFilter?: boolean;
}

export default function LayoutFilter({
  children,
  title,
  onClickAdd,
  onClickClear,
  onClickSearch,
  textAdd,
  altBtnText,
  onClickAddAlternative,
  onClickOpenFilter,
  openFilter,
}: ILayoutFilter) {
  return (
    <div className={styles.container}>
      <Row>
        <Col className={styles.titleGroup}>
          <h3>{title}</h3>
          <div style={{ display: "flex", gap: "1rem" }}>
            {onClickOpenFilter && (
              <div className={styles.btnFilter}>
                <Btn
                  text="Filtro"
                  type="button"
                  variante="info"
                  icon={"arrow"}
                  onClick={onClickOpenFilter}
                />
              </div>
            )}
            {onClickAddAlternative && (
              <Btn
                text={altBtnText ? altBtnText : ""}
                type="button"
                variante="info"
                icon={"plus"}
                onClick={onClickAddAlternative}
              />
            )}

            {onClickAdd && (
              <Btn
                text={textAdd ? textAdd : "Adicionar"}
                type="button"
                variante="primary"
                icon={"plus"}
                onClick={onClickAdd}
              />
            )}
          </div>
        </Col>
      </Row>
      <div className="mt-2" />
      {children}
      {onClickClear && onClickSearch && (
        <Row className="mt-2">
          <Col className={styles.btnGourp}>
            <div className={styles.btn}>
              <Btn
                text="Limpar"
                type="button"
                variante="secondary"
                icon="trash"
                onClick={onClickClear}
              />
              <Btn
                text="Buscar"
                type={"button"}
                variante="success"
                icon="search"
                onClick={onClickSearch}
              />
            </div>
            {openFilter && (
              <div className={styles.btnMin}>
                <Btn
                  text="Limpar"
                  type="button"
                  variante="secondary"
                  icon="trash"
                  onClick={onClickClear}
                />
                <Btn
                  text="Buscar"
                  type={"button"}
                  variante="success"
                  icon="search"
                  onClick={onClickSearch}
                />
              </div>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
}
