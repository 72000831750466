import globals from "../Globals.json";
import api from "./api";

export const getEmployees = () => {
  return api.get(`${globals.api.people}/type/employee`);
};

export const getReceiver = () => {
  return api.get(`${globals.api.people}?paginate=false`);
};

//?paginate=false
