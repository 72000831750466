import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 55vh;

  img {
    width: auto;
    height: 400px;
    opacity: 70%;
  }

  p {
    font-size: 13px;
    font-weight: bold;
  }
`;
