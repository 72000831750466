import * as S from "./styles";

export default function CustomLoader() {
  return (
    <S.Content>
      <div style={{ padding: "24px" }}>
        <S.Spinner />
      </div>
    </S.Content>
  );
}
