import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { ToastContainer } from "react-toastify";
import Layout from "../../data/Layout";
import { Button, Col, Form, Row } from "react-bootstrap";
import ReactSelect from "../../data/reactSelect";
import GetEndPoint from "../../../Services/GetEndPoint";
import LayoutTable from "../../data/LayoutTable";
import DataTable from "react-data-table-component";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import Globals from "../../../Globals.json";
import api from "../../../Services/api";
import Format from "../../../Services/Format";
import { notify, notifyError } from "../../notify/notify-component";
import tableStyleDefault from "../../../Services/tableStyleDefault";
import { InputDefault } from "../../data/inputDefault";
import LayoutFilter from "../../data/LayoutFilter";
import CustomLoader from "../../data/customLoader";
import { useAppSelector } from "../../../redux/store";
import * as S from "./styled";

export default function TabelaPreco() {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const ref = useRef<any>();
  const refInputs = useRef<Array<any>>([]);

  const [option, setOption] = useState("sell");

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState<number>(0);
  const [current_page, setCurrent_page] = useState<number>(0);
  const paginationOptions = {
    rowsPerPageText: "Registros por página",
    rangeSeparatorText: "de",
  };

  const [categoriaSelecionada, setCategoriaSelecionada] = useState("");
  const [nameProductCurrent, setNameProductCurrent] = useState("");
  const [current_id_row, setCurrent_id_row] = useState<number>(0);
  const [clearRows, setClearRows] = useState<boolean>(false);

  const [venda, setVenda] = useState("");
  const [custo, setCusto] = useState("");
  const [markupPrice, setMarkupPrice] = useState("");
  const [markupPercentil, setMarkupPercentil] = useState("");

  const [categoriesList, setCategoriesList] = useState<Array<any>>([]);
  const [productsList, setProductsList] = useState<Array<any>>([]);
  const [productsAllList, setProductsAllList] = useState<Array<any>>([]);
  const [listName, setListName] = useState<Array<any>>([]);
  const [sellDisabled, setSellDisabled] = useState<boolean>(true);
  const [markupPriceDisabled, setMarkupPriceDisabled] = useState<boolean>(true);
  const [markupPercentDisabled, setMarkupPercentDisabled] =
    useState<boolean>(true);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const handlePreco = (e: any, row: any) => {
    let preco = e.target.value;
    setCurrent_id_row(row.id);

    if (preco === undefined || preco.toString() === "NaN") {
      preco = 0;
    }

    productsList.forEach((x) => {
      if (x.id === row.id) {
        x.sale_price = preco;
      }
    });
    setProductsList([...productsList]);
  };

  const handleOnKeyPress = (e: any, row: any) => {
    if (e.key === "Enter") {
      let id = parseInt(e.target.id);

      if (id < perPage) {
        refInputs.current[id].focus();
      }

      if ((row?.cost_price ?? 0) > 0) {
        row.sale_price = parseFloat(
          row.sale_price.toString().replace(",", ".")
        );

        if ((row?.markup_value ?? 0) !== 0) {
          row.markup_value = row.sale_price - row.cost_price;
        }

        if ((row?.markup_percentil ?? 0) !== 0) {
          let resto = row.sale_price - row.cost_price;
          row.markup_percentil = ((resto / row.cost_price) * 100).toFixed(2);
        }
      }

      AtualizarPrecoProd(row);
      setCurrent_id_row(0);
    } else {
      let id = parseInt(e.target.id);

      if (id < perPage) {
        refInputs.current[id].focus();
      }

      if ((row?.cost_price ?? 0) > 0) {
        row.sale_price = parseFloat(
          row.sale_price.toString().replace(",", ".")
        );

        if ((row?.markup_value ?? 0) !== 0) {
          row.markup_value = row.sale_price - row.cost_price;
        }

        if ((row?.markup_percentil ?? 0) !== 0) {
          let resto = row.sale_price - row.cost_price;
          row.markup_percentil = ((resto / row.cost_price) * 100).toFixed(2);
        }
      }

      AtualizarPrecoProd(row);
      setCurrent_id_row(0);
    }
  };

  const addRefs = (el: any) => {
    if (el) {
      refInputs.current = refInputs.current.filter(
        (x) => parseInt(x.id) < parseInt(el.id)
      );
    }

    if (el && !refInputs.current.includes(el)) {
      refInputs.current.push(el);
    }
  };

  const handleInputPrice = (e: any) => {
    let valor = e.target.value.replace(/[^0-9,]+/g, "");
    setVenda(valor);
  };
  const handleInputCost = (e: any) => {
    let valor = e.target.value.replace(/[^0-9,]+/g, "");
    setCusto(valor);
  };
  const handleInputMarkupPrice = (e: any) => {
    let valor = e.target.value.replace(/[^0-9,]+/g, "");
    setMarkupPrice(valor);
  };
  const handleInputMarkupPerc = (e: any) => {
    let valor = e.target.value.replace(/[^0-9,]+/g, "");
    setMarkupPercentil(valor);
  };

  const onBlurInputPrice = (e: any) => {
    let valor = e.target.value.replace(/[^0-9,]+/g, "");
    if (custo !== "" && markupPercentil !== "") {
      setVenda(
        (
          (parseFloat(custo.replace(/[^0-9,]+/g, "")) *
            parseFloat(markupPercentil.replace(/[^0-9,]+/g, ""))) /
            100 +
          parseFloat(custo.replace(/[^0-9,]+/g, ""))
        )
          .toFixed(2)
          .toString()
          .replace(".", ",")
      );
    } else if (custo !== "" && markupPrice !== "") {
      setVenda(
        (parseFloat(custo) + parseFloat(markupPrice))
          .toFixed(2)
          .toString()
          .replace(".", ",")
      );
    } else if (parseFloat(valor)) {
      setVenda(
        parseFloat(valor.replace(",", ".")).toFixed(2).replace(".", ",")
      );
    } else {
      setVenda("");
    }
  };

  const clearFields = () => {
    setClearRows(true);
    setSelectedRows([]);
    limparCamposMassa();
  };

  const limparCamposMassa = () => {
    setMarkupPercentil("");
    setCusto("");
    setMarkupPrice("");
    setVenda("");
  };

  const handleName = (names: any) => {
    setNameProductCurrent(names[0]?.name ?? "");
    getProducts(categoriaSelecionada, 1, names[0]?.name ?? "");
  };

  const handleCategoria = (e: any) => {
    setCategoriaSelecionada(e);
    getProducts(e, 1, nameProductCurrent);
  };
  const fillProperties = (data: any) => {
    const meta = data.meta;
    const perpage =
      data.data.length < meta.per_page ? data.data.length : meta.per_page;
    setCurrent_page(meta.current_page);
    setPerPage(perpage);
    setTotalRows(meta.total);
    setProductsList([...data.data]);
  };

  const handlePageChange = (page: any) => {
    getProducts(categoriaSelecionada, page, nameProductCurrent);
  };

  const handleRowsChange = useCallback((state: any) => {
    setSelectedRows(state.selectedRows);
    setClearRows(false);

    if (state.selectedRows.length === 0) {
      limparCamposMassa();
    }
  }, []);
  const searchNames = (name: any) => {
    if (name.length >= 3) {
      setListName(
        productsAllList
          .filter((x) =>
            x.sale_name
              .toString()
              .toLowerCase()
              .includes(name.toString().toLowerCase())
          )
          .map((item: any) => ({
            id: item.id,
            name: item.sale_name,
          }))
      );
    }
  };

  const getProducts = (catSelecionada: any, page: any, nameProduct: string) => {
    let path: string = `?sort=sale_name&paginate=false&store_id=${storeMe.storeActive.id}`;

    if (catSelecionada)
      path = path + `&filter[product_category_id]=${catSelecionada}`;
    if (nameProduct) path = path + `&filter[product_name]=${nameProduct}`;

    setLoading(true);
    api
      .get(Globals.api.products + path)
      .then((response) => {
        setProductsList(response.data.data);
      })
      .catch((error) => {
        notifyError(`Ocorreu um erro ao obter produtos.`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAllProducts = () => {
    let path: string = `?paginate=false&store_id=${storeMe.storeActive.id}`;

    setLoading(true);
    api
      .get(Globals.api.products + path)
      .then((response) => {
        setProductsAllList(response.data.data);
      })
      .catch((error) => {
        notifyError(`Ocorreu um erro ao obter produtos.`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const AtualizarMassa = () => {
    let price = parseFloat(venda !== "" ? venda.replace(",", ".") : "");
    let mPrice = parseFloat(
      markupPrice !== "" ? markupPrice.replace(",", ".") : ""
    );
    let mPerc = parseFloat(
      markupPercentil !== "" ? markupPercentil.replace(",", ".") : "0"
    );

    setLoading(true);
    selectedRows.forEach((x: any, index) => {
      let data = {
        uuid: x.uuid,
        store_id: x.store_id,
        sale_price: price
          ? price
          : mPrice
          ? mPrice + x.sale_price
          : x.sale_price / (1 - mPerc / 100),
        cost_price: x.cost_price,
        markup_value: mPrice ? mPrice : 0,
        markup_percentil: mPerc ? mPerc : 0,
        sale_name: x.sale_name,
      };
      UpdateProdMassa(data);
    });

    setLoading(false);
    notify("Produtos atualizados com sucesso!");
    clearFields();
    getAllProducts();
    setTimeout(() => {
      getProducts(categoriaSelecionada, current_page, nameProductCurrent);
    }, 2000);
  };
  const AtualizarStatusProd = (row: any, staus: boolean) => {
    let data = {
      uuid: row.uuid,
      store_id: row.store_id,
      is_active: staus,
    };
    UpdateProd(data);

    productsList.forEach((x) => {
      if (x.id === row.id) {
        x.is_active = staus;
      }
    });
    setProductsList([...productsList]);
  };

  const AtualizarPrecoProd = (row: any) => {
    let data = {
      uuid: row.uuid,
      store_id: row.store_id,
      sale_price: row.sale_price,
      ...(row.markup_value && { markup_value: row.markup_value }),
      ...(row.markup_percentil && { markup_percentil: row.markup_percentil }),
    };

    UpdateProd(data);
  };

  const UpdateProdMassa = (produto: any) => {
    api
      .put(Globals.api.products + `/${produto.uuid}`, produto)
      .catch((error: Error) => {
        notifyError(
          `Não foi possivel atualizar o produto: ${produto.sale_name}`
        );
        setTimeout(() => {}, 500);
      });
  };

  const UpdateProd = (produto: any) => {
    api
      .put(Globals.api.products + `/${produto.uuid}`, produto)
      .then((response) => {
        if (response.status === 200) {
          notify("Produto atualizado com sucesso");
          setTimeout(() => {}, 500);
        } else {
          notifyError("Não foi possivel atualizar o produto");
          setTimeout(() => {}, 500);
        }
      })
      .catch((error: Error) => {
        notifyError(`Ocorreu um erro ${error.message}`);
      });
  };

  const columns = [
    {
      name: "Código",
      center: true,
      grow: 0.2,
      sortable: true,
      selector: (row: any) => Number(row.code),
    },
    {
      name: "Descrição",
      center: false,
      sortable: true,
      selector: (row: any) => row.sale_name.toLowerCase() ?? 0,
    },
    {
      name: "Custo",
      center: true,
      grow: 0.3,
      sortable: true,
      selector: (row: any) =>
        row.cost_price.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      name: "Preço",
      center: true,
      grow: 0.3,
      sortable: true,
      cell: (row: any, inp: any) => (
        <div>
          <Form.Control
            className="fs-8"
            type="number"
            autoComplete="off"
            value={
              (current_id_row ?? 0) === row.id ? row.sale_price : row.sale_price
            }
            name="sale_price"
            maxLength={16}
            id={inp + 1}
            ref={addRefs}
            onChange={(e: any) => handlePreco(e, row)}
            //onKeyPress={(e: any) => handleOnKeyPress(e, row)}
            onBlur={(e: any) => handleOnKeyPress(e, row)}
          />
        </div>
      ),
    },
    {
      name: "Markup R$",
      center: true,
      grow: 0.3,
      selector: (row: any) =>
        row.markup_value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      name: "Markup %",
      center: true,
      grow: 0.3,
      selector: (row: any) => row.markup_percentil + " %",
    },
    {
      name: "Status",
      center: true,
      grow: 0.3,
      cell: (row: any) => (
        <div className="actions">
          <Form.Check
            className="check"
            type="switch"
            label={row.is_active ? "Ativo" : "Inativo"}
            checked={row.is_active ? true : false}
            placeholder={row.is_active ? "Ativo" : "Inativo"}
            onChange={(e: any) => AtualizarStatusProd(row, e.target.checked)}
          />
        </div>
      ),
      button: true,
    },
  ];

  function calcMarkup(markUp: number, priceSale: number, markupPrice: number) {
    const calc =
      ((priceSale + markupPrice) * markUp) / 100 + (priceSale + markupPrice);

    if (calc === Infinity || !calc) {
      return "";
    }
    return calc.toFixed(2);
  }

  function calcMarkupValue(priceSale: number, priceCost: number) {
    const calc = priceSale + priceCost;
    if (calc === Infinity || !calc) {
      return "";
    }
    return calc.toFixed(2);
  }

  const handleChangeMarkupPriceValues = () => {
    if (parseFloat(markupPrice) > 0) {
      setVenda(calcMarkupValue(parseFloat(markupPrice), parseFloat(custo)));
    }
  };

  const handleChangeMarkupValues = () => {
    if (parseFloat(markupPercentil) > 0) {
      setVenda(
        calcMarkup(
          parseFloat(markupPercentil),
          parseFloat(custo),
          parseFloat(markupPrice)
        )
      );
    }
  };

  const handleRadiu = (event: string) => {
    setOption(event);
    switch (event) {
      case "sell":
        setVenda(venda);
        setMarkupPrice(markupPrice);
        setMarkupPrice(markupPrice);
        setMarkupPriceDisabled(true);
        setMarkupPercentDisabled(true);
        setSellDisabled(false);
        break;

      case "markupPrice":
        setVenda(venda);
        setMarkupPrice(markupPrice);
        setMarkupPrice(markupPrice);
        setMarkupPriceDisabled(false);
        setMarkupPercentDisabled(true);
        setSellDisabled(true);
        break;

      case "markupPercent":
        setVenda(venda);
        setMarkupPriceDisabled(true);
        setMarkupPercentDisabled(false);
        setSellDisabled(true);
        break;
      default:
        break;
    }
  };

  const handleRadiuPrice = (event: string) => {
    setOption(event);
    switch (event) {
      case "price":
        setSellDisabled(false);
        setMarkupPercentDisabled(true);
        setMarkupPriceDisabled(true);
        break;
      default:
        break;
    }
  };

  const handleSearchList = () => {};

  const onClickOpenFilter = () => {
    setOpenFilter(!openFilter);
  };

  useEffect(() => {
    if (productsList.length === 0) {
      getProducts(categoriaSelecionada, 1, nameProductCurrent);
      getAllProducts();
    }
  }, []);

  useEffect(() => {
    GetEndPoint.categories(setCategoriesList);
  }, []);

  useEffect(() => {
    handleChangeMarkupPriceValues();
  }, [markupPrice]);

  useEffect(() => {
    handleChangeMarkupValues();
  }, [markupPercentil]);

  return (
    <>
      <LayoutFilter
        title="Tabela de preços"
        onClickClear={clearFields}
        onClickSearch={handleSearchList}
        onClickOpenFilter={onClickOpenFilter}
        openFilter={openFilter}
      >
        <S.Wrapper>
          {openFilter && (
            <div className="min-screen">
              <ReactSelect
                label="Categoria"
                placeholder="Categoria"
                options={categoriesList}
                onChange={handleCategoria}
              />

              <ReactSelect
                label="Produto"
                placeholder="Produto"
                onChange={handleName}
                options={listName}
              />
            </div>
          )}

          <div className="max-screen">
            <ReactSelect
              label="Categoria"
              options={categoriesList}
              onChange={handleCategoria}
            />

            <ReactSelect
              label="Produto"
              onChange={handleName}
              options={listName}
            />
          </div>
        </S.Wrapper>
      </LayoutFilter>
      {productsList.length > 0 && (
        <Layout>
          <Form>
            <Row className="">
              <Row>
                <Col md="8">
                  <S.Wrapper>
                    <div className="max-screen">
                      <InputDefault
                        label="Venda (R$)"
                        type="text"
                        autoComplete="off"
                        state={venda}
                        placeholder="Informe o Preço"
                        maxLength={18}
                        onChenge={(e: any) => handleInputPrice(e)}
                        onBlur={(e: any) => onBlurInputPrice(e)}
                        disabled={sellDisabled}
                      />
                    </div>
                    <div className="min-screen">
                      <InputDefault
                        label="Venda (R$)"
                        type="text"
                        autoComplete="off"
                        state={venda}
                        placeholder="Informe o Preço"
                        maxLength={18}
                        onChenge={(e: any) => handleInputPrice(e)}
                        onBlur={(e: any) => onBlurInputPrice(e)}
                        disabled={sellDisabled}
                      />
                    </div>
                  </S.Wrapper>
                </Col>
                <Col md="4">
                  <label className=" mx-3">Atualizar preço em massa:</label>
                  {["radio"].map((type: any) => (
                    <div key={`inline-${type}`} className="mb-3 mx-3">
                      <Form.Check
                        inline
                        label="Preço de venda"
                        type={type}
                        id="price"
                        value={option}
                        checked={option === "price"}
                        disabled={selectedRows.length ? false : true}
                        onChange={(e: any) => handleRadiuPrice("price")}
                      />
                    </div>
                  ))}
                </Col>
              </Row>

              <Col md="8">
                <S.Wrapper>
                  <div className="max-screen">
                    <InputDefault
                      label="Markup (R$)"
                      type="text"
                      autoComplete="off"
                      state={markupPrice}
                      placeholder="Informe o Markup (R$)"
                      maxLength={18}
                      onChenge={(e: any) => handleInputMarkupPrice(e)}
                      disabled={markupPriceDisabled}
                    />
                    <InputDefault
                      label="Markup (%)"
                      type="text"
                      autoComplete="off"
                      state={markupPercentil}
                      placeholder="Informe o Markup (%)"
                      maxLength={18}
                      onChenge={(e: any) => handleInputMarkupPerc(e)}
                      disabled={markupPercentDisabled}
                    />
                  </div>
                  <div className="min-screen">
                    <InputDefault
                      label="Markup (R$)"
                      type="text"
                      autoComplete="off"
                      state={markupPrice}
                      placeholder="Informe o Markup (R$)"
                      maxLength={18}
                      onChenge={(e: any) => handleInputMarkupPrice(e)}
                      disabled={markupPriceDisabled}
                    />
                    <InputDefault
                      label="Markup (%)"
                      type="text"
                      autoComplete="off"
                      state={markupPercentil}
                      placeholder="Informe o Markup (%)"
                      maxLength={18}
                      onChenge={(e: any) => handleInputMarkupPerc(e)}
                      disabled={markupPercentDisabled}
                    />
                  </div>
                </S.Wrapper>
              </Col>

              <Col md="3">
                <label className="mb-2">Cálcular por:</label>
                {["radio"].map((type: any) => (
                  <div key={`inline-${type}`} className="">
                    <Form.Check
                      inline
                      label="Markup (R$)"
                      name="group1"
                      type={type}
                      id="MarkupR"
                      value={option}
                      checked={option === "markupPrice"}
                      disabled={selectedRows.length ? false : true}
                      onChange={(e: any) => handleRadiu("markupPrice")}
                    />
                    <Form.Check
                      inline
                      label="Markup (%)"
                      name="group1"
                      type={type}
                      id="MarkupP"
                      value={option}
                      checked={option === "markupPercent"}
                      disabled={selectedRows.length ? false : true}
                      onChange={(e: any) => handleRadiu("markupPercent")}
                    />
                  </div>
                ))}
              </Col>

              <Col className="mt-4 ">
                <Button
                  variant="success"
                  size="sm"
                  disabled={selectedRows.length ? false : true}
                  onClick={AtualizarMassa}
                >
                  Atualizar
                </Button>
              </Col>
            </Row>
          </Form>
        </Layout>
      )}

      <LayoutTable>
        <DataTable
          columns={columns}
          data={productsList || []}
          onChangePage={handlePageChange}
          onSelectedRowsChange={handleRowsChange}
          clearSelectedRows={clearRows}
          selectableRows
          striped
          pagination
          paginationDefaultPage={1}
          paginationPerPage={25}
          customStyles={tableStyleDefault}
          paginationComponentOptions={paginationOptions}
          progressPending={loading}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhuma informação foi encontrada."
        />
      </LayoutTable>

      <ToastContainer
        position="top-center"
        autoClose={300}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
}
