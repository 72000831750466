import { useEffect, useState } from "react";
import { Row, Form, Col } from "react-bootstrap/";
import { ToastContainer } from "react-toastify";
import Loading from "../../../Loading/Loading";
import "./styles.scss";
import "react-toastify/dist/ReactToastify.css";
import { notify, notifyError } from "../../../notify/notify-component";
import { createStockAdjust } from "../../../../Services/stock-adjust-service";
import { getStocks } from "../../../../Services/stock-service";
import {
  getProductStockQty,
  getStockableProducts,
} from "../../../../Services/product-service";
import Layout from "../../../data/Layout";
import ReactSelect from "../../../data/reactSelect";
import { InputDefault } from "../../../data/inputDefault";
import GetEndPoint from "../../../../Services/GetEndPoint";
import { ButtonGroup, Button } from "react-bootstrap";
import { IFormMovimentacaoEstoque } from "../../../../Pages/Cadastro/CadAjusteEstoque";
import { useAuth } from "../../../../context/Auth";
import { useAppSelector } from "../../../../redux/store";
import * as S from "../../styled";

export function FormAjusteEstoque({
  title,
  OnAddNewEstoqueMovimentacao,
  editando,
  estoque,
}: IFormMovimentacaoEstoque) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [storeDestinationId, setStoreDestinationId] = useState<number>();
  const [listStores, setListStores] = useState<Array<any>>([]);
  const [disableActualQty, setDisableActualQty] = useState<boolean>(true);
  const [note, setNote] = useState<string>("");
  const [productId, setProductId] = useState<number>();
  const [stockQuoteSituationId, setStockQuoteSituationId] = useState<number>(1);

  const [stock, setStock] = useState<number>();
  const [uuid, setUuid] = useState<string>("");
  const [qty, setQty] = useState<string>("");
  const [actualQty, setActualQty] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [listProducts, setListProducts] = useState<Array<any>>([]);
  const [listProductsCode, setListProductsCode] = useState<Array<any>>([]);
  const [listProductsBarCode, setListProductsBarCode] = useState<Array<any>>(
    []
  );
  const [listStocks, setListStocks] = useState<Array<any>>([]);

  const [validStore, setValidStore] = useState<boolean>(false);
  const [validProduct, setValidProduct] = useState<boolean>(false);
  const [validQty, setValidQty] = useState<boolean>(false);
  const [validNote, setValidNote] = useState<boolean>(false);

  const fillProductsByStock = async () => {
    setLoading(true);
    await getStockableProducts().then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

      let product = "";

      if (stockQuoteSituationId === 1) {
        product = "purchase_name";
      }

      if (stockQuoteSituationId === 2) {
        product = "code";
      }

      if (stockQuoteSituationId === 3) {
        product = "barcode";
      }

      let list: any = {};
      let listArray: any = [];
      data.map((item: any) => {
        if (item.stock_local_id === stock) {
          list = {
            value: item.id,
            uuid: item.uuid,
            label: item?.purchase_name,
          };
          listArray.push(list);
        }
      });

      let listCode: any = {};
      let listArrayCode: any = [];
      data.map((item: any) => {
        if (item.stock_local_id === stock) {
          listCode = {
            value: item.id,
            uuid: item.uuid,
            label: item?.code,
          };
          listArrayCode.push(listCode);
        }
      });

      let listBarCode: any = {};
      let listArrayBarCode: any = [];
      data.map((item: any) => {
        if (item.stock_local_id === stock) {
          listBarCode = {
            value: item.id,
            uuid: item.uuid,
            label: item?.barcode,
          };
          listArrayBarCode.push(listBarCode);
        }
      });
      setTimeout(() => {
        setListProducts(listArray);
        setListProductsCode(listArrayCode);
        setListProductsBarCode(listArrayBarCode);
        setLoading(false);
      }, 300);
    });
  };

  const fillProductsByStore = async () => {
    setLoading(true);
    await getStockableProducts().then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);
      let product = "";

      if (stockQuoteSituationId === 1) {
        product = "purchase_name";
      }

      if (stockQuoteSituationId === 2) {
        product = "code";
      }

      if (stockQuoteSituationId === 3) {
        product = "barcode";
      }

      let list: any = {};
      let listArray: any = [];
      data.map((item: any) => {
        if (item.store_id === storeDestinationId) {
          list = {
            value: item.id,
            uuid: item.uuid,
            label: item?.purchase_name,
          };
          listArray.push(list);
        }
      });

      let listCode: any = {};
      let listArrayCode: any = [];
      data.map((item: any) => {
        if (item.store_id === storeDestinationId) {
          listCode = {
            value: item.id,
            uuid: item.uuid,
            label: item?.code,
          };
          listArrayCode.push(listCode);
        }
      });

      let listBarCode: any = {};
      let listArrayBarCode: any = [];
      data.map((item: any) => {
        if (item.store_id === storeDestinationId) {
          listBarCode = {
            value: item.id,
            uuid: item.uuid,
            label: item?.barcode,
          };
          listArrayBarCode.push(listBarCode);
        }
      });

      setTimeout(() => {
        setListProducts(listArray);
        setListProductsCode(listArrayCode);
        setListProductsBarCode(listArrayBarCode);
        setLoading(false);
      }, 300);
    });
  };

  async function handleSelectStock(id: string) {
    if (!id) {
      setStock(0);
      return;
    }
    clearFilds();
    setLoading(true);
    setStock(parseInt(id));
    getStocks().then((res: any) => {
      res.data.data.map((objStock: any) => {
        if (objStock.id === parseInt(id)) {
          setUuid(objStock.uuid);
        }
      });
    });
    setStock(parseInt(id));
  }

  async function handleSelectStore(id: string) {
    clearFilds();
    setStoreDestinationId(parseInt(id));
    fillProductsByStore();
  }

  function handleSelectProduct(e: any) {
    setLoading(true);
    setProductId(e.value);

    getStocks().then((res: any) => {
      res.data.data.map((objStock: any) => {
        if (objStock.id === e.stock_local_id) {
          setUuid(objStock.uuid);
        }
      });
    });

    getProductStockQty(e?.uuid).then((response: any) => {
      setActualQty(
        response.data.data.stock_qty ? response.data.data.stock_qty : 0
      );
    });
    setDisableActualQty(false);

    setLoading(false);
  }

  function handleSubmit() {
    const valid = handleValidete();

    if (valid === false) {
      notifyError("Por favor preencha todos os campos obrigatórios.");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }

    const oModel = {
      store_id: storeMe.storeActive.id,
      uuid: uuid,
      note: note,
      products: [
        {
          product_id: productId,
          qty: parseInt(qty),
        },
      ],
    };

    createStockAdjust(oModel).then((res: any) => {
      setLoading(true);
      if (!res) {
        setTimeout(() => {
          notifyError("Ocorreu um erro");
          setLoading(false);
        }, 100);
      } else {
        setTimeout(() => {
          window.location.reload();
          setLoading(false);
        }, 100);
      }
    });
  }

  const handleCancel = () => {
    OnAddNewEstoqueMovimentacao(false);
  };

  const handleValidete = () => {
    if (!storeDestinationId) setValidStore(true);
    if (storeDestinationId) setValidStore(false);
    if (!productId) setValidProduct(true);
    if (productId) setValidProduct(false);
    if (!qty) setValidQty(true);
    if (qty) setValidQty(false);
    if (!note) setValidNote(true);
    if (note) setValidNote(false);

    if (storeDestinationId && productId && qty && note) {
      return true;
    }

    return false;
  };

  const clearFilds = () => {
    setStock(0);
    setActualQty("");
    setProductId(0);
    setQty("");
  };

  useEffect(() => {
    GetEndPoint.store(setListStores);
    GetEndPoint.products(setListProducts);
  }, []);

  useEffect(() => {
    GetEndPoint.stocks(setListStocks, storeDestinationId);
    fillProductsByStore();
    clearFilds();
  }, [storeDestinationId]);

  useEffect(() => {
    fillProductsByStock();
  }, [stock]);

  useEffect(() => {
    if (!stock) {
      GetEndPoint.products(setListProducts);
    }
  }, [stock]);

  return (
    <>
      <Layout
        title={title}
        isEditable={editando}
        onClickSave={handleSubmit}
        onClickEdit={handleSubmit}
        onClickCancel={handleCancel}
      >
        <S.BtnAdd>
          <ButtonGroup>
            <Button
              variant={stockQuoteSituationId === 1 ? "primary" : "light"}
              active={stockQuoteSituationId === 1 ? true : false}
              size="sm"
              onClick={(e) => setStockQuoteSituationId(1)}
            >
              Nome
            </Button>
            <Button
              variant={stockQuoteSituationId === 2 ? "primary" : "light"}
              active={stockQuoteSituationId === 2 ? true : false}
              size="sm"
              onClick={(e) => setStockQuoteSituationId(2)}
            >
              Codigo
            </Button>
            <Button
              variant={stockQuoteSituationId === 3 ? "primary" : "light"}
              active={stockQuoteSituationId === 3 ? true : false}
              size="sm"
              onClick={(e) => setStockQuoteSituationId(3)}
            >
              Codigo de barras
            </Button>
          </ButtonGroup>
        </S.BtnAdd>
        <S.Wrapper>
          <ReactSelect
            label="Loja"
            state={storeDestinationId}
            placeholder="Loja"
            options={storeMe.user.relationships.stores.map((item) => ({
              ...item,
              value: item.id,
              label: item.trading_name,
            }))}
            onChange={(e: any) => handleSelectStore(e?.value)}
            isInvalid={validStore}
          />

          <ReactSelect
            label="Estoque"
            isClearable
            state={stock}
            onChange={(e: any) => handleSelectStock(e?.value)}
            placeholder="Local de estoque"
            options={listStocks}
            isDisabled={!storeDestinationId}
          />

          {stockQuoteSituationId === 1 && (
            <ReactSelect
              label="Nome"
              placeholder="Produto pelo nome"
              options={listProducts}
              state={productId}
              onChange={(e: any) => handleSelectProduct(e)}
              isDisabled={!storeDestinationId}
              isInvalid={validProduct}
            />
          )}
          {stockQuoteSituationId === 2 && (
            <ReactSelect
              placeholder={"Produto pelo código"}
              label="Código"
              options={listProductsCode}
              state={productId}
              onChange={(e: any) => handleSelectProduct(e)}
              isDisabled={!storeDestinationId}
              isInvalid={validProduct}
            />
          )}
          {stockQuoteSituationId === 3 && (
            <ReactSelect
              placeholder={"Produto pelo código de barras"}
              label="Cód. barras"
              options={listProductsBarCode}
              state={productId}
              onChange={(e: any) => handleSelectProduct(e)}
              isDisabled={!storeDestinationId}
              isInvalid={validProduct}
            />
          )}

          <InputDefault
            label="Qtd Atual"
            type="number"
            autoComplete="off"
            placeholder={disableActualQty ? "Produto" : "Quantidade atual"}
            maxLength={50}
            state={actualQty}
            disabled={true}
          />

          <InputDefault
            label="Qtd. Desejada"
            type="number"
            autoComplete="off"
            placeholder="Quantidade desejada"
            maxLength={50}
            state={qty}
            setState={setQty}
            isInvalid={validQty}
          />
          <InputDefault
            type="text"
            label="Motivo"
            placeholder="Motivo do Ajuste"
            size="sm"
            rows={5}
            maxLength={500}
            onChenge={(e) => setNote(e.target.value)}
            state={note}
          />
        </S.Wrapper>
      </Layout>

      {loading && <Loading />}
      {!listStores && <Loading />}

      <ToastContainer
        position="top-center"
        autoClose={300}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
}
