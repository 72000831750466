import styled from "styled-components";

export const Content = styled.div`
  display: grid;
  margin: 0 0rem;

  @media only screen and (min-width: 1100px) {
    grid-template-columns: 1fr 10fr;
  }
`;

export const WrapperImg = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;

  button {
    border: 1px solid white;
    color: white;
    height: 1.8rem;
    width: 100%;
  }
`;

export const Wrapper = styled.main`
  .title {
    font-size: 16px;
    padding: 1rem 0;
    color: gray;
  }
  p {
    color: #c0c0c0;
  }

  .border-top {
    border-top: 1px solid gray;
    margin-top: 2rem;
  }

  .btn-logo {
    margin-top: 1.3rem;
  }
`;

export const WrapperInput = styled.div`
  @media only screen and (min-width: 1100px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1.5rem;
    gap: 0.3rem;
    align-self: center;
    height: 110px;
    margin-top: 1.5rem;

    .value-label {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .flex {
        display: flex;
      }
    }

    .initial-value {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .final_value {
      input {
        width: 110px;
      }
    }

    label > .label {
      text-align: right;
      margin-right: 0.5rem;
    }
  }

  .label {
    font-size: 11px;
    font-weight: 500;
    text-align: left;
  }

  label {
    display: grid;
    grid-template-columns: 1fr 3fr;
    text-align: left;

    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;
      .label {
        display: none;
      }
    }
  }

  .wrapper-date {
    display: grid;
    grid-template-columns: 1fr;
  }

  .btnAdd {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 1rem;
  }
`;

export const WrapperInput1 = styled.div`
  margin: 1rem 1rem;

  @media only screen and (min-width: 1100px) {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1rem;
    gap: 0.3rem;

    .value-label {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .flex {
        display: flex;
      }
    }

    .initial-value {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .final_value {
      input {
        width: 110px;
      }
    }

    label > .label {
      text-align: right;
      margin-right: 0.5rem;
    }
  }

  .label {
    font-size: 11px;
    font-weight: 500;
    text-align: left;
  }

  label {
    display: grid;
    grid-template-columns: 1fr 3fr;
    text-align: left;

    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;
      .label {
        display: none;
      }
    }
  }

  .wrapper-date {
    display: grid;
    grid-template-columns: 1fr;
  }

  .btnAdd {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 1rem;
  }
`;

export const BtnAdd = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  padding-left: 8rem;
`;
