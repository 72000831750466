import { useState, useContext } from "react";
import ListaPlanoContas from "../../../Components/Financeiro/PlanoConta/ListaPlanoContas";
import Container from "react-bootstrap/Container";
import IPlanoConta from "../../../Components/Financeiro/Interface/IPlanoConta";
import "./styles.scss";
import { ThemeContext } from "../../../context/theme-context";

export default function CadPlanoContas() {
  const { collapsed } = useContext(ThemeContext);
  const [cad, setCad] = useState<boolean>(false);
  const [toEditPlanoContas, setToEditPlanoContas] = useState<IPlanoConta>();
  const [editando, setEditando] = useState<boolean>(false);
  const [menuSelected, setMenuSelected] = useState<string>("");

  const handleNew = (e: any) => {
    setCad(e);
    setEditando(false);
  };

  const handleClearCancelBtn = (e: any) => {
    setToEditPlanoContas(undefined);
    setMenuSelected("");
  };

  const handleEdit = (props: IPlanoConta) => {
    setToEditPlanoContas(props);
    setCad(true);
    setEditando(true);
  };

  return (
    <>
      {!cad && (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <ListaPlanoContas
            Title="Plano de Contas"
            OnAddNewCli={(e: any) => {
              handleNew(e);
            }}
            setMenuSelected={setMenuSelected}
            onEditItem={(item: IPlanoConta) => handleEdit(item)}
            menuSelected={menuSelected}
          />
        </Container>
      )}
    </>
  );
}
