// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fundo {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  z-index: 2000;
  justify-content: center;
  align-items: center;
}

.form {
  top: 100px;
}

.spinner {
  width: 4.6rem !important;
  height: 4.6rem !important;
  border: 0.55em solid !important;
  border-right-color: transparent !important;
  justify-content: center !important;
  align-items: center !important;
}

.label {
  font-size: 1.2rem;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/Loading/styles.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,8BAAA;EACA,eAAA;EACA,QAAA;EACA,SAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEE;EACI,UAAA;AACN;;AAEE;EACE,wBAAA;EACA,yBAAA;EACA,+BAAA;EACA,0CAAA;EACA,kCAAA;EACA,8BAAA;AACJ;;AAEE;EACE,iBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;AACJ","sourcesContent":[".fundo{\r\n    width: 100vw;\r\n    height: 100vh;\r\n    background: rgb(0,0,0,.3);\r\n    position: fixed;\r\n    top: 0px;\r\n    left: 0px;\r\n    display: flex;\r\n    z-index: 2000;\r\n    justify-content: center;\r\n    align-items: center;\r\n  }\r\n\r\n  .form{\r\n      top: 100px;\r\n  }\r\n  \r\n  .spinner{\r\n    width: 4.6rem !important;\r\n    height: 4.6rem !important;\r\n    border: 0.55em solid !important;\r\n    border-right-color: transparent !important;\r\n    justify-content: center !important;\r\n    align-items: center !important;\r\n  } \r\n  \r\n  .label{\r\n    font-size: 1.2rem;\r\n    font-weight: bold;\r\n    justify-content: center;\r\n    align-items: center;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
