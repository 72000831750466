import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { InputHook } from "../../../../reactHookForm/InputHook";
import { InputButton } from "../../../../reactHookForm/inputButton";
import { TextAreaHook } from "../../../../reactHookForm/textAreaHook";
import { InputHookCnpjPeople } from "../../../../reactHookForm/InputHookCnpjPeople";
import * as S from "./styled";

interface IProps {
  gender: string;
  setGender: Dispatch<SetStateAction<string>>;
  register: any;
  errors: any;
  control: any;
  watch: any;
  setValue: any;
  setError: any;
  reset?: any;
  setStateForm?: any;
  data: any;
  isEditable: boolean;
  path: string;
  setArrayContacts: any;
  setArrayAdress: any;
}

export function PersonDataPJ({
  gender,
  setGender,
  register,
  errors,
  control,
  setError,
  setValue,
  watch,
  reset,
  setStateForm,
  data,
  isEditable,
  path,
  setArrayContacts,
  setArrayAdress,
}: IProps) {
  const statusStore = watch("status");
  const ieStore = watch("isento_ie");
  const [storeStatus, setCnpjStatus] = useState<boolean>();
  const [ieActived, setIeActived] = useState<boolean>();

  useEffect(() => {
    setCnpjStatus(
      isEditable
        ? data?.status === 2
          ? false
          : true || true
        : statusStore === 2
        ? false
        : true
    );
  }, [statusStore]);

  useEffect(() => {
    setIeActived(statusStore === 2 ? false : true);
  }, [ieStore]);

  return (
    <>
      <S.Wrapper>
        <div className="min-screen">
          <InputHookCnpjPeople
            register={register}
            watch={watch}
            setValue={setValue}
            setError={setError}
            errors={errors}
            reset={reset}
            setStateForm={setStateForm}
            label="CNPJ"
            mask="99.999.999/9999-99"
            name="cpf_cnpj"
            placeholder="CNPJ"
            dataEdit={data}
            defaultValue={data.cnpj}
            path={path}
            setArrayContacts={setArrayContacts}
            setArrayAdress={setArrayAdress}
          />
          <InputHook
            register={register}
            errors={errors}
            label="Rezão social"
            name="company_name"
            placeholder="Rezão social"
            type="text"
          />
          <InputHook
            register={register}
            errors={errors}
            label="Nome fantasia"
            name="trading_name"
            placeholder="Nome fantasia"
            type="text"
          />

          <InputHook
            register={register}
            errors={errors}
            label="Email"
            name="email"
            type="text"
            placeholder="Email"
          />
          <InputHook
            register={register}
            errors={errors}
            label="Suframa"
            name="suframa"
            placeholder="Suframa"
            type="text"
          />
          <InputHook
            register={register}
            errors={errors}
            label="Ins. Municipal"
            name="municipal_registration"
            type="text"
            placeholder="Inscrição Municipal"
          />

          <InputHook
            register={register}
            errors={errors}
            label="Inscrição Municipal"
            name="additional_data"
            placeholder="Inscrição Municipal"
          />
        </div>

        <div className="max-screen">
          <InputHookCnpjPeople
            register={register}
            watch={watch}
            setValue={setValue}
            setError={setError}
            errors={errors}
            reset={reset}
            setStateForm={setStateForm}
            label="CNPJ"
            mask="99.999.999/9999-99"
            name="cpf_cnpj"
            placeholder="CNPJ"
            dataEdit={data}
            defaultValue={data.cnpj}
            path={path}
            setArrayContacts={setArrayContacts}
            setArrayAdress={setArrayAdress}
          />
          <InputHook
            register={register}
            errors={errors}
            label="Rezão social"
            name="company_name"
            placeholder="Rezão social"
            type="text"
          />
          <InputHook
            register={register}
            errors={errors}
            label="Nome fantasia"
            name="trading_name"
            placeholder="Nome fantasia"
            type="text"
          />

          <InputHook
            register={register}
            errors={errors}
            label="Email"
            name="email"
            type="text"
            placeholder="Email"
          />
          <InputHook
            register={register}
            errors={errors}
            label="Suframa"
            name="suframa"
            placeholder="Suframa"
            type="text"
          />
          <InputHook
            register={register}
            errors={errors}
            label="Ins. Municipal"
            name="municipal_registration"
            type="text"
            placeholder="Inscrição Municipal"
          />

          <InputHook
            register={register}
            errors={errors}
            label="Inscrição Municipal"
            name="additional_data"
            placeholder="Inscrição Municipal"
          />
        </div>
        <div className="BtnWrapper">
          <InputButton
            register={register}
            errors={errors}
            stateBtn={ieActived}
            setStateBtn={setIeActived}
            defaultValue={1}
            isTextTrue="Sim"
            isTextFalse="Não"
            label="Insenta de IE"
            name="isento_ie"
          />
          <InputButton
            register={register}
            errors={errors}
            stateBtn={storeStatus}
            setStateBtn={setCnpjStatus}
            defaultValue={1}
            isTextTrue="Ativo"
            isTextFalse="Inativo"
            label="Status"
            name="status"
          />
        </div>
      </S.Wrapper>
    </>
  );
}
