import { defaultThemes } from "react-data-table-component";

const tableStyleDefaultTrans: any = {
  table: {
    style: {
      minHeight: "20vh",
    },
  },
  header: {
    style: {
      minHeight: "0px",
      fontWeight: "bold",
    },
  },
  headRow: {
    style: {
      color: "gray",
      borderStyle: "solid",
      fontWeight: "bold",
      borderWidth: "0px",
      backgroundColor: "#D8D8D8",
      fontSize: "10px",
      minHeight: "0px",
    },
  },
  headCells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
  cells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: defaultThemes.default.divider.default,
        textTransform: "capitalize",
        fontSize: "11px",
        height: "57px",
      },
    },
  },
  rows: {
    style: {
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: defaultThemes.default.divider.default,
      fontSize: "11px",
      textTransform: "capitalize",
      borderTopStyle: "none",
      "&:hover": {
        backgroundColor: "#E0F7FA",
      },
      height: "57px",
    },
  },
};

export default tableStyleDefaultTrans;
