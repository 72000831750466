import { Col, Row } from "react-bootstrap";
import {
  objectPeriodo,
  objectStatus,
} from "../../../../../../utils/objectSelect";
import Format from "../../../../../../Services/Format";
import DateUtils from "../../../../../../Services/DateUtils";
import { InputHook } from "../../../../../reactHookForm/InputHook";
import { SelectHook } from "../../../../../reactHookForm/SelectHook";

interface IProps {
  register: any;
  control: any;
  watch: any;
  selectOptions: any;
}

export function InputFilter({
  register,
  control,
  watch,
  selectOptions,
}: IProps) {
  const periodoState = watch("periodo");

  return (
    <>
      <Row>
        <Col md="6">
          <SelectHook
            register={register}
            control={control}
            label="Nome"
            name="name"
            placeholder="Filtrar por nome"
            options={selectOptions.representative.map((item: any) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Col>

        <Col md="3">
          <InputHook
            register={register}
            label="CNPJ"
            name="cnpj"
            placeholder="Filtrar por cnpj"
            type="text"
          />
        </Col>
        <Col md="3">
          <SelectHook
            register={register}
            control={control}
            label="Status"
            defaultValue={objectStatus[0]}
            name="status"
            placeholder="Filtrar por status"
            options={objectStatus}
          />
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <SelectHook
            register={register}
            control={control}
            label="Localidade (UF)"
            name="uf"
            placeholder="Filtrar por uf"
            options={selectOptions?.state}
          />
        </Col>
        <Col md="3">
          <SelectHook
            register={register}
            control={control}
            label="Período"
            defaultValue={objectPeriodo[0]}
            name="periodo"
            placeholder="Filtrar por período"
            options={objectPeriodo}
            isClearable={false}
          />
        </Col>
        <Col md="3">
          <InputHook
            register={register}
            label="Data inicial"
            name="inicialData"
            placeholder="Filtrar por data inicial"
            type="date"
            disabled={periodoState?.value === 1 || !periodoState ? true : false}
            defaultValue={Format.date(new Date(), "YYYY-MM-DD")}
          />
        </Col>
        <Col md="3">
          <InputHook
            register={register}
            label="Data Final"
            name="finalData"
            placeholder="Filtrar por data final"
            type="date"
            disabled={periodoState?.value === 1 || !periodoState ? true : false}
            defaultValue={Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD")}
          />
        </Col>
      </Row>
    </>
  );
}
