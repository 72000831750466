import { useState, useContext } from "react";
import { ThemeContext } from '../../../context/theme-context';
import { Container } from 'react-bootstrap';
import ListaFichaTecnica from '../../../Components/Cadastro/Fichatecnica/ListaFichaTecnica';
import FormFichaTecnica from '../../../Components/Cadastro/Fichatecnica/FormFichaTecnica';
import { IFichaTecnica } from '../../../Components/Cadastro/Fichatecnica/interface/IFichaTecnica';
import { DatasheetProvider } from '../../../context/data-sheet';

export interface IListFichaTecnica {
  title: string;
  OnAddNewCli: (e: boolean) => void;
  onEditItem: (item: IFichaTecnica) => void;
  OnAddNewFichaTecnica: (e: boolean) => void;
}

export interface IFormFichaTecnica {
  title: string;
  OnAddNewFichaTecnica: (e: boolean) => void;
  fichaTecnica?: IFichaTecnica;
  editando: boolean;
}

export function CadFichaTecnica() {
  const { collapsed } = useContext(ThemeContext);

  const [cad, setCad] = useState<boolean>(false);
  const [toEditFichaTecnica, setToEditFichaTecnica] = useState<IFichaTecnica>();
  const [editando, setEditando] = useState<boolean>(false);


  const handleNew = (e: boolean) => {
    setEditando(false);
    setCad(e);
  };

  const handleEdit = (props: IFichaTecnica) => {
    setToEditFichaTecnica(props);
    setCad(true);
    setEditando(true);
  };

  return (
    <DatasheetProvider>
      {!cad ? (
        <Container className={collapsed ? 'contenteLayoutCollapsed' : "contenteLayout"}>
          <ListaFichaTecnica
            title="Ficha Técnica"
            onEditItem={(item: IFichaTecnica) => handleEdit(item)}
            OnAddNewCli={(e: boolean) => handleNew(e)}
            OnAddNewFichaTecnica={(e: boolean) => handleNew(e)}
          />

        </Container>
      ) : (
        <Container className={collapsed ? 'contenteLayoutCollapsed' : "contenteLayout"} >
          <FormFichaTecnica
            title={
              toEditFichaTecnica
                ? "Editar Ficha Técnica"
                : "Nova Ficha Técnica"
            }
            OnAddNewFichaTecnica={(e: boolean) => handleNew(e)}
            fichaTecnica={toEditFichaTecnica}
            editando={editando}
          />
        </Container>
      )}
    </DatasheetProvider>
  )
};
