import { useEffect, useState, useContext } from "react";
import { Col, Row, Table } from "react-bootstrap";
import Loading from "../../../../Components/Loading/Loading";
import "./style.scss";
import { ToastContainer } from "react-toastify";
import Layout from "../../../data/Layout";
import { IFormProducao } from "../../../../Pages/Cadastro/CadProducao";
import ReactSelect from "../../../data/reactSelect";
import GetEndPoint from "../../../../Services/GetEndPoint";
import { InputDefault } from "../../../data/inputDefault";
import { CreateAndEdit } from "../../../../Services/CreateAndEdit";
import globals from "../../../../Globals.json";
import { StoreContext } from "../../../../context/store-context";
import { notify, notifyError } from "../../../notify/notify-component";
import { useAppSelector } from "../../../../redux/store";
import * as S from "./styled";

export const Producao = ({
  OnAddNewProducao,
  producao,
  title,
  editando,
}: IFormProducao) => {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [loading, setLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<number>(0);
  const [qty, setQty] = useState<string>("");
  const [stockId, setStockId] = useState<number>(0);
  const [isValidproduto, setIsValidproduto] = useState<boolean>(false);
  const [isValidQty, setIsValidQty] = useState<boolean>(false);
  const [productUuid, setProductUuid] = useState<number>(0);
  const [productComplit, setProductComplit] = useState<Array<any>>([]);
  const [localStoquePadrao, setLocalStoquePadrao] = useState<string>("");
  const [quantidadeAtual, setQuantidadeAtual] = useState<string>("");
  const [rendimentos, setRendimentos] = useState<string>("");
  const [pro, setPro] = useState<string>("");

  const [productList, setProductList] = useState<Array<any>>([]);
  const [listaProdutosAdd, setListaProdutosAdd] = useState<any>([]);
  const [listaMensureId, setListaMensureId] = useState<any>([]);
  const [listProdutoEstocavelComplenta, setListProdutoEstocavelComplenta] =
    useState<any>([]);
  const [localStockList, setLocalStockList] = useState<any[]>([]);

  function handleCancel() {
    //clearFilds(); vem do context
    OnAddNewProducao(false);
  }

  async function handleSubmit() {
    if (!product || !qty) {
      setIsValidproduto(true);
      setIsValidQty(true);
      return;
    }

    const oModel = {
      store_id: storeMe.storeActive.id,
      qty: qty,
      stock_id: stockId,
    };

    try {
      await CreateAndEdit.post(
        globals.api.creations + "/" + productUuid + "/produce",
        oModel
      ).then((res: any) => {
        setLoading(true);
        if (!res) {
          setTimeout(() => {
            setLoading(false);
          }, 100);
        } else {
          setTimeout(() => {
            handleCancel();
            setLoading(false);
          }, 100);
        }
      });
    } catch (error) {
      notifyError("Não há estoque para os insumos desta produção.");
    }
  }

  useEffect(() => {
    if (!product) {
      setLocalStoquePadrao("");
      setQuantidadeAtual("");
      setRendimentos("");
      setListaProdutosAdd([]);
      return;
    }

    setLocalStoquePadrao(
      productComplit?.find((item: any) => item.id === product)?.relationships
        ?.product?.stock_local_id
    );

    setQuantidadeAtual(
      listProdutoEstocavelComplenta?.find(
        (item: any) =>
          item.id ===
          productComplit?.find((item: any) => item.id === product).relationships
            ?.product?.id
      ).stock_qty
    );

    setRendimentos(
      productComplit?.find((item: any) => item.id === product)?.qty
    );

    setListaProdutosAdd(
      productComplit?.find((item: any) => item.id === product)?.relationships
        ?.inputs
    );

    setProductUuid(
      productComplit?.find((item: any) => item.id === product)?.uuid
    );

    setStockId(
      productComplit?.find((item: any) => item.id === product)?.relationships
        ?.product?.stock_local_id
    );

    setIsValidproduto(false);
  }, [product]);

  useEffect(() => {
    if (qty) {
      setIsValidQty(false);
    }
  }, [qty]);

  useEffect(() => {
    GetEndPoint.productCreation(setProductList, setProductComplit);
    GetEndPoint.products(setPro, setListProdutoEstocavelComplenta);
    GetEndPoint.productMeasureUnits(setListaMensureId);
    GetEndPoint.stocks(setLocalStockList);
  }, []);

  let newProductList = productList.filter(
    (item) => item.store_id === storeMe.storeActive.id
  );

  return (
    <>
      <Layout
        title="Produção"
        isEditable={editando}
        onClickSave={handleSubmit}
        onClickEdit={handleSubmit}
        onClickCancel={handleCancel}
      >
        <S.Wrapper>
          <ReactSelect
            label="Produto"
            state={product}
            isClearable={true}
            placeholder="Produto"
            options={newProductList}
            onChange={(e: any) => setProduct(e?.value)}
            isInvalid={isValidproduto}
          />

          <InputDefault
            label="Quantidade"
            type="number"
            autoComplete="off"
            state={qty}
            setState={setQty}
            placeholder="0,00"
            min="0"
            isInvalid={isValidQty}
          />

          <ReactSelect
            label="Local padrão"
            isClearable
            options={localStockList}
            state={localStoquePadrao}
            setState={setLocalStoquePadrao}
            placeholder="Local de estoque padrão"
            isDisabled={true}
          />

          <InputDefault
            label="Estoque atual"
            type="text"
            autoComplete="off"
            state={quantidadeAtual}
            placeholder="Estoque atual do produto"
            maxLength={50}
            disabled={true}
          />

          <InputDefault
            label="Rendimentos"
            type="number"
            autoComplete="off"
            state={rendimentos}
            placeholder="Rendimento do produto"
            maxLength={50}
            disabled={true}
          />
        </S.Wrapper>

        {listaProdutosAdd.length > 0 && (
          <>
            <h3 className="mt-4 mb-2">Insumos</h3>

            <Table striped hover bordered>
              <thead>
                <tr className="trTable">
                  <th>Nome do produto</th>
                  <th>Quantidade</th>
                  <th>Estoque atual</th>
                  <th>Estoque final</th>
                </tr>
              </thead>

              <tbody>
                {listaProdutosAdd?.map((item: any) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        {
                          listProdutoEstocavelComplenta.find(
                            (product: any) => product?.id === item.product_id
                          )?.purchase_name
                        }
                      </td>
                      <td>
                        {item.qty}
                        <span>
                          {" "}
                          {
                            listaMensureId.find(
                              (measure: any) =>
                                measure?.value === item.measure_unit_id
                            )?.label
                          }
                        </span>
                      </td>
                      <td>
                        {
                          listProdutoEstocavelComplenta.find(
                            (product: any) => product?.id === item.product_id
                          )?.stock_qty
                        }
                        <span>
                          {" "}
                          {
                            listaMensureId.find(
                              (measure: any) =>
                                measure?.value === item.measure_unit_id
                            )?.label
                          }
                        </span>
                      </td>
                      <td>
                        {listProdutoEstocavelComplenta.find(
                          (product: any) => product?.id === item.product_id
                        )?.stock_qty - item.qty}
                        <span>
                          {" "}
                          {
                            listaMensureId.find(
                              (measure: any) =>
                                measure?.value === item.measure_unit_id
                            )?.label
                          }
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        )}
      </Layout>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
};
export default Producao;
