import styled from "styled-components";

interface Icollapsed {
  collapsed: boolean;
  menuStore: boolean;
  menuMessage: boolean;
  menuUser: boolean;
}

export const Display = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 90;
  background-color: white;
`;

export const Content = styled.nav<Icollapsed>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: 60px;
  box-shadow: 0 10px 30px 0 rgb(82 63 105 / 5%);

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    figure {
      transition: all 0.3s ease-in-out;
      margin-left: ${({ collapsed }) => (!collapsed ? "17rem" : "6.1rem")};
      margin-top: 1.5rem;
      img {
        width: auto;
        height: 40px;
      }
    }

    .grid-icons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.4rem;
      margin: 0 1rem;

      .company-name {
        font-size: 11px;
        font-weight: bolder;
        margin-right: 1rem;
        padding-top: 1rem;
      }

      .grid-store {
        position: relative;
        transition: all 0.3s ease-in-out;

        .grid-companies {
          position: absolute;
          background-color: white;
          box-shadow: 0px 0px 10px 1px rgba(186, 186, 186, 1);
          width: 200px;
          top: 40px;
          display: ${({ menuStore }) => (menuStore ? "block" : "none")};
          right: 0;
          border-radius: 2px;
          transition: all 0.3s ease-in-out;
          z-index: 2;

          h3 {
            font-size: 14px;
            gap: 0.5rem;
            display: flex;
            align-items: center;
            padding: 5px 10px;
            color: #bfbfbf;
            border-bottom: 1px solid #bfbfbf;
          }
          div {
            cursor: pointer;
            :hover {
              background-color: var(--blue-10);
              span {
                color: white;
              }
            }
          }
          div span {
            font-size: 12px;
            padding: 20px 10px;
            color: #8c8c8c;
          }
        }
      }

      .grid-message {
        position: relative;
        transition: all 0.2s ease-in-out;

        .numeric {
          position: absolute;
          height: 15px;
          width: 15px;
          border-radius: 50px;
          background-color: green;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 9px;
          font-weight: bold;
          top: -2px;
          right: -8px;
        }

        .grid-companies {
          position: absolute;
          background-color: white;
          box-shadow: 0px 0px 10px 1px rgba(186, 186, 186, 1);
          width: 200px;
          top: 40px;
          display: ${({ menuMessage }) => (menuMessage ? "block" : "none")};
          right: 0;
          border-radius: 2px;
          transition: all 0.2s ease-in-out;
          z-index: 2;

          h3 {
            font-size: 14px;
            gap: 0.5rem;
            display: flex;
            align-items: center;
            padding: 5px 10px;
            color: #bfbfbf;
            border-bottom: 1px solid #bfbfbf;
          }
          div {
            cursor: pointer;
            :hover {
              background-color: var(--blue-10);
              span {
                color: white;
              }
            }
          }
          div span {
            font-size: 12px;
            padding: 20px 10px;
            color: #8c8c8c;
          }
        }
      }

      .grid-user {
        position: relative;
        transition: all 0.3s ease-in-out;

        .grid-companies {
          position: absolute;
          background-color: white;
          box-shadow: 0px 0px 10px 1px rgba(186, 186, 186, 1);
          width: 200px;
          top: 40px;
          display: ${({ menuUser }) => (menuUser ? "block" : "none")};
          right: 0;

          border-radius: 2px;
          transition: all 0.3s ease-in-out;
          z-index: 2;

          h3 {
            font-size: 12px;
            gap: 0.5rem;
            display: flex;
            align-items: center;
            padding: 5px 10px;
            color: #bfbfbf;
            border-bottom: 1px solid #bfbfbf;
          }
          div {
            cursor: pointer;
            :hover {
              background-color: var(--blue-10);
              span {
                color: white;
              }
            }
          }
          div span {
            font-size: 12px;
            padding: 20px 10px;
            color: #8c8c8c;
          }

          button {
            background-color: #ff4d4d;
            padding: 0.4rem 0.2rem;
            width: 100%;
            border: none;
            font-weight: bold;
            color: white;
            transition: all 0.3s ease-in-out;

            :hover {
              background-color: #ff8080;
            }
          }
        }
      }
    }
  }

  .overlay-notification-modal {
    position: absolute;
    display: block;
    top: -15px;
    left: -100vw;
    width: 250vw;
    height: 100vh;
    z-index: 1;
    opacity: 0;
    animation: opacity linear 0.2s forwards;
  }
`;
