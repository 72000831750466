import { useContext } from "react";
import ListaEstoque from "../../../Components/Estoque/LocaisEstoque/ListaEstoque";
import Container from "react-bootstrap/Container";
import "./styles.scss";
import { ThemeContext } from "../../../context/theme-context";

export default function CadEstoque() {
  const { collapsed } = useContext(ThemeContext);

  return (
    <Container
      className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
    >
      <ListaEstoque Title="Estoque" Path="stocks" />
    </Container>
  );
}
