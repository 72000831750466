import { toast } from "react-toastify";
import { useContext } from "react";
import styles from "./styles.module.scss";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import ModalGerarApMovi from "../ModalGerarApMovi";
import { ImprimirContext } from "../../../../context/print-receipt";
import LayoutFilter from "../../../data/LayoutFilter";
import { InputDefault } from "../../../data/inputDefault";
import { InputInternText } from "../../../data/inputInternText";
import { useAppSelector } from "../../../../redux/store";
import { notifyError } from "../../../notify/notify-component";
import * as S from "../styled";

export default function CadImprimirRecibo(props: any) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  let storeData = storeMe.storeActive;

  const {
    valueRef,
    setValueRef,
    referent,
    setReferent,
    date,
    setDate,
    handleShow,
    received,
    setReceived,
  } = useContext(ImprimirContext);

  function handleSubmit() {
    if (!received || !valueRef || !referent || !date) {
      return notifyError("Por favor preencha todos os campos!");
    }
    props.OnAddPrintReceipt(true);
  }

  const local = storeData.city + "-" + storeData.state;

  return (
    <LayoutFilter
      title={props.Title}
      onClickAdd={handleShow}
      textAdd="Gerar a partir de movimentação"
    >
      <ModalGerarApMovi {...props} />

      <S.Wrapper>
        <InputDefault
          label="Recebido de"
          placeholder="Recebido de"
          type="text"
          autoComplete="off"
          state={received}
          maxLength={50}
          setState={setReceived}
        />

        <InputDefault
          label="Valor"
          type="number"
          step="0.01"
          autoComplete="off"
          state={valueRef}
          placeholder="Valor"
          min="0"
          setState={setValueRef}
        />
        <InputDefault
          label="Referente à"
          placeholder="Referente à"
          size="sm"
          type="text"
          rows={3}
          autoComplete="off"
          state={referent}
          maxLength={800}
          onChenge={(e: any) => setReferent(e.target.value)}
        />
      </S.Wrapper>
      <S.Wrapper>
        <InputDefault
          label="Nome"
          placeholder="Nome"
          disabled={true}
          type="text"
          autoComplete="off"
          state={storeData.company_name}
          maxLength={50}
        />
        <InputDefault
          label="Documento "
          placeholder="Documento (CPF / CNPJ / RG)"
          disabled={true}
          size="sm"
          type="string"
          step="any"
          autoComplete="off"
          state={storeData.cnpj}
        />

        <InputDefault
          label="Local"
          placeholder="Local"
          disabled={true}
          type="text"
          autoComplete="off"
          state={local}
          maxLength={50}
        />
        <InputDefault
          label="Data"
          type="date"
          state={date}
          setState={setDate}
        />
      </S.Wrapper>

      <Row>
        <Col className={styles.addButtonCol}>
          <Button
            size="sm"
            variant="primary"
            className="mt-4"
            onClick={handleSubmit}
          >
            Próximo
          </Button>
        </Col>
      </Row>
    </LayoutFilter>
  );
}
