import * as Yup from "yup";

export const inventarySchema = Yup.object().shape({
  name: Yup.string().required("Por favor, informe o nome."),
  store: Yup.number()
    .transform((originalValue, originalObject) => {
      return originalObject && typeof originalObject === "object"
        ? originalObject.value
        : originalValue;
    })
    .required("Por favor, selecione a loja."),
  stock: Yup.number()
    .transform((originalValue, originalObject) => {
      return originalObject && typeof originalObject === "object"
        ? originalObject.value
        : originalValue;
    })
    .required("Por favor, selecione o stoque."),
});
