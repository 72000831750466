import * as Yup from "yup";

export const pdvSchema = Yup.object().shape({
  description: Yup.string().required("Por favor, insira a descrição."),

  br_payment_type_id: Yup.mixed().test(
    "is-string",
    "Por favor, selecione o Tipo de Pagamento",
    function (value) {
      if (!value) {
        return false;
      }

      if (typeof value === "object" && value.value) {
        return true;
      }
      return false;
    }
  ),

  br_payment_type_provider_id: Yup.mixed().test(
    "is-string",
    "Por favor, selecione a Operadora Credenciada.",
    function (value) {
      if (!value) {
        return false;
      }

      if (typeof value === "object" && value.value) {
        return true;
      }
      return false;
    }
  ),

  payment_group: Yup.mixed().test(
    "is-string",
    "Por favor, selecione o Grupo de Pagamento.",
    function (value) {
      if (!value) {
        return false;
      }

      if (typeof value === "object" && value.value) {
        return true;
      }
      return false;
    }
  ),

  business_account_id: Yup.mixed().test(
    "is-string",
    "Por favor, selecione a Moeda.",
    function (value) {
      if (!value) {
        return false;
      }

      if (typeof value === "object" && value.value) {
        return true;
      }
      return false;
    }
  ),

  payment_mechanism_id: Yup.mixed().test(
    "is-string",
    "Por favor, o tipo de pagamento.",
    function (value) {
      if (!value) {
        return false;
      }

      if (typeof value === "object" && value.value) {
        return true;
      }
      return false;
    }
  ),

  br_payment_type_description: Yup.string().required(
    "Por favor, insira a descrição."
  ),
});
