import { useEffect, useState } from "react";
import Select from "react-select";

import * as S from "./styles";

import {
  msgStyles,
  NoOptionsMessage,
} from "../../NoOptionsMessage/no-options-message-component";
import { Controller } from "react-hook-form";

interface IProps {
  label?: string;
  options: any;
  isClearable?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  onChange?: any;
  name: string;
  readOnly?: boolean;
  register: any;
  control: any;
  defaultValue?: any;
  errors?: any;
  setValue?: any;
  onchenge?: any;
  minWidth?: string | undefined;
}

export function SelectHookFlex({
  options,
  isClearable,
  placeholder,
  label,
  isDisabled,
  name,
  control,
  defaultValue,
  errors,
  setValue,
  onchenge,
  minWidth,
}: IProps) {
  const errorToDisplay = errors
    ? Object?.keys(errors[0])?.find((key) => key === name)
    : "";

  useEffect(() => {
    if (defaultValue) {
      control.setValue && control.setValue(name, defaultValue);
    }
  }, [defaultValue, name, control]);

  return (
    <S.Input>
      <S.Wrapper minWidth={minWidth}>
        <label className="">{label}</label>
        <div className="select">
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => (
              <Select
                {...field}
                isClearable={isClearable}
                placeholder={
                  <div className="placeholderSelect">{placeholder}</div>
                }
                defaultValue={defaultValue}
                options={options}
                isDisabled={isDisabled}
                components={{ NoOptionsMessage }}
                styles={{
                  noOptionsMessage: (base) => ({ ...base, ...msgStyles }),
                  control: (base) => ({
                    ...base,
                    border: errorToDisplay
                      ? "1px solid #F1416C"
                      : "1px solid var(--color-input-border)",
                    boxShadow: "var(--color-input-shadow)",
                    borderRadius: 4,
                    height: 18,
                    display: "flex",
                    alignContent: "center",
                    minHeight: 24.5,
                    textAlign: "left",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary50: "#e4e6ef",
                    primary25: "#e4e6ef",
                    primary75: "#e4e6ef",
                    primary: "#5d98f1e8",
                  },
                })}
              />
            )}
          />
        </div>
      </S.Wrapper>
      {errorToDisplay && (
        <S.Error>{errors[0][errorToDisplay]?.message}</S.Error>
      )}
    </S.Input>
  );
}
