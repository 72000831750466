import globals from "../Globals.json";
import api from "./api";

export const createChartAccount = async (objChartAccount: any) => {
  if (objChartAccount.uuid) {
    return api
      .put(
        `${globals.api.chartAccounts}/${objChartAccount.uuid}`,
        objChartAccount
      )
      .then((res: any) => {
        if (res.status === 201 || res.status === 200) {
          const objCallback = {
            data: res.data.data,
            status: true,
          };
          return objCallback;
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  } else {
    return api
      .post(`${globals.api.chartAccounts}`, objChartAccount)
      .then((res: any) => {
        if (res.status === 201 || res.status === 200) {
          const objCallback = {
            data: res.data.data,
            status: true,
          };
          return objCallback;
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }
};

export const getChartAccount = () => {
  return api.get(`${globals.api.chartAccounts}?paginate=false`);
};
