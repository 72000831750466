import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { createStockMovimentAsync } from "./services";
import { IStockMoviment } from "./interface";

interface InitialState {
  value: StockMovimentState;
}

interface StateForm {
  state: "error" | "loading" | "send" | "default";
  message?: string;
}

interface StockMovimentState {
  loading: boolean;
  fetchError: string;
  stateForm: StateForm;
  setEditando: boolean;
  setCad: boolean;
  stockMoviment: IStockMoviment[];
  budgetSelected: any;
}

const initialState: InitialState = {
  value: {
    loading: false,
    fetchError: "",
    stockMoviment: [],
    stateForm: { state: "default" },
    setEditando: false,
    setCad: false,
    budgetSelected: {},
  } as StockMovimentState,
};

export const stockMoviment = createSlice({
  name: "stock-moviment",
  initialState,
  reducers: {
    handleNew: (state: InitialState, action) => {
      state.value.setEditando = false;
      state.value.setCad = action.payload;
    },
    handleEditeRedux: (state: InitialState, action) => {
      state.value.setEditando = true;
      state.value.setCad = action.payload;
    },
    setBudgetSelected: (state: InitialState, action) => {
      state.value.budgetSelected = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(createStockMovimentAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      createStockMovimentAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IStockMoviment[]>) => {
        state.value.loading = false;
        state.value.stockMoviment = action.payload;
      }
    );
    builder.addCase(
      createStockMovimentAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );
  },
});

export const { handleNew, handleEditeRedux, setBudgetSelected } =
  stockMoviment.actions;

export default stockMoviment.reducer;
