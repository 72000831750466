import { useContext } from "react";
import ListaContasBancarias from "../../../Components/Financeiro/ContasBancarias/ListaContasBancarias";
import Container from "react-bootstrap/Container";
import "./styles.scss";
import { ThemeContext } from "../../../context/theme-context";

export default function CadBankAccounts() {
  const { collapsed } = useContext(ThemeContext);

  return (
    <Container
      className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
    >
      <ListaContasBancarias Title="Contas Bancárias" Path="bank-accounts" />
    </Container>
  );
}
