import { createContext, ReactNode, useState } from "react";
import Format from '../../Services/Format';

interface ChildrenProps {
  children: ReactNode;
}

interface InitialContextInterface {
  received: string;
  setReceived: React.Dispatch<React.SetStateAction<string>>;
  receivedMovi: string;
  setReceivedMovi: React.Dispatch<React.SetStateAction<string>>;
  receivedMoviPrint: string;
  setReceivedMoviPrint: React.Dispatch<React.SetStateAction<string>>;
  valueRef: number | undefined;
  setValueRef: React.Dispatch<React.SetStateAction<number | undefined>>;
  referent: string;
  setReferent: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  document: string;
  setDocument: React.Dispatch<React.SetStateAction<string>>;
  place: string;
  setPlace: React.Dispatch<React.SetStateAction<string>>;
  date: string;
  setDate: React.Dispatch<React.SetStateAction<string>>;
  show: boolean;
  handleClose: () => void;
  handleShow: () => void;
  handleCancelar: (props: any) => void;
}

export const ImprimirContext = createContext<InitialContextInterface>(
  {} as InitialContextInterface
);

export const ImprimirProvider = ({ children }: ChildrenProps) => {
  const [received, setReceived] = useState<string>("");
  const [receivedMovi, setReceivedMovi] = useState<string>("");
  const [receivedMoviPrint, setReceivedMoviPrint] = useState<string>("");
  const [valueRef, setValueRef] = useState<number>();
  const [referent, setReferent] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [document, setDocument] = useState<string>("");
  const [place, setPlace] = useState<string>("");
  const [date, setDate] = useState<string>(
    Format.date(new Date(), "YYYY-MM-DD")
  );

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setReceived("");
    setValueRef(undefined);
    setReferent("");
    setName("");
    setDocument("");
    setPlace("");
    setDate(Format.date(new Date(), "YYYY-MM-DD"));
  };

  const handleShow = () => {
    setShow(true);
    setReceived("");
    setValueRef(undefined);
    setReferent("");
    setName("");
    setDocument("");
    setPlace("");
    setDate(Format.date(new Date(), "YYYY-MM-DD"));
  };

  function handleCancelar(props: any) {
    props.OnAddNewPrintReceipt(false);

    setReceived("");
    setReceivedMovi("");
    setValueRef(undefined);
    setReferent("");
    setName("");
    setDocument("");
    setPlace("");
    setDate(Format.date(new Date(), "YYYY-MM-DD"));
  }

  return (
    <ImprimirContext.Provider
      value={{
        received, receivedMovi, receivedMoviPrint,
        valueRef,
        referent,
        name,
        document,
        place,
        date,
        show,
        setValueRef,
        setReceived, setReceivedMovi, setReceivedMoviPrint,
        setReferent,
        setName,
        setDocument,
        setPlace,
        setDate,
        handleClose,
        handleShow,
        handleCancelar,
      }}
    >
      {children}
    </ImprimirContext.Provider>
  );
};
