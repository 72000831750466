import DragDropImage from '../../DragDropImage';


interface IProps {
   state: any;
   setState: any;
}

export default function InputImg({ state, setState }: IProps) {

   return (
      <div>
         <DragDropImage
            src={state}
            onGetImage={(data: string) => setState(data)}
         />
      </div>
   )
}