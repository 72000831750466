import styled from "styled-components";

export const Section = styled.section`
  .contractStores {
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 0.4rem 1rem;
    margin: 1rem 0;
    max-width: 12rem;
    font-size: 0.9rem;
    color: #737373;
    font-weight: bold;
  }

  .card-store {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    @media screen and (max-width: 900px) {
      grid-template-columns: 1fr;
    }

    @media screen and (min-width: 1800px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`;
