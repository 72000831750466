import { IFinancialTransaction } from "../interface";

export default class FilterBy {
  static document(listData: IFinancialTransaction[], document: string) {
    return listData?.filter((item: IFinancialTransaction) => {
      const itemDocument = item.document?.toString();
      const stockDocument = item?.relationships?.stockMovement?.document_stock;
      return itemDocument === document || stockDocument === document;
    });
  }

  static amount(
    listData: IFinancialTransaction[],
    initialValue: any,
    finalValue: any
  ) {
    const filtered = listData.filter((obj: IFinancialTransaction) => {
      const amount = obj.amount;
      return amount >= initialValue && amount <= finalValue;
    });

    return filtered;
  }

  static receiver(listData: IFinancialTransaction[], receiver: number) {
    return listData.filter(
      (item: IFinancialTransaction) => item.receiver_people_id === receiver
    );
  }

  static chartAccount(listData: IFinancialTransaction[], chartAccount: number) {
    return listData.filter(
      (item: IFinancialTransaction) => item.chart_account_id === chartAccount
    );
  }

  static dueDate(
    listData: IFinancialTransaction[],
    startDate: any,
    endDate: any
  ) {
    const filtered = listData.filter((obj: IFinancialTransaction) => {
      const objDate = new Date(obj.due_date);
      const start = new Date(startDate);
      const end = new Date(endDate);

      return objDate >= start && objDate <= end;
    });

    return filtered;
  }

  static dueCreate(
    listData: IFinancialTransaction[],
    startDate: any,
    endDate: any
  ) {
    const filtered = listData.filter((obj: IFinancialTransaction) => {
      const objDate = new Date(obj.created_at);
      const start = new Date(startDate);
      const end = new Date(endDate);

      return objDate >= start && objDate <= end;
    });

    return filtered;
  }

  static status(listData: any, status: number) {
    return listData.filter((item: any) => item.status === status);
  }
}
