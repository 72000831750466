import * as Yup from "yup";

export const paymantSchema = Yup.object().shape({
  description: Yup.string().required("Por favor, insira a descrição."),
  chart_account_id: Yup.mixed().test(
    "is-string",
    "Por favor, selecione um plano de conta",
    function (value) {
      if (!value) {
        return false;
      }

      if (typeof value === "object" && value.value) {
        return true;
      }
      return false;
    }
  ),
});
