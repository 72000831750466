import { useContext, useState } from "react";
import { ThemeContext } from "../../../context/theme-context";
import { ISelect } from "../../../redux/features/inventary/services/object";
import { IStore } from "../../../Components/Configuracao/interface/store";
import { ListInventario } from "../../../Components/Estoque/Inventario/ListInventario";
import { FormInventario } from "../../../Components/Estoque/Inventario/FormInventario";
import { Container } from "react-bootstrap";
import { IInventary } from "../../../redux/features/inventary/interface";

export interface IListInventary {
  onEditItem: (item: IInventary) => void;
  onAddNewCli: (e: boolean) => void;
  inventaryData?: any;
  title: string;
}

export interface IFormInventary {
  title: string;
  OnAddNewInventary: (e: boolean) => void;
  inventaryData?: any;
  editando: boolean;
}

export interface ISelectOptions {
  type: ISelect[];
  store: IStore[];
}

export function CardInventario() {
  const { collapsed } = useContext(ThemeContext);
  const [cad, setCad] = useState<boolean>(false);
  const [toEditInventary, setToEditInventary] = useState<{}>({});
  const [editando, setEditando] = useState<boolean>(false);

  const handleNew = (e: boolean) => {
    setEditando(false);
    setToEditInventary({});
    setCad(e);
  };

  const handleEdit = (props: IInventary) => {
    setToEditInventary(props);
    setCad(true);
    setEditando(true);
  };

  return (
    <>
      {!cad ? (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <ListInventario
            title={"Inventário"}
            onEditItem={(item: IInventary) => handleEdit(item)}
            inventaryData={toEditInventary}
            onAddNewCli={(e: boolean) => {
              handleNew(e);
            }}
          />
        </Container>
      ) : (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <FormInventario
            title={toEditInventary ? "Criar Inventário" : "Editar Inventário"}
            OnAddNewInventary={(e: boolean) => handleNew(e)}
            inventaryData={toEditInventary}
            editando={editando}
          />
        </Container>
      )}
    </>
  );
}
