import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { setupCache } from "axios-cache-adapter";
import { getToken } from "./auth";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  adapter: cache.adapter,
});

// Interceptador para adicionar token de autorização e X-Store ao cabeçalho da requisição
api.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["X-Store"] = localStorage.getItem("X-Store");
  }
  return config;
});

// Interceptador para lidar com erros de resposta
api.interceptors.response.use(
  (response) => {
    const token = getToken();

    if (token) {
      response.headers["Authorization"] = `Bearer ${token}`;
    }

    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
