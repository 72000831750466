import { useContext, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useAuth } from "../../context/Auth";
import { ThemeContext } from "../../context/theme-context";
import { FormHandles } from "@unform/core";
import { StateForm } from "../../interfaces/state-form";
import { InputClone } from "../../Components/MyAccount/InputClone";

export default function MyAccount() {
  const { user } = useAuth();
  const { collapsed } = useContext(ThemeContext);
  const formRef = useRef<FormHandles>(null);
  const [stateForm, setStateForm] = useState<StateForm>({
    state: "default",
    message: "",
  });

  const handleSubmit = () => {};

  return (
    <Container
      className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
    >
      <form title="Minha Conta">
        <InputClone user={user} />
      </form>
    </Container>
  );
}
