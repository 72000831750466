import { Fragment, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../../../Loading/Loading";
import { NoData } from "../../../../noData";
import { useAppSelector } from "../../../../../redux/store";
import { Btn } from "../../../../data/Btn";
import { Tree } from "./Tree";
import ModalPDV from "./ModalPDV";
import { IPaymentEntry } from "../../../../../redux/features/paymentType/interface";

export default function ExpenseModal({
  OnAddNewChartAccount,
  setKeyActived,
}: any) {
  const { expenxe } = useAppSelector(
    ({ categorieFinancialReducer }) => categorieFinancialReducer.value
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [openModalCadPDV, setModalCadPDV] = useState<boolean>(false);
  const [listPDV, setListPDV] = useState<any[]>([]);
  const [listEditeItem, setListEditeItem] = useState<
    IPaymentEntry | undefined
  >();

  const handleEdit = (item: any) => {
    setListEditeItem(item);
    setModalCadPDV(true);
  };

  return (
    <>
      <ModalPDV
        show={openModalCadPDV}
        setShow={setModalCadPDV}
        setLoading={setLoading}
        listPDV={listPDV}
        setListPDV={setListPDV}
        listEditeItem={listEditeItem}
        setListEditeItem={setListEditeItem}
        setKeyActived={setKeyActived}
      />
      <div className="mb-2 mt-4">
        <Btn
          text={"Novo recebimento"}
          type="button"
          variante="primary"
          icon="plus"
          onClick={() => setModalCadPDV(true)}
        />
      </div>
      <div className="mt-4">
        {expenxe.length > 0 && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "2fr 1fr 2fr 2fr 2fr ",
              flex: "1",
              fontSize: "13px",
              paddingLeft: "1.9rem",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            <div>Data de Cadastro</div>
            <div>Código</div>
            <div>Descrição</div>

            <span style={{ maxWidth: "50px" }}>Status</span>
            <div style={{ padding: "0 15px" }}></div>
          </div>
        )}
        {expenxe.length > 0 ? (
          expenxe?.map((tree: any) => (
            <Fragment key={tree.id}>
              <Tree
                categorie={tree}
                OnAddNewCategorie={OnAddNewChartAccount}
                handleEdit={handleEdit}
              />
            </Fragment>
          ))
        ) : (
          <NoData />
        )}
      </div>
      {loading && <Loading />}
    </>
  );
}
