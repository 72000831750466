import { useContext } from "react";
import { Row, Form, Col } from "react-bootstrap";
import Layout from "../../../data/Layout";
import { CategoryContext } from "../../../../context/category";

export function InfoSales() {
  const { setSelfService, selfService } = useContext(CategoryContext);

  return (
    <div className="px-4 pb-5">
      <Row className="px-2">
        <Col md="3">
          <Form.Switch
            label="Autoatendimento"
            onChange={(e: any) => setSelfService(e.target.checked)}
            checked={selfService}
          />
        </Col>
      </Row>
    </div>
  );
}
