import { useEffect, useState, useContext } from "react";
import { Row, Col, Button } from "react-bootstrap";
import GetEndPoint from "../../../../Services/GetEndPoint";
import ReactSelect from "../../../data/reactSelect";
import InputCode from "../../../data/inputCode";
import { InputDefault } from "../../../data/inputDefault";
import InputImg from "../../../data/inputImg";
import Layout from "../../../data/Layout";
import { ProdutoContext } from "../../../../context/product";
import { useAppSelector } from "../../../../redux/store";
import ModalSupplier from "../FormProduto/ModalSupplier";
import globals from "../../../../Globals.json";
import api from "../../../../Services/api";
import Loading from "../../../Loading/Loading";
import * as S from "./styled";
import ReactSelectFornecedor from "../../../data/reactSelectFornecedor";
import { IoPeopleSharp } from "react-icons/io5";

export default function DadosGerais() {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const [show, setShow] = useState<boolean>(false);

  const {
    status,
    setStatus,
    image,
    setImage,
    code,
    setCode,
    barcode,
    setBarcode,
    descriptionSale,
    setDescriptionSale,
    descriptionPurchase,
    setDescriptionPurchase,
    descriptionTouch,
    setDescriptionTouch,
    category,
    setCategory,
    vendor,
    setVendor,
    productType,
    setProductType,
    validCode,
    validDescriptionSale,
    validDescriptionPurchase,
    validDescriptionTouch,
    validCategory,
    validProductType,
    setListCode,
    listCode,
    setLoadingListCode,
    loadingListCode,
    setLoading,
  } = useContext(ProdutoContext);

  const [vendorList, setVendorList] = useState<Array<any>>([]);
  const [categoriesList, setCategoriesList] = useState<Array<any>>([]);
  const [typeList, setTypeList] = useState<Array<any>>([]);

  function generateCode() {
    if (listCode.length === 0) {
      setCode("1");
      return;
    }

    const valores = listCode
      .map((item: any) => parseInt(item.label))
      .filter((value: any) => !isNaN(value));
    const maximoAtual = Math.max(...valores);

    const novoValor = (maximoAtual + 1).toString();

    setCode(novoValor);
  }

  function generateProductCode() {
    let codBar = Math.floor(Math.random() * 10000000000000);
    setBarcode(codBar.toString());
  }

  const getListCode = async () => {
    setLoadingListCode(true);
    await api.get(`${globals.api.products}?paginate=false`).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

      let list = data.map((item: any) => ({
        value: item.id,
        label: `${item.code}`,
        store_id: item.store_id,
      }));
      setListCode(list);
    });
    setLoadingListCode(false);
  };

  useEffect(() => {
    GetEndPoint.categories(setCategoriesList);
    GetEndPoint.vendor(setVendorList);
    GetEndPoint.productTypes(setTypeList);
    getListCode();
  }, []);

  useEffect(() => {
    generateCode();
    setProductType(1);
  }, [listCode]);

  let newListCategorie = categoriesList.filter(
    (item) => item.store_id === storeMe.storeActive.id
  );
  let newVendorList = vendorList.filter(
    (item) => item.store_id === storeMe.storeActive.id
  );
  const bg = status ? "#16a34a" : "#f87171";

  return (
    <>
      <ModalSupplier
        show={show}
        setShow={setShow}
        setLoading={setLoading}
        setSuplier={setVendorList}
      />
      <Layout
        title="Dados gerais"
        check
        statusCheck={status}
        setStatusCheck={setStatus}
      >
        <S.WrapperImg>
          <button
            type="button"
            onClick={() => setStatus(!status)}
            style={{ background: bg }}
          >
            {status ? "Ativo" : "Inativo"}
          </button>
        </S.WrapperImg>
        <S.Content>
          <S.WrapperImg>
            <InputImg state={image} setState={setImage} />
          </S.WrapperImg>
          <S.Wrapper>
            <InputCode
              label="Código"
              placeholder="Código"
              type="text"
              autoComplete="off"
              state={code}
              onClick={generateCode}
              isInvalid={validCode}
            />
            <InputCode
              label="Cod. Barra"
              placeholder="SKU ou EAN"
              type="text"
              autoComplete="off"
              state={barcode}
              onClick={generateProductCode}
            />
            <InputDefault
              label="Des. Compra"
              type="text"
              placeholder="Descrição de compra"
              autoComplete="off"
              state={descriptionPurchase}
              setState={setDescriptionPurchase}
              maxLength={50}
              isInvalid={validDescriptionPurchase}
            />
            <InputDefault
              label="Des. Venda"
              placeholder="Descrição de venda"
              type="text"
              autoComplete="off"
              state={descriptionSale}
              onChenge={(e: any) => {
                setDescriptionSale(e.target.value);
                setDescriptionPurchase(e.target.value);
                setDescriptionTouch(e.target.value);
              }}
              maxLength={50}
              isInvalid={validDescriptionSale}
            />
            <InputDefault
              label="Des. touch"
              placeholder="Descrição touch"
              type="text"
              autoComplete="off"
              state={descriptionTouch}
              setState={setDescriptionTouch}
              maxLength={50}
              isInvalid={validDescriptionTouch}
            />
            <ReactSelect
              label="Categoria"
              placeholder="Categoria"
              isClearable
              options={newListCategorie}
              state={category}
              setState={setCategory}
              isInvalid={validCategory}
            />
            <ReactSelectFornecedor
              label="Fornecedor"
              placeholder="Fornecedor"
              isClearable
              options={newVendorList}
              state={vendor}
              setState={setVendor}
              setShow={setShow}
              icon={<IoPeopleSharp />}
            />

            <ReactSelect
              label="Tipo"
              placeholder="Tipo"
              isClearable
              options={typeList}
              state={productType}
              setState={setProductType}
              isInvalid={validProductType}
            />
          </S.Wrapper>
        </S.Content>
      </Layout>

      {loadingListCode && <Loading />}
    </>
  );
}
