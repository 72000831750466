import { useContext, useState } from "react";
import { Container } from "react-bootstrap";
import { ThemeContext } from "../../../context/theme-context";
import { IPerfilFiscal } from "../../../Components/Cadastro/PerfilFiscal/interface/IPerfilFiscal";
import ListaPerfilFiscal from "../../../Components/Cadastro/PerfilFiscal/ListaPerfilFiscal";
import FormPerfilFiscal from "../../../Components/Cadastro/PerfilFiscal/FormPerfilFiscal";
import { useAppSelector } from "../../../redux/store";

export interface IListPerfilFiscal {
  title: string;
  OnAddNewCli: (e: boolean) => void;
  onEditItem: (item: IPerfilFiscal) => void;
  OnAddNewPerfilFiscal: (e: boolean) => void;
}

export interface IFormPerfilFiscal {
  title: string;
  OnAddNewPerfilFiscal: (e: boolean) => void;
  perfilFical?: IPerfilFiscal;
  editando: boolean;
}

export function CadPerfilFiscal() {
  const { collapsed } = useContext(ThemeContext);

  const [cad, setCad] = useState<boolean>(false);
  const [toEditPerfilFiscal, setToEditPerfilFiscal] = useState<IPerfilFiscal>();
  const [editando, setEditando] = useState<boolean>(false);

  const handleNew = (e: boolean) => {
    setEditando(false);
    setCad(e);
  };

  const handleEdit = (props: IPerfilFiscal) => {
    setToEditPerfilFiscal(props);
    setCad(true);
    setEditando(true);
  };

  return (
    <>
      {!cad ? (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <ListaPerfilFiscal
            title="Perfil Fiscal"
            onEditItem={(item: IPerfilFiscal) => handleEdit(item)}
            OnAddNewCli={(e: boolean) => handleNew(e)}
            OnAddNewPerfilFiscal={(e: boolean) => handleNew(e)}
          />
        </Container>
      ) : (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <FormPerfilFiscal
            title={
              toEditPerfilFiscal ? "Editar Perfil Fiscal" : "Nova Perfil Fiscal"
            }
            OnAddNewPerfilFiscal={(e: boolean) => handleNew(e)}
            perfilFical={toEditPerfilFiscal}
            editando={editando}
          />
        </Container>
      )}
    </>
  );
}
