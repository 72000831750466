import * as S from "./styles";
export function NoData() {
  return (
    <S.Content>
      {/*
      <img src="/img/noData1.svg" alt="no data" />
      */}
      <p>Nenhuma informação foi encontrada.</p>
    </S.Content>
  );
}
