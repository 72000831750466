import { Form } from "react-bootstrap";
import styled from "styled-components";

interface IProps {
  errorToDisplay: any;
}

export const Input = styled.div<IProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.3rem;
  margin-left: 4px;

  input {
    box-shadow: var(--color-input-shadow);
    border: ${({ errorToDisplay }) =>
      errorToDisplay
        ? "1px solid var(--error)"
        : "1px solid var(--color-input-border)"};
    border-radius: 2px;
    padding: 2.5px 9px;

    ::placeholder {
      font-size: 12px;
      color: var(--color-input-placeholder);
    }
  }

  .inputMask {
    font-size: 13px;
    width: 100%;
    padding: 0.8px 9px;
    border-radius: 2px;
    transition: all 0.08s ease-in-out;
    border: ${({ errorToDisplay }) =>
      errorToDisplay
        ? "1px solid var(--error)"
        : "1px solid var(--color-input-border)"};

    ::placeholder {
      font-size: 12px;
      color: var(--color-input-placeholder);
    }

    :focus {
      outline: 4px solid #c2dbfe;
      border: 1px solid #86b7fe;
    }
  }
`;

export const Wrapper = styled.div``;

export const FormInput = styled(Form.Control)`
  font-size: 12px;
  display: none;
  ::placeholder {
    font-size: 12px;
    color: var(--color-input-placeholder);
  }
`;

export const Error = styled.div`
  font-size: 10px;
  color: #dc2229;
  margin-top: 0.2rem;
`;
