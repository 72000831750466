import {
  Button,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { IListInventary } from "../../../../Pages/Cadastro/CadInventario";
import Loading from "../../../Loading/Loading";
import { ToastContainer } from "react-toastify";
import { AppDispatch, useAppSelector } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  chargeBackInventory,
  closeInventory,
  getAllProductsAsync,
  getCategoriesAsync,
  getInventaryAsync,
  getProductsAsync,
  getStockAsync,
} from "../../../../redux/features/inventary/services";
import LayoutHookTable from "../../../reactHookForm/LayoutTable";
import DataTable from "react-data-table-component";
import tableStyleDefault from "../../../../Services/tableStyleDefault";
import { paginationOptions } from "../../../data/PagineteInfo";
import { FaEdit, FaEye } from "react-icons/fa";
import { InputFilter } from "./InputFilter";
import { SubmitHandler, useForm } from "react-hook-form";
import LayoutHookFilter from "./LayoutFilter";
import Format from "../../../../Services/Format";
import DateUtils from "../../../../Services/DateUtils";
import { IInventary } from "../../../../redux/features/inventary/interface";
import { RiArrowGoBackFill } from "react-icons/ri";
import {
  notify,
  notifyError,
  notifyWarning,
} from "../../../notify/notify-component";
import { FaRocket } from "react-icons/fa6";
import ModalBootstrap from "../../../data/Modal";
import { NoData } from "../../../noData";
import CustomLoader from "../../../data/customLoader";
import ReactSelect from "../../../data/reactSelect";
import { setContagemSelected } from "../../../../redux/features/inventary/inventary-slice";
import * as S from "./styled";

interface IFormInput {}

export function ListInventario({
  title,
  onEditItem,
  onAddNewCli,
}: IListInventary) {
  const dispatch = useDispatch<AppDispatch>();
  const inventary = useAppSelector(
    ({ inventaryReducer }) => inventaryReducer.value
  );
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [openModalClose, setOpeonModalClose] = useState<boolean>(false);
  const [uuidModalClose, setUuidModalClose] = useState<string>("");
  const [openModalChargeBack, setOpeonModalChargeBack] =
    useState<boolean>(false);
  const [uuidModalChargeBack, setUuidModalChargeBack] = useState<string>("");
  const [modalContagem, setModalContagem] = useState<boolean>(false);
  const [contagemId, setContagemId] = useState<number>();
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm<IFormInput>();
  const inputError = [errors];

  const columns: any = [
    {
      name: "ID",
      selector: (row: IInventary) => row.id,
      center: true,
      sortable: true,
      grow: 0.2,
    },
    {
      name: "Tipo",
      center: true,
      grow: 0.3,
      selector: (row: IInventary) =>
        row.type === 1 ? "Contagem" : "Inventário",
    },
    {
      name: "Nome",
      center: true,
      sortable: true,
      selector: (row: IInventary) => row?.name?.toLowerCase() ?? "---",
    },

    {
      name: "Status",
      center: true,
      sortable: true,
      grow: 0.3,
      selector: (row: IInventary) => row?.status_description?.toLowerCase(),
    },

    {
      name: "Data Criação",
      center: true,
      grow: 0.5,
      selector: (row: IInventary) =>
        Format.date(DateUtils.toDate(row.created_at), "DD/MM/YYYY"),
    },
    {
      name: "Data Finalização",
      center: true,
      grow: 0.5,
      selector: (row: IInventary) =>
        Format.date(DateUtils.toDate(row.created_at), "DD/MM/YYYY"),
    },
    {
      name: "Usuario",
      sortable: true,
      center: true,
      selector: (row: IInventary) =>
        row?.relationships?.user?.name?.toLowerCase(),
    },
    {
      name: "Ação",
      center: true,
      grow: 0.3,
      cell: (row: IInventary) => (
        <>
          {row.status === 1 ? (
            <>
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-disabled">Fechar</Tooltip>}
              >
                <span className="d-inline-block">
                  <Button
                    onClick={() => handleOpenModalClose(row.uuid)}
                    variant="contained"
                    color="primary"
                  >
                    <FaRocket size={16} color="8e8d8d" />
                  </Button>
                </span>
              </OverlayTrigger>
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-disabled">Editar</Tooltip>}
              >
                <span className="d-inline-block">
                  <Button
                    onClick={() => handleEdit(row)}
                    variant="contained"
                    color="primary"
                  >
                    <FaEdit size={16} color="8e8d8d" />
                  </Button>
                </span>
              </OverlayTrigger>
            </>
          ) : row.uuid === inventary.lastUuidCanChargeBack ? (
            <>
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">
                    {row.relationships?.entryMovement?.situation_id === 2
                      ? "Já estornado"
                      : "Estornar"}
                  </Tooltip>
                }
              >
                <span className="d-inline-block ">
                  <Button
                    className="border-0"
                    onClick={() => handleOpenModalChargeBack(row?.uuid)}
                    variant="contained"
                    color="primary"
                    disabled={
                      row.relationships?.entryMovement?.situation_id === 2
                    }
                  >
                    <RiArrowGoBackFill size={16} color="8e8d8d" />
                  </Button>
                </span>
              </OverlayTrigger>
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-disabled">Visalizar</Tooltip>}
              >
                <span className="d-inline-block">
                  <Button
                    onClick={() => handleEdit(row)}
                    variant="contained"
                    color="primary"
                  >
                    <FaEye size={16} color="8e8d8d" />
                  </Button>
                </span>
              </OverlayTrigger>
            </>
          ) : (
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Visalizar</Tooltip>}
            >
              <span className="d-inline-block">
                <Button
                  onClick={() => handleEdit(row)}
                  variant="contained"
                  color="primary"
                >
                  <FaEye size={16} color="8e8d8d" />
                </Button>
              </span>
            </OverlayTrigger>
          )}
        </>
      ),
      button: true,
    },
  ];

  const handleEdit = (row: IInventary) => {
    onEditItem(row);
  };

  const handleAdd = () => {
    onAddNewCli(true);
  };

  const onSubmit: SubmitHandler<IFormInput> = async (data: any) => {};

  const handleCloseInventary = (uuid: string) => {
    dispatch(closeInventory(uuid)).then((result: any) => {
      if (closeInventory.fulfilled.match(result)) {
        notify("Inventário fechado.");
        dispatch(getInventaryAsync(storeMe.storeActive.id));
      } else {
        notifyWarning("Inventário esta vazio.");
      }
    });
    setOpeonModalClose(false);
  };

  const handleChargeBackConfirmation = (uuid: string) => {
    dispatch(chargeBackInventory(uuid)).then((result: any) => {
      if (chargeBackInventory.fulfilled.match(result)) {
        notify("Inventário estornado.");
        dispatch(getInventaryAsync(storeMe.storeActive.id));
      } else {
        notifyError("Erro ao estornar inventário.");
      }
    });
  };

  const onClickAddAlternative = () => {
    setModalContagem(true);
  };

  const handleSelectedContagem = () => {
    const selected = inventary.contagem?.find(
      (item: any) => item.id === contagemId
    );
    dispatch(setContagemSelected(selected));
    onAddNewCli(true);
  };

  const handleCancelModalContagem = () => {
    setModalContagem(false);
    setContagemId(0);
  };

  const handleOpenModalClose = (uuid: string) => {
    setOpeonModalClose(true);
    setUuidModalClose(uuid);
  };

  const handleOpenModalChargeBack = (uuid: string) => {
    setOpeonModalChargeBack(true);
    setUuidModalChargeBack(uuid);
  };

  const onClickOpenFilter = () => {
    setOpenFilter(!openFilter);
  };

  useEffect(() => {
    //dispatch(getStoreMeAsync());
    dispatch(getInventaryAsync(storeMe.storeActive.id));
    dispatch(getAllProductsAsync());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getStockAsync(storeMe.storeActive ? storeMe.storeActive.id : 0));
    dispatch(
      getCategoriesAsync(storeMe.storeActive ? storeMe.storeActive.id : 0)
    );
    dispatch(
      getProductsAsync(inventary.stock.length > 0 ? inventary.stock[0].id : 0)
    );
  }, [storeMe, dispatch]);

  return (
    <div>
      <LayoutHookFilter
        title={title}
        onClickAdd={handleAdd}
        onClickClear={() => reset()}
        altBtnText="Contagem"
        onClickAddAlternative={onClickAddAlternative}
        onClickOpenFilter={onClickOpenFilter}
        openFilter={openFilter}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputFilter
            register={register}
            control={control}
            watch={watch}
            setValue={setValue}
            setError={setError}
            errors={inputError}
            reset={reset}
            openFilter={openFilter}
          />
        </form>
      </LayoutHookFilter>

      <LayoutHookTable>
        <DataTable
          columns={columns}
          data={inventary?.invetary || []}
          striped
          pagination
          paginationDefaultPage={1}
          paginationPerPage={25}
          customStyles={tableStyleDefault}
          paginationComponentOptions={paginationOptions}
          progressPending={inventary.loading}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhuma informação foi encontrada."
        />
      </LayoutHookTable>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <ModalBootstrap
        text="Ao fechar o inventário, você não poderá mais fazer alterações. Tem certeza de que deseja continuar?"
        state={openModalClose}
        setState={setOpeonModalClose}
        handleAction={() => handleCloseInventary(uuidModalClose)}
      />

      <ModalBootstrap
        text="Deseja realmente estornar este inventário?"
        state={openModalChargeBack}
        setState={setOpeonModalChargeBack}
        handleAction={() => handleChargeBackConfirmation(uuidModalClose)}
      />

      <Modal show={modalContagem} onHide={handleCancelModalContagem}>
        <Modal.Header closeButton style={{ backgroundColor: "#ced0d4f4" }}>
          <h3 style={{ fontSize: "18px" }}>Utilizar Orçamento</h3>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#f7f7f8f4" }}>
          <p>
            Selecione uma contagem para ser utilizado como base para a criação
            de um inventário:
          </p>

          <S.Wrapper>
            <ReactSelect
              state={contagemId}
              label="Orçamento"
              placeholder="Orçamento"
              options={inventary?.contagem?.map((item: IInventary) => ({
                value: item.id,
                label: item?.name,
              }))}
              setState={setContagemId}
            />
          </S.Wrapper>
          <span style={{ fontSize: "10px" }}>
            As informações base para uma movimentação de estoque restantes serão
            preenchidas a seguir.
          </span>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: "#f7f7f8f4",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="secondary"
            onClick={handleCancelModalContagem}
            size="sm"
          >
            Cancelar
          </Button>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              variant="success"
              onClick={handleSelectedContagem}
              size="sm"
              style={{ padding: "4px 20px" }}
            >
              Avançar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
