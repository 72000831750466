export const ObjType = [
  {
    value: 1,
    label: "Matriz",
  },
  {
    value: 2,
    label: "Filial",
  },
];

export const ObjRegimeTributario = [
  {
    value: "1",
    label: "1 - Simples Nacional",
  },
  {
    value: "2",
    label: "2 - Simples Nacional - excesso de sublimite de receita bruta",
  },
  {
    value: "3",
    label: "3 - Regime Normal (Lucro Presumido ou Lucro Real)",
  },
  {
    value: "4",
    label: "4 - Lucro Arbitrado",
  },
  {
    value: "5",
    label: "5 - Regime Especial de Tributação (RET)",
  },
  {
    value: "6",
    label: "6 - Regime Especial de Incentivos para o Desenvolvimento (REID)",
  },
  {
    value: "7",
    label: "7 - Simples Internacional",
  },
  {
    value: "8",
    label: "8 - Zona Franca de Manaus",
  },
];

export const ObjCSOSN = [
  {
    value: "101",
    label: "101 - Tributada pelo Simples Nacional com permissão de crédito",
  },
  {
    value: "102",
    label: "102 - Tributada pelo Simples Nacional sem permissão de crédito",
  },
  {
    value: "103",
    label:
      "103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta",
  },
  {
    value: "201",
    label:
      "201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária",
  },
  {
    value: "202",
    label:
      "202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária",
  },
  {
    value: "203",
    label:
      "203 - Isenção do ICMS no Simples Nacional para faixa de receita bruta com cobrança do ICMS por substituição tributária",
  },
  {
    value: "300",
    label: "300 - Imune ao ICMS no Simples Nacional",
  },
  {
    value: "400",
    label: "400 - Não tributada pelo Simples Nacional",
  },
  {
    value: "500",
    label:
      "500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação",
  },
  {
    value: "900",
    label: "900 - Outros",
  },
];

export const ObjSituacoesTributarias = [
  {
    value: "1",
    label: "00 - Tributação pelo Simples Nacional",
  },
  {
    value: "10",
    label:
      "10 - Tributação pelo Simples Nacional com excesso de sublimite de receita bruta",
  },
  {
    value: "20",
    label: "20 - Tributação pelo Simples Nacional com permissão de crédito",
  },
  {
    value: "30",
    label: "30 - Tributação pelo Simples Nacional sem permissão de crédito",
  },
  {
    value: "40",
    label:
      "40 - Tributação pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária",
  },
  {
    value: "41",
    label:
      "41 - Tributação pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária",
  },
  {
    value: "50",
    label:
      "50 - Tributação pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária",
  },
  {
    value: "51",
    label:
      "51 - Tributação pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária",
  },
  {
    value: "60",
    label:
      "60 - Tributação pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária",
  },
  {
    value: "70",
    label:
      "70 - Tributação no Simples Nacional, por substituição tributária, com permissão de crédito",
  },
  {
    value: "90",
    label: "90 - Tributação no Simples Nacional, sem permissão de crédito",
  },
  {
    value: "101",
    label: "101 - Tributada pelo Simples Nacional com permissão de crédito",
  },
  {
    value: "102",
    label: "102 - Tributada pelo Simples Nacional sem permissão de crédito",
  },
  {
    value: "103",
    label:
      "103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta",
  },
  {
    value: "201",
    label:
      "201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária",
  },
  {
    value: "202",
    label:
      "202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária",
  },
  {
    value: "203",
    label:
      "203 - Isenção do ICMS no Simples Nacional para faixa de receita bruta com cobrança do ICMS por substituição tributária",
  },
  {
    value: "300",
    label: "300 - Imune ao ICMS no Simples Nacional",
  },
  {
    value: "400",
    label: "400 - Não tributada pelo Simples Nacional",
  },
  {
    value: "500",
    label:
      "500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação",
  },
  {
    value: "900",
    label: "900 - Outros",
  },
];

export const CFOPVendaComST = [
  {
    value: "5102",
    label:
      "5102 - Venda de mercadoria adquirida ou recebida de terceiros com ICMS por substituição tributária",
  },
  {
    value: "5403",
    label:
      "5403 - Venda de mercadoria adquirida ou recebida de terceiros, sujeita ao ICMS por substituição tributária",
  },
  {
    value: "5405",
    label:
      "5405 - Venda de mercadoria adquirida ou recebida de terceiros com ICMS por substituição tributária",
  },
  {
    value: "5415",
    label:
      "5415 - Venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao ICMS por substituição tributária",
  },
  {
    value: "5203",
    label:
      "5203 - Devolução de venda de mercadoria adquirida ou recebida de terceiros, sujeita ao ICMS por substituição tributária",
  },
  {
    value: "5205",
    label:
      "5205 - Devolução de venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao ICMS por substituição tributária",
  },
  {
    value: "2411",
    label:
      "2411 - Devolução de venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao ICMS por substituição tributária",
  },
  {
    value: "1411",
    label:
      "1411 - Devolução de venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao ICMS por substituição tributária",
  },
];

export const CFOPVendaComICMS = [
  {
    value: "5101",
    label: "5101 - Venda de produção do estabelecimento",
  },
  {
    value: "5102",
    label: "5102 - Venda de mercadoria adquirida ou recebida de terceiros",
  },
  {
    value: "5403",
    label:
      "5403 - Venda de mercadoria adquirida ou recebida de terceiros, sujeita ao ICMS",
  },
  {
    value: "5405",
    label:
      "5405 - Venda de mercadoria adquirida ou recebida de terceiros com ICMS por substituição tributária",
  },
  {
    value: "5415",
    label:
      "5415 - Venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao ICMS por substituição tributária",
  },
  {
    value: "5910",
    label: "5910 - Remessa em bonificação, doação ou brinde",
  },
  {
    value: "5949",
    label:
      "5949 - Outra saída de mercadoria ou prestação de serviço não especificado",
  },
  {
    value: "6403",
    label:
      "6403 - Transferência de mercadoria adquirida ou recebida de terceiros",
  },
  {
    value: "6152",
    label: "6152 - Transferência de produção do estabelecimento",
  },
  {
    value: "6102",
    label: "6102 - Venda de mercadoria adquirida ou recebida de terceiros",
  },
  {
    value: "6107",
    label:
      "6107 - Venda de produção do estabelecimento, destinada a não contribuinte",
  },
  {
    value: "6108",
    label:
      "6108 - Venda de produção do estabelecimento, destinada a não contribuinte, com cobrança do ICMS por substituição tributária",
  },
  {
    value: "6929",
    label:
      "6929 - Lançamento efetuado em decorrência de emissão de documento fiscal relativo a operação ou prestação também registrada em equipamento Emissor de Cupom Fiscal - ECF",
  },
  {
    value: "6655",
    label:
      "6655 - Venda de combustível ou lubrificante adquirido ou recebido de terceiros destinado à comercialização",
  },
  {
    value: "7102",
    label: "7102 - Venda de mercadoria adquirida ou recebida de terceiros",
  },
  {
    value: "5702",
    label: "5702 - Devolução de compra para industrialização ou produção rural",
  },
  {
    value: "5901",
    label: "5901 - Remessa para industrialização por encomenda",
  },
  {
    value: "5902",
    label:
      "5902 - Retorno de mercadoria utilizada na industrialização por encomenda",
  },
  {
    value: "5908",
    label: "5908 - Remessa de mercadoria por conta e ordem de terceiros",
  },
  {
    value: "5915",
    label:
      "5915 - Remessa de mercadoria, adquirida ou recebida de terceiros, destinada a outra unidade da mesma empresa, ou de outra empresa do mesmo titular",
  },
  {
    value: "5916",
    label:
      "5916 - Devolução de mercadoria recebida em transferência para industrialização e o retorno de mercadoria recebida em transferência para industrialização por encomenda",
  },
  {
    value: "6916",
    label:
      "6916 - Devolução de mercadoria recebida em transferência para industrialização e o retorno de mercadoria recebida em transferência para industrialização por encomenda",
  },
  {
    value: "5917",
    label:
      "5917 - Remessa de mercadoria em consignação mercantil ou industrial",
  },
  {
    value: "6922",
    label:
      "6922 - Lançamento efetuado a título de simples faturamento decorrente de venda para entrega futura (NFC-e)",
  },
  {
    value: "6923",
    label:
      "6923 - Lançamento efetuado a título de simples faturamento decorrente de venda para entrega futura (NFC-e)",
  },
];

export const CFOPDevolucaoVendasComST = [
  {
    value: "5202",
    label: "5202 - Devolução de venda de produção do estabelecimento",
  },
  {
    value: "5204",
    label:
      "5204 - Devolução de venda de mercadoria adquirida ou recebida de terceiros",
  },
  {
    value: "5203",
    label:
      "5203 - Devolução de venda de mercadoria adquirida ou recebida de terceiros, sujeita ao ICMS por substituição tributária",
  },
  {
    value: "5205",
    label:
      "5205 - Devolução de venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao ICMS por substituição tributária",
  },
  {
    value: "1411",
    label:
      "1411 - Devolução de venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao regime de substituição tributária",
  },
  {
    value: "1403",
    label: "1403 - Devolução de venda de mercadoria para o exterior",
  },
];

export const CFOPTransferenciaEstoque = [
  {
    value: "5927",
    label: "5927 - Transferência de produção do estabelecimento",
  },
  {
    value: "5401",
    label:
      "5401 - Transferência de mercadoria adquirida ou recebida de terceiros",
  },
  {
    value: "5910",
    label: "5910 - Remessa em bonificação, doação ou brinde",
  },
  {
    value: "5922",
    label:
      "5922 - Lançamento efetuado a título de simples faturamento decorrente de venda para entrega futura",
  },
  {
    value: "1102",
    label: "1102 - Transferência de produção do estabelecimento",
  },
  {
    value: "1152",
    label: "1152 - Transferência para comercialização",
  },
  {
    value: "1201",
    label: "1201 - Devolução de venda de produção do estabelecimento",
  },
  {
    value: "1403",
    label: "1403 - Devolução de venda de mercadoria para o exterior",
  },
  {
    value: "1411",
    label:
      "1411 - Devolução de venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao regime de substituição tributária",
  },
  {
    value: "1917",
    label:
      "1917 - Entrada de mercadoria recebida em consignação mercantil ou industrial",
  },
  {
    value: "1926",
    label:
      "1926 - Lançamento efetuado a título de reclassificação de mercadoria decorrente de formação de kit ou de sua desagregação",
  },
  {
    value: "2102",
    label: "2102 - Devolução de venda de produção do estabelecimento",
  },
  {
    value: "2152",
    label: "2152 - Transferência para comercialização",
  },
  {
    value: "2201",
    label: "2201 - Devolução de venda de produção do estabelecimento",
  },
  {
    value: "2403",
    label:
      "2403 - Devolução de venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao regime de substituição tributária",
  },
  {
    value: "2411",
    label:
      "2411 - Devolução de venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao regime de substituição tributária",
  },
  {
    value: "3102",
    label:
      "3102 - Transferência de mercadoria adquirida ou recebida de terceiros",
  },
  {
    value: "5152",
    label:
      "5152 - Transferência de mercadoria adquirida ou recebida de terceiros",
  },
  {
    value: "6403",
    label:
      "6403 - Transferência de mercadoria adquirida ou recebida de terceiros",
  },
  {
    value: "6102",
    label: "6102 - Venda de mercadoria adquirida ou recebida de terceiros",
  },
  {
    value: "6107",
    label:
      "6107 - Venda de produção do estabelecimento, destinada a não contribuinte",
  },
  {
    value: "6108",
    label:
      "6108 - Venda de produção do estabelecimento, destinada a não contribuinte",
  },
  {
    value: "6152",
    label: "6152 - Venda de mercadoria adquirida ou recebida de terceiros",
  },
  {
    value: "6655",
    label:
      "6655 - Venda de combustível ou lubrificante adquirido ou recebido de terceiros destinado à comercialização",
  },
  {
    value: "6929",
    label:
      "6929 - Lançamento efetuado em decorrência de emissão de documento fiscal relativo a operação ou prestação também registrada em equipamento Emissor de Cupom Fiscal - ECF",
  },
  {
    value: "7102",
    label: "7102 - Venda de mercadoria adquirida or recebida de terceiros",
  },
];

export const CFOPsEmissaoDeNotasFiscaisPorCupomFiscal = [
  {
    value: "5929",
    label:
      "5929 - Lançamento efetuado a título de simples faturamento decorrente de venda para entrega futura (ECF)",
  },
  {
    value: "5102",
    label:
      "5102 - Venda de mercadoria adquirida ou recebida de terceiros (NFC-e)",
  },
  {
    value: "1152",
    label: "1152 - Transferência para comercialização",
  },
  {
    value: "2152",
    label: "2152 - Transferência para comercialização",
  },
  {
    value: "5405",
    label:
      "5405 - Venda de mercadoria adquirida ou recebida de terceiros com ICMS por substituição tributária",
  },
  {
    value: "5403",
    label:
      "5403 - Venda de mercadoria adquirida ou recebida de terceiros, sujeita ao ICMS por substituição tributária",
  },
  {
    value: "5415",
    label:
      "5415 - Venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao ICMS por substituição tributária",
  },
  {
    value: "6107",
    label:
      "6107 - Venda de produção do estabelecimento, destinada a não contribuinte",
  },
  {
    value: "6108",
    label:
      "6108 - Venda de produção do estabelecimento, destinada a não contribuinte",
  },
  {
    value: "6152",
    label: "6152 - Venda de mercadoria adquirida ou recebida de terceiros",
  },
  {
    value: "6655",
    label:
      "6655 - Venda de combustível ou lubrificante adquirido ou recebido de terceiros destinado à comercialização",
  },
  {
    value: "6929",
    label:
      "6929 - Lançamento efetuado em decorrência de emissão de documento fiscal relativo a operação ou prestação também registrada em equipamento Emissor de Cupom Fiscal - ECF",
  },
  {
    value: "7102",
    label: "7102 - Venda de mercadoria adquirida or recebida de terceiros",
  },
];

export const objGener = [
  {
    value: "m",
    label: "Masculino",
  },
  {
    value: "f",
    label: "Feminino",
  },
];
