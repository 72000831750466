// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-design {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.26) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.26) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.26) !important;
  border-left: 1px solid rgba(0, 0, 0, 0.26) !important;
}

.thead-design {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.26) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.26) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.26) !important;
  border-left: 1px solid rgba(0, 0, 0, 0.26) !important;
}

.tr-design {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.247) !important;
}

.td-design {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.247) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.247) !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/Cadastro/Producao/Producao/style.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,oDAAA;EACA,sDAAA;EACA,uDAAA;EACA,qDAAA;AACF;;AAEA;EACE,SAAA;EACA,oDAAA;EACA,sDAAA;EACA,uDAAA;EACA,qDAAA;AACF;;AAEA;EACE,SAAA;EACA,wDAAA;AACF;;AAEA;EACE,SAAA;EACA,wDAAA;EACA,uDAAA;AACF","sourcesContent":[".table-design {\n  border: 0;\n  border-top: 1px solid rgba(0, 0, 0, 0.26) !important;\n  border-right: 1px solid rgba(0, 0, 0, 0.26) !important;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.26) !important;\n  border-left: 1px solid rgba(0, 0, 0, 0.26) !important;\n}\n\n.thead-design {\n  border: 0;\n  border-top: 1px solid rgba(0, 0, 0, 0.26) !important;\n  border-right: 1px solid rgba(0, 0, 0, 0.26) !important;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.26) !important;\n  border-left: 1px solid rgba(0, 0, 0, 0.26) !important;\n}\n\n.tr-design {\n  border: 0;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.247) !important;\n}\n\n.td-design {\n  border: 0;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.247) !important;\n  border-right: 1px solid rgba(0, 0, 0, 0.247) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
