import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";

export const getCategorieFinancialAsync = createAsyncThunk(
  "categorie/financial",
  async (storeActivedId: number, thunkAPI) => {
    try {
      const response = await api.get(
        globals.api.transactionCategories +
          `?paginate=false&store_id=${storeActivedId}`
      );
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createCategoryFinancialAsync = createAsyncThunk(
  "create/payment-type",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.post(globals.api.transactionCategories, data);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateCategoryFinancialAsync = createAsyncThunk(
  "update/payment-type",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.put(
        globals.api.transactionCategories + `/${data.uuid}`,
        data
      );

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteCategoryFinancialAsync = createAsyncThunk(
  "delete/payment-type",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.delete(
        globals.api.transactionCategories + `/${uuid}`
      );

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
