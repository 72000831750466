import { toast } from "react-toastify";
import * as S from "./styles";
import { useEffect } from "react";

interface ICheckboxProps {
  label?: string;
  name: string;
  disabled?: boolean;
  isInvalid?: boolean;
  defaultValue?: boolean; // Valores default para checkbox devem ser booleanos
  errors?: any;
  register: any;
  watch?: any;
  setValue?: any;
}

export function CheckboxHook({
  label,
  name,
  disabled,
  defaultValue,
  register,
  errors,
  watch,
  setValue,
}: ICheckboxProps) {
  const { ref, ...inputProps } = register(name);

  useEffect(() => {
    if (watch) {
      watch(name);
    }
  }, [watch, name]);

  const errorToDisplay = errors
    ? Object?.keys(errors).find((key) => key === name)
    : null;

  return (
    <S.Input
      errorToDisplay={errorToDisplay && errors[name]?.message.length > 0}
    >
      <label className="mx-1">
        <span>{label}</span>
        <input
          {...inputProps}
          ref={ref}
          className="mt-1"
          type="checkbox"
          disabled={disabled}
          defaultChecked={defaultValue}
        />
      </label>
      {errorToDisplay && <S.Error>{errors[name]?.message}</S.Error>}
    </S.Input>
  );
}
