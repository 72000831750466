import { createContext, ReactNode, useState, useEffect } from "react";
import { ICategories } from "../../Components/Cadastro/Categorias/interface/ICategories";

interface ChildrenProps {
  children: ReactNode;
}

interface InitialContextInterface {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  xdFollowItem: boolean;
  setXdFollowItem: React.Dispatch<React.SetStateAction<boolean>>;
  selfService: boolean;
  setSelfService: React.Dispatch<React.SetStateAction<boolean>>;
  xdPrinters: string;
  setXdPrinters: React.Dispatch<React.SetStateAction<string>>;
  nomeCategoria: string;
  setNomeCategoria: React.Dispatch<React.SetStateAction<string>>;
  image: string;
  setImage: React.Dispatch<React.SetStateAction<string>>;
  complemento: number;
  setComplemento: React.Dispatch<React.SetStateAction<number>>;
  nomeSubcategoria: number;
  setNomeSubcategoria: React.Dispatch<React.SetStateAction<number>>;
  perfilFiscal: number;
  setPerfilFiscal: React.Dispatch<React.SetStateAction<number>>;
  complementList: any[];
  setComplementList: React.Dispatch<React.SetStateAction<any[]>>;
  listaPerfilFiscal: any[];
  setListaPerfilFiscal: React.Dispatch<React.SetStateAction<any[]>>;
  listaSubCategoria: any[];
  setListaSubCategoria: React.Dispatch<React.SetStateAction<any[]>>;
  dataSheetList: any[];
  setDataSheetList: React.Dispatch<React.SetStateAction<any[]>>;
  nomeCategoriaInValid: boolean;
  setNomeCategoriaInValid: React.Dispatch<React.SetStateAction<boolean>>;
  nomelistaSubCategoriaInValid: boolean;
  setNomelistaSubCategoriaInValid: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  nomelistalistaPerfilFiscalInValid: boolean;
  setNomelistalistaPerfilFiscalInValid: React.Dispatch<
    React.SetStateAction<boolean>
  >;

  clearFilds: () => void;
  handleValidete: () => boolean;
  handleEdit: (props: ICategories) => void;
}

export const CategoryContext = createContext<InitialContextInterface>(
  {} as InitialContextInterface
);

export const CategoryProvider = ({ children }: ChildrenProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [xdFollowItem, setXdFollowItem] = useState<boolean>(false);
  const [selfService, setSelfService] = useState<boolean>(false);
  const [xdPrinters, setXdPrinters] = useState<string>(
    "0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0"
  );
  const [nomeCategoria, setNomeCategoria] = useState<string>("");
  const [complemento, setComplemento] = useState<number>(0);
  const [nomeSubcategoria, setNomeSubcategoria] = useState<number>(0);
  const [perfilFiscal, setPerfilFiscal] = useState<number>(0);

  const [complementList, setComplementList] = useState<Array<any>>([]);
  const [listaPerfilFiscal, setListaPerfilFiscal] = useState<any[]>([]);
  const [listaSubCategoria, setListaSubCategoria] = useState<any[]>([]);
  const [dataSheetList, setDataSheetList] = useState<any[]>([]);
  const [image, setImage] = useState("");

  const [nomeCategoriaInValid, setNomeCategoriaInValid] =
    useState<boolean>(false);
  const [nomelistaSubCategoriaInValid, setNomelistaSubCategoriaInValid] =
    useState<boolean>(false);
  const [
    nomelistalistaPerfilFiscalInValid,
    setNomelistalistaPerfilFiscalInValid,
  ] = useState<boolean>(false);

  const handleValidete = () => {
    if (!nomeCategoria) setNomeCategoriaInValid(true);
    if (nomeCategoria) setNomeCategoriaInValid(false);
    if (!perfilFiscal) setNomelistalistaPerfilFiscalInValid(true);
    if (perfilFiscal) setNomelistalistaPerfilFiscalInValid(false);

    if (nomeCategoria && perfilFiscal) {
      return true;
    }

    return false;
  };

  const clearFilds = () => {
    setXdFollowItem(false);
    setXdPrinters("0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0");
    setNomeCategoria("");
    setComplemento(0);
    setNomeSubcategoria(0);
    setPerfilFiscal(0);
    setDataSheetList([]);
    setImage("");
  };

  const handleEdit = (props: ICategories) => {
    setXdFollowItem(props.xd_follow_item);
    setXdPrinters(props.xd_printers);
    setNomeCategoria(props.name);
    //setComplemento(props.relationships?.complements);
    setNomeSubcategoria(props.parent_id);
    setPerfilFiscal(props.tax_profile_id);
    setDataSheetList(props.relationships?.complements ?? []);
    setSelfService(props?.self_service);
    setImage(props?.image);
  };

  useEffect(() => {
    if (nomeCategoria) setNomeCategoriaInValid(false);
    if (nomeSubcategoria) setNomelistaSubCategoriaInValid(false);
    if (perfilFiscal) setNomelistalistaPerfilFiscalInValid(false);
  }, [nomeCategoria, nomeSubcategoria, perfilFiscal]);

  return (
    <CategoryContext.Provider
      value={{
        loading,
        setLoading,
        xdFollowItem,
        setXdFollowItem,
        xdPrinters,
        setXdPrinters,
        nomeCategoria,
        setNomeCategoria,
        complemento,
        setComplemento,
        nomeSubcategoria,
        setNomeSubcategoria,
        perfilFiscal,
        setPerfilFiscal,
        complementList,
        setComplementList,
        listaPerfilFiscal,
        setListaPerfilFiscal,
        listaSubCategoria,
        setListaSubCategoria,
        dataSheetList,
        setDataSheetList,
        nomeCategoriaInValid,
        setNomeCategoriaInValid,
        nomelistaSubCategoriaInValid,
        setNomelistaSubCategoriaInValid,
        nomelistalistaPerfilFiscalInValid,
        setNomelistalistaPerfilFiscalInValid,
        clearFilds,
        handleValidete,
        handleEdit,
        setSelfService,
        selfService,
        image,
        setImage,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};
