import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Format from "../../../../Services/Format";
import {
  FaCheck,
  FaCheckDouble,
  FaEdit,
  FaEye,
  FaRocket,
} from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../../Loading/Loading";
import api from "../../../../Services/api";
import Globals from "../../../../Globals.json";
import DateUtils from "../../../../Services/DateUtils";
import { getEmployees } from "../../../../Services/people-service";
import LayoutFilter from "../../../data/LayoutFilter";
import { InputDefault } from "../../../data/inputDefault";
import ReactSelect from "../../../data/reactSelect";
import LayoutTable from "../../../data/LayoutTable";
import { sortByCreated } from "../../../../Services/sortByCreated";
import GetEndPoint from "../../../../Services/GetEndPoint";
import { AppDispatch, useAppSelector } from "../../../../redux/store";
import tableStyleDefault from "../../../../Services/tableStyleDefault";
import { NoData } from "../../../noData";
import CustomLoader from "../../../data/customLoader";
import ActiveInactiv from "../../../data/activeInactive";
import { Dropdown } from "react-bootstrap";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { FcApproval } from "react-icons/fc";
import { createQuote } from "../../../../Services/quotes-service";
import { notify, notifyError } from "../../../notify/notify-component";
import * as S from "./styled";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import {
  handleNew,
  setBudgetSelected,
} from "../../../../redux/features/stockMoviment/stock-moviment-slice";

export default function ConsultaOrcamentos(props: any) {
  const dispatch = useDispatch<AppDispatch>();
  const router = useHistory();
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const [orcamento, setOrcamento] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const [vendor_id, setVendorId] = useState<string>("");
  const [emitting_date, setEmittingDate] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>(
    Format.firstDate("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(
    Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD")
  );
  const [status, setStatus] = useState<number>(0);

  const [listVendor, setListVendor] = useState<Array<any>>([]);

  // Erros
  const [validDateIni, setValidDateIni] = useState<boolean>(false);
  const [validDateEnd, setValidDateEnd] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const [filter, setFilter] = useState<string>("");

  const listdate = [
    { value: 0, label: "Todos" },
    { value: 1, label: "Data de emissão" },
  ];

  const objectStatus = [
    {
      value: 0,
      label: "Todos",
    },
    {
      value: 1,
      label: "Em Orçamento",
    },
    {
      value: 2,
      label: "Aprovado",
    },
    {
      value: 3,
      label: "Concluído",
    },
  ];

  const handleEdit = (row: any) => {
    if (typeof props.onEditItem === "function" && row !== undefined) {
      props.onEditItem(row);
    }
  };

  const handleView = (row: any) => {
    if (typeof props.onEditItem === "function" && row !== undefined) {
      props.onViewItem(row);
    }
  };

  const handleToApprove = (row: any) => {
    const oModel = {
      store_id: storeMe.storeActive.id,
      uuid: row?.uuid,
      stock_quote_situation_id: 2,
      description: row?.description,
      amount: row?.amount,
      discount: row?.discount,
      ...(row?.discountType && {
        discount_type: row?.discount_type,
      }),
      ...(row?.vendor_id && {
        vendor_id: row?.vendor_id,
      }),

      final_amount: row?.final_amount,
      products: row?.products,
    };

    createQuote(oModel).then((res: any) => {
      setLoading(true);
      if (!res) {
        setTimeout(() => {
          notifyError("Ocorreu um erro");
          setLoading(false);
        }, 1200);
      } else {
        notify("Item alterado para aprovado.");
        setTimeout(() => {
          setLoading(false);
          getQuotes();
        }, 500);
      }
    });
  };

  const handleConclude = (row: any) => {
    const oModel = {
      store_id: storeMe.storeActive.id,
      uuid: row?.uuid,
      stock_quote_situation_id: 3,
      description: row?.description,
      amount: row?.amount,
      discount: row?.discount,
      ...(row?.discountType && {
        discount_type: row?.discount_type,
      }),
      ...(row?.vendor_id && {
        vendor_id: row?.vendor_id,
      }),
      final_amount: row?.final_amount,
      products: row?.products,
    };

    createQuote(oModel).then((res: any) => {
      setLoading(true);
      if (!res) {
        setTimeout(() => {
          notifyError("Ocorreu um erro");
          setLoading(false);
        }, 1200);
      } else {
        notify("Item alterado para concluído.");
        setTimeout(() => {
          setLoading(false);
          getQuotes();
        }, 500);
      }
    });
  };

  const handleToLaucher = (row: any) => {
    dispatch(setBudgetSelected(row));
    dispatch(handleNew(true));
    router.push(`/dashboard/estoque/movimentacoes`);
  };

  const isLater = (start: string, end: string) => {
    return start > end;
  };

  const handleDateStart = async (date: string) => {
    if (!date) return;
    if (date.length !== 10) {
      setValidDateIni(false);
      return;
    }
    setStartDate(date);
    let dateValid = isLater(date, endDate);
    setValidDateIni(dateValid);
    if (dateValid) {
      return;
    }
  };

  const handleDateEnd = async (date: string) => {
    if (!date) return;
    if (date.length !== 10) {
      setValidDateIni(false);
      return;
    }
    setEndDate(date);
    let dateValid = isLater(startDate, date);
    setValidDateEnd(dateValid);

    if (dateValid) {
      return;
    }
  };

  const clearFields = () => {
    setCode("");
    setVendorId("");
    setStatus(0);
    setStartDate(Format.firstDate("YYYY-MM-DD"));
    setEndDate(Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD"));
    setFilter("");
    setListVendor(listVendor);
  };

  const createFilter = (filterList: Array<string>): string => {
    let filter = "";
    for (let i = 0; i < filterList.length; i++) {
      if (i >= 1) {
        filter += "&";
      }
      filter += `filter${filterList[i]}`;
    }
    return filter;
  };

  const handleSearchList = () => {
    if (validDateIni || validDateEnd) return;
    let filterList = [];
    if (code !== "") filterList.push(`[id]=${code}`);
    if (vendor_id !== "") filterList.push(`[vendor_id]=${vendor_id}`);
    if (status !== 0) filterList.push(`[stock_quote_situation_id]=${status}`);
    if (emitting_date === 1) {
      filterList.push(`[starts_at]=${startDate}`);
      filterList.push(`[ends_at]=${endDate}`);
    }

    if (filterList.length > 0) {
      const filter = createFilter(filterList);
      if (filter !== "") {
        getQuotes(filter);
        setFilter(filter);
      }
      return;
    }
    getQuotes();
  };

  const columns: any = [
    {
      name: "Código",
      center: true,
      sortable: true,
      width: "90px",
      selector: (row: any) => row.id,
    },
    {
      name: "Descrição",
      sortable: true,
      selector: (row: any) => row.description?.toLowerCase(),
    },
    {
      name: "Fornecedor",
      sortable: true,
      selector: (row: any) => row.relationships?.vendor?.name?.toLowerCase(),
    },
    {
      name: "Data Emissão",
      center: true,
      grow: 0.5,
      selector: (row: any) =>
        Format.date(DateUtils.toDate(row.created_at), "DD/MM/YYYY"),
    },
    {
      name: "Valor Bruto",
      center: true,
      sortable: true,
      grow: 0.6,
      selector: (row: any) =>
        row.amount.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      name: "Valor Descontado",
      center: true,
      grow: 0.7,
      selector: (row: any) =>
        `${
          row.discount_type === "fixo"
            ? row.discount.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            : row.discount.toFixed(2).replace(".", ",") + "%"
        }`,
    },
    {
      name: "Valor Final",
      center: true,
      sortable: true,
      grow: 0.5,
      selector: (row: any) =>
        row.final_amount.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      name: "Status",
      center: true,
      grow: 0.6,
      selector: (row: any) => {
        if (
          row.relationships.stock_quote_situation?.description === "Concluído"
        ) {
          return (
            <ActiveInactiv
              status={
                row.relationships.stock_quote_situation?.description ===
                "Concluído"
                  ? true
                  : false
              }
              text="Concluído"
            />
          );
        } else if (
          row.relationships.stock_quote_situation?.description === "Aprovado"
        ) {
          return (
            <ActiveInactiv
              status={
                row.relationships.stock_quote_situation?.description ===
                "Aprovado"
                  ? true
                  : false
              }
              color="#8080ff"
              text="Aprovado"
            />
          );
        } else {
          return (
            <ActiveInactiv
              status={
                row.relationships.stock_quote_situation?.description ===
                "Em Orçamento"
                  ? true
                  : false
              }
              color="yellow"
              text="Em Orçamento"
            />
          );
        }
      },
    },
    {
      name: "Ação",
      width: "60px",
      center: true,
      cell: (row: any) => (
        <Dropdown>
          <Dropdown.Toggle variant="white" id="dropdown-basic" size="sm">
            <HiOutlineDotsVertical />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {row.relationships.stock_quote_situation?.description !==
              "Concluído" && (
              <>
                <Dropdown.Item onClick={() => handleToApprove(row)}>
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                      fontSize: "12px",
                    }}
                  >
                    <FaCheck
                      size={18}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Duplicar Produto"
                      className="action"
                      color="#8080ff"
                    />
                    Aprovar
                  </div>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleConclude(row)}>
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                      fontSize: "12px",
                    }}
                  >
                    <FaCheckDouble
                      size={18}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Duplicar Produto"
                      className="action"
                      color="green"
                    />
                    Concluir
                  </div>
                </Dropdown.Item>
              </>
            )}
            <Dropdown.Item
              onClick={() =>
                row.relationships.stock_quote_situation?.description !==
                "Concluído"
                  ? handleEdit(row)
                  : handleView(row)
              }
            >
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  fontSize: "12px",
                }}
              >
                {row.relationships.stock_quote_situation?.description !==
                "Concluído" ? (
                  <FaEdit
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Editar"
                    className="action"
                    size={16}
                  />
                ) : (
                  <FaEye
                    size={16}
                    color="8e8d8d"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Visualizar"
                    className="action"
                  />
                )}
                {row.relationships.stock_quote_situation?.description !==
                "Concluído"
                  ? "Editar"
                  : "Visualizar"}
              </div>
            </Dropdown.Item>
            {row.relationships.stock_quote_situation?.description ===
              "Concluído" && (
              <Dropdown.Item onClick={() => handleToLaucher(row)}>
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    fontSize: "12px",
                  }}
                >
                  <FaRocket
                    size={16}
                    color="8e8d8d"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Lançar"
                    className="action"
                  />
                  Lançar
                </div>
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      ),
      button: true,
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Registros por página",
    rangeSeparatorText: "de",
  };

  const handleAdd = () => {
    props.OnAddNewQuote(true);
  };

  const handleVendorSelect = (vendor: any) => {
    setVendorId(vendor);
  };

  const getQuotes = (filter?: string) => {
    const uri =
      filter !== undefined
        ? `${filter}&paginate=false&store_id=${storeMe.storeActive.id}`
        : `paginate=false&store_id=${storeMe.storeActive.id}`;

    setLoading(true);
    api
      .get(Globals.api.stockQuotes + `?${uri}`)
      .then((response) => {
        const data = response?.data;

        setOrcamento(sortByCreated(data?.data));
      })
      .catch((error) => {
        notifyError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickOpenFilter = () => {
    setOpenFilter(!openFilter);
  };

  useEffect(() => {
    getQuotes();
    GetEndPoint.vendor(setListVendor);
  }, []);

  let newListVendor = listVendor.filter(
    (item) => item.store_id === storeMe.storeActive.id
  );

  return (
    <>
      <LayoutFilter
        title={props.Title}
        onClickAdd={handleAdd}
        onClickClear={clearFields}
        onClickSearch={handleSearchList}
        onClickOpenFilter={onClickOpenFilter}
        openFilter={openFilter}
      >
        <S.Wrapper>
          {openFilter && (
            <div className="min-screen">
              <InputDefault
                label="Código"
                type="number"
                autoComplete="off"
                state={code}
                setState={setCode}
                maxLength={50}
              />
              <ReactSelect
                label="Fornecedor"
                state={vendor_id}
                onChange={(e: any) => handleVendorSelect(e?.value)}
                options={newListVendor}
              />
              <ReactSelect
                label="Status"
                state={status}
                setState={setStatus}
                options={objectStatus}
              />
              <ReactSelect
                label="Períodota"
                state={emitting_date}
                options={listdate}
                isInvalid={false}
                setState={setEmittingDate}
              />
              <InputDefault
                label="De"
                type="date"
                disabled={emitting_date === 0}
                state={startDate}
                onChenge={(e: any) => handleDateStart(e.target.value)}
                isInvalid={validDateIni}
              />
              <InputDefault
                label="Até"
                type="date"
                disabled={emitting_date === 0}
                state={endDate}
                onChenge={(e) => handleDateEnd(e.target.value)}
                isInvalid={validDateEnd}
              />
            </div>
          )}

          <div className="max-screen">
            <InputDefault
              label="Código"
              type="number"
              autoComplete="off"
              state={code}
              setState={setCode}
              maxLength={50}
            />
            <ReactSelect
              label="Fornecedor"
              state={vendor_id}
              onChange={(e: any) => handleVendorSelect(e?.value)}
              options={newListVendor}
            />
            <ReactSelect
              label="Status"
              state={status}
              setState={setStatus}
              options={objectStatus}
            />
            <ReactSelect
              label="Períodota"
              state={emitting_date}
              options={listdate}
              isInvalid={false}
              setState={setEmittingDate}
            />
            <InputDefault
              label="De"
              type="date"
              disabled={emitting_date === 0}
              state={startDate}
              onChenge={(e: any) => handleDateStart(e.target.value)}
              isInvalid={validDateIni}
            />
            <InputDefault
              label="Até"
              type="date"
              disabled={emitting_date === 0}
              state={endDate}
              onChenge={(e) => handleDateEnd(e.target.value)}
              isInvalid={validDateEnd}
            />
          </div>
        </S.Wrapper>
      </LayoutFilter>

      <LayoutTable>
        <DataTable
          columns={columns}
          data={orcamento || []}
          striped
          pagination
          paginationDefaultPage={1}
          paginationPerPage={25}
          customStyles={tableStyleDefault}
          paginationComponentOptions={paginationOptions}
          progressPending={loading}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhuma informação foi encontrada."
        />
      </LayoutTable>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
