import { useContext } from "react";
import { Row, Form, Col } from "react-bootstrap";
import { ProdutoContext } from "../../../../context/product";
import Layout from "../../../data/Layout";

export function InfoSales() {
  const {
    xdRequestQty,
    setXdRequestQty,
    xdRequestPriceWhenZero,
    setXdRequestPriceWhenZero,
    xdBalanceQty,
    setXdBalanceQty,
    xdOrderGrouped,
    setXdOrderGrouped,
    xdBillGroup,
    setXdBillGroup,
    xdAddTips,
    setXdAddTips,
    xdExcludeMinimumConsumption,
    setXdExcludeMinimumConsumption,
    xdBook,
    setXdBook,
    selfService,
    setSelfService,
  } = useContext(ProdutoContext);

  return (
    <Layout title="Informações de Venda">
      <Row className="px-2 mt-4 mx-4">
        <Col md="3">
          <Form.Switch
            label="Pede quantidade"
            onChange={(e: any) => setXdRequestQty(e.target.checked)}
            checked={xdRequestQty}
          />
        </Col>

        <Col md="3">
          <Form.Switch
            label="Pede preço quando zero"
            onChange={(e: any) => setXdRequestPriceWhenZero(e.target.checked)}
            checked={xdRequestPriceWhenZero}
          />
        </Col>

        <Col md="3">
          <Form.Switch
            label="Quantidade na balança"
            onChange={(e: any) => setXdBalanceQty(e.target.checked)}
            checked={xdBalanceQty}
          />
        </Col>

        <Col md="3">
          <Form.Switch
            label="Agrupado nos pedidos"
            onChange={(e: any) => setXdOrderGrouped(e.target.checked)}
            checked={xdOrderGrouped}
          />
        </Col>
      </Row>

      <Row className="px-2 mx-4">
        <Col md="3">
          <Form.Switch
            label="Agrupado na conta"
            onChange={(e: any) => setXdBillGroup(e.target.checked)}
            checked={xdBillGroup}
          />
        </Col>

        <Col md="3">
          <Form.Switch
            label="Adicionar serviço automatico"
            onChange={(e: any) => setXdAddTips(e.target.checked)}
            checked={xdAddTips}
          />
        </Col>

        <Col md="3">
          <Form.Switch
            label="Excluir do consumo mínimo"
            onChange={(e: any) =>
              setXdExcludeMinimumConsumption(e.target.checked)
            }
            checked={xdExcludeMinimumConsumption}
          />
        </Col>

        <Col md="3">
          <Form.Switch
            label="Agenda"
            onChange={(e: any) => setXdBook(e.target.checked)}
            checked={xdBook}
          />
        </Col>
        <Col md="3">
          <Form.Switch
            label="Autoatendimento"
            onChange={(e: any) => setSelfService(e.target.checked)}
            checked={selfService}
          />
        </Col>
      </Row>
    </Layout>
  );
}
