// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_grid__UIZ41 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.styles_complement__x-op1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.styles_complement__x-op1 button {
  margin-top: 20px;
}

.styles_trTable__bL1mD {
  font-size: 12px;
}

.styles_tbTable__eAorm {
  font-size: 12px;
}

.styles_trash__NAnFM div {
  margin: 0 20px;
  background-color: transparent;
  cursor: pointer;
}

@media (max-width: 900px) {
  .styles_grid__UIZ41 {
    grid-template-columns: 1fr;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Cadastro/Categorias/FormCategorias/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AACF;AACE;EACE,gBAAA;AACJ;;AAGA;EACE,eAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAIE;EACE,cAAA;EACA,6BAAA;EACA,eAAA;AADJ;;AAKA;EACE;IACE,0BAAA;EAFF;AACF","sourcesContent":[".grid {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 40px;\n}\n\n.complement {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n\n  button {\n    margin-top: 20px;\n  }\n}\n\n.trTable {\n  font-size: 12px;\n}\n\n.tbTable {\n  font-size: 12px;\n}\n\n.trash {\n  div {\n    margin: 0 20px;\n    background-color: transparent;\n    cursor: pointer;\n  }\n}\n\n@media (max-width: 900px) {\n  .grid {\n    grid-template-columns: 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `styles_grid__UIZ41`,
	"complement": `styles_complement__x-op1`,
	"trTable": `styles_trTable__bL1mD`,
	"tbTable": `styles_tbTable__eAorm`,
	"trash": `styles_trash__NAnFM`
};
export default ___CSS_LOADER_EXPORT___;
