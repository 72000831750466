// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 13px;
  color: rgb(136, 136, 136);
}

.footer-data {
  text-align: right;
  width: 100%;
}

.line {
  width: 100%;
  border-top: 1px solid rgb(224, 222, 222);
  margin-top: 5px;
  margin-bottom: 1rem;
}

.pagination-controls {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.pagination-controls select {
  border: none;
  color: rgb(136, 136, 136);
  margin: 0 8px;
  cursor: pointer;
}

.pagination-controls span {
  margin: 0 6px;
}

.pagination-controls button {
  background: none;
  border: none;
  cursor: pointer;
}

.pagination-controls button:disabled {
  cursor: default;
  opacity: 0.5;
  color: rgb(204, 199, 199);
}

/*
.pagination-controls button:hover {
  background: rgb(136, 136, 136);
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
*/
`, "",{"version":3,"sources":["webpack://./src/Components/data/paginationWrapper/PaginationWrapper.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,wCAAwC;EACxC,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;;;;;;;CAOC","sourcesContent":[".pagination-wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  font-size: 13px;\n  color: rgb(136, 136, 136);\n}\n\n.footer-data {\n  text-align: right;\n  width: 100%;\n}\n\n.line {\n  width: 100%;\n  border-top: 1px solid rgb(224, 222, 222);\n  margin-top: 5px;\n  margin-bottom: 1rem;\n}\n\n.pagination-controls {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  margin-right: 1rem;\n  margin-bottom: 1rem;\n}\n\n.pagination-controls select {\n  border: none;\n  color: rgb(136, 136, 136);\n  margin: 0 8px;\n  cursor: pointer;\n}\n\n.pagination-controls span {\n  margin: 0 6px;\n}\n\n.pagination-controls button {\n  background: none;\n  border: none;\n  cursor: pointer;\n}\n\n.pagination-controls button:disabled {\n  cursor: default;\n  opacity: 0.5;\n  color: rgb(204, 199, 199);\n}\n\n/*\n.pagination-controls button:hover {\n  background: rgb(136, 136, 136);\n  height: 20px;\n  width: 20px;\n  border-radius: 50%;\n}\n*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
