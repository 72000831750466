// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resultCalc{
    margin-top: 10px;
    color: black;
    font-weight: 500;
    font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Cadastro/Produtos/Precos/styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".resultCalc{\n    margin-top: 10px;\n    color: black;\n    font-weight: 500;\n    font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
