import { useState, useContext } from "react";
import { ThemeContext } from "../../../context/theme-context";
import ListaCategorias from "../../../Components/Cadastro/Categorias/ListaCategorias";
import FormCategories from "../../../Components/Cadastro/Categorias/FormCategorias";
import { ICategories } from "../../../Components/Cadastro/Categorias/interface/ICategories";
import { Container } from "react-bootstrap";
import { CategoryProvider } from "../../../context/category";

export interface IListCategories {
  title: string;
  OnAddNewCli: (e: boolean) => void;
  onEditItem: (item: ICategories) => void;
  OnAddNewCategorie: (e: boolean) => void;
}

export interface IFormCategories {
  title: string;
  OnAddNewCategorie: (e: boolean) => void;
  categories?: ICategories | undefined;
  editando: boolean;
}

export function CadCategorias() {
  const { collapsed } = useContext(ThemeContext);

  const [cad, setCad] = useState<boolean>(false);
  const [toEditCategorias, setToEditCategorias] = useState<
    ICategories | undefined
  >();
  const [editando, setEditando] = useState<boolean>(false);

  const handleNew = (e: boolean) => {
    setEditando(false);
    setToEditCategorias(undefined);
    setCad(e);
  };

  const handleEdit = (props: ICategories) => {
    setToEditCategorias(props);
    setCad(true);
    setEditando(true);
  };

  return (
    <CategoryProvider>
      {!cad ? (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <ListaCategorias
            title="Categorias"
            onEditItem={(item: ICategories) => handleEdit(item)}
            OnAddNewCli={(e: boolean) => handleNew(e)}
            OnAddNewCategorie={(e: boolean) => handleNew(e)}
          />
        </Container>
      ) : (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <FormCategories
            title={toEditCategorias ? "Editar Categorias" : "Nova Categorias"}
            OnAddNewCategorie={(e: boolean) => handleNew(e)}
            categories={toEditCategorias}
            editando={editando}
          />
        </Container>
      )}
    </CategoryProvider>
  );
}
