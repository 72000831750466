import { IStore } from "../../interface/store";
import * as S from "./styles";

interface IProps {
  store: IStore;
  handleEdit: any;
}

export default function CardStore({ store, handleEdit }: IProps) {
  return (
    <S.Content onClick={() => handleEdit(store)}>
      <div className="grid-item">
        {store?.logo ? (
          <img src={store?.logo} alt="logo" />
        ) : (
          <div className="img">
            <span>Sem imagem</span>
          </div>
        )}
        <ul>
          <li
            className={
              store?.status === 1 ? "status-active" : "status-inactived"
            }
          >
            {store?.status === 1 ? "Ativo" : "Inativo"}
          </li>
          <li className="name">{store?.trading_name || store?.company_name}</li>
          <li>
            <div className="cnpj-phone">
              <div className="items">
                <span className="title">CNPJ</span>
                <span>{store?.cnpj}</span>
              </div>
              <div className="items">
                <span className="title">Telefone</span>
                <span>(99) 999999-9999</span>
              </div>
            </div>
            <div className="items">
              <span className="title">Cidade</span>
              <span>
                {store?.city +
                  ` - ` +
                  store?.state +
                  `, CEP - ` +
                  store?.address_zip_code}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </S.Content>
  );
}
