import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICategorieFinancial } from "./interface";
import { getCategorieFinancialAsync } from "./services";
import { getEntryType } from "./utils";

interface InitialState {
  value: categorieFinancialState;
}

interface StateForm {
  state: "error" | "loading" | "send" | "default";
  message?: string;
}

interface categorieFinancialState {
  loading: boolean;
  fetchError: string;
  revenue: ICategorieFinancial[];
  expenxe: ICategorieFinancial[];
  menuSelected: string;
  stateForm: StateForm;
}

const initialState: InitialState = {
  value: {
    loading: false,
    fetchError: "",
    revenue: [],
    expenxe: [],
    menuSelected: "",
    stateForm: { state: "default" },
  } as categorieFinancialState,
};

export const categorieFinancial = createSlice({
  name: "categorieFinancial",
  initialState,
  reducers: {
    setMenuSelected: (state: InitialState, action) => {
      state.value.menuSelected = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(
      getCategorieFinancialAsync.pending,
      (state: InitialState) => {
        state.value.loading = true;
      }
    );
    builder.addCase(
      getCategorieFinancialAsync.fulfilled,
      (state: InitialState, action: PayloadAction<ICategorieFinancial[]>) => {
        state.value.loading = false;
        state.value.revenue = getEntryType(action.payload, 1);
        state.value.expenxe = getEntryType(action.payload, 0);
      }
    );
    builder.addCase(
      getCategorieFinancialAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );
  },
});

export const { setMenuSelected } = categorieFinancial.actions;

export default categorieFinancial.reducer;
