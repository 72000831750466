import { useState, useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import { ThemeContext } from "../../../../context/theme-context";
import { FormRepresentative } from "../../../../Components/Dashboard/Admin/Representative/FormRepresentative";
import { ListRepresentative } from "../../../../Components/Dashboard/Admin/Representative/ListRepresentative";
import { IRepresentative } from "../../../../Components/Dashboard/Admin/Representative/interface/IRepresentative";
import GetEndPoint from "../../../../Services/GetEndPoint";
import globals from "../../../../Globals.json";

export interface IListRepresentative {
  title: string;
  OnAddNewCli: (e: boolean) => void;
  onEditItem: (item: IRepresentative) => void;
  OnAddNewRepresentative: (e: boolean) => void;
  selectOptions?: any;
}

export interface IFormRepresentative {
  title: string;
  OnAddNewRepresentative: (e: boolean) => void;
  representative?: any;
  editando: boolean;
  selectOptions?: any;
}

export interface ISelectOptions {
  state: any;
  representative: any;
}

export default function CadRepresentative() {
  const { collapsed } = useContext(ThemeContext);

  const [cad, setCad] = useState<boolean>(false);
  const [toEditRepresentative, setToEditRepresentative] = useState<{}>();
  const [editando, setEditando] = useState<boolean>(false);
  const [stateOptions, setStateOptions] = useState();
  const [stateRepresentative, setStateRepresentative] = useState<
    IRepresentative[]
  >([]);

  const handleNew = (e: boolean) => {
    setEditando(false);
    setToEditRepresentative({});
    setCad(e);
    GetEndPoint.data(globals.api.representative, setStateRepresentative);
  };

  const handleEdit = (props: IRepresentative) => {
    setToEditRepresentative(props);
    setCad(true);
    setEditando(true);
  };

  const selectOptions: ISelectOptions = {
    state: stateOptions,
    representative: stateRepresentative,
  };

  useEffect(() => {
    GetEndPoint.state(setStateOptions);
    GetEndPoint.data(globals.api.representative, setStateRepresentative);
  }, []);

  return (
    <>
      {!cad ? (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <ListRepresentative
            title="Representante"
            onEditItem={(item: IRepresentative) => handleEdit(item)}
            OnAddNewCli={(e: boolean) => handleNew(e)}
            OnAddNewRepresentative={(e: boolean) => handleNew(e)}
            selectOptions={selectOptions}
          />
        </Container>
      ) : (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <FormRepresentative
            title={editando ? "Editar Representante" : "Novo Representante"}
            OnAddNewRepresentative={(e: boolean) => handleNew(e)}
            representative={toEditRepresentative}
            editando={editando}
            selectOptions={selectOptions}
          />
        </Container>
      )}
    </>
  );
}
