export interface IObj {
  value: string;
  label: string;
}

export const paymentsTypeObj: IObj[] = [
  {
    value: "0",
    label: "Numerario",
  },
  {
    value: "1",
    label: "Cheque",
  },
  {
    value: "2",
    label: "Cartão Débito",
  },
  {
    value: "3",
    label: "Cartão Crédito",
  },
  {
    value: "4",
    label: "Multibanco",
  },
  {
    value: "5",
    label: "Tick Refeição",
  },
  {
    value: "6",
    label: "Conta Assinada",
  },
  {
    value: "7",
    label: "Não Aplicável",
  },
  {
    value: "8",
    label: "Compensação de Saldos em Conta Corrente",
  },
  {
    value: "9",
    label: "Letra comercial",
  },
  {
    value: "10",
    label: "Permuta de bens",
  },
  {
    value: "11",
    label: "Transferência bancária ou Débito direto",
  },
  {
    value: "12",
    label: "Cheque ou Cartçao oferta",
  },
  {
    value: "13",
    label: "Dinheiro eletrónico (Cartões de fidelidade ou pontos)",
  },
  {
    value: "14",
    label: "Crédito documentário internacional",
  },
];

export const paymentsGroupObj: IObj[] = [
  {
    value: "0",
    label: "Numerario",
  },
  {
    value: "1",
    label: "Catoes",
  },
  {
    value: "1",
    label: "Outros",
  },
];

export const tefPaymentsTypeObj: IObj[] = [
  {
    value: "0",
    label: "Nenhum",
  },
  {
    value: "1",
    label: "Dinheiro",
  },
  {
    value: "2",
    label: "Cheque",
  },
  {
    value: "3",
    label: "Cartão de Crédito",
  },
  {
    value: "4",
    label: "Cheque",
  },
  {
    value: "5",
    label: "Credito de Loja",
  },
  {
    value: "10",
    label: "Vale Alimentação",
  },
  {
    value: "11",
    label: "Vale Refeição",
  },
  {
    value: "12",
    label: "Vale Presente",
  },
  {
    value: "13",
    label: "Vale Combustivel",
  },
  {
    value: "14",
    label: "Duplicata Mercantil",
  },
  {
    value: "15",
    label: "Boleto Bancario",
  },
  {
    value: "16",
    label: "Deposito Bancario",
  },
  {
    value: "17",
    label: "Pagamento Instantaneo (PIX)",
  },
  {
    value: "18",
    label: "Transferência Bancaria, Carteira Digital",
  },
  {
    value: "19",
    label: "Programa de fidelidade, Cashback, Credito Virtual",
  },
  {
    value: "90",
    label: "Sem Pagemento",
  },
  {
    value: "99",
    label: "Outros",
  },
];

export const tefPaymentProviderObj: IObj[] = [
  {
    value: "0",
    label: "Nenhum",
  },
  {
    value: "1",
    label: "Dinheiro",
  },
  {
    value: "2",
    label: "Cheque",
  },
  {
    value: "3",
    label: "Cartão de Crédito",
  },
  {
    value: "4",
    label: "Cheque",
  },
  {
    value: "5",
    label: "Credito de Loja",
  },
  {
    value: "10",
    label: "Vale Alimentação",
  },
  {
    value: "11",
    label: "Vale Refeição",
  },
  {
    value: "12",
    label: "Vale Presente",
  },
  {
    value: "13",
    label: "Vale Combustivel",
  },
  {
    value: "14",
    label: "Duplicata Mercantil",
  },
  {
    value: "15",
    label: "Boleto Bancario",
  },
  {
    value: "16",
    label: "Deposito Bancario",
  },
  {
    value: "17",
    label: "Pagamento Instantaneo (PIX)",
  },
  {
    value: "18",
    label: "Transferência Bancaria, Carteira Digital",
  },
  {
    value: "19",
    label: "Programa de fidelidade, Cashback, Credito Virtual",
  },
  {
    value: "90",
    label: "Sem Pagemento",
  },
  {
    value: "99",
    label: "Outros",
  },
];

export const businessAccountObj: IObj[] = [
  {
    value: "0",
    label: "Real",
  },
];

export interface PaymentOptions {
  payment_mechanism_id: any;
  id: number;
  uuid: string;
  description: string;
  payment_group: string;
  business_account_id: number;
  sort_order: number;
  br_payment_type_id: number;
  br_payment_type_provider_id: number;
  br_payment_type_description: string;
  inactive: boolean;
  visible_delivery: boolean;
  requires_exchange: boolean;
  sent_to_checking_account: boolean;
}
