import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { InputHook } from "../../../../../reactHookForm/InputHook";
import { SelectHook } from "../../../../../reactHookForm/SelectHook";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { InputHookCnpj } from "../../../../../reactHookForm/InputHookCnpj";
import { InputHookCep } from "../../../../../reactHookForm/InputHookCep";
import { StateForm } from "../../../../../../interfaces/state-form";
import { InputButton } from "../../../../../reactHookForm/inputButton";
import {
  CFOPDevolucaoVendasComST,
  CFOPTransferenciaEstoque,
  CFOPVendaComICMS,
  CFOPsEmissaoDeNotasFiscaisPorCupomFiscal,
  CFOPVendaComST,
  ObjCSOSN,
  ObjRegimeTributario,
  ObjSituacoesTributarias,
  ObjType,
} from "../../../../../../utils/objetos/objt";
import * as S from "./styles";
import { ModalImage } from "../../../../../data/modalImage";
import { Btn } from "../../../../../data/Btn";
import { useAppSelector } from "../../../../../../redux/store";
import { ICsts } from "../../../../../../redux/features/store/interface";
import InputImg from "../../../../../data/inputImg";
import { CheckboxHook } from "../../../../../reactHookForm/inputCheckbox";

interface IProps {
  register: any;
  control: any;
  watch: any;
  setValue: any;
  setError: any;
  errors: any;
  setStateForm: Dispatch<SetStateAction<StateForm>>;
  reset?: any;
  data: any;
  isEditable: boolean;
  logo: any;
  setLogo: any;
}

export function InputForm({
  register,
  control,
  watch,
  setValue,
  setError,
  errors,
  reset,
  data,
  setStateForm,
  isEditable,
  logo,
  setLogo,
}: IProps) {
  const { csts } = useAppSelector(({ storeReducer }) => storeReducer.value);

  const statusStore = watch("status");
  const ieStore = watch("isento_ie");
  const logobd = watch("isento_ie");

  const [key, setKey] = useState("generalData");
  const [storeStatus, setStoreStatus] = useState<boolean>();
  const [ieActived, setIeActived] = useState<boolean>();
  const [status, setStatus] = useState<any>();
  const [logoBd, setLogoBd] = useState<any>();
  const [showModal, setShowModal] = useState(false);

  const isMatriz = watch("type_id")?.value === 2 ? true : false;

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleModalLogo = () => {
    handleShow();
  };

  const handleImageChange = (event: any) => {
    setLogo(null);
    const file = event?.target.files[0];
    const render = new FileReader();
    render.readAsDataURL(file);
    render.onload = function (event) {
      setLogo(event?.target?.result);
      setValue("logo", event?.target?.result);
    };
  };

  const bg = statusStore ? "#16a34a" : "#f87171";

  useEffect(() => {
    setStoreStatus(
      isEditable
        ? data?.status === 2
          ? false
          : true || true
        : statusStore === 2
        ? false
        : true
    );
  }, [statusStore]);

  useEffect(() => {
    setIeActived(statusStore === 2 ? false : true);
  }, [ieStore]);

  useEffect(() => {
    setLogoBd(data.logo);
  }, []);

  return (
    <S.Wrapper>
      <ModalImage
        showModal={showModal}
        handleClose={handleClose}
        logo={logo}
        logoBd={logoBd}
        setLogo={setLogo}
        handleImageChange={handleImageChange}
        register={register}
      />

      <Tabs
        defaultActiveKey="generalData"
        className="mt-2"
        activeKey={key}
        onSelect={(k: any) => setKey(k)}
      >
        <Tab eventKey="generalData" title="Dados Gerais" className="mx-3">
          <Row>
            <Col>
              <h3 className="title">Dados Gerais</h3>
            </Col>
          </Row>

          <S.Content>
            <S.WrapperImg>
              <InputImg state={logo} setState={setLogo} />

              <button
                type="button"
                //onClick={() => setStatus(!status)}
                style={{ background: bg }}
              >
                {statusStore ? "Ativo" : "Inativo"}
              </button>
            </S.WrapperImg>

            <S.WrapperInput>
              <InputHookCnpj
                register={register}
                watch={watch}
                setValue={setValue}
                setError={setError}
                errors={errors}
                setStateForm={setStateForm}
                label="CNPJ"
                mask="99.999.999/9999-99"
                name="cnpj"
                placeholder="Cnpj"
                reset={reset}
                isEditable={isEditable}
                dataEdit={data}
                defaultValue={data.cnpj}
              />

              <InputHook
                register={register}
                errors={errors}
                label="Razão social"
                name="company_name"
                placeholder="azão social"
                type="text"
              />

              <InputHook
                register={register}
                errors={errors}
                label="Nome fantasia"
                name="trading_name"
                placeholder="Nome fantasia"
                type="text"
              />

              <SelectHook
                register={register}
                control={control}
                errors={errors}
                label="Tipo"
                name="type_id"
                placeholder="Tipo"
                options={ObjType}
              />

              <InputHook
                register={register}
                label="CNPJ da matriz"
                mask="99.999.999/9999-99"
                name="cnpj_matriz"
                placeholder="CNPJ da matriz"
                disabled={!isMatriz}
              />

              <InputHook
                register={register}
                errors={errors}
                label="Ins. Municipal"
                name="municipal_registration"
                type="text"
                placeholder="Inscrição Municipal"
              />

              <InputHook
                register={register}
                errors={errors}
                label="Ins. estadual"
                name="state_registration"
                type="text"
                placeholder="Inscrição estadual"
              />

              <InputHook
                register={register}
                errors={errors}
                label="Suframa"
                name="suframa"
                type="text"
                placeholder="Suframa"
              />

              <InputHook
                register={register}
                errors={errors}
                label="Acesso Externo"
                name="representor_external_uuid"
                type="text"
                placeholder="Acesso Externo"
              />
            </S.WrapperInput>
          </S.Content>
          <S.BtnAdd>
            <CheckboxHook
              register={register}
              errors={errors}
              label="Insenta de IE"
              name="isento_ie"
            />
          </S.BtnAdd>

          <Row>
            <Col>
              <h3 className="title border-top">Endereço</h3>
            </Col>
          </Row>

          <S.WrapperInput>
            <InputHookCep
              register={register}
              watch={watch}
              setValue={setValue}
              setError={setError}
              errors={errors}
              setStateForm={setStateForm}
              label="Cep"
              name="cep"
              type="number"
              mask="99999-999"
              placeholder="Cep"
              dataEdit={data}
              defaultValue={data.cep}
            />

            <InputHook
              register={register}
              errors={errors}
              label="Endereço"
              name="address_street"
              type="text"
              placeholder="Endereço"
            />

            <InputHook
              register={register}
              errors={errors}
              label="Número"
              name="address_number"
              type="text"
              placeholder="Número"
            />

            <InputHook
              register={register}
              errors={errors}
              label="Complemento"
              name="address_complement"
              type="text"
              placeholder="Complemento"
            />

            <InputHook
              register={register}
              errors={errors}
              label="Bairro"
              name="address_district"
              type="text"
              placeholder="Bairro"
            />

            <InputHook
              register={register}
              errors={errors}
              label="Cidade"
              name="city"
              type="text"
              placeholder="Cidade"
              disabled
            />

            <InputHook
              register={register}
              errors={errors}
              label="Estado"
              name="state"
              type="text"
              placeholder="Estado"
              disabled
            />
          </S.WrapperInput>
          <Row>
            <Col>
              <h3 className="title border-top">Informações de contato</h3>
            </Col>
          </Row>

          <S.WrapperInput>
            <InputHook
              register={register}
              errors={errors}
              label="Nome"
              name="name"
              placeholder="Nome"
              type="text"
            />

            <InputHook
              register={register}
              errors={errors}
              type="number"
              label="Telefone"
              name="phone"
              mask="(99) 9999-9999"
              placeholder="Telefone"
            />

            <InputHook
              register={register}
              errors={errors}
              type="number"
              label="Celular"
              name="mobile"
              mask="(99) 99999-9999"
              placeholder="Celular"
            />

            <InputHook
              register={register}
              errors={errors}
              label="E-mail"
              name="email"
              placeholder="E-mail"
              type="text"
            />

            <InputHook
              register={register}
              errors={errors}
              label="Site"
              name="site"
              placeholder="Site"
              type="text"
            />
          </S.WrapperInput>
        </Tab>

        <Tab eventKey="taxationData" title="Tributação" className="mx-3">
          <Row md="12">
            <Col>
              <h3 className="title">Regime tributário da empresa</h3>
              <p>
                Defina o regime tributário, o CSOSN e a alíquota de Simples
                Nacional.
              </p>
            </Col>
          </Row>

          <S.WrapperInput>
            <SelectHook
              register={register}
              control={control}
              label="Reg. tributário"
              name="tax_regime"
              placeholder="Regime tributário"
              options={ObjRegimeTributario}
            />

            <SelectHook
              register={register}
              control={control}
              label="CSOSN"
              name="csosn_id"
              placeholder="CSOSN"
              options={csts.map((item: ICsts) => ({
                value: item.id,
                label: item.cst_description,
              }))}
            />

            <InputHook
              register={register}
              label="Simpl. Nacional"
              name="aliquot_sn"
              placeholder="Alíquota Simples Nacional (%)"
              step="0.01"
              min="0"
              type="number"
            />
          </S.WrapperInput>
          <Row md="12">
            <Col>
              <h3 className="title">PIS e COFINS</h3>
              <p>
                Defina a situação tributária do PIS e sua alíquota, e a situação
                tributária do COFINS e sua alíquota.
              </p>
            </Col>
          </Row>
          <S.WrapperInput>
            <SelectHook
              register={register}
              control={control}
              errors={errors}
              label="Sit.tributário"
              name="tax_situation"
              placeholder="Selecione o situação tributário"
              options={ObjSituacoesTributarias}
            />

            <InputHook
              register={register}
              label="PIS"
              name="aliquot_pis"
              placeholder="Alíquota - PIS (%)"
              step="0.01"
              min="0"
              type="number"
            />

            <InputHook
              register={register}
              label="COFINS"
              name="aliquot_cofins"
              placeholder="Alíquota - COFINS (%)"
              step="0.01"
              min="0"
              type="number"
            />
          </S.WrapperInput>

          <Row md="12">
            <Col>
              <h3 className="title">CFOP</h3>
              <p>
                Defina os CFOPs que serão utilizados automaticamente pelo no
                momento que você realizar suas vendas.
              </p>
            </Col>
          </Row>
          <S.WrapperInput1>
            <SelectHook
              register={register}
              control={control}
              errors={errors}
              label="Venda de produtos com ICMS com substituição tributária"
              name="cfop_venda_icms_tributario"
              placeholder="CFOP de venda de produtos com ICMS com substituição tributária"
              options={CFOPVendaComST}
              defaultValue={CFOPVendaComST[2]}
            />

            <SelectHook
              register={register}
              control={control}
              errors={errors}
              label="Venda de produtos com ICMS"
              name="cfop_venda_icms"
              placeholder="CFOP de venda de produtos com ICMS"
              options={CFOPVendaComICMS}
              defaultValue={CFOPVendaComICMS[1]}
            />

            <SelectHook
              register={register}
              control={control}
              errors={errors}
              label="Devolução de vendas com ICMS com substituição tributária"
              name="cfop_devolucao_icms_tributario"
              placeholder="CFOP de devolução de vendas com ICMS com substituição tributária"
              options={CFOPDevolucaoVendasComST}
              defaultValue={CFOPDevolucaoVendasComST[4]}
            />

            <SelectHook
              register={register}
              control={control}
              errors={errors}
              label="CFOP de transferência de estoque"
              name="cfop_transferencia_estoque"
              placeholder="CFOP de transferência de estoque"
              options={CFOPTransferenciaEstoque}
              defaultValue={CFOPTransferenciaEstoque[17]}
            />

            <SelectHook
              register={register}
              control={control}
              label="Emissão de notas fiscais a partir de cupom fiscal"
              name="cfop_emissao_nota_fiscal"
              placeholder="CFOP de emissão de notas fiscais a partir de cupom fiscal"
              options={CFOPsEmissaoDeNotasFiscaisPorCupomFiscal}
              defaultValue={CFOPsEmissaoDeNotasFiscaisPorCupomFiscal[0]}
            />
          </S.WrapperInput1>
        </Tab>

        <Tab eventKey="pdv" title="PDV"></Tab>
      </Tabs>
    </S.Wrapper>
  );
}
