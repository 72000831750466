import { notifyError } from "../Components/notify/notify-component";
import globals from "../Globals.json";
import api from "./api";

export const createFinancialTransaction = async (
  objFinancialTransaction: any
) => {
  if (objFinancialTransaction.uuid) {
    return api
      .put(
        `${globals.api.financialTransaction}/${objFinancialTransaction.uuid}`,
        objFinancialTransaction
      )
      .then((res: any) => {
        if (res.status === 201 || res.status === 200) {
          const objCallback = {
            data: res.data.data,
            status: true,
          };
          return objCallback;
        }
      })
      .catch((error: any) => {
        notifyError(error);
      });
  } else {
    return api
      .post(`${globals.api.financialTransaction}`, objFinancialTransaction)
      .then((res: any) => {
        if (res.status === 201 || res.status === 200) {
          const objCallback = {
            data: res.data.data,
            status: true,
          };
          return objCallback;
        }
      })
      .catch((error: any) => {
        notifyError(error);
      });
  }
};

export const getFinancialTransaction = () => {
  return api.get(`${globals.api.financialTransaction}?paginate=false`);
};
