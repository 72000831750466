import { Button, Col, Modal, Row } from "react-bootstrap";
import { InputDefault } from "../../../../data/inputDefault";
import React, { useState } from "react";
import { useAppSelector } from "../../../../../redux/store";
import api from "../../../../../Services/api";
import Globals from "../../../../../Globals.json";
import { notify, notifyError } from "../../../../notify/notify-component";
import GetEndPoint from "../../../../../Services/GetEndPoint";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { StateForm } from "../../../../../interfaces/state-form";
import { Services } from "../../../../../Services/newService";
import { InputHookCpf } from "../../../../reactHookForm/InputHookCpf";
import { InputHookCnpjPeople } from "../../../../reactHookForm/InputHookCnpjPeople";
import { InputHook } from "../../../../reactHookForm/InputHook";
import { peopleSchemaProduct } from "../../../../../schemas/peopleSchema-product";
import Loading from "../../../../Loading/Loading";
import * as S from "./styled";
import { TypePersonGroup } from "../../../../Cadastro/Pessoa/InputClone/typePersonGroup";
import { TypeRecordGroup } from "../../../../Cadastro/Pessoa/InputClone/typeRecordGroup";
import { ITypeRecord } from "../../../../Cadastro/Pessoa/FormPessoa";
import { peopleSchema } from "../../../../../schemas/peopleSchema";

interface IProps {
  show: boolean;
  setShow: any;
  setLoading: any;
  setSuplier: any;
}

type FormData = Yup.InferType<typeof peopleSchema>;

interface IInput {
  cpf_cnpj: string;
  company_name: string;
}

const initialTypeRecord = {
  customer: true,
  vendor: false,
  employee: false,
  carrier: false,
};

export default function ModalReceiver({
  show,
  setShow,
  setLoading,
  setSuplier,
}: IProps) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [typeRecord, setTypeRecord] = useState<ITypeRecord>(initialTypeRecord);
  const [typePerson, setTypePerson] = useState<number>(1);

  const [stateForm, setStateForm] = useState<StateForm>({
    state: "default",
    message: "",
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(peopleSchema),
  });

  const handleClose = () => {
    reset();
    setShow(false);
  };

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    setLoading(true);
    setStateForm({ state: "loading" });

    const newData = {
      ...data,
      store_id: storeMe.storeActive.id,
      is_customer: typeRecord?.customer,
      is_vendor: typeRecord?.vendor,
      is_employee: typeRecord?.employee,
      is_carrier: typeRecord?.carrier,
    };
    try {
      const res = await Services.people(newData, setStateForm);
      if (res?.status === 200) {
        notify(`Fornecedor cadastrado.`);
        GetEndPoint.people(setSuplier);
        setLoading(false);
        setStateForm({ state: "send" });
        handleClose();
      }
    } catch (err: any) {
      const erroAxios = err?.response?.data?.errors;
      const erroHandler = erroAxios ? Object.values(erroAxios)[0] : err;

      if (typeof erroHandler === "string") {
        const parseError = JSON.parse(erroHandler);
        notifyError(parseError.cause[0].message || `Erro ao cadastrar.`);
        setLoading(false);
        setStateForm({ state: "error" });
        return;
      }
    }
  };

  const inputError = [errors];

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Novo Favorecido</h3>
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <S.Typeperson>
              <TypePersonGroup
                typePerson={typePerson}
                setTypePerson={setTypePerson}
              />
              <TypeRecordGroup
                typeRecord={typeRecord}
                setTypeRecord={setTypeRecord}
              />
            </S.Typeperson>
            <S.Wrapper>
              {typePerson === 1 ? (
                <>
                  <InputHook
                    register={register}
                    errors={inputError}
                    label="Nome"
                    name="name"
                    type="text"
                    placeholder="Nome"
                  />
                  <InputHookCpf
                    register={register}
                    errors={inputError}
                    setValue={setValue}
                    setError={setError}
                    reset={reset}
                    setStateForm={setStateForm}
                    watch={watch}
                    path={""}
                    mask="999.999.999-99"
                    label="CPF"
                    name="cpf_cnpj"
                    placeholder="CPF"
                  />
                </>
              ) : (
                <>
                  <InputHookCnpjPeople
                    register={register}
                    watch={watch}
                    setValue={setValue}
                    setError={setError}
                    errors={inputError}
                    reset={reset}
                    setStateForm={setStateForm}
                    label="CNPJ"
                    mask="99.999.999/9999-99"
                    name="cpf_cnpj"
                    placeholder="Cnpj"
                    path={"vendor"}
                    setArrayContacts={[]}
                    setArrayAdress={[]}
                  />
                  <InputHook
                    register={register}
                    errors={inputError}
                    label="Razão social"
                    name="company_name"
                    placeholder="Razão social"
                    type="text"
                    disabled={stateForm.state === "loading"}
                  />

                  <InputHook
                    register={register}
                    errors={inputError}
                    label="Nome fantasia"
                    name="trading_name"
                    placeholder="Nome fantasia"
                    type="text"
                    disabled={stateForm.state === "loading"}
                  />
                </>
              )}

              <InputHook
                register={register}
                errors={inputError}
                label="Email"
                name="email"
                type="text"
                placeholder="Email"
                disabled={stateForm.state === "loading"}
              />
            </S.Wrapper>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={stateForm.state === "loading"}
            >
              Salvar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      {stateForm.state === "loading" && <Loading />}
    </>
  );
}
