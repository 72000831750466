import { Col, Row } from "react-bootstrap";
import styles from "./styles.module.scss";
import { ReactNode } from "react";
import { Btn } from "../../../../data/Btn";

interface ILayout {
  children: ReactNode;
  title?: string;
  onClickAdd?: () => void;
  onClickClear?: () => void;
  textAdd?: string;
  handleSubmit?: any;
  onSubmit?: any;
  onClickOpenFilter?: any;
  openFilter?: boolean;
}

export default function LayoutHookFilter({
  children,
  title,
  onClickAdd,
  onClickClear,
  textAdd,
  handleSubmit,
  onSubmit,
  onClickOpenFilter,
  openFilter,
}: ILayout) {
  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col className={styles.titleGroup}>
          <h3>{title}</h3>
          <div className={styles.wrapperBtn}>
            {onClickOpenFilter && (
              <div className={styles.btnFilter}>
                <Btn
                  text="Filtro"
                  type="button"
                  variante="info"
                  icon={"arrow"}
                  onClick={onClickOpenFilter}
                />
              </div>
            )}
            {onClickAdd && (
              <Btn
                text={textAdd ? textAdd : "Adicionar"}
                type="button"
                variante="primary"
                icon={"plus"}
                onClick={onClickAdd}
              />
            )}
          </div>
        </Col>
      </Row>
      {children}
      <Row className="mt-2">
        <Col className={styles.btnGourp}>
          <div className={styles.btn}>
            <Btn
              text="Limpar"
              type="button"
              variante="secondary"
              icon="trash"
              onClick={onClickClear}
            />
            <Btn
              text="Buscar"
              type={onSubmit ? "submit" : "button"}
              variante="success"
              icon="search"
            />
          </div>
          {openFilter && (
            <div className={styles.btnMin}>
              <Btn
                text="Limpar"
                type="button"
                variante="secondary"
                icon="trash"
                onClick={onClickClear}
              />
              <Btn
                text="Buscar"
                type={onSubmit ? "submit" : "button"}
                variante="success"
                icon="search"
              />
            </div>
          )}
        </Col>
      </Row>
    </form>
  );
}
