export interface ISelect {
  value: number;
  label: string;
}

export const objectStatus: ISelect[] = [
  {
    value: 1,
    label: "Todos",
  },
  {
    value: 2,
    label: "Ativo",
  },
  {
    value: 3,
    label: "Inativo",
  },
];

export const objectPeriodo: ISelect[] = [
  {
    value: 1,
    label: "Todos",
  },
  {
    value: 2,
    label: "Data de cadastro",
  },
];

export const objectTypePerson: ISelect[] = [
  {
    value: 1,
    label: "Pessoa fisíca",
  },
  {
    value: 2,
    label: "Pessoa jurídica",
  },
];

export const objectTypeRecord: ISelect[] = [
  {
    value: 1,
    label: "Cliente",
  },
  {
    value: 2,
    label: "Fornecedor",
  },
  {
    value: 3,
    label: "Funcionário",
  },
  {
    value: 4,
    label: "Transportador",
  },
];

export const objectIE: ISelect[] = [
  {
    value: 1,
    label: "Ativo",
  },
  {
    value: 2,
    label: "Inativo",
  },
];

export const objectSex: ISelect[] = [
  {
    value: 1,
    label: "Masculino",
  },
  {
    value: 2,
    label: "Feminino",
  },
];
