import * as S from "./style";

interface IProps {
  status: boolean;
  text: string;
  onClick?: any;
  color?: string;
}

export default function ActiveInactiv({
  status,
  text,
  onClick,
  color,
}: IProps) {
  const isButton = onClick ? "pointer" : "";

  const textColor = color === "yellow" ? "#4b4b4b" : "";

  return (
    <S.Content
      onClick={onClick}
      status={status}
      style={{ cursor: isButton, backgroundColor: color, color: textColor }}
    >
      {status ? text : text}
    </S.Content>
  );
}
