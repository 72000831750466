import { useEffect, useState, useContext } from "react";
import "./styles.scss";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";

import { FaEdit, FaRegTrashAlt } from "react-icons/fa";

import { ToastContainer, toast } from "react-toastify";

import Loading from "../../../Components/Loading/Loading";

import api from "../../../Services/api";
import Globals from "../../../Globals.json";
import { sortByCreated } from "../../../Services/sortByCreated";
import { ThemeContext } from "../../../context/theme-context";
import LayoutFilter from "../../../Components/data/LayoutFilter";
import tableStyleDefault from "../../../Services/tableStyleDefault";
import { InputDefault } from "../../../Components/data/inputDefault";
import { notifyError } from "../../../Components/notify/notify-component";
import { useAppSelector } from "../../../redux/store";
import { NoData } from "../../../Components/noData";
import CustomLoader from "../../../Components/data/customLoader";
import * as S from "./styled";

interface IMeasureUnits {
  uuid?: string;
  store_id?: number;
  initials?: string;
  name?: string;
  decimal_places?: number;
}

export default function CadUnidMedida(props: any) {
  const { collapsed } = useContext(ThemeContext);
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [show, setShow] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const [measureUnits, setMeasureUnits] = useState<Array<any>>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [actionButton, setActionButton] = useState<string>("");

  const [nome, setNome] = useState<string>("");
  const [sigla, setSigla] = useState<string>("");
  const [casas, setCasas] = useState<number>(0);

  const [edit, setEdit] = useState<boolean>(false);
  const [nomeInValid, setNomeInValid] = useState<boolean>(false);
  const [siglaInValid, setSiglaInValid] = useState<boolean>(false);
  const [casasInValid, setCasasInValid] = useState<boolean>(false);

  const [unidade, setUnidade] = useState<IMeasureUnits>();

  const handleCloseDelete = () => setShowDelete(false);
  const handleShow = () => setShow(true);
  const handleShowDelete = () => setShowDelete(true);

  const handleClose = () => {
    setShow(false);
    clear();
  };

  const notifySucess = (text: string) =>
    toast.success(text, {
      position: "top-center",
      autoClose: 300,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });

  const handleNome = (item: any) => {
    setNome(item);
    if (item.length > 2) {
      setUnidade({ ...unidade, name: item });
      setNomeInValid(false);
      return;
    }
    setNomeInValid(true);
  };
  const handlSigla = (item: any) => {
    setSigla(item);
    if (item.length > 1 && item.length <= 3) {
      setUnidade({ ...unidade, initials: item });
      setSiglaInValid(false);
      return;
    }
    setSiglaInValid(true);
  };
  const handleCasas = (item: any) => {
    setCasas(item);
    if (item > 0) {
      setUnidade({ ...unidade, decimal_places: item });
      setCasasInValid(false);
      return;
    }
    setCasasInValid(true);
  };

  const verify = (): boolean => {
    let result: boolean = true;
    if (nome.length < 3) {
      result = false;
      setNomeInValid(true);
    }
    if (casas === 0) {
      result = false;
      setCasasInValid(true);
    }
    if (sigla.length < 2) {
      result = false;
      setSiglaInValid(true);
    }
    return result;
  };
  const handleSave = () => {
    if (verify()) {
      const data = { ...unidade, store_id: storeMe.storeActive.id };
      if (edit) {
        doUpdate(data);
        return;
      }
      doAddNew(data);
    }
  };

  const clear = () => {
    setNome("");
    setSigla("");
    setCasas(0);
    setEdit(false);
    setNomeInValid(false);
    setSiglaInValid(false);
    setCasasInValid(false);
    setUnidade(undefined);
  };

  const handleEdit = (item: IMeasureUnits) => {
    setUnidade({ ...unidade, uuid: item.uuid });
    setNome(item.name || "");
    setSigla(item.initials?.toUpperCase() || "");
    setCasas(item.decimal_places || 0);

    setEdit(true);
    setActionButton("Atualizar");
    handleShow();
  };

  const handleDelete = (item: IMeasureUnits) => {
    setUnidade(item);
    handleShowDelete();
  };

  const columns = [
    {
      name: "Nome",
      center: true,
      selector: (row: any) => row.name,
      grow: 2,
    },
    {
      name: "Sigla",
      center: true,
      selector: (row: any) => row.initials,
      grow: 2,
    },
    {
      name: "Casas decimais",
      center: true,
      selector: (row: any) => row.decimal_places,
    },
    {
      name: "Ação",
      cell: (row: any) => (
        <div className="actions">
          <FaEdit
            size={15}
            data-toggle="tooltip"
            data-placement="top"
            title="Editar"
            className="action"
            onClick={() => handleEdit(row)}
            style={{ marginRight: 16 }}
            color="8e8d8d"
          />
          <FaRegTrashAlt
            data-toggle="tooltip"
            data-placement="top"
            title="Deletar"
            className="action"
            onClick={() => handleDelete(row)}
            size={14}
            color="8e8d8d"
          />
        </div>
      ),
      button: true,
    },
  ];

  const hanldeAdd = () => {
    setActionButton("Salvar");
    setEdit(false);
    handleShow();
  };

  const doAddNew = (unidade: any) => {
    console.log("chegou");

    setLoading(true);
    api
      .post(Globals.api.measure, unidade)
      .then((response) => {
        if (response.status === 201) {
          notifySucess("Unidade cadastrada com sucesso");
          handleClose();
          clear();
          getData();
        } else {
          notifyError("Não foi possivel cadastrar a unidade");
        }
      })
      .catch((error: Error) => {
        notifyError(`Ocorreu um erro ${error.message}`);
        console.error("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const doUpdate = (unidade: any) => {
    setLoading(true);
    api
      .put(Globals.api.measure + `/${unidade.uuid}`, unidade)
      .then((response) => {
        if (response.status === 200) {
          notifySucess("Unidade atualizada com sucesso");
          handleClose();
          clear();
          getData();
        } else {
          notifyError(`Não foi possivel atualizar o registro`);
        }
      })
      .catch((error: Error) => {
        notifyError(`Ocorreu um erro ${error.message}`);
        console.error("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const doDelete = () => {
    if (!unidade) return;
    setLoading(true);
    api
      .delete(Globals.api.measure + `/${unidade.uuid}`)
      .then((response) => {
        if (response.status === 200) {
          notifySucess("Unidade excluida com sucesso");
          handleCloseDelete();
          clear();
          getData();
        } else {
          notifyError(`Não foi possivel excluir o registro`);
        }
      })
      .catch((error: Error) => {
        notifyError(`Ocorreu um erro ${error.message}`);
        console.error("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getData = () => {
    let url = `?paginate=false&store_id=${storeMe.storeActive.id}`;
    setLoading(true);

    api
      .get(Globals.api.measure + url)
      .then((response) => {
        const data = response.data;

        setMeasureUnits(sortByCreated(data.data));
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const paginationOptions = {
    rowsPerPageText: "Registros por página",
    rangeSeparatorText: "de",
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Container
        className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
      >
        <LayoutFilter title="Unidades de Medida" onClickAdd={hanldeAdd}>
          <div className="mt-3">
            <DataTable
              columns={columns}
              data={measureUnits || []}
              striped
              pagination
              paginationDefaultPage={1}
              paginationPerPage={25}
              customStyles={tableStyleDefault}
              paginationComponentOptions={paginationOptions}
              progressPending={loading}
              progressComponent={<CustomLoader />}
            />
          </div>
        </LayoutFilter>
        {measureUnits.length === 0 && <NoData />}

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>{edit ? "Atualizar " : "Nova "} unidade de medida </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <S.Wrapper>
              <InputDefault
                label="Nome"
                type="text"
                autoComplete="off"
                state={nome}
                placeholder="Nome"
                maxLength={50}
                isInvalid={nomeInValid}
                onChenge={(e) => handleNome(e.target.value)}
              />

              <InputDefault
                label="Sigla"
                type="text"
                autoComplete="off"
                state={sigla}
                placeholder="Sigla"
                maxLength={3}
                isInvalid={siglaInValid}
                onChenge={(e) => handlSigla(e.target.value.toUpperCase())}
              />

              <InputDefault
                label="Qtd casas decimais"
                type="number"
                autoComplete="off"
                state={casas}
                isInvalid={casasInValid}
                maxLength={2}
                onChenge={(e) => handleCasas(parseInt(e.target.value))}
              />
            </S.Wrapper>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
            <Button variant="primary" onClick={handleSave}>
              {actionButton}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showDelete} onHide={handleCloseDelete}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Excluir unidade de medida</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <p id="excludeMsg">
                Deseja realmente excluir a unidade {unidade?.name}?
              </p>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
              Não
            </Button>
            <Button variant="primary" onClick={doDelete}>
              Sim
            </Button>
          </Modal.Footer>
        </Modal>

        <ToastContainer
          position="top-center"
          autoClose={300}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
        />
      </Container>
    </>
  );
}
