import { Col, Row } from "react-bootstrap";
import { InputHook } from "../../../../reactHookForm/InputHook";
import { Btn } from "../../../../data/Btn";
import Format from "../../../../../Services/Format";
import LayoutTableNotShadow from "../../../../data/LayoutTableNotShadow";
import { paginationOptions } from "../../../../data/PagineteInfo";
import DataTable from "react-data-table-component";
import { SetStateAction, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import { generateId } from "../../../../../Services/generateId";
import { Services } from "../../../../../Services/newService";
import { notify } from "../../../../notify/notify-component";
import ModalBootstrap from "../../../../data/Modal";
import CustomLoader from "../../../../data/customLoader";
import tableStyleDefaulInternal from "../../../../../Services/tableStyleDefaultInternal";
import * as S from "./styled";

interface IProps {
  register: any;
  errors: any;
  watch: any;
  setValue: any;
  setError: any;
  setStateForm: React.Dispatch<React.SetStateAction<any>>;
  data: any;
  arrayContacts: any[];
  setArrayContacts: React.Dispatch<SetStateAction<any[]>>;
  isEditable: boolean;
}

export function ContactData({
  register,
  errors,
  setError,
  setValue,
  watch,
  setStateForm,
  data,
  arrayContacts,
  setArrayContacts,
  isEditable,
}: IProps) {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [uuidContact, setUuidContact] = useState<any>();
  const [modalConfirmation, setModalConfirmation] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [uuid, setuuid] = useState<string>("");

  const columns = [
    {
      name: "Nome",
      minWidth: "190px",
      selector: (row: any) => row.name_1,
      grow: 2,
    },
    {
      name: "Celular",
      minWidth: "120px",
      selector: (row: any) => Format.formatCelular(row?.cell_phone ?? "") ?? "",
    },
    {
      name: "Telefone",
      minWidth: "110px",
      selector: (row: any) => Format.formatTelefone(row?.phone ?? "") ?? "",
    },
    {
      name: "Ação",
      minWidth: "80px",
      center: true,
      cell: (row: any) => (
        <div
          className="actions"
          style={{ display: "flex", gap: "15px", marginLeft: "14px" }}
        >
          <FaEdit
            data-toggle="tooltip"
            data-placement="top"
            title="Editar Contato"
            className="action"
            onClick={() => handleEdit(row)}
            size={17}
            color="#50cd89"
          />
          <BsTrash
            size={16}
            data-toggle="tooltip"
            data-placement="top"
            title="Deletar Contato"
            className="action"
            onClick={() => handleModalId(row?.id, row?.uuid)}
            style={{ marginRight: 15 }}
            color="#0095e8"
          />
        </div>
      ),
      button: true,
    },
  ];

  const handleContacts = () => {
    setIsEdit(false);

    if (!watch("cell_phone"))
      return setError("cell_phone", {
        type: "custom",
        message: "Por favor informe o celular.",
      });

    if (!watch("name_1"))
      return setError("name_1", {
        type: "custom",
        message: "Por favor informe o nome.",
      });

    const newContacts = {
      id: generateId(),
      name_1: watch("name_1"),
      cell_phone: watch("cell_phone"),
      phone: watch("phone") ? watch("phone") : "---",
    };

    arrayContacts && setArrayContacts([...arrayContacts, newContacts]);
    !arrayContacts && setArrayContacts([newContacts]);

    setValue("name_1", "");
    setError("name_1", {
      type: "custom",
      message: "",
    });
    setValue("cell_phone", "");
    setError("cell_phone", {
      type: "custom",
      message: "",
    });
    setValue("phone", "");
    setError("phone", {
      type: "custom",
      message: "",
    });
  };

  const handleEdit = (data: any) => {
    setIsEdit(true);
    setUuidContact(data?.uuid);
    setValue("name_1", data.name_1);
    setValue("cell_phone", data.cell_phone);
    setValue("phone", data.phone);

    const removeContacts = arrayContacts.filter(
      (item: any) => item?.id !== data?.id
    );

    setArrayContacts([...removeContacts]);
  };

  const handleDelete = async (id: string, uuid: string) => {
    if (isEditable) {
      setStateForm({ state: "loading" });
      const newContactsApi: any = await Services.peopleDeleteContacts(
        data?.uuid,
        uuid,
        setStateForm
      );
      setStateForm({ state: "default" });
      notify("Contato deletado");
      const newContacts = arrayContacts.filter(
        (item: any) => item?.id !== newContactsApi?.data?.data?.id
      );
      setArrayContacts(newContacts);
    } else {
      const newContacts = arrayContacts.filter((item: any) => item.id !== id);
      setArrayContacts(newContacts);
    }
  };

  const handleContactsIsEditable = async () => {
    setIsEdit(false);
    if (!watch("cell_phone"))
      return setError("cell_phone", {
        type: "custom",
        message: "Por favor informe o celular.",
      });

    if (!watch("name_1"))
      return setError("name_1", {
        type: "custom",
        message: "Por favor informe o nome.",
      });

    setStateForm({ state: "loading" });

    const newContacts = {
      uuid: uuidContact,
      id: generateId(),
      name_1: watch("name_1"),
      cell_phone: watch("cell_phone"),
      phone: watch("phone") ? watch("phone") : "---",
    };

    if (isEditable && isEdit) {
      const response: any = await Services.peoplePutContacts(
        newContacts,
        data?.uuid,
        setStateForm
      );

      setArrayContacts([...arrayContacts, response?.data?.data]);
      setStateForm({ state: "default" });
      notify("Contato atualizado.");
    } else {
      const newContactsApi: any = await Services.addNewContacts(
        data?.uuid,
        newContacts,
        setStateForm
      );

      arrayContacts &&
        setArrayContacts([...arrayContacts, newContactsApi?.data?.data]);
      setStateForm({ state: "default" });
      notify("Contato Adicionado.");
    }

    setValue("name_1", "");
    setError("name_1", {
      type: "custom",
      message: "",
    });
    setValue("cell_phone", "");
    setError("cell_phone", {
      type: "custom",
      message: "",
    });
    setValue("phone", "");
    setError("phone", {
      type: "custom",
      message: "",
    });
  };
  const handleModalId = (id: string, uuid: string) => {
    setId(id);
    setuuid(uuid);
    setModalConfirmation(true);
  };

  const handleDeleteAdress = () => {
    setModalConfirmation(false);
    handleDelete(id, uuid);
  };

  return (
    <>
      <ModalBootstrap
        state={modalConfirmation}
        setState={setModalConfirmation}
        text="Certeza que deseja excluir o contato?"
        handleAction={handleDeleteAdress}
      />
      <S.Wrapper>
        <div className="min-screen">
          <InputHook
            register={register}
            errors={errors}
            label="Nome"
            name="name_1"
            placeholder="Nome"
            type="text"
          />
          <InputHook
            register={register}
            errors={errors}
            type="number"
            label="Telefone"
            name="phone"
            mask="(99) 9999-9999"
            placeholder="Telefone"
          />
          <InputHook
            register={register}
            errors={errors}
            type="number"
            label="Celular"
            name="cell_phone"
            mask="(99) 99999-9999"
            placeholder="Celular"
          />
        </div>
        <div className="max-screen">
          <InputHook
            register={register}
            errors={errors}
            label="Nome"
            name="name_1"
            placeholder="Nome"
            type="text"
          />
          <InputHook
            register={register}
            errors={errors}
            type="number"
            label="Telefone"
            name="phone"
            mask="(99) 9999-9999"
            placeholder="Telefone"
          />
          <InputHook
            register={register}
            errors={errors}
            type="number"
            label="Celular"
            name="cell_phone"
            mask="(99) 99999-9999"
            placeholder="Celular"
          />
        </div>
        <div className="btnAdd">
          <Btn
            text={isEdit ? "Atualizar Contato" : "Adicionar"}
            type="button"
            variante="primary"
            onClick={isEditable ? handleContactsIsEditable : handleContacts}
          />
        </div>
      </S.Wrapper>

      <LayoutTableNotShadow>
        <DataTable
          columns={columns}
          data={arrayContacts || []}
          striped
          paginationPerPage={25}
          customStyles={tableStyleDefaulInternal}
          paginationComponentOptions={paginationOptions}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhuma informação foi encontrada"
        />
      </LayoutTableNotShadow>
    </>
  );
}
