import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { PersonData } from "./personData";
import { AdressData } from "./adressData";
import { ContactData } from "./contactData";
import { TypePersonGroup } from "./typePersonGroup";
import { TypeRecordGroup } from "./typeRecordGroup";
import { ITypeRecord } from "../FormPessoa";
import { PersonDataPJ } from "./PersonDataPJ";
import { StateForm } from "../../../../interfaces/state-form";
import { filterObject } from "../../../../utils/filterObj";

interface IProps {
  typePerson: number;
  setTypePerson: Dispatch<SetStateAction<number>>;
  gender: string;
  setGender: Dispatch<SetStateAction<string>>;
  typeRecord: ITypeRecord;
  setTypeRecord: Dispatch<SetStateAction<ITypeRecord>>;
  register: any;
  control: any;
  watch: any;
  selectOptions: any;
  setValue: any;
  setError: any;
  errors: any;
  setStateForm: Dispatch<SetStateAction<StateForm>>;
  reset?: any;
  data: any;
  isEditable: boolean;
  arrayAdress: any[];
  setArrayAdress: any;
  arrayContacts: any[];
  setArrayContacts: any;
  path: string;
}

export function InputClone({
  register,
  control,
  watch,
  selectOptions,
  setValue,
  setError,
  errors,
  reset,
  data,
  setStateForm,
  isEditable,
  typePerson,
  setTypePerson,
  typeRecord,
  setTypeRecord,
  gender,
  setGender,
  arrayAdress,
  setArrayAdress,
  arrayContacts,
  setArrayContacts,
  path,
}: IProps) {
  const [key, setKey] = useState("personData");

  useEffect(() => {
    const filteredObject = filterObject(data);
    setTimeout(() => {
      if (data) {
        const resetData = {
          ...filteredObject,
        };
        setArrayAdress(data?.relationships?.addresses);
        setArrayContacts(data?.relationships?.contacts);
        reset(resetData);
      }
    }, 500);
  }, [typePerson]);

  useEffect(() => {
    if (Object.keys(data).length === 0) {
      if (typeRecord.vendor === true || typeRecord.carrier === true) {
        setTypePerson(2);
      } else {
        setTypePerson(1);
      }
    }
  }, [typeRecord]);

  return (
    <>
      <TypePersonGroup typePerson={typePerson} setTypePerson={setTypePerson} />

      <TypeRecordGroup typeRecord={typeRecord} setTypeRecord={setTypeRecord} />

      <Tabs
        defaultActiveKey="personData"
        className="mt-2"
        activeKey={key}
        onSelect={(k: any) => setKey(k)}
      >
        <Tab eventKey="personData" title="Dados Pessoais" className="mx-3">
          {typePerson === 1 ? (
            <PersonData
              gender={gender}
              setGender={setGender}
              register={register}
              errors={errors}
              control={control}
              watch={watch}
              setValue={setValue}
              setError={setError}
              reset={reset}
              setStateForm={setStateForm}
              path={path}
              data={data}
            />
          ) : (
            <PersonDataPJ
              gender={gender}
              setGender={setGender}
              register={register}
              errors={errors}
              control={control}
              watch={watch}
              setValue={setValue}
              setError={setError}
              reset={reset}
              setStateForm={setStateForm}
              data={data}
              path={path}
              isEditable={isEditable}
              setArrayContacts={setArrayContacts}
              setArrayAdress={setArrayAdress}
            />
          )}
        </Tab>
        <Tab eventKey="adressData" title="Endereço">
          <AdressData
            register={register}
            errors={errors}
            watch={watch}
            setValue={setValue}
            setError={setError}
            setStateForm={setStateForm}
            data={data}
            arrayAdress={arrayAdress}
            setArrayAdress={setArrayAdress}
            isEditable={isEditable}
          />
        </Tab>
        <Tab eventKey="contactData" title="Contato">
          <ContactData
            register={register}
            errors={errors}
            watch={watch}
            setValue={setValue}
            setError={setError}
            setStateForm={setStateForm}
            data={data}
            arrayContacts={arrayContacts}
            setArrayContacts={setArrayContacts}
            isEditable={isEditable}
          />
        </Tab>
      </Tabs>
    </>
  );
}
