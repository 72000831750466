import { IFormInventary } from "../../../../Pages/Cadastro/CadInventario";
import * as Yup from "yup";
import { inventarySchema } from "../../../../redux/features/inventary/schema";
import { SubmitHandler, useForm } from "react-hook-form";
import LayoutHookForm from "../../../reactHookForm/LayoutForm";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../../../redux/store";
import { InputClone } from "./InputClone";
import { useEffect, useState } from "react";
import {
  createInventaryAsync,
  getInventaryByUuidAsync,
} from "../../../../redux/features/inventary/services";
import {
  addItensTable,
  deleteAllItensTable,
  setContagemSelected,
  setIncludeInactiveProductsFalse,
  setStateForm,
  setUuidEdit,
} from "../../../../redux/features/inventary/inventary-slice";
import { notify, notifyError } from "../../../notify/notify-component";
import {
  IProduct,
  IStock,
  IStore,
} from "../../../../redux/features/inventary/interface";
import { yupResolver } from "@hookform/resolvers/yup";
import Loading from "../../../Loading/Loading";

type FormData = Yup.InferType<typeof inventarySchema>;

interface IFormInput {
  store: string;
  stock: string;
  type: string;
}

export function FormInventario({
  OnAddNewInventary,
  title,
  editando,
  inventaryData,
}: IFormInventary) {
  const dispatch = useDispatch<AppDispatch>();
  const inventary = useAppSelector(
    ({ inventaryReducer }) => inventaryReducer.value
  );
  const isDisable =
    inventary.InventaryByUuid?.status === 2 ||
    inventary.InventaryByUuid?.status === 3;
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(inventarySchema),
  });
  const inputError = [errors];

  const onSubmit: SubmitHandler<any> = async (data) => {
    dispatch(setStateForm({ state: "loading" }));
    if (inventary.itensTable.length === 0) {
      notifyError("Adicione pelo menos um produto.");
      return;
    }

    const newData = {
      store_id: data?.store?.value
        ? parseInt(data?.store?.value)
        : storeMe.user.relationships.stores[0].id,
      stock_id: data?.stock?.value
        ? parseInt(data?.stock?.value)
        : inventary.stock[0].id,
      name: data.name,
      data: data?.stock.name,
      type: 2,
      products: inventary.itensTable,
    };

    if (!editando) {
      dispatch(createInventaryAsync(newData)).then((result: any) => {
        if (createInventaryAsync.fulfilled.match(result)) {
          handleCancel();
        } else {
          notifyError("Erro ao registrar inventário.");
        }
      });
    } else {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };

  const handleCancel = () => {
    dispatch(deleteAllItensTable());
    reset();
    OnAddNewInventary(false);
    dispatch(setStateForm({ state: "default" }));
    dispatch(setUuidEdit({}));
    dispatch(setIncludeInactiveProductsFalse());
    dispatch(setContagemSelected(undefined));
  };

  useEffect(() => {
    if (!inventaryData.uuid) return;
    dispatch(getInventaryByUuidAsync(inventaryData.uuid));
  }, [dispatch, inventaryData.uuid]);

  useEffect(() => {
    dispatch(deleteAllItensTable());

    if (
      inventary.InventaryByUuid &&
      inventary.InventaryByUuid.relationships?.products.length > 0
    ) {
      for (let value of inventary.InventaryByUuid.relationships?.products) {
        let getProduct = inventary.allProductsTable.find(
          (item: IProduct) => item?.id === value?.product_id
        );

        let newItem = {
          ...getProduct,
          qty: value.qty,
        };
        dispatch(addItensTable({ ...newItem, uuidTable: value.uuid }));
      }
    } else if (
      inventary.contagemSelected &&
      inventary.contagemSelected.relationships?.products.length > 0
    ) {
      for (let value of inventary.contagemSelected.relationships?.products) {
        let getProduct = inventary.allProductsTable.find(
          (item: IProduct) => item?.id === value?.product_id
        );

        let newItem = {
          ...getProduct,
          product_id: getProduct?.id,
          qty: value.qty,
        };

        dispatch(addItensTable({ ...newItem, uuidTable: value.uuid }));
      }
    }
  }, [inventary.InventaryByUuid]);

  return (
    <>
      <LayoutHookForm
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        title={editando ? "Criar Inventário" : "Editar Inventário"}
        isEditable={editando}
        onClickCancel={handleCancel}
        stateForm={inventary.stateForm}
        isDisable={isDisable}
      >
        <InputClone
          register={register}
          control={control}
          watch={watch}
          setValue={setValue}
          setError={setError}
          errors={inputError}
          reset={reset}
          isEditable={editando}
          inventaryData={inventaryData}
          contagemName={inventary?.contagemSelected?.name || undefined}
        />
      </LayoutHookForm>

      {inventary.stateForm.state === "loading" && <Loading />}
    </>
  );
}
