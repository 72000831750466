import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Format from "../../../../Services/Format";
import { FaEdit } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DateUtils from "../../../../Services/DateUtils";
import tableStyleDefault from "../../../../Services/tableStyleDefault";
import ActiveInactiv from "../../../data/activeInactive";
import CustomLoader from "../../../data/customLoader";
import { AppDispatch, useAppSelector } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import LayoutFilter from "../../../data/LayoutFilter";
import { paginationOptions } from "../../../data/PagineteInfo";
import ModalCad from "./ModalCad";
import { getLocalStockAsync } from "../../../../redux/features/localStock/services";

export default function ConsultaEstoque(props: any) {
  const dispatch = useDispatch<AppDispatch>();

  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const { loading, localStock } = useAppSelector(
    ({ localStockReducer }) => localStockReducer.value
  );

  const [openModalCadPDV, setModalCadPDV] = useState<boolean>(false);
  const [listEditeItem, setListEditeItem] = useState<any>();

  const handleEdit = (item: any) => {
    setListEditeItem(item);
    setModalCadPDV(true);
  };

  const columns: any = [
    {
      name: "Nome",
      sortable: true,
      selector: (row: any) =>
        row?.name?.length > 18
          ? row.name.substring(0, 18) + "..."
          : row?.name?.toLowerCase(),
    },
    {
      name: "Data de cadastro",
      center: true,
      grow: 0.3,
      selector: (row: any) =>
        Format.date(DateUtils.toDate(row.created_at), "DD/MM/YYYY"),
    },

    {
      name: "Permite Negativo",
      center: true,
      grow: 0.2,
      selector: (row: any) =>
        row.is_negative_allowed ? (
          <ActiveInactiv status={row.is_negative_allowed} text="Sim" />
        ) : (
          <ActiveInactiv status={row.is_negative_allowed} text="Não" />
        ),
    },
    {
      name: "Status",
      center: true,
      grow: 0.2,
      selector: (row: any) =>
        row.is_active ? (
          <ActiveInactiv status={row.is_active} text="Ativo" />
        ) : (
          <ActiveInactiv status={row.is_active} text="Inativo" />
        ),
    },
    {
      name: "Ação",
      center: true,
      grow: 0.2,
      cell: (row: any) => (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">Editar</Tooltip>}
        >
          <span className="d-inline-block">
            <Button
              onClick={() => handleEdit(row)}
              variant="contained"
              color="primary"
            >
              <FaEdit size={18} color="8e8d8d" />
            </Button>
          </span>
        </OverlayTrigger>
      ),
      button: true,
    },
  ];

  useEffect(() => {
    dispatch(getLocalStockAsync(storeMe.storeActive.id));
  }, [dispatch]);

  return (
    <>
      <LayoutFilter
        title={"Locais de Estoque"}
        onClickAdd={() => setModalCadPDV(true)}
      >
        <div className="mt-3" />
        <DataTable
          className={styles.dataTable}
          columns={columns}
          data={localStock || []}
          striped
          pagination
          paginationDefaultPage={1}
          paginationPerPage={25}
          customStyles={tableStyleDefault}
          paginationComponentOptions={paginationOptions}
          progressPending={loading}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhuma informação foi encontrada."
        />
      </LayoutFilter>

      <ModalCad
        show={openModalCadPDV}
        setShow={setModalCadPDV}
        listEditeItem={listEditeItem}
        setListEditeItem={setListEditeItem}
      />

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
