import styled from "styled-components";

export const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  text-transform: capitalize;
  flex: 1;
  text-align: center;
`;

export const Wrapper = styled.div`
  ul {
    list-style: none;
    display: grid;
    gap: 0.3rem;
    font-size: 13px;
    color: #505050f4;
    padding: 1rem;
  }

  ul li {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-transform: uppercase;
    border-bottom: 1px solid #ced0d4f4;
  }

  ul li span {
    font-weight: bold;
    text-transform: uppercase;
  }
`;
