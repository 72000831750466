// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_modalHeader__wc-iH {
  height: 55px;
}

.styles_form__PIv\\+F {
  width: 455px;
}

.styles_footer__VWMZ2 {
  height: 70px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Financeiro/TrancacaoFinanceira/CadLaunch/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".modalHeader {\n  height: 55px;\n}\n\n.form {\n  width: 455px;\n}\n\n.footer {\n  height: 70px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalHeader": `styles_modalHeader__wc-iH`,
	"form": `styles_form__PIv+F`,
	"footer": `styles_footer__VWMZ2`
};
export default ___CSS_LOADER_EXPORT___;
