import globals from "../Globals.json";
import api from "./api";

export const createStockLocal = (objStockLocal: any) => {
  if (objStockLocal.uuid) {
    return api
      .put(`${globals.api.stocks}/${objStockLocal.uuid}`, objStockLocal)
      .then((res: any) => {
        if (res.status === 201 || res.status === 200) {
          const objCallback = {
            data: res.data.data,
            status: true,
          };
          return objCallback;
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  } else {
    return api
      .post(`${globals.api.stocks}`, objStockLocal)
      .then((res: any) => {
        if (res.status === 201 || res.status === 200) {
          const objCallback = {
            data: res.data.data,
            status: true,
          };
          return objCallback;
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }
};

export const getStocks = () => {
  return api.get(`${globals.api.stocks}?paginate=false`);
};

export const getStocksByStoreId = (store_id?: number) => {
  return api.get(
    `${globals.api.stocks}?paginate=false&filter[store_id]=${store_id}`
  );
};

export const showStocks = (uuid: string) => {
  return api.get(`${globals.api.stocks}/${uuid}`);
};
