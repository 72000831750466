import styles from "./styles.module.scss";

import { Header } from "../header/index";
import { Footer } from "../footer/Footer";
import { MenuSide } from "../menu/index";

export interface LayoutProps {
  children: React.ReactNode;
}

export function GlobalLayout(props: LayoutProps) {
  return (
    <>
      <div className={styles.container}>
        <MenuSide />
        <Header />
        <main className={styles.children}>{props.children}</main>
        <Footer />
      </div>
    </>
  );
}

export default GlobalLayout;
