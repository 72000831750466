import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 0.5rem;
  @media only screen and (max-width: 1100px) {
    .max-screen {
      display: none;
    }

    .min-screen {
      label > .label {
        text-align: left;
        margin-right: 0.5rem;
        font-size: 11px;
        font-weight: normal;
      }
    }
  }

  @media only screen and (min-width: 1100px) {
    .max-screen {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 1.2rem;

      .value-label {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .flex {
          display: flex;
        }
      }

      .initial-value {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      .final_value {
        input {
          width: 110px;
        }
      }
      label > .label {
        text-align: right;
        margin-right: 0.5rem;
        font-size: 11px;
        font-weight: normal;
      }
    }

    .min-screen {
      display: none;
    }
  }

  label {
    display: grid;
    grid-template-columns: 1fr 3fr;

    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;

      .label {
        display: none;
      }
    }
  }

  .wrapper-date {
    display: grid;
    grid-template-columns: 1fr;
  }
`;
