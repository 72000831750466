import styled from "styled-components";

interface IProps {
  minWidth: string | undefined;
}

export const Input = styled.div`
  margin-bottom: 0.3rem;
  margin-top: 0px;
`;

export const Wrapper = styled.div<IProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 11px !important;

  label {
    margin-left: 4px;
    min-width: ${({ minWidth }) => (minWidth ? minWidth : "")};
  }

  .select {
    width: 100%;
  }

  .placeholderSelect {
    font-size: 11px;
    color: var(--color-input-placeholder);
    text-align: left;
  }
`;

export const Error = styled.div`
  font-size: 10px;
  color: #dc2229;
  margin-top: 0.2rem;
`;
