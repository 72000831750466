import DataTable from "react-data-table-component";
import "./PaginationWrapper.css";
import { useEffect, useState } from "react";
import tableStyleDefaultTrans from "../../../Services/tableStyleTitleTransparente";

export default function PaginationWrapper({
  rowsPerPage,
  rowCount,
  currentPage,
  onChangePage,
  onChangeRowsPerPage,
  footerData,
}: any) {
  const totalPages = Math.ceil(rowCount / rowsPerPage);
  const [value, setValue] = useState<number>(0);
  const [valuePaid, setValuePaid] = useState<number>(0);

  const getBalance = () => {
    const value: any = [];
    const valuePaid: any = [];

    for (let index = 0; index < footerData.length; index++) {
      value.push({
        amount: footerData[index].amount,
      });

      valuePaid.push({
        amount_paid: footerData[index].amount_paid,
      });
    }

    if (value.length === 1) {
      setValue(value[0].amount);
    } else {
      setValue(value.reduce((a: any, b: any) => a + b.amount, 0));
    }

    if (valuePaid.length === 1) {
      setValuePaid(valuePaid[0].amount_paid);
    } else {
      setValuePaid(valuePaid.reduce((a: any, b: any) => a + b.amount_paid, 0));
    }
  };

  const columns: any = [
    {
      center: true,
      grow: 0.2,
    },
    { grow: 0.6, center: true },
    {
      grow: 0.8,
    },
    {
      grow: 0.5,
      center: true,
    },
    {
      grow: 0.7,
      center: true,
    },
    {
      grow: 0.8,
    },
    {
      grow: 0.6,
      center: true,
      selector: () => {
        return (
          <span style={{ fontWeight: "bolder" }}>
            {"R$ " + value.toFixed(2)}
          </span>
        );
      },
    },

    {
      grow: 0.6,
      center: true,
      selector: () => {
        return (
          <span style={{ fontWeight: "bolder" }}>
            {"R$ " + valuePaid.toFixed(2)}
          </span>
        );
      },
    },
    {
      grow: 0.6,
      center: true,
    },

    {
      grow: 0.6,
      center: true,
    },

    {
      width: "60px",
      center: true,
    },
  ];

  useEffect(() => {
    getBalance();
  }, [footerData]);

  return (
    <div className="pagination-wrapper">
      <div className="footer-data">
        <DataTable
          columns={columns}
          data={[{}]}
          customStyles={tableStyleDefaultTrans}
        />
      </div>
      <div className="line" />
      <div className="pagination-controls">
        <span>Registros por página</span>
        <select
          value={rowsPerPage}
          onChange={(e) =>
            onChangeRowsPerPage(Number(e.target.value), currentPage)
          }
        >
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
          <option value={25}>25</option>
          <option value={30}>30</option>
        </select>
        <span>
          {`${(currentPage - 1) * rowsPerPage + 1}-${Math.min(
            currentPage * rowsPerPage,
            rowCount
          )} de ${rowCount}`}
        </span>
        <button
          onClick={() => onChangePage(1)}
          disabled={currentPage === 1}
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="rgb(161, 161, 161)"
            viewBox="0 0 256 256"
          >
            <path d="M200.49,199.51a12,12,0,0,1-17,17l-80-80a12,12,0,0,1,0-17l80-80a12,12,0,0,1,17,17L129,128ZM72,36A12,12,0,0,0,60,48V208a12,12,0,0,0,24,0V48A12,12,0,0,0,72,36Z"></path>
          </svg>
        </button>
        <button
          onClick={() => onChangePage(currentPage - 1)}
          disabled={currentPage === 1}
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="rgb(161, 161, 161)"
            viewBox="0 0 256 256"
          >
            <path d="M168.49,199.51a12,12,0,0,1-17,17l-80-80a12,12,0,0,1,0-17l80-80a12,12,0,0,1,17,17L97,128Z"></path>
          </svg>
        </button>
        <button
          onClick={() => onChangePage(currentPage + 1)}
          disabled={currentPage === totalPages}
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="rgb(161, 161, 161)"
            viewBox="0 0 256 256"
          >
            <path d="M184.49,136.49l-80,80a12,12,0,0,1-17-17L159,128,87.51,56.49a12,12,0,1,1,17-17l80,80A12,12,0,0,1,184.49,136.49Z"></path>
          </svg>
        </button>
        <button
          onClick={() => onChangePage(totalPages)}
          disabled={currentPage === totalPages}
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="rgb(161, 161, 161)"
            viewBox="0 0 256 256"
          >
            <path d="M152.49,119.51a12,12,0,0,1,0,17l-80,80a12,12,0,0,1-17-17L127,128,55.51,56.49a12,12,0,0,1,17-17ZM184,36a12,12,0,0,0-12,12V208a12,12,0,0,0,24,0V48A12,12,0,0,0,184,36Z"></path>
          </svg>
        </button>
      </div>
    </div>
  );
}
