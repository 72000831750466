// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__-SurH {
  display: grid;
  gap: 5px;
  padding: 17px;
  margin-bottom: 10px;
  box-shadow: 1px 1px 1px -1px rgba(164, 166, 163, 0.9803921569);
  border-radius: 5px;
  background-color: white;
  margin-top: 1rem;
  box-shadow: 0px 0px 14px -10px rgb(122, 122, 122);
}`, "",{"version":3,"sources":["webpack://./src/Components/data/LayoutTable/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,aAAA;EACA,mBAAA;EACA,8DAAA;EACA,kBAAA;EACA,uBAAA;EACA,gBAAA;EAIA,iDAAA;AAAF","sourcesContent":[".container {\n  display: grid;\n  gap: 5px;\n  padding: 17px;\n  margin-bottom: 10px;\n  box-shadow: 1px 1px 1px -1px #a4a6a3fa;\n  border-radius: 5px;\n  background-color: white;\n  margin-top: 1rem;\n\n  -webkit-box-shadow: 0px 0px 14px -10px rgba(122, 122, 122, 1);\n  -moz-box-shadow: 0px 0px 14px -10px rgba(122, 122, 122, 1);\n  box-shadow: 0px 0px 14px -10px rgba(122, 122, 122, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__-SurH`
};
export default ___CSS_LOADER_EXPORT___;
