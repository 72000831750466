// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__yL168 {
  display: grid;
  padding: 17px;
  gap: 4px;
  margin-bottom: 10px;
  box-shadow: 1px 1px 1px -1px rgba(164, 166, 163, 0.9803921569);
  border-radius: 5px;
  font-size: 12px;
  background-color: white;
  box-shadow: 0px 0px 17px -10px rgb(122, 122, 122);
}

.styles_titleGroup__mk\\+5O {
  display: flex;
  justify-content: space-between;
}

.styles_wrapperBtn__LhE4I {
  display: flex;
  gap: 0.5rem;
}

@media screen and (min-width: 1100px) {
  .styles_btnFilter__ZVdXF {
    display: none;
  }
}

.styles_btnGourp__nHXOt {
  display: flex;
  justify-content: flex-start;
}

.styles_btn__C\\+gW9 {
  display: flex;
  gap: 1rem;
}
@media screen and (max-width: 1100px) {
  .styles_btn__C\\+gW9 {
    display: none;
  }
}

.styles_btnMin__BIT4P {
  display: flex;
  gap: 1rem;
}
@media screen and (min-width: 1100px) {
  .styles_btnMin__BIT4P {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/data/LayoutFilter/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,QAAA;EAEA,mBAAA;EACA,8DAAA;EACA,kBAAA;EACA,eAAA;EACA,uBAAA;EAIA,iDAAA;AADF;;AAIA;EACE,aAAA;EACA,8BAAA;AADF;;AAIA;EACE,aAAA;EACA,WAAA;AADF;;AAKE;EADF;IAEI,aAAA;EADF;AACF;;AAIA;EACE,aAAA;EACA,2BAAA;AADF;;AAIA;EACE,aAAA;EACA,SAAA;AADF;AAGE;EAJF;IAKI,aAAA;EAAF;AACF;;AAGA;EACE,aAAA;EACA,SAAA;AAAF;AAEE;EAJF;IAKI,aAAA;EACF;AACF","sourcesContent":[".container {\n  display: grid;\n  padding: 17px;\n  gap: 4px;\n\n  margin-bottom: 10px;\n  box-shadow: 1px 1px 1px -1px #a4a6a3fa;\n  border-radius: 5px;\n  font-size: 12px;\n  background-color: white;\n\n  -webkit-box-shadow: 0px 0px 17px -10px rgba(122, 122, 122, 1);\n  -moz-box-shadow: 0px 0px 17px -10px rgba(122, 122, 122, 1);\n  box-shadow: 0px 0px 17px -10px rgba(122, 122, 122, 1);\n}\n\n.titleGroup {\n  display: flex;\n  justify-content: space-between;\n}\n\n.wrapperBtn {\n  display: flex;\n  gap: 0.5rem;\n}\n\n.btnFilter {\n  @media screen and (min-width: 1100px) {\n    display: none;\n  }\n}\n\n.btnGourp {\n  display: flex;\n  justify-content: flex-start;\n}\n\n.btn {\n  display: flex;\n  gap: 1rem;\n\n  @media screen and (max-width: 1100px) {\n    display: none;\n  }\n}\n\n.btnMin {\n  display: flex;\n  gap: 1rem;\n\n  @media screen and (min-width: 1100px) {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__yL168`,
	"titleGroup": `styles_titleGroup__mk+5O`,
	"wrapperBtn": `styles_wrapperBtn__LhE4I`,
	"btnFilter": `styles_btnFilter__ZVdXF`,
	"btnGourp": `styles_btnGourp__nHXOt`,
	"btn": `styles_btn__C+gW9`,
	"btnMin": `styles_btnMin__BIT4P`
};
export default ___CSS_LOADER_EXPORT___;
