import { Col, Row } from "react-bootstrap";
import styles from "./styles.module.scss";
import { Btn } from "../Btn";

interface ILayoutFilter {
  children: any;
  title: string;
  onClickAdd?: () => void;
}

export default function LayoutNotFilter({
  children,
  title,
  onClickAdd,
}: ILayoutFilter) {
  return (
    <div className={styles.container}>
      <Row className="mb-1">
        <Col className={styles.titleGroup}>
          <h3>{title}</h3>
          {onClickAdd && (
            <Btn
              text="Adicionar"
              type="button"
              variante="primary"
              icon="plus"
              onClick={onClickAdd}
            />
          )}
        </Col>
      </Row>
      {children}
    </div>
  );
}
