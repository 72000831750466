import { Col, Form, Row } from "react-bootstrap";
import styles from "./styles.module.scss";

import { ReactNode } from "react";
import { SubmitHandler, UseFormHandleSubmit } from "react-hook-form";
import { Btn } from "../../data/Btn";

interface ILayout {
  children: ReactNode;
  title?: string;
  handleSubmit: UseFormHandleSubmit<any, undefined>;
  onClickAdd?: () => void;
  onClickClear?: () => void;
  onSubmit: SubmitHandler<any>;
  textAdd?: string;
}

export default function LayoutHookFilter({
  children,
  title,
  onClickAdd,
  onClickClear,
  onSubmit,
  handleSubmit,
  textAdd,
}: ILayout) {
  return (
    <Form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col className={styles.titleGroup}>
          <h3>{title}</h3>
          {onClickAdd && (
            <Btn
              text={textAdd ? textAdd : "Adicionar"}
              type="button"
              variante="primary"
              icon={"plus"}
              onClick={onClickAdd}
            />
          )}
        </Col>
      </Row>
      {children}

      <Row className="mt-2">
        <Col className={styles.btnGourp}>
          <div className={styles.btn}>
            <Btn
              text="Limpar"
              type="button"
              variante="secondary"
              icon="trash"
              onClick={onClickClear}
            />
            <Btn text="Buscar" type="submit" variante="success" icon="search" />
          </div>
        </Col>
      </Row>
    </Form>
  );
}
