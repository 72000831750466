import { Container } from "react-bootstrap";
import TabelaPreco from "../../../Components/Cadastro/TabelaPreco";
import { useContext } from "react";
import { ThemeContext } from "../../../context/theme-context";

export default function CadTabelaPreco() {
  const { collapsed } = useContext(ThemeContext);

  return (
    <Container
      className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
    >
      <TabelaPreco />
    </Container>
  );
}
