import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { IListPerfilFiscal } from "../../../../Pages/Cadastro/CadPerfilFiscal";
import LayoutFilter from "../../../data/LayoutFilter";
import ReactSelect from "../../../data/reactSelect";
import LayoutTable from "../../../data/LayoutTable";
import DataTable from "react-data-table-component";
import tableStyleDefault from "../../../../Services/tableStyleDefault";
import { FaEdit } from "react-icons/fa";
import { IPerfilFiscal } from "../interface/IPerfilFiscal";
import { ToastContainer } from "react-toastify";
import { sortByCreated } from "../../../../Services/sortByCreated";
import api from "../../../../Services/api";
import globals from "./../../../../Globals.json";
import { notifyError } from "../../../notify/notify-component";
import GetEndPoint from "../../../../Services/GetEndPoint";
import CustomLoader from "../../../data/customLoader";
import { useAppSelector } from "../../../../redux/store";
import * as S from "./styled";

export default function ListaPerfilFiscal({
  OnAddNewCli,
  title,
  onEditItem,
}: IListPerfilFiscal) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [loading, setLoading] = useState<boolean>();
  const [profileNameId, setProfileNameId] = useState<number>(0);
  const [profileName, setProfileName] = useState<string>("");
  const [cest, setCest] = useState<number>(0);
  const [ncm, setNcm] = useState<number>(0);

  const [profileList, setProfileList] = useState<Array<any>>([]);
  const [profileListAutoCl, setProfileListAutoCl] = useState<Array<any>>([]);
  const [cestList, setCestList] = useState<Array<any>>([]);
  const [ncmList, setncmList] = useState<Array<any>>([]);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const columns = [
    {
      name: "Nome",
      center: false,
      sortable: true,
      grow: 2,
      selector: (row: any) => row.tax_profile_name,
    },
    {
      name: "CEST",
      center: true,
      grow: 1,
      selector: (row: any) => row.relationships?.cest?.cest_code ?? 0,
    },
    {
      name: "NCM",
      center: true,
      grow: 1,
      selector: (row: any) => row?.relationships?.ncm?.ncm_code ?? 0,
    },
    {
      name: "Ação",
      center: true,
      grow: 0.2,
      cell: (row: any) => (
        <div className="actions">
          <FaEdit
            size={16}
            data-toggle="tooltip"
            data-placement="top"
            title="Editar"
            className="action"
            onClick={() => handleEdit(row)}
            color="8e8d8d"
          />
        </div>
      ),
      button: true,
    },
  ];

  const handleAdd = () => {
    OnAddNewCli(true);
  };

  const handleEdit = (item: IPerfilFiscal) => {
    onEditItem(item);
  };

  const clearFields = () => {
    setProfileNameId(0);
    setProfileName("");
    setNcm(0);
    setCest(0);
    handlePerfilFiscal();
  };

  const createFilter = (filterList: Array<string>): string => {
    let filter = "";
    for (let i = 0; i < filterList.length; i++) {
      if (i >= 1) {
        filter += "&";
      }
      filter += `filter${filterList[i]}`;
    }
    return filter;
  };

  const handleSearchList = async () => {
    let filterList = [];
    if (profileName !== "")
      filterList.push(`[tax_profile_name]=${profileName}`);
    if (ncm) filterList.push(`[ncm_id]=${Number(ncm)}`);
    if (cest) filterList.push(`[cest_id]=${Number(cest)}`);
    if (filterList.length > 0) {
      const filter = createFilter(filterList);
      if (filter !== "") {
        handlePerfilFiscal(filter);
      }
      return;
    }
    handlePerfilFiscal();
  };

  function handlePerfilFiscal(filter?: string) {
    setLoading(true);

    let uri = !filter
      ? globals.api.taxProfile +
        `?paginate=false&store_id=${storeMe.storeActive.id}`
      : globals.api.taxProfile +
        `?${filter}&store_id=${storeMe.storeActive.id}`;

    api
      .get(uri)
      .then((response) => {
        const data = response.data;

        setProfileList(sortByCreated(data.data));
      })
      .catch((error: Error) => {
        notifyError(`Ocorreu um erro ${error.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const paginationOptions = {
    rowsPerPageText: "Registros por página",
    rangeSeparatorText: "de",
  };

  const onClickOpenFilter = () => {
    setOpenFilter(!openFilter);
  };

  useEffect(() => {
    handlePerfilFiscal();
    GetEndPoint.taxProfile(setProfileListAutoCl);
    GetEndPoint.cests(setCestList);
    GetEndPoint.ncms(setncmList);
  }, []);

  return (
    <>
      <LayoutFilter
        title={title}
        onClickAdd={handleAdd}
        onClickClear={clearFields}
        onClickSearch={handleSearchList}
        onClickOpenFilter={onClickOpenFilter}
        openFilter={openFilter}
      >
        <S.Wrapper>
          {openFilter && (
            <div className="min-screen">
              <ReactSelect
                label="Perfil fiscal"
                isClearable={true}
                placeholder="Perfil fical"
                options={profileListAutoCl}
                state={profileNameId}
                setState={setProfileName}
                onChange={(e: any) => {
                  setProfileName(e?.label);
                  setProfileNameId(e?.value);
                }}
              />

              <ReactSelect
                label="CEST"
                isClearable={true}
                placeholder={"Cest"}
                options={cestList}
                state={cest}
                setState={setCest}
              />

              <ReactSelect
                label="NCM"
                isClearable={true}
                placeholder={"Ncm"}
                options={ncmList}
                state={ncm}
                setState={setNcm}
              />
            </div>
          )}

          <div className="max-screen">
            <ReactSelect
              label="Perfil fiscal"
              isClearable={true}
              options={profileListAutoCl}
              state={profileNameId}
              setState={setProfileName}
              onChange={(e: any) => {
                setProfileName(e?.label);
                setProfileNameId(e?.value);
              }}
            />

            <ReactSelect
              label="CEST"
              isClearable={true}
              options={cestList}
              state={cest}
              setState={setCest}
            />

            <ReactSelect
              label="NCM"
              isClearable={true}
              options={ncmList}
              state={ncm}
              setState={setNcm}
            />
          </div>
        </S.Wrapper>
      </LayoutFilter>

      <LayoutTable>
        <DataTable
          columns={columns}
          data={profileList || []}
          striped
          pagination
          paginationDefaultPage={1}
          paginationPerPage={25}
          customStyles={tableStyleDefault}
          paginationComponentOptions={paginationOptions}
          progressPending={loading}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhuma informação foi encontrada."
        />
      </LayoutTable>

      <ToastContainer
        position="top-center"
        autoClose={300}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
}
