import { Form, InputGroup } from "react-bootstrap";
import styled from "styled-components";

export const Wrapper = styled.div`
  gap: 0.5rem;
  margin-bottom: 0.3rem;
`;

export const Group = styled(InputGroup)``;

export const FormInput = styled(Form.Control)`
  font-size: 11px;
  border-radius: 4px;
  border-color: var(--color-input-border);
  box-shadow: var(--color-input-shadow);
  padding: 2px 9px;

  ::placeholder {
    font-size: 12px;
    color: var(--color-input-placeholder);
  }
`;
