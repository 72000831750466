// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__W0KcM {
  display: grid;
  gap: 5px;
  background-color: #f1f1f1 !important;
}

.styles_titleGroup__d5PrV {
  display: flex;
  justify-content: space-between;
}

.styles_btnGourp__uAfk2 {
  display: flex;
  justify-content: space-between;
}

.styles_btn__oOHRR {
  border: none;
  font-size: 12px !important;
  font-weight: bolder;
  padding: 6px 20px;
  color: white;
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/Components/data/LayoutProduct/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,oCAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;AACF;;AAEA;EACE,YAAA;EACA,0BAAA;EACA,mBAAA;EACA,iBAAA;EACA,YAAA;EACA,kBAAA;AACF","sourcesContent":[".container {\n  display: grid;\n  gap: 5px;\n  background-color: #f1f1f1 !important;\n}\n\n.titleGroup {\n  display: flex;\n  justify-content: space-between;\n}\n\n.btnGourp {\n  display: flex;\n  justify-content: space-between;\n}\n\n.btn {\n  border: none;\n  font-size: 12px !important;\n  font-weight: bolder;\n  padding: 6px 20px;\n  color: white;\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__W0KcM`,
	"titleGroup": `styles_titleGroup__d5PrV`,
	"btnGourp": `styles_btnGourp__uAfk2`,
	"btn": `styles_btn__oOHRR`
};
export default ___CSS_LOADER_EXPORT___;
