import React, { useEffect } from "react";
import { toast } from "react-toastify";
import * as S from "./styles";

interface ICheckboxProps {
  label?: string;
  name: string;
  disabled?: boolean;
  isInvalid?: boolean;
  defaultValue?: boolean; // Valores default para checkbox devem ser booleanos
  errors?: any;
  checked: boolean;
  setChecked: any;
  dispatch: any;
}

export function InputCheckboxUseStatekbox({
  label,
  name,
  disabled,
  defaultValue,
  errors,
  checked,
  setChecked,
  dispatch,
}: ICheckboxProps) {
  useEffect(() => {
    setChecked(defaultValue || false);
  }, [defaultValue, setChecked]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setChecked());
  };

  const errorToDisplay = errors
    ? Object.keys(errors).find((key) => key === name)
    : null;

  return (
    <S.Input
      errorToDisplay={errorToDisplay && errors[name]?.message.length > 0}
    >
      <label className="mx-1">
        <span>{label}</span>
        <input
          className="mt-1"
          type="checkbox"
          disabled={disabled}
          checked={checked}
          onChange={handleChange}
        />
      </label>
      {errorToDisplay && <S.Error>{errors[name]?.message}</S.Error>}
    </S.Input>
  );
}
