import Container from "react-bootstrap/Container";
import { useState, useContext } from "react";
import { ThemeContext } from "../../../context/theme-context";
import ListaProducao from "../../../Components/Cadastro/Producao/ListaProducao";
import Producao from "../../../Components/Cadastro/Producao/Producao";
import { IProducao } from "../../../Components/Cadastro/Producao/interface/IProducao";

export interface IListProducao {
  title: string;
  OnAddNewCli: (e: boolean) => void;
  onEditItem: (item: IProducao) => void;
  OnAddNewProducao: (e: boolean) => void;
}

export interface IFormProducao {
  title: string;
  OnAddNewProducao: (e: boolean) => void;
  producao?: IProducao;
  editando: boolean;
}

export default function CadProducao() {
  const { collapsed } = useContext(ThemeContext);

  const [cad, setCad] = useState<boolean>(false);
  const [toEditProducao, setToEditProducao] = useState<IProducao>();
  const [editando, setEditando] = useState<boolean>(false);

  const handleNew = (e: boolean) => {
    setEditando(false);
    setCad(e);
  };

  const handleEdit = (props: IProducao) => {
    setToEditProducao(props);
    setCad(true);
    setEditando(true);
  };

  return (
    <>
      {!cad ? (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <ListaProducao
            title="Produção"
            onEditItem={(item: IProducao) => handleEdit(item)}
            OnAddNewCli={(e: boolean) => handleNew(e)}
            OnAddNewProducao={(e: boolean) => handleNew(e)}
          />
        </Container>
      ) : (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <Producao
            title={
              toEditProducao ? "Editar Ficha Técnica" : "Nova Ficha Técnica"
            }
            OnAddNewProducao={(e: boolean) => handleNew(e)}
            producao={toEditProducao}
            editando={editando}
          />
        </Container>
      )}
    </>
  );
}
