import { useEffect, useState } from "react";
import { Row, Form, Col, Table } from "react-bootstrap/";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../../Loading/Loading";
import "react-toastify/dist/ReactToastify.css";
import { notify, notifyError } from "../../../notify/notify-component";
import { getStockableProducts } from "../../../../Services/product-service";
import { IFormMovimentacaoEstoque } from "../../../../Pages/Cadastro/CadMovimEstoque";
import ReactSelect from "../../../data/reactSelect";
import { InputDefault } from "../../../data/inputDefault";
import { InputInternText } from "../../../data/inputInternText";
import Parcelamento from "../../../data/ParcelaComponente";
import GetEndPoint from "../../../../Services/GetEndPoint";
import { CreateAndEdit } from "../../../../Services/CreateAndEdit";
import Layout from "../../../data/Layout";
import Format from "../../../../Services/Format";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";
import { format } from "date-fns";
import { useAppSelector } from "../../../../redux/store";
import ModalBootstrap from "../../../data/Modal";
import { FaEdit, FaTrash } from "react-icons/fa";
import * as S from "../../styled";

export function FormMovimentacaoEstoque({
  title,
  OnAddNewEstoqueMovimentacao,
  editando,
  estoque,
  budgetSelected,
}: IFormMovimentacaoEstoque) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const onlyView = estoque ? true : false;

  const [loading, setLoading] = useState<boolean>(false);

  const [storeDestinationId, setStoreDestinationId] = useState<number>();
  const [operationType, setOperationType] = useState<number>(0);
  const [stock_movement_type_id, setStock_movement_type_id] = useState<number>(
    estoque?.stock_movement_type_id || 0
  );
  const [vendor_id, setVendor_id] = useState<number>(estoque?.vendor_id || 0);
  const [operationTypeForInstallments, setOperationTypeForInstallments] =
    useState<number>(0);
  const [document, setDocument] = useState<string>(estoque?.document || "");
  const [serie, setSerie] = useState<number | undefined>(estoque?.serial);
  const [nfe, setNfe] = useState<string>(estoque?.nfe_key || "");

  const [chart_account_id, setChart_account_id] = useState<number>(
    estoque?.relationships?.financial_transaction?.chart_account_id || 0
  );
  const [
    financial_transaction_category_id,
    setFinancial_transaction_category_id,
  ] = useState<number>(
    estoque?.relationships?.financial_transaction
      ?.financial_transaction_category_id || 0
  );
  const [due_date, setDue_date] = useState<string>(
    estoque?.relationships?.financial_transaction?.due_date ||
      Format.date(new Date(), "YYYY-MM-DD")
  );
  const [banckAccountId, setBanckaccountId] = useState<number>(
    estoque?.relationships?.financial_transaction?.bank_account_id || 0
  );
  const [paymentTypeId, setPaymentTypeId] = useState<number>(
    estoque?.relationships?.financial_transaction?.payment_type_id || 0
  );

  const [stock, setStock] = useState<number>(0);
  const [stockDestination, setStockDestination] = useState<number>(0);
  const [productUuid, setProductUuid] = useState<string>("");
  const [qty, setQty] = useState<string>("");
  const [actualQty, setActualQty] = useState<string>("");
  const [costPrice, setCostPrice] = useState<string>("");
  const [salePrice, setSalePrice] = useState<string>("");
  const [profitPerUnity, setProfitPerUnity] = useState<string>("");
  const [totalPrice, setTotalPrice] = useState<string>("");
  const [unit, setUnit] = useState<string>("");
  const [productName, setProductName] = useState<string>("");
  const [productType, setProductType] = useState<number>(0);
  const [idProduct, setIdProduct] = useState<number>();
  const [uuid, setUuid] = useState<string>("");
  const [parcelamento, setParcelamento] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false);
  const [dataInstallments, setDataInstallments] = useState<any[]>([]);
  const [listMovementType, setListMovementType] = useState<Array<any>>([]);
  const [listMovementTypeAll, setListMovementTypeAll] = useState<Array<any>>(
    []
  );
  const [aProducts, setAProducts] = useState<Array<any>>([]);
  const [listVendor, setListVendor] = useState<Array<any>>([]);
  const [listStores, setListStores] = useState<Array<any>>([]);
  const [listMovStock, setListMovStock] = useState<Array<any>>([]);

  const [disableActualQty, setDisableActualQty] = useState<boolean>(true);
  const [disableProduct, setDisableProduct] = useState<boolean>(true);
  const [disableSellPrice, setDisableSellPrice] = useState<boolean>(true);
  const [disableBuyPrice, setDisableCorrectQty] = useState<boolean>(true);
  const [listProducts, setListProducts] = useState<Array<any>>([]);
  const [listAllProducts, setListAllProducts] = useState<Array<any>>([]);
  const [listStocks, setListStocks] = useState<Array<any>>([]);
  const [listStockComplet, setListStocksComplet] = useState<Array<any>>([]);

  const [isvalidOperationType, setIsValidOperationType] =
    useState<boolean>(false);
  const [isvalidMovimentType, setIsValidMovimentType] =
    useState<boolean>(false);
  const [isvalidVendor, setIsValidVendor] = useState<boolean>(false);
  const [isvalidChartAccount, setIsValidChartAccount] =
    useState<boolean>(false);
  const [isvalidTransitionCategory, setIsValidTransitionCategory] =
    useState<boolean>(false);

  const [isvalidStore, setIsValidStore] = useState<boolean>(false);
  const [isvalidStock, setIsValidStock] = useState<boolean>(false);
  const [isvalidProduct, setIsValidProduct] = useState<boolean>(false);
  const [isvalidQty, setIsValidQty] = useState<boolean>(false);
  const [isvalidCostPrice, setIsValidCostPrice] = useState<boolean>(false);
  const [isvalidSalePrice, setIsValidSalePrice] = useState<boolean>(false);
  const [isvalidBanck, setIsValidBanck] = useState<boolean>(false);
  const [isvalidPayment, setIsValidPayment] = useState<boolean>(false);
  const [isvalidDocument, setIsValidDocument] = useState<boolean>(false);
  const [editeItem, setEditeItem] = useState<boolean>(false);

  const [modalConfirmation, setModalConfirmation] = useState<boolean>(false);
  const [uuidConfirmation, setUuidConfirmation] = useState<string>("");

  const oOperationType = [
    {
      value: 1,
      label: "Entrada",
    },
    {
      value: 2,
      label: "Saída",
    },
  ];

  useEffect(() => {
    fillAllProducts();
  }, []);

  const handleSubmitForm = async () => {
    let valid = validGeralItens();
    if (!stock_movement_type_id)
      return notifyError("Selecione o tipo de movimentação.");
    valid = validFinancialIfInstalled();

    if (aProducts.length === 0) {
      validTableItens();
      return notifyError("Por favor adicione pelo menos um item.");
    }

    if (valid === false) {
      return;
    }

    if (!document) {
      toast.error("Informe todos os campos obrigatórios.");
      return;
    }

    let date = new Date();
    const dataObj = new Date(date);
    const dataFormatada = format(dataObj, "yyyy-MM-dd");
    const dataObjtDueDate = new Date(due_date);
    const dataFormatadaDue_date = format(dataObjtDueDate, "yyyy-MM-dd");

    const oModel = {
      uuid: estoque?.uuid,
      store_id: storeMe.storeActive.id,
      user_id: storeMe.user.id,
      situation_id: 1,
      stock_uuid: listStockComplet.filter(
        (item: any) => item?.store_id === storeMe.storeActive.id
      )?.[0]?.uuid,
      movement_dt: dataFormatada,
      stock_movement_type_id: stock_movement_type_id,
      vendor_id: vendor_id,
      document_stock: document,
      nfe_key: nfe,
      serial: serie ? Number(serie) : null,
      products: aProducts,
      financial_transaction: {
        creation_date: dataFormatada,
        chart_account_id: chart_account_id,
        financial_transaction_category_id: financial_transaction_category_id,
        status: status ? 2 : 1,
        receiver_people_id: vendor_id,
        due_date: dataFormatadaDue_date,
        amount: totalCostPrice,
        installments:
          dataInstallments.length > 0
            ? dataInstallments
            : [
                {
                  amount: totalCostPrice,
                  bank_account_id: banckAccountId,
                  due_date: dataFormatadaDue_date,
                  installment_group: 0,
                  number: 1,
                  payment_type_id: paymentTypeId,
                },
              ],
        payment_type_id: paymentTypeId,
        bank_account_id: banckAccountId,
      },
    };

    CreateAndEdit.postMoviments(oModel).then((res: any) => {
      setLoading(true);
      if (!res) {
        setTimeout(() => {
          setLoading(false);
          notifyError("Erro ao enviar movimentação");
        }, 100);
      } else {
        if (status === true) {
          try {
            if (dataInstallments.length === 0) {
              api.post(
                `${globals.api.financialTransaction}/${res.data.relationships.financial_transaction.uuid}/entry`,
                {
                  amount: res.data.relationships.financial_transaction.amount,
                  payment_type_id:
                    res.data.relationships.financial_transaction
                      .payment_type_id,
                  bank_account_id:
                    res.data.relationships.financial_transaction
                      .bank_account_id,
                  must_close: false,
                  discount: res.data.relationships.financial_transaction
                    .discount
                    ? res.data.relationships.financial_transaction.discount
                    : 0,
                  interest: res.data.relationships.financial_transaction
                    .interest
                    ? res.data.relationships.financial_transaction.interest
                    : 0,
                }
              );
            } else {
            }
          } catch (error) {
            console.log("erro", error);
          }
        }
        if (budgetSelected) {
          api.delete(globals.api.stockQuotes + `/${budgetSelected.uuid}`);
        }

        setTimeout(() => {
          handleCancel();
          setLoading(false);
        }, 100);
      }
    });
  };

  const handleCancel = () => {
    OnAddNewEstoqueMovimentacao(false);
  };

  const clearChangeOperationType = () => {
    setStock_movement_type_id(0);
    setStoreDestinationId(0);
    setStock(0);
    setProductUuid("");
    setQty("");
    setActualQty("");
    setCostPrice("");
    setSalePrice("");
    setProductType(0);
    setProfitPerUnity("");
    setTotalPrice("");
    setAProducts([]);
  };

  const handleStore = (e: any) => {
    if (!operationType) {
      return notifyError("Por favor selecionar tipo de operação.");
    }
    setStoreDestinationId(e);
  };

  const fillAllProducts = async () => {
    await getStockableProducts().then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

      setLoading(true);
      setTimeout(() => {
        setListAllProducts(data);
        setLoading(false);
      }, 300);
    });
  };

  const fillProducts = async () => {
    setLoading(true);
    await getStockableProducts().then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);
      const products = data.filter(
        (item: any) => item.stock_local_id === stock
      );

      setListProducts(products);
      setDisableProduct(false);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 300);
    });
  };

  function addProductToTable() {
    const valid = validTableItens();

    if (valid === false) return;

    let existId = aProducts.find(
      (item: any) => item.product_uuid === productUuid
    );

    if (existId) return notifyError("Produto ja adicionado na lista.");

    if (actualQty < qty && operationType === 2)
      return notifyError(
        "Estoque atual não tem posição para quantidade desejada."
      );

    const oModel = {
      store_id: listStores?.find((item: any) => item.id === storeDestinationId)
        ?.company_name,
      stock_local_id: listStockComplet?.find(
        (item: any) => item.value === stock
      )?.label,
      store: storeDestinationId,
      stock: stock,
      productName: productName,
      product_uuid: productUuid,
      product_id: idProduct,
      qty: parseFloat(qty),
      unit: unit,
      total_price: parseFloat(totalPrice),
      cost_price: parseFloat(costPrice),
      sale_price: parseFloat(salePrice),
    };

    setLoading(true);
    setTimeout(() => {
      aProducts.push(oModel);
      setProductUuid("");
      setQty("");
      setActualQty("");
      setSalePrice("");
      setCostPrice("");
      setProductType(0);
      setProfitPerUnity("");
      setTotalPrice("");
      setDisableActualQty(true);
      setDisableSellPrice(true);
      setDisableCorrectQty(true);
      setLoading(false);
    }, 300);
  }

  const editeProductToTable = () => {
    const valid = validTableItens();

    if (valid === false) return;

    setAProducts(
      aProducts.filter((item: any) => item.product_uuid !== productUuid)
    );

    if (actualQty < qty && operationType === 2)
      return notifyError(
        "Estoque atual não tem posição para quantidade desejada."
      );

    const oModel = {
      store_id: listStores?.find((item: any) => item.id === storeDestinationId)
        ?.company_name,
      stock_local_id: listStockComplet?.find(
        (item: any) => item.value === stock
      )?.label,
      store: storeDestinationId,
      stock: stock,
      productName: productName,
      product_uuid: productUuid,
      product_id: idProduct,
      qty: parseFloat(qty),
      unit: unit,
      total_price: parseFloat(totalPrice),
      cost_price: parseFloat(costPrice),
      sale_price: parseFloat(salePrice),
    };

    setLoading(true);
    setTimeout(() => {
      setAProducts((prev: any) => [...prev, oModel]);
      setProductUuid("");
      setQty("");
      setActualQty("");
      setSalePrice("");
      setCostPrice("");
      setProductType(0);
      setProfitPerUnity("");
      setTotalPrice("");
      setDisableActualQty(true);
      setDisableSellPrice(true);
      setDisableCorrectQty(true);
      setEditeItem(false);
      setLoading(false);
    }, 300);
  };

  async function handleSelectStock(id: string) {
    setStock(parseInt(id));
  }

  function handleSelectProduct(product_uuid: any) {
    if (!product_uuid) return;

    let selectdProduct = listAllProducts.find(
      (item: any) => item.uuid === product_uuid
    );
    setUnit(selectdProduct?.sale_measure_unit?.name);
    setActualQty(selectdProduct?.stock_qty ? selectdProduct?.stock_qty : 0);
    setCostPrice(selectdProduct?.cost_price);
    setSalePrice(selectdProduct?.sale_price);
    setIdProduct(selectdProduct?.id);
    setProductUuid(selectdProduct?.uuid);
    setProductName(selectdProduct?.purchase_name);
    setProductType(selectdProduct.product_purpose_id);
    setDisableCorrectQty(false);
    setDisableActualQty(false);
    setDisableSellPrice(false);
    setLoading(false);
  }

  function handleActualQty(actual_qty: string) {
    if (actual_qty !== "") {
      setActualQty(actual_qty);
      setDisableCorrectQty(false);
    }
  }

  function deleteProducts(product_uuid: string) {
    setTimeout(() => {
      setAProducts(
        aProducts.filter((item: any) => item.product_uuid !== product_uuid)
      );
    }, 300);
  }

  let totalCostPrice: number = aProducts.reduce(
    (acc, oModel) => acc + Number(oModel.total_price),
    0
  );

  if (budgetSelected) {
    const discount = budgetSelected.discount;
    const discountType = budgetSelected.discount_type;
    const finalTotal =
      discountType === "porcentagem" && discount && discount > 0
        ? totalCostPrice - totalCostPrice * (discount / 100)
        : totalCostPrice - (discount ? discount : 0);

    totalCostPrice = finalTotal;
  }

  const validGeralItens = () => {
    let valid = true;

    if (!operationType) {
      setIsValidOperationType(true);
      valid = false;
    } else {
      setIsValidOperationType(false);
    }
    if (!stock_movement_type_id) {
      setIsValidMovimentType(true);
      valid = false;
    } else {
      setIsValidMovimentType(false);
    }
    if (!vendor_id) {
      setIsValidVendor(true);
      valid = false;
    } else {
      setIsValidVendor(false);
    }
    if (!chart_account_id) {
      setIsValidChartAccount(true);
      valid = false;
    } else {
      setIsValidChartAccount(false);
    }
    if (!financial_transaction_category_id) {
      setIsValidTransitionCategory(true);
      valid = false;
    } else {
      setIsValidTransitionCategory(false);
    }
    if (!document) {
      setIsValidDocument(true);
      valid = false;
    } else {
      setIsValidDocument(false);
    }
    return valid;
  };

  const validTableItens = () => {
    let valid = true;

    if (!storeDestinationId) {
      setIsValidStore(true);
      valid = false;
    } else {
      setIsValidStore(false);
    }
    if (!stock) {
      setIsValidStock(true);
      valid = false;
    } else {
      setIsValidStock(false);
    }
    if (!productUuid) {
      setIsValidProduct(true);
      valid = false;
    } else {
      setIsValidProduct(false);
    }
    if (!qty) {
      setIsValidQty(true);
      valid = false;
    } else {
      setIsValidQty(false);
    }
    if (!costPrice) {
      setIsValidCostPrice(true);
      valid = false;
    } else {
      setIsValidCostPrice(false);
    }
    if (productType !== 2) {
      if (!salePrice) {
        setIsValidSalePrice(true);
        valid = false;
      } else {
        setIsValidSalePrice(false);
      }
    }

    return valid;
  };

  const validFinancialIfInstalled = () => {
    let valid = true;
    if (parcelamento && dataInstallments.length === 0) {
      notifyError("Por favor adicone as parcelas.");
      valid = false;
    } else {
      if (!paymentTypeId) {
        setIsValidPayment(true);
        valid = false;
      } else {
        setIsValidPayment(false);
      }
      if (!banckAccountId) {
        setIsValidBanck(true);
        valid = false;
      } else {
        setIsValidBanck(false);
      }
    }
    return valid;
  };

  const handleEdite = (item: any) => {
    setEditeItem(true);

    setStoreDestinationId(item.store);
    setStock(item.stock);
    handleSelectProduct(item.product_uuid);
  };

  const getStocksQuote = async (store_id: number) => {
    const response = await api.get(`${globals.api.stocks}?paginate=false`);

    const data = response.data.data;
    const filterData = data.filter((item: any) => item.store_id === store_id);
    setUuid(filterData[0].uuid);
    return filterData;
  };

  const handleModalId = (uuid: string) => {
    setUuidConfirmation(uuid);
    setModalConfirmation(true);
  };

  const handleDeleteItemTable = () => {
    setModalConfirmation(false);
    deleteProducts(uuidConfirmation);
  };

  useEffect(() => {
    GetEndPoint.movimentTypes(
      setListMovementType,
      operationType === 1 ? "entrada" : "saida"
    );
    GetEndPoint.movimentTypes(setListMovementTypeAll);
    setOperationTypeForInstallments(operationType === 1 ? 0 : 1);
    if (!budgetSelected) {
      clearChangeOperationType();
    }
  }, [operationType]);

  useEffect(() => {
    if (storeDestinationId) {
      GetEndPoint.stocks(setListStocks, storeDestinationId);
      setStock(0);
      setProductUuid("");
      setActualQty("");
      setCostPrice("");
      setSalePrice("");
      setProductType(0);
      setListProducts([]);
      setListStocks([]);
      setDisableSellPrice(true);
      setDisableCorrectQty(true);
      setDisableProduct(true);
    }
  }, [storeDestinationId]);

  useEffect(() => {
    fillProducts();
  }, [stock]);

  useEffect(() => {
    setProfitPerUnity(
      salePrice && costPrice
        ? (parseFloat(salePrice) - parseFloat(costPrice)).toString()
        : ""
    );

    const totalPrice =
      operationType === 1
        ? parseFloat(costPrice) * (qty ? parseFloat(qty) : 0)
        : parseFloat(salePrice) * (qty ? parseFloat(qty) : 0);
    setTotalPrice(totalPrice.toString());
  }, [salePrice, costPrice, costPrice, qty, operationType]);

  useEffect(() => {
    setTimeout(() => {
      if (estoque) {
        setAProducts(estoque.relationships.products);
        //setDataInstallments(estoque.relationships.financial_transaction.) Esperar para trazer do back end
      }
    }, 1000);
  }, [estoque]);

  useEffect(() => {
    GetEndPoint.vendor(setListVendor);
    GetEndPoint.store(setListStores);
    GetEndPoint.movimentStock(setListMovStock);
    GetEndPoint.stocks(setListStocksComplet);
  }, []);

  let newListVendor = listVendor.filter(
    (item) => item.store_id === storeMe.storeActive.id
  );

  const getAProductsQuote = async () => {
    //const response = await api.get(`${globals.api.products}?paginate=false`);
    //const allProducts = response.data.data;

    if (budgetSelected) {
      setVendor_id(budgetSelected.vendor_id);
      if (budgetSelected.relationships.products.length > 0) {
        let tableProducts = [];
        let index = 0;
        for (let product of budgetSelected.relationships.products) {
          let selectdProduct = listAllProducts.find(
            (item: any) => item.id === product.product_id
          );

          let total =
            parseFloat(
              budgetSelected.relationships.products[
                index
              ]?.cost_price.toString()
            ) *
              (budgetSelected.relationships.products[index]?.qty.toString()
                ? parseFloat(
                    budgetSelected.relationships.products[index]?.qty.toString()
                  )
                : 0) || "0,00";

          const oModel = {
            store_id: listStores?.find(
              (item: any) => item.id === selectdProduct?.store_id
            )?.company_name,
            stock_local_id: listStockComplet?.find(
              (item: any) => item.value === selectdProduct?.stock_local_id
            )?.label,
            productName: selectdProduct?.sale_name,
            product_uuid: selectdProduct?.uuid,
            product_id: selectdProduct?.id,
            qty: parseFloat(
              budgetSelected.relationships.products[index]?.qty.toString()
            ),
            unit: selectdProduct?.relationships?.sale_measure_unit?.name,
            total_price: total,
            cost_price: parseFloat(
              budgetSelected?.relationships?.products[
                index
              ]?.cost_price.toString()
            ),
            sale_price: parseFloat(selectdProduct?.sale_price),
          };
          tableProducts.push(oModel);
          index += 1;
        }
        setAProducts(tableProducts);
      }
    }
  };

  useEffect(() => {
    if (listAllProducts.length > 0 && budgetSelected) {
      setOperationType(1);
      getStocksQuote(budgetSelected.store_id);
      getAProductsQuote();
      setStock_movement_type_id(50);
    }
  }, [budgetSelected, listAllProducts, listStocks]);

  return (
    <>
      <ModalBootstrap
        state={modalConfirmation}
        setState={setModalConfirmation}
        text="Certeza que deseja excluir o produto?"
        handleAction={handleDeleteItemTable}
      />
      <Layout
        title={title}
        isEditable={editando}
        onClickSave={handleSubmitForm}
        onClickEdit={handleSubmitForm}
        onlyView={onlyView}
        onClickCancel={handleCancel}
      >
        <S.Wrapper>
          {!estoque ? (
            <>
              {!budgetSelected ? (
                <ReactSelect
                  label="Operação"
                  placeholder="Operação"
                  options={oOperationType}
                  state={operationType}
                  setState={setOperationType}
                  isInvalid={isvalidOperationType}
                  isDisabled={onlyView}
                />
              ) : (
                <InputDefault
                  label="Operação"
                  type="string"
                  state={"Entrada"}
                  disabled={true}
                  onChenge={() => setOperationType(1)}
                />
              )}

              <ReactSelect
                label="Tip. Movim."
                isClearable
                placeholder="Tipo de movimentação"
                options={listMovementType}
                isDisabled={budgetSelected ? true : !operationType}
                state={stock_movement_type_id}
                setState={setStock_movement_type_id}
                isInvalid={isvalidMovimentType}
              />
            </>
          ) : (
            <ReactSelect
              label="Tip. Movim."
              options={listMovementTypeAll}
              isDisabled={onlyView}
              state={estoque?.stock_movement_type_id}
              setState={""}
            />
          )}

          <ReactSelect
            label="Fornecedor"
            isClearable
            placeholder="Fornecedor"
            options={newListVendor}
            state={vendor_id}
            setState={setVendor_id}
            isInvalid={isvalidVendor}
            isDisabled={onlyView}
          />

          <InputDefault
            label="Número"
            type="string"
            autoComplete="off"
            placeholder="Número"
            maxLength={50}
            state={document}
            onChenge={(e: any) => setDocument(e.target.value)}
            isInvalid={isvalidDocument}
            disabled={onlyView}
          />

          <InputDefault
            label="Série"
            type="number"
            autoComplete="off"
            placeholder="Série"
            maxLength={300}
            state={serie}
            setState={setSerie}
            disabled={onlyView}
          />

          <InputDefault
            label="Chave NFe"
            type="text"
            autoComplete="off"
            placeholder="Chave NFe"
            maxLength={300}
            state={nfe}
            setState={setNfe}
            disabled={onlyView}
          />
        </S.Wrapper>

        <hr style={{ width: "100%", marginTop: "2rem" }} />
        {!budgetSelected && !onlyView && (
          <>
            <Row>
              <br />
              <h3> Adicionar Itens </h3>
            </Row>

            <S.Wrapper>
              <ReactSelect
                label="Loja"
                state={storeDestinationId}
                placeholder="Loja"
                options={storeMe.user.relationships.stores.map((item: any) => ({
                  value: item.id,
                  label: item.company_name || item.trading_name,
                }))}
                onChange={(e: any) => handleStore(e?.value)}
                isInvalid={isvalidStore}
                isDisabled={onlyView}
              />

              <ReactSelect
                label="Loc.Estoque"
                state={stock}
                onChange={(e: any) => handleSelectStock(e?.value)}
                placeholder="Local de estoque"
                options={listStocks}
                isDisabled={!storeDestinationId}
                isInvalid={isvalidStock}
              />

              <ReactSelect
                label="Produto"
                state={productUuid}
                onChange={(e: any) => {
                  handleSelectProduct(e?.value);
                  setUnit(e?.unit);
                }}
                isDisabled={disableProduct}
                placeholder={"Produto"}
                options={listProducts.map((item: any) => ({
                  value: item.uuid,
                  label: item.purchase_name,
                  unit: item.relationships?.sale_measure_unit?.name,
                }))}
                isInvalid={isvalidProduct}
              />

              <InputDefault
                label="Qtd Desejada"
                type="text"
                autoComplete="off"
                disabled={disableActualQty}
                state={qty}
                placeholder={"Quantidade desejada"}
                maxLength={300}
                setState={setQty}
                isInvalid={isvalidQty}
              />

              <InputDefault
                label="Qtd Atual"
                type="number"
                autoComplete="off"
                state={actualQty}
                placeholder="Quantidade atual"
                maxLength={50}
                onChenge={(e) => handleActualQty(e.target.value)}
                disabled={true}
              />

              <InputDefault
                label="Preço Comp."
                placeholder={"0,00"}
                type="number"
                autoComplete="off"
                step="0.01"
                min="0"
                state={costPrice}
                setState={setCostPrice}
                disabled={disableBuyPrice}
                isInvalid={isvalidCostPrice}
              />

              <InputDefault
                label="Preço Venda"
                placeholder={"0,00"}
                type="number"
                autoComplete="off"
                step="0.01"
                min="0"
                state={salePrice}
                onChenge={(e: any) => setSalePrice(e.target.value)}
                disabled={disableSellPrice}
                isInvalid={isvalidSalePrice}
              />

              <InputDefault
                label="Lucro/Unidade"
                placeholder={"0,00"}
                type="number"
                autoComplete="off"
                step="0.01"
                min="0"
                state={parseFloat(profitPerUnity)?.toFixed(2)}
                disabled={true}
              />

              <InputDefault
                label="Preço Total"
                placeholder={"0,00"}
                type="number"
                autoComplete="off"
                step="0.01"
                min="0"
                state={parseFloat(totalPrice)?.toFixed(2)}
                disabled={true}
              />
            </S.Wrapper>
            <S.BtnAdd>
              <div>
                <Form.Control
                  type="button"
                  size="sm"
                  onClick={editeItem ? editeProductToTable : addProductToTable}
                  value={editeItem ? "Editar Item" : "Adicionar Item"}
                  disabled={onlyView}
                />
              </div>
            </S.BtnAdd>
          </>
        )}
        {aProducts.length > 0 && (
          <>
            <Row>
              <br />
              <h3> Itens </h3>
            </Row>

            <Table
              striped
              hover
              bordered
              size="sm"
              style={{ marginTop: "20px" }}
            >
              <thead>
                <tr className="trTable">
                  <th>Produto</th>
                  <th>Loja</th>
                  <th>Estoque</th>
                  <th>Quantidade</th>
                  <th>Preço de Custo Total</th>
                  <th>Preço de Compra</th>
                  <th>Preço de Venda</th>
                  {!onlyView && <th>Ações</th>}
                </tr>
              </thead>
              <tbody>
                {aProducts.map((item: any, index: number) => {
                  return (
                    <tr key={item.product_uuid}>
                      <td>
                        {item.productName
                          ? item.productName
                          : estoque?.relationships.products[index].relationships
                              .product.purchase_name}
                      </td>
                      <td>
                        {item.store_id
                          ? item.store_id
                          : listStores?.find(
                              (item: any) =>
                                item.id ===
                                estoque?.relationships.products[index]
                                  .relationships.product.store_id
                            )?.company_name}
                      </td>
                      <td>
                        {item.stock_local_id
                          ? item.stock_local_id
                          : estoque?.relationships.products[index].relationships
                              .product.stock_reference}
                      </td>
                      <td>
                        {!estoque
                          ? item.qty + " " + item.unit
                          : item.qty +
                            " " +
                            estoque?.relationships?.products[index]
                              .relationships.product.relationships
                              .sale_measure_unit.name}
                      </td>
                      <td>
                        R${" "}
                        {!estoque
                          ? parseFloat(item.total_price)
                              .toFixed(2)
                              .replace(".", ",")
                          : estoque.relationships.stock_movement_type.type ===
                            "entrada"
                          ? (
                              estoque.relationships.products[index].cost_price *
                              estoque.relationships.products[index].qty
                            )
                              .toFixed(2)
                              .replace(".", ",")
                          : (
                              estoque.relationships.products[index].sale_price *
                              estoque.relationships.products[index].qty
                            )
                              .toFixed(2)
                              .replace(".", ",")}
                      </td>
                      <td>
                        R${" "}
                        {parseFloat(item.cost_price)
                          .toFixed(2)
                          .replace(".", ",")}
                      </td>
                      <td>
                        R${" "}
                        {parseFloat(item.sale_price)
                          .toFixed(2)
                          .replace(".", ",")}
                      </td>
                      {!onlyView && (
                        <td
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "1rem",
                          }}
                        >
                          <button
                            style={{ border: "none", background: "none" }}
                            type="button"
                            onClick={() => handleEdite(item)}
                          >
                            <FaEdit size={14} color="8e8d8d" />
                          </button>

                          <button
                            style={{ border: "none", background: "none" }}
                            type="button"
                            onClick={() => handleModalId(item.product_uuid)}
                          >
                            <FaTrash size={13} color="8e8d8d" />
                          </button>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {!estoque && (
              <Col md="3">
                <InputDefault
                  label="Custo total"
                  placeholder="Custo Total"
                  type="number"
                  autoComplete="off"
                  step="0.01"
                  min="0"
                  state={totalCostPrice.toFixed(2)}
                  disabled={true}
                />
              </Col>
            )}
          </>
        )}

        <hr style={{ width: "100%", marginTop: "2rem" }} />

        <Row>
          <br />
          <h3> Financeiro </h3>
        </Row>
        <Row className="mt-2">
          <br />
          <h4>Incluir Financeiro</h4>
        </Row>

        <Parcelamento
          total={totalCostPrice}
          entryType={operationTypeForInstallments}
          dataInstallments={dataInstallments}
          setDataInstallments={setDataInstallments}
          banckAccountId={banckAccountId}
          setBanckaccountId={setBanckaccountId}
          paymentTypeId={paymentTypeId}
          setPaymentTypeId={setPaymentTypeId}
          chart_account_id={chart_account_id}
          setChart_account_id={setChart_account_id}
          financial_transaction_category_id={financial_transaction_category_id}
          setFinancial_transaction_category_id={
            setFinancial_transaction_category_id
          }
          due_date={due_date}
          setDue_date={setDue_date}
          isvalidBanck={isvalidBanck}
          isvalidPayment={isvalidPayment}
          parcelamento={parcelamento}
          setParcelamento={setParcelamento}
          aProducts={aProducts}
          isvalidChartAccount={isvalidChartAccount}
          isvalidTransitionCategory={isvalidTransitionCategory}
          onlyView={onlyView}
          status={status}
          setStatus={setStatus}
        />
      </Layout>

      {loading && <Loading />}
      <ToastContainer
        position="top-center"
        autoClose={300}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
}
