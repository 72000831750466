import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { isAuthenticated } from "../Services/auth";

import CadClientes from "../Pages/Cadastro/CadClientes";
import { CadFichaTecnica } from "../Pages/Cadastro/CadFichaTecnica/index";
import CadFornecedores from "../Pages/Cadastro/CadFornecedor";
import CadFuncionarios from "../Pages/Cadastro/CadFuncionario";
import CadProdutos from "../Pages/Cadastro/CadProdutos";
import CadTransportadoras from "../Pages/Cadastro/CadTransportador";
import CadUnidMedida from "../Pages/Cadastro/CadUnidMedida";
import Dashboard from "../Pages/dashboard";
import Login from "../Pages/login/index";

import { v4 as uuidv4 } from "uuid";

import GlobalLayout from "../Components/GlobalLayout";

import Globals from "../Globals.json";
import CadAjustes from "../Pages/Cadastro/CadAjusteEstoque";
import CadBankAccounts from "../Pages/Cadastro/CadBankAccounts";
import CadCategoriaMovimentacao from "../Pages/Cadastro/CadCategoriaMovimentacao";
import CadEstoque from "../Pages/Cadastro/CadLocalEstoque";
import CadFormaPagamento from "../Pages/Cadastro/CadFormaPagamento";
import CadMovimEstoque from "../Pages/Cadastro/CadMovimEstoque";
import CadOrcamentos from "../Pages/Cadastro/CadOrcamentos";
import CadPlanoContas from "../Pages/Cadastro/CadPlanoContas";
import CadPosicoes from "../Pages/Cadastro/CadPosicoes";
import CadProducao from "../Pages/Cadastro/CadProducao";
import CadTabelaPreco from "../Pages/Cadastro/CadTabelaPreco";
import CadTiposMovimentacoes from "../Pages/Cadastro/CadTiposMovimentacoes";
import CadTransfEstoque from "../Pages/Cadastro/CadTransfEstoque";
import CadVendas from "../Pages/Cadastro/CadListagemVendas";
import CadImprimirRecibos from "../Pages/Cadastro/CadImprimirRecibos";
import CadStore from "../Pages/Cadastro/CadStore";
import { CadCategorias } from "../Pages/Cadastro/cadCategorias";
import MovimentacaoBancario from "../Components/Financeiro/MovimentacaoBancaria/MovimentacaoBancaria";
import ExtratoBancario from "../Components/Financeiro/ExtratoBancario/ExtratoBancario";
import { CadPerfilFiscal } from "../Pages/Cadastro/CadPerfilFiscal";
import Forgot from "../Pages/forgot";
import MyAccount from "../Pages/my-account";
import CadRepresentative from "../Pages/dashboard/Admin/CadRepresentative";
import AcessoLoja from "../Pages/Cadastro/AcessoLoja";
import CadFormaRecebimento from "../Pages/Cadastro/CadFormaRecebimento";
import { CardInventario } from "../Pages/Cadastro/CadInventario";
import CadFinanceiroReceita from "../Pages/Cadastro/CadFinanceiroReceita";
import CadFinanceiroDespesa from "../Pages/Cadastro/CadFinanceiroDespesa";
import { CardContagem } from "../Pages/Cadastro/CadContagem";
import Recovery from "../Pages/recovery";

const PrivateRoute = ({ component: Component, ...rest }: any) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <GlobalLayout>
          <MovimentacaoBancario />
          <ExtratoBancario />
          <Component {...props} key={uuidv4()} />
        </GlobalLayout>
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute exact path={Globals.paths.home} component={Dashboard} />
        {/* DashBoard Admin*/}
        <PrivateRoute
          exact
          path={Globals.paths.representative}
          component={CadRepresentative}
        />

        {/* DashBoard Representante*/}
        <PrivateRoute
          exact
          path={Globals.paths.cadStore}
          component={CadStore}
        />

        {/* DashBoard Store*/}
        <PrivateRoute
          exact
          path={Globals.paths.accessStores}
          component={AcessoLoja}
        />

        <PrivateRoute
          exact
          path={Globals.paths.representative}
          component={MyAccount}
        />
        <PrivateRoute
          exact
          path={Globals.paths.cadFornecedores}
          component={CadFornecedores}
        />
        <PrivateRoute
          exact
          path={Globals.paths.cadClientes}
          //component={CadCustumers}
          component={CadClientes}
        />
        <PrivateRoute
          exact
          path={Globals.paths.cadTransportadoras}
          component={CadTransportadoras}
        />
        <PrivateRoute
          exact
          path={Globals.paths.CadVendas}
          component={CadVendas}
        />
        <PrivateRoute
          exact
          path={Globals.paths.cadLocaisEstoque}
          component={CadEstoque}
        />
        <PrivateRoute
          exact
          path={Globals.paths.cadTransferencias}
          component={CadTransfEstoque}
        />
        <PrivateRoute
          exact
          path={Globals.paths.cadMovimentacoes}
          component={CadMovimEstoque}
        />
        <PrivateRoute
          exact
          path={Globals.paths.cadAjustes}
          component={CadAjustes}
        />
        <PrivateRoute
          exact
          path={Globals.paths.cadPosicoes}
          component={CadPosicoes}
        />
        <PrivateRoute
          exact
          path={Globals.paths.cadTiposMovimentacoes}
          component={CadTiposMovimentacoes}
        />
        <PrivateRoute
          exact
          path={Globals.paths.cadInventario}
          component={CardInventario}
        />
        <PrivateRoute
          exact
          path={Globals.paths.cadContagem}
          component={CardContagem}
        />
        <PrivateRoute
          exact
          path={Globals.paths.cadOrcamentos}
          component={CadOrcamentos}
        />
        <PrivateRoute
          exact
          path={Globals.paths.cadFuncionarios}
          component={CadFuncionarios}
        />
        <PrivateRoute
          exact
          path={Globals.paths.CadUnidMedida}
          component={CadUnidMedida}
        />
        <PrivateRoute
          exact
          path={Globals.paths.CadCatProdutos}
          component={CadCategorias}
        />

        <PrivateRoute
          exact
          path={Globals.paths.CadProdutos}
          component={CadProdutos}
        />

        <PrivateRoute
          exact
          path={Globals.paths.CadFichaTecnica}
          component={CadFichaTecnica}
        />

        <PrivateRoute
          exact
          path={Globals.paths.CadPerfilFiscal}
          component={CadPerfilFiscal}
        />

        <PrivateRoute
          exact
          path={Globals.paths.CadProducao}
          component={CadProducao}
        />
        <PrivateRoute
          exact
          path={Globals.paths.CadTabelaPreco}
          component={CadTabelaPreco}
        />
        <PrivateRoute
          exact
          path={Globals.paths.cadPlanoContas}
          component={CadPlanoContas}
        />
        <PrivateRoute
          exact
          path={Globals.paths.cadFormaRecebimento}
          component={CadFormaRecebimento}
        />
        <PrivateRoute
          exact
          path={Globals.paths.cadFormaPagamento}
          component={CadFormaPagamento}
        />
        <PrivateRoute
          exact
          path={Globals.paths.cadCategoriaMovimentacao}
          component={CadCategoriaMovimentacao}
        />
        <PrivateRoute
          exact
          path={Globals.paths.cadBankAccounts}
          component={CadBankAccounts}
        />
        <PrivateRoute
          exact
          path={Globals.paths.cadImprimirRecibos}
          component={CadImprimirRecibos}
        />
        <PrivateRoute
          exact
          path={Globals.paths.cadTransacoesReceitas}
          component={CadFinanceiroReceita}
        />
        <PrivateRoute
          exact
          path={Globals.paths.cadTransacoesDespesas}
          component={CadFinanceiroDespesa}
        />

        <Route exact path={Globals.paths.login} component={Login} />
        <Route exact path={Globals.paths.forgot} component={Forgot} />
        <Route exact path={Globals.paths.recovery} component={Recovery} />
        <Redirect path="*" to={Globals.paths.home} />
      </Switch>
    </BrowserRouter>
  );
}
