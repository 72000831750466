export const formaDateToSort = (dateStr: any) => {
  if (!dateStr) return "";
  return dateStr
    .split("-")
    .map((item: any) => item.padStart(2, "0"))
    .join();
};

export function formatDateToSort(dateString: string): number | string {
  if (!dateString) return "";

  const date = new Date(dateString);
  return date.getTime();
}
