import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Row, Form, Col, Card, Button } from "react-bootstrap/";
import { ToastContainer } from "react-toastify";
import Loading from "../../../Loading/Loading";
import Select from "react-select";
import "./styles.scss";
import "react-toastify/dist/ReactToastify.css";
import {
  msgStyles,
  NoOptionsMessage,
} from "../../../NoOptionsMessage/no-options-message-component";
import { createMovementType } from "../../../../Services/movement-type-service";
import { notify, notifyError } from "../../../notify/notify-component";
import Layout from "../../../data/Layout";
import ReactSelect from "../../../data/reactSelect";
import { InputDefault } from "../../../data/inputDefault";
import * as S from "../../styled";

export function FormMovimentacao(props: any) {
  const [description, setDescription] = useState<string>("");
  const [movementType, setMovementType] = useState<number>();

  // List
  const [listMovementType, setListMovementType] = useState<Array<any>>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const objMovementType = [
    {
      id: 1,
      value: "ENTRADA",
    },
    {
      id: 2,
      value: "SAÍDA",
    },
  ];

  const fillMovementType = async () => {
    let list = objMovementType.map((item: any) => ({
      value: item.id,
      label: item.value,
    }));
    setListMovementType(list);
  };

  useEffect(() => {
    fillMovementType();
    if (props.tipoMovimentacao) {
      setDescription(props.tipoMovimentacao.description);
      setMovementType(props.tipoMovimentacao.type === "entrada" ? 1 : 2);
    }
  }, []);

  function handleCancelar() {
    props.OnAddNewMovementType(false);
    props.OnClear(true);
  }

  function handleSubmit() {
    const oModel = {
      uuid: props.tipoMovimentacao?.uuid ? props.tipoMovimentacao?.uuid : null,
      description: description,
      type: movementType === 1 ? "entrada" : "saida",
    };

    createMovementType(oModel).then((res: any) => {
      setLoading(true);
      if (!res) {
        setTimeout(() => {
          notifyError("Ocorreu um erro");
          setLoading(false);
        }, 100);
      } else {
        setTimeout(() => {
          handleCancelar();
          setLoading(false);
        }, 100);
      }
    });
  }

  return (
    <>
      <Layout
        title={props.Title}
        isEditable={props.editando}
        onClickSave={handleSubmit}
        onClickEdit={handleSubmit}
        onClickCancel={handleCancelar}
      >
        <S.Wrapper>
          <ReactSelect
            label="Tipo"
            placeholder="Tipo de movimentação"
            options={listMovementType}
            state={movementType}
            setState={setMovementType}
          />

          <InputDefault
            label="Descrição"
            type="text"
            autoComplete="off"
            placeholder="Descrição"
            maxLength={300}
            state={description}
            setState={setDescription}
          />
        </S.Wrapper>
      </Layout>

      {loading && <Loading />}

      <ToastContainer
        position="top-center"
        autoClose={300}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
}
