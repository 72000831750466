import { FaEdit, FaTrash } from "react-icons/fa";
import ActiveInactiv from "../../../../../data/activeInactive";

import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../redux/store";
import ModalBootstrap from "../../../../../data/Modal";
import { notify, notifyError } from "../../../../../notify/notify-component";
import { useState } from "react";
import Format from "../../../../../../Services/Format";
import DateUtils from "../../../../../../Services/DateUtils";
import { deletePAymentTypesync } from "../../../../../../redux/features/paymentType/services";

interface Props {
  key?: any;
  categorie: any;
  handleEdit: any;
  OnAddNewCategorie: (e: boolean) => void;
}

export function Tree({ categorie, handleEdit }: Props) {
  const dispatch = useDispatch<AppDispatch>();

  const [modalConfirmation, setModalConfirmation] = useState<boolean>(false);
  const [uuid, setUuid] = useState<string>("");

  const handleDelete = async (uuid: string) => {
    dispatch(deletePAymentTypesync(uuid)).then((result: any) => {
      if (deletePAymentTypesync.fulfilled.match(result)) {
        notify("Recebimento excluido.");
      } else {
        notifyError("Erro ao excluir item.");
      }
    });
  };

  const handleModalUuid = (uuid: string) => {
    setUuid(uuid);
    setModalConfirmation(true);
  };

  const handleDeleteItem = () => {
    setModalConfirmation(false);
    handleDelete(uuid);
  };

  return (
    <>
      <ModalBootstrap
        state={modalConfirmation}
        setState={setModalConfirmation}
        text={"Certeza que deseja excluir?"}
        handleAction={handleDeleteItem}
      />
      <div
        className="row ItemTree"
        style={{
          paddingTop: "10px",
          paddingLeft: "2rem",
          fontFamily: "Open Sans",
          fontWeight: "normal",
        }}
      >
        <div>
          <section>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr 2fr 2fr 2fr ",
                flex: "1",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              <div>
                {Format.date(
                  DateUtils.toDate(categorie?.created_at),
                  "DD/MM/YYYY"
                )}
              </div>
              <div>{categorie?.id}</div>
              <div>{categorie?.description}</div>

              <span style={{ maxWidth: "55px", marginLeft: "1rem" }}>
                {categorie?.is_active ? (
                  <ActiveInactiv status={categorie?.is_active} text="Ativo" />
                ) : (
                  <ActiveInactiv status={categorie?.is_active} text="Inativo" />
                )}
              </span>

              <div>
                <button style={{ margin: "0 15px" }}>
                  <FaEdit
                    size={16}
                    color="8e8d8d"
                    onClick={() => handleEdit(categorie)}
                  />
                </button>
                <button>
                  <FaTrash
                    size={14}
                    color="8e8d8d"
                    onClick={() => handleModalUuid(categorie.uuid)}
                  />
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
