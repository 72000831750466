export const TOKEN_KEY = "@token";
export const ID_KEY = "@id";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);

//export const getStoreUuid = () => localStorage.getItem('X-Store');
export const getStoreUuid = () => localStorage.getItem(ID_KEY);

export const login = (token, id, store_uuid) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};
