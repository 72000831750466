import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import LayoutNotFilter from "../../../data/LayoutNotFilter";
import { Tab, Tabs } from "react-bootstrap";
import { AppDispatch, useAppSelector } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import RevenueModal from "./revenueModal";
import ExpenseModal from "./expenseModal";
import { getCategorieFinancialAsync } from "../../../../redux/features/categorieFinancial/services";

export default function ConsultaCategoriaMovimentacao(props: any) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const dispatch = useDispatch<AppDispatch>();

  const [keyActived, setKeyActived] = useState("");
  const [key, setKey] = useState<string>(keyActived ? keyActived : "revenue");

  useEffect(() => {
    dispatch(getCategorieFinancialAsync(storeMe.storeActive.id));
  }, [dispatch]);

  return (
    <LayoutNotFilter title={props.Title}>
      <Tabs
        defaultActiveKey="personData"
        className="mt-2"
        activeKey={key}
        onSelect={(k: any) => setKey(k)}
      >
        <Tab eventKey="revenue" title="Receitas" className="mx-3">
          <RevenueModal setKeyActived={setKeyActived} />
        </Tab>
        <Tab eventKey="expense" title="Despesas" className="mx-3">
          <ExpenseModal setKeyActived={setKeyActived} />
        </Tab>
      </Tabs>
    </LayoutNotFilter>
  );
}
