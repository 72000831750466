import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";
import { sortByCreated } from "../../../../Services/sortByCreated";
import { ICategorie, IInventary, IProduct, IStock } from "../interface";

export const createInventaryAsync = createAsyncThunk(
  "create/inventary",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.post(globals.api.inventary, data);

      if (res.data.data.uuid) {
        await api.post(
          globals.api.inventary + `/${res.data.data.uuid}/products`,
          data.products
        );
      }

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editeInventaryAsync = createAsyncThunk(
  "put/inventary",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.put(globals.api.inventary, data);

      if (res.data.data.uuid) {
        await api.post(
          globals.api.inventary + `/${res.data.data.uuid}/products`,
          data.products
        );
      }

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getInventaryAsync = createAsyncThunk(
  "inventary",
  async (activedStoreId: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.inventary + `?paginate=false&store_id=${activedStoreId}`
      );
      return sortByCreated(res.data.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getInventaryByUuidAsync = createAsyncThunk(
  "inventary/uuid",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.get(globals.api.inventary + `/${uuid}`);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getStoresAsync = createAsyncThunk(
  "inventary/stores",
  async (_, thunkAPI) => {
    try {
      const res = await api.get(globals.api.stores + "?paginate=false");
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getStockAsync = createAsyncThunk(
  "inventary/stock",
  async (idStore: number, thunkAPI) => {
    try {
      const res = await api.get(globals.api.stocks + "?paginate=false");

      let filteredStock = res.data.data.filter(
        (item: IStock) => item?.store_id === idStore
      );

      return idStore ? filteredStock : [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCategoriesAsync = createAsyncThunk(
  "inventary/categories",
  async (idStore: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.categories + "?filter[is_active]=true&paginate=false"
      );

      let filteredCategorie = res.data.data.filter(
        (item: ICategorie) => item?.store_id === idStore
      );

      return idStore ? filteredCategorie : res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllProductsAsync = createAsyncThunk(
  "inventary/all-products",
  async (_, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.products +
          "?paginate=false&filter[is_stockable]=true&append=stock_qty"
      );

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getProductsAsync = createAsyncThunk(
  "inventary/products",
  async (idStock: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.products +
          "?paginate=false&filter[is_stockable]=true&append=stock_qty"
      );

      let filteredProduct = res.data.data.filter(
        (item: IProduct) => item?.stock_local_id === idStock
      );

      return idStock ? filteredProduct : res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getProductsByCategorieAsync = createAsyncThunk(
  "inventary/products-by-categorie",
  async (idProductCategorie: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.products +
          "?paginate=false&filter[is_stockable]=true&append=stock_qty?paginate=false"
      );

      let filteredProduct = res.data.data.filter(
        (item: IProduct) => item?.product_category_id === idProductCategorie
      );

      return idProductCategorie ? filteredProduct : res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteItemProductTableAsync = createAsyncThunk(
  "inventary/delete",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.delete(globals.api.inventary + `/products/${uuid}`);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addItemProductTableAsync = createAsyncThunk(
  "inventary/add",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.post(
        globals.api.inventary + `/${data.uuidInventary}/products`,
        [data]
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const putItemProductTableAsync = createAsyncThunk(
  "inventary/put",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.put(
        globals.api.inventary + `/products/${data.idForEditeItemTable}`,
        {
          qty: data.qty,
        }
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const chargeBackInventory = createAsyncThunk(
  "inventary/chargeback",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.post(globals.api.inventary + `/${uuid}/chargeback`);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const closeInventory = createAsyncThunk(
  "inventary/close",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.post(globals.api.inventary + `/${uuid}/close`);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
