import { BtnGroupGender } from "../btnGroupGender";
import { Dispatch, SetStateAction } from "react";
import { InputHookCpf } from "../../../../reactHookForm/InputHookCpf";
import { InputHook } from "../../../../reactHookForm/InputHook";
import * as S from "./styled";

interface IProps {
  gender: string;
  setGender: Dispatch<SetStateAction<string>>;
  register: any;
  errors: any;
  control: any;
  watch?: any;
  setValue?: any;
  setError: any;
  reset?: any;
  setStateForm?: any;
  data: any;
  path: string;
}

export function PersonData({
  gender,
  setGender,
  register,
  errors,
  control,
  watch,
  setValue,
  setError,
  reset,
  setStateForm,
  data,
  path,
}: IProps) {
  return (
    <S.Wrapper>
      <div className="min-screen">
        <InputHookCpf
          register={register}
          errors={errors}
          setValue={setValue}
          setError={setError}
          reset={reset}
          setStateForm={setStateForm}
          dataEdit={data}
          watch={watch}
          path={path}
          defaultValue={data.cnpj}
          mask="999.999.999-99"
          label="CPF"
          name="cpf_cnpj"
          placeholder="CPF"
        />
        <InputHook
          register={register}
          errors={errors}
          label="Nome"
          name="name"
          type="text"
          placeholder="Nome"
        />
        <InputHook
          register={register}
          errors={errors}
          label="Email"
          name="email"
          type="text"
          placeholder="Email"
        />
        <InputHook
          register={register}
          errors={errors}
          label="Aniversário"
          name="birthdate"
          type="date"
          placeholder="Aniversário"
        />
        <InputHook
          register={register}
          errors={errors}
          label="RG"
          mask="99.999.999-9"
          name="rg"
          placeholder="RG"
        />

        <InputHook
          register={register}
          errors={errors}
          label="Doc. Estrangeiro"
          name="foreign_document"
          type="text"
          placeholder="Doc. Estrangeiro"
        />

        <InputHook
          register={register}
          errors={errors}
          label="Observação"
          name="additional_data"
          placeholder="Observação"
        />
      </div>
      <div className="min-screen">
        <BtnGroupGender gender={gender} setGender={setGender} />
      </div>
      <div className="max-screen">
        <InputHookCpf
          register={register}
          errors={errors}
          setValue={setValue}
          setError={setError}
          reset={reset}
          setStateForm={setStateForm}
          dataEdit={data}
          watch={watch}
          path={path}
          defaultValue={data.cnpj}
          mask="999.999.999-99"
          label="CPF"
          name="cpf_cnpj"
          placeholder="CPF"
        />
        <InputHook
          register={register}
          errors={errors}
          label="Nome"
          name="name"
          type="text"
          placeholder="Nome"
        />
        <InputHook
          register={register}
          errors={errors}
          label="Email"
          name="email"
          type="text"
          placeholder="Email"
        />
        <InputHook
          register={register}
          errors={errors}
          label="Aniversário"
          name="birthdate"
          type="date"
          placeholder="Aniversário"
        />
        <InputHook
          register={register}
          errors={errors}
          label="RG"
          mask="99.999.999-9"
          name="rg"
          placeholder="RG"
        />

        <InputHook
          register={register}
          errors={errors}
          label="Doc. Estrangeiro"
          name="foreign_document"
          type="text"
          placeholder="Doc. Estrangeiro"
        />

        <InputHook
          register={register}
          errors={errors}
          label="Observação"
          name="additional_data"
          placeholder="Observação"
        />
      </div>
      <div className="max-screen">
        <BtnGroupGender gender={gender} setGender={setGender} />
      </div>
    </S.Wrapper>
  );
}
