import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Format from "../../../Services/Format";
import { FaEye } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loading from "../../Loading/Loading";

import api from "../../../Services/api";
import Globals from "../../../Globals.json";
import DateUtils from "../../../Services/DateUtils";
import { Modal } from "react-bootstrap";
import { showSale } from "../../../Services/sales-service";
import { IVendas } from "./interfaces/IVendas";
import { IListVendas } from "../../../Pages/Cadastro/CadListagemVendas";
import LayoutFilter from "../../data/LayoutFilter";
import ReactSelect from "../../data/reactSelect";
import { InputDefault } from "../../data/inputDefault";
import LayoutTable from "../../data/LayoutTable";
import tableStyleDefault from "../../../Services/tableStyleDefault";
import { NoData } from "../../noData";
import CustomLoader from "../../data/customLoader";
import { useAppSelector } from "../../../redux/store";
import * as S from "./styled";

export default function ListaVendas({
  title,
  OnAddNewCli,
  OnAddNewVendas,
  onEditItem,
}: IListVendas) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [aVendas, setAVendas] = useState<IVendas>();

  const [movement, setMovement] = useState<Array<any>>([]);
  const [shift, setShift] = useState<string>("");
  const [receiptId, setReceiptId] = useState<string>("");
  const [netValue, setNetValue] = useState<string>("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState<number>(0);
  const [type_date, setTypeDate] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const [vendor_id, setVendorId] = useState<string>("");
  const [movementType, setMovementType] = useState<string>("");
  const [operationType, setOperationType] = useState<string>("");
  const [startDate, setStartDate] = useState<string>(
    Format.firstDate("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(
    Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD")
  );
  const [movementStartDate, setMovementStartDate] = useState<string>(
    Format.firstDate("YYYY-MM-DD")
  );
  const [movementEndDate, setMovementEndDate] = useState<string>(
    Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD")
  );
  const [origin, setOrigin] = useState<string>("");
  const [status, setStatus] = useState<string>("");

  const listdate = [
    { value: 0, label: "Todos" },
    { value: 1, label: "Data Criação" },
  ];

  const oOperationType = [
    {
      id: "entrada",
      value: "Entrada",
    },
    {
      id: "saida",
      value: "Saída",
    },
  ];

  const [listOperationType, setListOperationType] = useState<Array<any>>([]);

  const [validDateIni, setValidDateIni] = useState<boolean>(false);
  const [validDateEnd, setValidDateEnd] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const [filter, setFilter] = useState<string>("");

  const isLater = (start: string, end: string) => {
    return start > end;
  };

  const handleClose = () => setModalIsOpen(false);
  const handleShow = (row: any) => {
    showSale(row.uuid).then((res: any) => {
      setAVendas(res.data.data);
    });
    setModalIsOpen(true);
  };

  const handleDateStart = async (date: string) => {
    if (!date) return;
    if (date.length !== 10) {
      setValidDateIni(false);
      return;
    }
    if (type_date === 2) {
      setStartDate(date);
    } else {
      setMovementStartDate(date);
    }
    let dateValid = isLater(date, endDate);
    setValidDateIni(dateValid);
    if (dateValid) {
      return;
    }
  };

  const onClickOpenFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleDateEnd = async (date: string) => {
    if (!date) return;
    if (date.length !== 10) {
      setValidDateIni(false);
      return;
    }
    if (type_date === 2) {
      setEndDate(date);
    } else {
      setMovementEndDate(date);
    }
    let dateValid = isLater(startDate, date);
    setValidDateEnd(dateValid);

    if (dateValid) {
      return;
    }
  };

  const clearFields = () => {
    setShift("");
    setReceiptId("");
    setNetValue("");
    setType("");
    setOrigin("");
    setOperationType("");
    setVendorId("");
    setMovementType("");
    setStatus("");
    setStartDate(Format.date(new Date(), "YYYY-MM-DD"));
    setEndDate(Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD"));
    setMovementStartDate(Format.date(new Date(), "YYYY-MM-DD"));
    setMovementEndDate(Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD"));
    setFilter("");
    getMovement(1);
  };

  const handlePageChange = (page: any) => {
    getMovement(page, filter);
  };

  const createFilter = (filterList: Array<string>): string => {
    let filter = "";
    for (let i = 0; i < filterList.length; i++) {
      if (i >= 1) {
        filter += "&";
      }
      filter += `filter${filterList[i]}`;
    }
    return filter;
  };
  const handleSearchList = () => {
    if (validDateIni || validDateEnd) return;
    let filterList = [];
    if (type !== "") filterList.push(`[type]=${type}`);
    if (shift !== "") filterList.push(`[shift]=${shift}`);
    if (receiptId !== "") filterList.push(`[receipt_type_id]=${receiptId}`);
    if (netValue !== "") filterList.push(`[net_value]=${netValue}`);
    if (vendor_id) filterList.push(`[vendor_id]=${vendor_id}`);
    if (movementType)
      filterList.push(`[stock_movement_type_id]=${movementType}`);
    if (operationType) filterList.push(`[operation]=${operationType}`);
    if (origin !== "") filterList.push(`[origin]=${origin}`);
    if (status !== "") filterList.push(`[situation_id]=${status}`);
    if (type_date === 1) {
      filterList.push(`[created_from]=${movementStartDate}`);
      filterList.push(`[created_until]=${movementEndDate}`);
    }
    if (type_date === 2) {
      filterList.push(`[starts_at]=${startDate}`);
      filterList.push(`[ends_at]=${endDate}`);
    }
    if (filterList.length > 0) {
      const filter = createFilter(filterList);
      if (filter !== "") {
        getMovement(1, filter);
        setFilter(filter);
      }
      return;
    }
    getMovement(1);
  };

  const columns = [
    {
      name: "Data e Hora",
      center: true,
      grow: 0.4,
      selector: (row: any) =>
        Format.date(row.created_at, "DD/MM/YYYY HH:mm:ss"),
    },

    {
      name: "Turno",
      center: true,
      grow: 0.2,
      selector: (row: any) => row.shift,
    },
    {
      name: "Operação",
      center: true,
      grow: 0.2,
      selector: (row: any) => row.operation,
    },
    {
      name: "Recebimento",
      center: true,
      grow: 0.2,
      selector: (row: any) => row.receipt_type_id,
    },
    {
      name: "Valor Liquido",
      center: true,
      sortable: true,
      grow: 0.2,
      selector: (row: any) =>
        row.net_value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      name: "Serviço",
      center: true,
      sortable: true,
      grow: 0.2,
      selector: (row: any) =>
        row.service_value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      name: "Valor Bruto",
      center: true,
      grow: 0.2,
      sortable: true,
      selector: (row: any) =>
        row.gross_value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },

    {
      name: "Ação",
      center: true,
      grow: 0.2,
      cell: (row: any) => (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">Detalhar Venda</Tooltip>}
        >
          <span className="d-inline-block">
            <Button
              onClick={() => handleShow(row)}
              variant="contained"
              color="primary"
            >
              <FaEye size={18} color="8e8d8d" />
            </Button>
          </span>
        </OverlayTrigger>
      ),
      button: true,
    },
  ];

  const productsColumns = [
    {
      name: "QTD",
      selector: (row: any) => row.sale_qty,
    },

    {
      name: "DESCRIÇÃO",
      selector: (row: any) => row.product_id,
    },
    {
      name: "SUBTOTAL",
      selector: (row: any) => row.unit_sale_value,
    },
    {
      name: "TOTAL",
      selector: (row: any) => row.total_sale_value,
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Registros por página",
    rangeSeparatorText: "de",
  };

  const fillOperationType = async () => {
    let list = oOperationType.map((item: any) => ({
      value: item.id,
      label: item.value,
    }));
    setListOperationType(list);
  };

  const fillProperties = (data: any) => {
    const meta = data.meta;
    const perpage =
      data.data.length < meta.per_page ? data.data.length : meta.per_page;
    setPerPage(perpage);
    setTotalRows(meta.total);
    setLastPage(meta.last_page);
    setMovement(data.data);
  };

  const getMovement = (page: number, filter?: string) => {
    const uri =
      filter !== undefined
        ? `${filter}&paginate=false&store_id=${storeMe.storeActive.id}`
        : `paginate=false&store_id=${storeMe.storeActive.id}`;
    if (page > lastPage) {
      page = lastPage;
    }
    setLoading(true);
    api
      .get(Globals.api.sales + `?${uri}`)
      .then((response) => {
        const data = response.data;

        setMovement(data.data);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMovement(1);
    fillOperationType();
  }, []);

  return (
    <>
      <LayoutFilter
        title={title}
        onClickSearch={handleSearchList}
        onClickClear={clearFields}
        onClickOpenFilter={onClickOpenFilter}
        openFilter={openFilter}
      >
        <S.Wrapper>
          {openFilter && (
            <div className="min-screen">
              <ReactSelect
                label="Período"
                placeholder="Período"
                state={type_date}
                options={listdate}
                isInvalid={false}
                setState={setTypeDate}
              />

              <InputDefault
                label="De"
                type="date"
                disabled={type_date === 0}
                state={type_date === 2 ? startDate : movementStartDate}
                onChenge={(e: any) => handleDateStart(e.target.value)}
              />

              <InputDefault
                label="Até"
                type="date"
                disabled={type_date === 0}
                state={movementEndDate}
                onChenge={(e: any) => handleDateEnd(e.target.value)}
              />

              <InputDefault
                label="Recebimento"
                type="text"
                autoComplete="off"
                state={receiptId}
                placeholder="Recebimento"
                maxLength={50}
                onChenge={(e: any) => setReceiptId(e.target.value)}
              />

              <InputDefault
                label="Turno"
                type="text"
                autoComplete="off"
                state={shift}
                placeholder="Turno"
                maxLength={50}
                onChenge={(e: any) => setShift(e.target.value)}
              />

              <ReactSelect
                label="Operação"
                placeholder={"Operação"}
                state={operationType}
                options={listOperationType}
                setState={setOperationType}
              />
            </div>
          )}

          <div className="max-screen">
            <ReactSelect
              label="Período"
              state={type_date}
              options={listdate}
              isInvalid={false}
              setState={setTypeDate}
            />

            <InputDefault
              label="De"
              type="date"
              disabled={type_date === 0}
              state={type_date === 2 ? startDate : movementStartDate}
              onChenge={(e: any) => handleDateStart(e.target.value)}
            />

            <InputDefault
              label="Até"
              type="date"
              disabled={type_date === 0}
              state={movementEndDate}
              onChenge={(e: any) => handleDateEnd(e.target.value)}
            />

            <InputDefault
              label="Recebimento"
              type="text"
              autoComplete="off"
              state={receiptId}
              maxLength={50}
              onChenge={(e: any) => setReceiptId(e.target.value)}
            />

            <InputDefault
              label="Turno"
              type="text"
              autoComplete="off"
              state={shift}
              maxLength={50}
              onChenge={(e: any) => setShift(e.target.value)}
            />

            <ReactSelect
              label="Operação"
              state={operationType}
              options={listOperationType}
              setState={setOperationType}
            />
          </div>
        </S.Wrapper>
      </LayoutFilter>

      <LayoutTable>
        <DataTable
          columns={columns}
          data={movement || []}
          striped
          pagination
          paginationDefaultPage={1}
          paginationPerPage={25}
          customStyles={tableStyleDefault}
          paginationComponentOptions={paginationOptions}
          progressPending={loading}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhuma informação foi encontrada."
        />
      </LayoutTable>

      <Modal show={modalIsOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className={styles.modal}>
            <div className={styles.titleModal}> Venda #{aVendas?.id}</div>
            <div>Turno: {aVendas?.shift}</div>
            <div>
              Data: {Format.date(aVendas?.created_at, "DD/MM/YYYY")} Hora:{" "}
              {Format.date(aVendas?.created_at, "HH:mm:ss")}
            </div>
            <div>
              Status Venda:{" "}
              {aVendas?.relationships?.situation?.name
                ? aVendas?.relationships?.situation?.name
                : "Informação não disponível"}
            </div>
            <div>Usuário: {aVendas?.relationships.waiter.name}</div>
            <div>Loja: {aVendas?.store_id}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.subtitleColor}>
          <div className={styles.divTable}>
            {" "}
            {aVendas?.relationships.origin.name} :{" "}
            {aVendas?.relationships.origin.id}
          </div>
          <div className={styles.divCPFCNPJ}>
            CPF/CNPJ DO CONSUMIDOR: {aVendas?.relationships.customer.cpf_cnpj}
          </div>
          <div className={styles.productTable}>
            <DataTable
              className={styles.dataTable}
              columns={productsColumns}
              data={aVendas?.relationships.products || []}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.footerModal}>
          <div className={styles.footerDiv}>
            <span> Descontos: R$ {aVendas?.discount},00 </span>
            <span> Acréscimos: R$ 0,00 </span>
            <span> Total: R$ {aVendas?.net_value} </span>
          </div>
          <div className={styles.footerDiv}>
            <span> Dinheiro: R$ 10,00 </span>
            <span> CARTÃO DÉBITO: R$ 10,67 </span>
            <span> Entregue: R$ 20,67 </span>
            <span> Troco: 0,00 </span>
          </div>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
