import { FaEdit, FaTrash } from "react-icons/fa";
import ActiveInactiv from "../../../../../data/activeInactive";
import {
  IObj,
  paymentsGroupObj,
  paymentsTypeObj,
  tefPaymentProviderObj,
  tefPaymentsTypeObj,
} from "../typeOptionsGroup/obj";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../../../../../redux/store";
import {
  IBrPaymentTypes,
  IBrPaymentTypesProviders,
  IPaymentMechanisms,
} from "../../../../../../redux/features/PaymentTypePDV/interface";
import ModalBootstrap from "../../../../../data/Modal";
import { notify, notifyError } from "../../../../../notify/notify-component";
import { useEffect, useState } from "react";
import {
  deletePAymentTypePDVAsync,
  getPAymentTypePDVAsync,
} from "../../../../../../redux/features/PaymentTypePDV/services";
import Format from "../../../../../../Services/Format";
import DateUtils from "../../../../../../Services/DateUtils";

interface Props {
  key?: any;
  paymentTypes: any;
  handleEdit: any;
  OnAddNewCategorie: (e: boolean) => void;
}

export function TreePDV({ paymentTypes, handleEdit }: Props) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const dispatch = useDispatch<AppDispatch>();
  const {
    paymentTypeDdv,
    brPaymentTypes,
    brPaymentTypesProviders,
    paymentMechanisms,
  } = useAppSelector(
    ({ paymentTypePDVReducer }) => paymentTypePDVReducer.value
  );

  const [modalConfirmation, setModalConfirmation] = useState<boolean>(false);
  const [uuid, setUuid] = useState<string>("");

  const objBrPaymentTypes: any = brPaymentTypes.map(
    (item: IBrPaymentTypes) => ({
      label: item.descricao,
      value: item.id,
    })
  );

  const objPaymentMechanisms: any = paymentMechanisms.map(
    (item: IPaymentMechanisms) => ({
      label: item.descricao,
      value: item.id,
    })
  );

  const objBrPaymentTypesProviders: any = brPaymentTypesProviders.map(
    (item: IBrPaymentTypesProviders) => ({
      label: item.descricao,
      value: item.id,
    })
  );

  const handleDelete = async (uuid: string) => {
    dispatch(deletePAymentTypePDVAsync(uuid)).then((result: any) => {
      if (deletePAymentTypePDVAsync.fulfilled.match(result)) {
        notify("Recebimento PDV excluido.");
        dispatch(getPAymentTypePDVAsync(storeMe.storeActive.id));
      } else {
        notifyError("Erro ao excluir pdv.");
      }
    });
  };

  const handleModalUuid = (uuid: string) => {
    setUuid(uuid);
    setModalConfirmation(true);
  };

  const handleDeleteItem = () => {
    setModalConfirmation(false);
    handleDelete(uuid);
  };

  return (
    <>
      <ModalBootstrap
        state={modalConfirmation}
        setState={setModalConfirmation}
        text={"Certeza que deseja excluir?"}
        handleAction={handleDeleteItem}
      />
      <div
        className="row ItemTree"
        style={{
          paddingTop: "10px",
          paddingLeft: "2rem",
          fontFamily: "Open Sans",
          fontWeight: "normal",
        }}
      >
        <div>
          <section>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr 2fr 1fr 2fr 2fr 2fr 1fr 1fr",
                flex: "1",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              <div>
                {Format.date(
                  DateUtils.toDate(paymentTypes?.created_at),
                  "DD/MM/YYYY"
                )}
              </div>
              <div>{paymentTypes?.id}</div>
              <div>{paymentTypes?.description}</div>
              <div>
                {paymentsGroupObj.find(
                  (item: IObj) =>
                    item.value === paymentTypes?.payment_group?.toString()
                )?.label ?? "---"}
              </div>
              <div style={{ marginLeft: "1rem" }}>
                {objPaymentMechanisms.find(
                  (item: IObj) =>
                    item.value === paymentTypes?.payment_mechanism_id
                )?.label ?? "---"}
              </div>
              <div style={{ marginLeft: "1.5rem" }}>
                {objBrPaymentTypes.find(
                  (item: IObj) => item.value === paymentTypes.br_payment_type_id
                )?.label ?? "---"}
              </div>
              <div style={{ marginLeft: "1.5rem" }}>
                {objBrPaymentTypesProviders.find(
                  (item: IObj) =>
                    item.value === paymentTypes.br_payment_type_provider_id
                )?.label ?? "---"}
              </div>
              <span style={{ maxWidth: "55px", marginLeft: "1.4rem" }}>
                {!paymentTypes?.inactive ? (
                  <ActiveInactiv
                    status={!paymentTypes?.inactive}
                    text="Ativo"
                  />
                ) : (
                  <ActiveInactiv
                    status={!paymentTypes?.inactive}
                    text="Inativo"
                  />
                )}
              </span>

              <div>
                <button style={{ margin: "0 15px" }}>
                  <FaEdit
                    size={16}
                    color="8e8d8d"
                    onClick={() => handleEdit(paymentTypes)}
                  />
                </button>
                <button>
                  <FaTrash
                    size={14}
                    color="8e8d8d"
                    onClick={() => handleModalUuid(paymentTypes.uuid)}
                  />
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
