import {
  Badge,
  Button,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Store, useAuth } from "../../../context/Auth";

import DataTable from "react-data-table-component";
import tableStyleDefault from "../../../Services/tableStyleDefault";
import { powerOff, powerOn } from "../../icons/icons";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../../redux/store";
import { IStore } from "../../../redux/features/userMe/interface";

interface IProps {
  user: any;
}

export function ChangeStores({ user }: IProps) {
  const { handleActiveStore, storeActive } = useAuth();

  const dispatch = useDispatch<AppDispatch>();
  const store = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const columns = [
    {
      name: "Selecionar Loja",
      minWidth: "100px",
      cell: (row: IStore) => (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              {row?.uuid === store.storeActive?.uuid
                ? "Loja Selecionada"
                : "Selecionar Loja"}
            </Tooltip>
          }
        >
          <span className="d-inline-block">
            <Button
              style={{ border: "none" }}
              //onClick={() => handleActiveStore(row?.uuid)}
              variant="contained"
              color="primary"
              disabled={row?.uuid === store.storeActive?.uuid}
            >
              {row?.uuid === store.storeActive?.uuid ? powerOn : powerOff}
            </Button>
          </span>
        </OverlayTrigger>
      ),
      button: true,
    },
    {
      name: "Nome da Empresa",
      minWidth: "190px",
      selector: (row: IStore) => row.company_name,
      grow: 2,
    },
    {
      name: "Nome fantasia",
      minWidth: "190px",
      selector: (row: IStore) => row.trading_name,
      grow: 2,
    },
    {
      name: "CPF | CNPJ",
      minWidth: "150px",
      selector: (row: IStore) => row.cnpj,
    },
    {
      name: "Tipo",
      maxWidth: "100px",
      selector: (row: IStore) => (row.type_id === 1 ? "Matriz" : "Filial"),
    },
    {
      name: "Celular",

      selector: (row: IStore) => "(99) 99999-9999",
    },
    {
      name: "Telefone",

      selector: (row: IStore) => "(99) 9999-9999",
    },
    {
      name: "Localização",
      maxWidth: "200px",
      selector: (row: IStore) => row.city + "-" + row.state,
    },

    {
      name: "Status",
      minWidth: "80px",
      center: true,
      cell: (row: IStore) =>
        row.status === 1 ? (
          <span className="fs-6 ">
            <Badge bg="success">Ativo</Badge>
          </span>
        ) : (
          <span className="fs-6 ">
            <Badge bg="danger">Inátivo</Badge>
          </span>
        ),
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Registros por página",
    rangeSeparatorText: "de",
  };

  return (
    <>
      <DataTable
        columns={columns}
        className="mt-3"
        data={store.user.relationships.stores || []}
        striped
        pagination
        paginationServer
        paginationDefaultPage={1}
        customStyles={tableStyleDefault}
        paginationComponentOptions={paginationOptions}
      />
    </>
  );
}
