// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__aeHmZ {
  display: grid;
  gap: 5px;
  margin-bottom: 10px;
  margin-top: 2rem;
  border-radius: 5px;
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/Components/data/LayoutTableNotShadow/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,uBAAA;AACF","sourcesContent":[".container {\n  display: grid;\n  gap: 5px;\n  margin-bottom: 10px;\n  margin-top: 2rem;\n  border-radius: 5px;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__aeHmZ`
};
export default ___CSS_LOADER_EXPORT___;
