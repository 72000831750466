import { createContext, ReactNode, useState } from "react";
import DateUtils from "../../../../Services/DateUtils";
import Format from "../../../../Services/Format";
import api from "../../../../Services/api";
import Globals from "../../../../Globals.json";
import { getBankAccounts } from "../../../../Services/bank-accounts";

interface ChildrenProps {
  children: ReactNode;
}

interface InitialContextInterface {
  startDate: string;
  endDate: string;
  pdv: number;
  setPdv: React.Dispatch<React.SetStateAction<number>>;
  listPdv: any[];
  listBankaccount: any[];
  listBank: any[];
  banckAccountId: number;
  setBanckaccountId: React.Dispatch<React.SetStateAction<number>>;
  showMovimentacao: boolean;
  showList: boolean;
  loading: boolean;
  listExtratoBancario: any[];

  handleCloseMovimentacao: () => void;
  handleShowMovimentacao: () => void;
  handleCloseList: () => void;
  handleShowList: () => void;
  fillBankAccounts: () => void;
  fillPdv: () => void;
  handleSearchList: () => void;
  handleDateStart: (date: string) => void;
  handleDateEnd: (date: string) => void;
}

export const MovimentacaoContext = createContext<InitialContextInterface>(
  {} as InitialContextInterface
);

export const MovimentacaoProvider = ({ children }: ChildrenProps) => {
  const [showMovimentacao, setShowMovimentacao] = useState(false);
  const [showList, setShowList] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [listExtratoBancario, setListExtratoBancario] = useState<Array<any>>(
    []
  );
  const [banckAccountId, setBanckaccountId] = useState<number>(0);
  const [pdv, setPdv] = useState<number>(1);
  const [startDate, setStartDate] = useState<string>(
    Format.firstDate("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(
    Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD")
  );

  const [validDateIni, setValidDateIni] = useState<boolean>(false);
  const [validDateEnd, setValidDateEnd] = useState<boolean>(false);

  const [listPdv, setListPdv] = useState<Array<any>>([]);
  const [listBank, setListBank] = useState<Array<any>>([]);
  const [listBankaccount, setListBankAccount] = useState<Array<any>>([]);

  const objPdv = [
    {
      id: 1,
      value: "Sim",
    },
    {
      id: 2,
      value: "Não",
    },
  ];

  const fillPdv = async () => {
    let list = objPdv.map((item: any) => ({
      value: item.id,
      label: item.value,
    }));
    setListPdv(list);
  };

  const fillBankAccounts = async () => {
    await getBankAccounts().then((response: any) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);
      setListBank(data);
      setListBankAccount(data);
    });
  };

  const handleCloseMovimentacao = () => {
    setShowMovimentacao(false);
    setPdv(1);
    setBanckaccountId(0);
    setStartDate(Format.firstDate("YYYY-MM-DD"));
    setEndDate(Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD"));
    setShowList(false);
    setListExtratoBancario([]);
  };
  const handleShowMovimentacao = () => {
    setShowMovimentacao(true);
  };

  const handleCloseList = () => {
    setPdv(1);
    setBanckaccountId(0);
    setStartDate(Format.firstDate("YYYY-MM-DD"));
    setEndDate(Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD"));
    setShowList(false);
    setListExtratoBancario([]);
  };
  const handleShowList = () => {
    setShowList(true);
  };

  const createFilter = (filterList: Array<string>): string => {
    let filter = "";
    for (let i = 0; i < filterList.length; i++) {
      if (i >= 1) {
        filter += "&";
      }
      filter += `filter${filterList[i]}`;
    }
    return filter;
  };

  const handleSearchList = () => {
    setShowList(true);

    if (validDateIni || validDateEnd) return;
    let filterList = [];

    if (banckAccountId) filterList.push(`[bank_account_id]=${banckAccountId}`);
    if (pdv) filterList.push(`[exclude_pdv]=${pdv === 1 ? true : false}`);
    if (startDate || endDate) {
      filterList.push(`[starts_at]=${startDate}`);
      filterList.push(`[ends_at]=${endDate}`);
    }

    if (filterList.length > 0) {
      const filter = createFilter(filterList);
      if (filter !== "") {
        getFinancialTransactionEntries(filter);
      }
      return;
    }
    getFinancialTransactionEntries();
  };

  const handleDateStart = async (date: string) => {
    if (!date) return;
    if (date.length !== 10) {
      setValidDateIni(false);
      return;
    }
    setStartDate(date);

    let dateValid = isLater(date, endDate);
    setValidDateIni(dateValid);
    if (dateValid) {
      return;
    }
  };

  const handleDateEnd = async (date: string) => {
    if (!date) return;
    if (date.length !== 10) {
      setValidDateIni(false);
      return;
    }

    setEndDate(date);

    let dateValid = isLater(startDate, date);
    setValidDateEnd(dateValid);

    if (dateValid) {
      return;
    }
  };

  const isLater = (start: string, end: string) => {
    return start > end;
  };

  const getFinancialTransactionEntries = (filter?: string) => {
    setLoading(true);
    api
      .get(Globals.api.financialEntries + `?${filter}`)
      .then((response) => {
        const data = response.data;
        setListExtratoBancario(data.data);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <MovimentacaoContext.Provider
      value={{
        showMovimentacao,
        showList,
        listBankaccount,
        listBank,
        banckAccountId,
        setBanckaccountId,
        pdv,
        setPdv,
        listPdv,
        startDate,
        endDate,
        loading,
        listExtratoBancario,

        fillBankAccounts,
        fillPdv,
        handleCloseMovimentacao,
        handleShowMovimentacao,
        handleCloseList,
        handleShowList,
        handleSearchList,
        handleDateStart,
        handleDateEnd,
      }}
    >
      {children}
    </MovimentacaoContext.Provider>
  );
};
