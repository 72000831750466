import { useContext, useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { FormHandles } from "@unform/core";
import { ThemeContext } from "../../../context/theme-context";
import { useAuth } from "../../../context/Auth";
import { InputClone } from "../../../Components/MyAccount/InputClone";
import { StateForm } from "../../../interfaces/state-form";

export default function AcessoLoja() {
  const { user, getMe } = useAuth();
  const { collapsed } = useContext(ThemeContext);
  const formRef = useRef<FormHandles>(null);
  const [stateForm, setStateForm] = useState<StateForm>({
    state: "default",
    message: "",
  });

  const handleSubmit = () => {};

  useEffect(() => {
    getMe();
  }, []);

  return (
    <Container
      className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
    >
      <form title="Acesso a Lojas">
        <InputClone user={user} />
      </form>
    </Container>
  );
}
