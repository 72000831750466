import { useState, useContext } from "react";
import Container from "react-bootstrap/Container";
import "./styles.scss";
import ListaVendas from "../../../Components/Vendas/ListaVendas";
import { ThemeContext } from "../../../context/theme-context";
import { IVendas } from "../../../Components/Vendas/ListaVendas/interfaces/IVendas";

export interface IListVendas {
  title: string;
  OnAddNewCli: (e: boolean) => void;
  onEditItem: (item: IVendas) => void;
  OnAddNewVendas: (e: boolean) => void;
}

export interface IFormVendas {
  title: string;
  OnAddNewVendas: (e: boolean) => void;
  vendas?: IVendas;
  editando: boolean;
}

export default function CadVendas() {
  const { collapsed } = useContext(ThemeContext);

  const [cad, setCad] = useState<boolean>(false);
  const [toEditAjustes, setToEditAjustes] = useState<IVendas>();
  const [editando, setEditando] = useState<boolean>(false);

  const handleNew = (e: any) => {
    setEditando(false);
    setCad(e);
  };

  const handleEdit = (props: IVendas) => {
    setToEditAjustes(props);
    setCad(true);
    setEditando(true);
  };

  return (
    <>
      {!cad ? (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <ListaVendas
            title="Listagem de Vendas"
            onEditItem={(item: IVendas) => handleEdit(item)}
            OnAddNewCli={(e: boolean) => handleNew(e)}
            OnAddNewVendas={(e: boolean) => handleNew(e)}
          />
        </Container>
      ) : (
        ""
      )}
    </>
  );
}
