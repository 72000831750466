import styled from "styled-components";

export const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  text-transform: capitalize;
  flex: 1;
  text-align: center;
`;

export const Wrapper = styled.div`
  ul {
    list-style: none;
    display: grid;
    gap: 0.3rem;
    font-size: 13px;
    color: #505050f4;
    padding: 1rem;
  }

  ul li {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-transform: uppercase;
    border-bottom: 1px solid #ced0d4f4;
  }

  ul li span {
    font-weight: bold;
    text-transform: uppercase;
  }

  .borderTop {
    border-top: 1px solid green;
  }
`;

export const Span = styled.span`
  font-size: 12px;
  display: flex;
  color: red;
  border-bottom: 1px solid red;
  cursor: pointer;
`;

export const Thead = styled.tr`
  font-size: 12px !important;
  text-align: center;
`;

export const Wrapperr = styled.div`
  margin-top: 0.5rem;
  label > .label {
    text-align: right;
    margin-right: 0.5rem;
    font-size: 11px;
    font-weight: normal;
  }

  @media only screen and (max-width: 1100px) {
    .max-screen {
      display: none;
    }

    .min-screen {
      label > .label {
        text-align: left;
        margin-right: 0.5rem;
        font-size: 11px;
        font-weight: normal;
      }
    }
  }

  @media only screen and (min-width: 1100px) {
    .max-screen {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 1.2rem;

      .value-label {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .flex {
          display: flex;
        }
      }

      .initial-value {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      .final_value {
        input {
          width: 110px;
        }
      }
      label > .label {
        text-align: right;
        margin-right: 0.5rem;
        font-size: 11px;
        font-weight: normal;
      }
    }

    .min-screen {
      display: none;
    }
  }

  label {
    display: grid;
    grid-template-columns: 1fr 3fr;

    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;

      .label {
        display: none;
      }
    }
  }

  .wrapper-date {
    display: grid;
    grid-template-columns: 1fr;
  }
`;
