import { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DataTable from "react-data-table-component";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../Services/api";
import Globals from "../../../Globals.json";
import LayoutFilter from "../../data/LayoutFilter";
import ReactSelect from "../../data/reactSelect";
import LayoutTable from "../../data/LayoutTable";
import GetEndPoint from "../../../Services/GetEndPoint";
import { useAppSelector } from "../../../redux/store";
import tableStyleDefault from "../../../Services/tableStyleDefault";
import CustomLoader from "../../data/customLoader";
import { sortByCreated } from "../../../Services/sortByCreated";
import * as S from "./styled";

export default function ListaPosicoes(props: any) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [product, setProduct] = useState<string>("");
  const [stock, setStock] = useState<string>("");
  const [category, setCategory] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  // Lists
  const [listPositions, setListPositions] = useState<Array<any>>([]);
  const [listProduct, setListProduct] = useState<Array<any>>([]);
  const [listPositionsComplete, setListPositionsComplete] = useState<
    Array<any>
  >([]);
  const [listCategories, setListCategories] = useState<Array<any>>([]);
  const [listStocks, setListStocks] = useState<Array<any>>([]);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const clearFields = () => {
    setProduct("");
    setCategory("");
    setStock("");
    getPosition();
  };

  const createFilter = (filterList: Array<string>): string => {
    let filter = "";
    for (let i = 0; i < filterList.length; i++) {
      if (i >= 1) {
        filter += "&";
      }
      filter += `filter${filterList[i]}`;
    }
    return filter;
  };

  const handleSearchList = () => {
    let filterList = [];
    if (stock !== "") filterList.push(`[stock_id]=${stock}`);
    if (product) filterList.push(`[product_id]=${product}`);
    if (category !== "") filterList.push(`[product_category_id]=${category}`);

    if (filterList.length > 0) {
      const filter = createFilter(filterList);
      if (filter !== "") {
        getPosition(filter);
      }
      return;
    }
    getPosition();
  };

  const columns = [
    {
      name: "Código",
      sortable: true,
      center: true,
      grow: 0.4,
      selector: (row: any) => row?.relationships.product.code.toLowerCase(),
    },

    {
      name: "Produto",
      sortable: true,
      selector: (row: any) => row?.sale_name.toLowerCase(),
    },
    {
      name: "Categoria",
      center: true,
      grow: 0.4,
      selector: (row: any) =>
        listCategories
          .find(
            (item) =>
              item.value === row?.relationships.product.product_category_id
          )
          ?.label.toLowerCase(),
    },
    {
      name: "Local",
      center: true,
      grow: 0.4,
      selector: (row: any) => row?.name?.toLowerCase(),
    },
    {
      name: "Custo",
      center: true,
      sortable: true,
      grow: 0.3,
      selector: (row: any) =>
        row?.cost_price.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      name: "Venda",
      center: true,
      sortable: true,
      grow: 0.3,
      selector: (row: any) =>
        row?.sale_price.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      name: "Quantidade",
      center: true,
      sortable: true,
      grow: 0.3,
      selector: (row: any) => row.qty,
    },
    {
      name: "Unidade",
      center: true,
      grow: 0.4,
      selector: (row: any) =>
        row?.measure_unit
          ? row?.measure_unit
          : listProduct?.find((item: any) => item.value === row.product_id)
              ?.unit,
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Registros por página",
    rangeSeparatorText: "de",
  };

  const getPosition = (filter?: string) => {
    const uri =
      filter !== undefined
        ? `${filter}&paginate=false&store_id=${storeMe.storeActive.id}`
        : `paginate=false&store_id=${storeMe.storeActive.id}`;

    setLoading(true);
    api
      .get(Globals.api.stockPositions + `?${uri}`)
      .then((response) => {
        const data = response.data;
        setListPositions(sortByCreated(data.data));
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickOpenFilter = () => {
    setOpenFilter(!openFilter);
  };

  useEffect(() => {
    getPosition();
    GetEndPoint.stocks(setListStocks);
    GetEndPoint.categories(setListCategories);
    GetEndPoint.products(setListProduct);
    GetEndPoint.positions(setListPositionsComplete, storeMe.storeActive.id);
  }, []);

  let newListStocks = listStocks.filter(
    (item) => item.store_id === storeMe.storeActive.id
  );

  let newListCategories = listCategories.filter(
    (item) => item.store_id === storeMe.storeActive.id
  );

  return (
    <>
      <LayoutFilter
        title={props.Title}
        onClickClear={clearFields}
        onClickSearch={handleSearchList}
        onClickOpenFilter={onClickOpenFilter}
        openFilter={openFilter}
      >
        <S.Wrapper>
          {openFilter && (
            <div className="min-screen">
              <ReactSelect
                label="Produto"
                placeholder="Produto"
                state={product}
                setState={setProduct}
                options={listPositionsComplete}
              />

              <ReactSelect
                label="Local Est."
                placeholder="Local de Estoque"
                state={stock}
                setState={setStock}
                options={newListStocks}
              />

              <ReactSelect
                label="Categoria"
                placeholder="Categoria"
                state={category}
                setState={setCategory}
                options={newListCategories}
              />
            </div>
          )}

          <div className="max-screen">
            <ReactSelect
              label="Produto"
              state={product}
              setState={setProduct}
              options={listPositionsComplete}
            />

            <ReactSelect
              label="Local Est."
              state={stock}
              setState={setStock}
              options={newListStocks}
            />

            <ReactSelect
              label="Categoria"
              state={category}
              setState={setCategory}
              options={newListCategories}
            />
          </div>
        </S.Wrapper>
      </LayoutFilter>

      <LayoutTable>
        <DataTable
          columns={columns}
          data={listPositions || []}
          striped
          pagination
          paginationDefaultPage={1}
          paginationPerPage={25}
          customStyles={tableStyleDefault}
          paginationComponentOptions={paginationOptions}
          progressPending={loading}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhuma informação foi encontrada."
        />
      </LayoutTable>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
