import { SubmitHandler, useForm } from "react-hook-form";
import { IFormStore } from "../../../../../Pages/dashboard/Representative/CadStores";
import { yupResolver } from "@hookform/resolvers/yup";
import { storeSchema } from "../../../../../schemas/storeSchema";
import { useEffect, useState } from "react";
import { StateForm } from "../../../../../interfaces/state-form";
import * as Yup from "yup";
import { Services } from "../../../../../Services/newService";
import LayoutHookForm from "../../../../reactHookForm/LayoutForm";
import { InputForm } from "./inputForm";
import Loading from "../../../../Loading/Loading";
import { useAuth } from "../../../../../context/Auth";
import {
  ObjRegimeTributario,
  ObjSituacoesTributarias,
  ObjType,
} from "../../../../../utils/objetos/objt";
import { toast } from "react-toastify";
import { notify, notifyError } from "../../../../notify/notify-component";
import { filterObject } from "../../../../../utils/filterObj";
import { AppDispatch, useAppSelector } from "../../../../../redux/store";
import { ICsts } from "../../../../../redux/features/store/interface";
import { useDispatch } from "react-redux";
import { getStoreMeAsync } from "../../../../../redux/features/userMe/services";
import { getStoreAsync } from "../../../../../redux/features/store/services";

type FormData = Yup.InferType<typeof storeSchema>;

export function FormStore({
  title,
  store,
  editando,
  OnAddNewStore,
  selectOptions,
}: IFormStore) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState,
    formState: { errors },
  } = useForm<FormData>({ resolver: yupResolver(storeSchema) });
  const dispatch = useDispatch<AppDispatch>();
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const { csts, stores } = useAppSelector(
    ({ storeReducer }) => storeReducer.value
  );

  const [stateForm, setStateForm] = useState<StateForm>({
    state: "default",
    message: "",
  });

  const onSubmit: SubmitHandler<FormData> = async (data: any) => {
    try {
      setStateForm({ state: "loading" });
      const filteredObject = filterObject(data);
      const { email, seller_id, ...newObj } = filteredObject;
      const newData = {
        ...newObj,
        status: parseInt(data.status) ? parseInt(data.status) : 1,
        headquarter_id: storeMe.storeActive.id,
        type_id: data?.type_id?.value
          ? parseInt(data.type_id.value)
          : store.relationships.type.id,
        address_zip_code: data.cep,
        cell_phone: data.mobile, //Remover informar que esta dando erro neste campo
        tax_regime: data?.tax_regime?.value,
        cfop_devolucao_icms_tributario:
          data?.cfop_devolucao_icms_tributario?.value,
        tax_situation: data?.tax_situation?.value,
        cfop_emissao_nota_fiscal: data?.cfop_emissao_nota_fiscal?.value,
        cfop_transferencia_estoque: data?.cfop_transferencia_estoque?.value,
        cfop_venda_icms: data?.cfop_venda_icms?.value,
        csosn_id: data?.csosn_id?.value,
        cfop_venda_icms_tributario: data?.cfop_venda_icms_tributario?.value,
        //Verificar  csosn_id
        //Verificar  seller_id
      };

      const storeEdited = await Services.storePut(newData, setStateForm);
      window.location.reload();
      if (!storeEdited) return;

      setTimeout(() => {
        handleCancel();
      }, 100);
    } catch (err: any) {
      const erroAxios = err?.response?.data?.errors;
      const erroHandler = erroAxios ? Object.values(erroAxios)[0] : err;

      if (typeof erroHandler === "string") {
        const parseError = JSON.parse(erroHandler);

        setStateForm({
          state: "error",
          message:
            parseError.cause[0].message || "api_error_please_contact_support",
        });
        return;
      }
    }
  };

  const handleCancel = () => {
    reset();
    OnAddNewStore(false);
  };

  const objCsosn = csts.map((item: ICsts) => ({
    label: item.cst_description,
    value: item.id,
  }));

  useEffect(() => {
    const storeData: any = {};

    for (const key in store) {
      storeData[key] = store[key] ?? "";
    }

    const resetData = {
      ...storeData,
      cep: store?.address_zip_code,
      cell_phone: store.cell_phone || "",
      type_id: ObjType.find((item) => item.value === store?.type_id),
      csosn_id: objCsosn.find((item) => item.value === store?.csosn_id),
      tax_regime: ObjRegimeTributario.find(
        (item) => item.value === store?.tax_regime
      ),
      tax_situation: ObjSituacoesTributarias.find(
        (item) => item.value === store?.tax_situation
      ),
    };

    reset(resetData);
  }, []);

  useEffect(() => {
    if (Object.keys(formState.errors).length > 0) {
      notifyError("Por favor preencher todos os campos obrigatórios");
    }
  }, [formState.errors]);

  const inputError = [errors];

  return (
    <>
      <LayoutHookForm
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        title={title}
        isEditable={editando}
        onClickCancel={handleCancel}
        stateForm={stateForm}
      >
        <InputForm
          register={register}
          control={control}
          watch={watch}
          setValue={setValue}
          setError={setError}
          errors={inputError}
          setStateForm={setStateForm}
          reset={reset}
          data={store}
          isEditable={editando}
        />
      </LayoutHookForm>

      {stateForm.state === "loading" && <Loading />}
    </>
  );
}
