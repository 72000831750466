import { Button } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import * as S from "./styles";

interface IProps {
  label: string;
  state: any;
  onClick: any;
  type: "string" | "number" | "date" | "text";
  autoComplete?: "on" | "off";
  placeholder?: string;
  maxLength?: number;
  isInvalid?: boolean;
  disabled?: boolean;
}

export default function InputCode({
  label,
  type,
  state,
  autoComplete,
  onClick,
  placeholder,
  maxLength,
  isInvalid,
  disabled,
}: IProps) {
  return (
    <label>
      <div className="label">{label}</div>
      <S.Wrapper>
        <S.Group>
          <S.FormInput
            type={type}
            autoComplete={autoComplete}
            value={state}
            placeholder={placeholder}
            maxLength={maxLength}
            isInvalid={isInvalid}
            disabled={disabled}
          />
          <Button onClick={onClick} disabled={disabled} size="sm">
            <AiOutlinePlus size={10} />
          </Button>
        </S.Group>
      </S.Wrapper>
    </label>
  );
}
