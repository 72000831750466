// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__Cu9cT {
  display: grid;
  padding: 17px;
  margin-bottom: 10px;
  box-shadow: 1px 1px 1px -1px rgba(164, 166, 163, 0.9803921569);
  border-radius: 5px;
  font-size: 12px;
  box-shadow: 0px 0px 17px -10px rgb(122, 122, 122);
}

.styles_titleGroup__otwbU {
  display: flex;
  justify-content: space-between;
}

.styles_btnGourp__OpfcE {
  display: flex;
  justify-content: flex-start;
}

.styles_btn__EA4tV {
  display: flex;
  gap: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/reactHookForm/LayoutFilter/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,mBAAA;EACA,8DAAA;EACA,kBAAA;EACA,eAAA;EAIA,iDAAA;AAAF;;AAGA;EACE,aAAA;EACA,8BAAA;AAAF;;AAGA;EACE,aAAA;EACA,2BAAA;AAAF;;AAGA;EACE,aAAA;EACA,SAAA;AAAF","sourcesContent":[".container {\n  display: grid;\n  padding: 17px;\n  margin-bottom: 10px;\n  box-shadow: 1px 1px 1px -1px #a4a6a3fa;\n  border-radius: 5px;\n  font-size: 12px;\n\n  -webkit-box-shadow: 0px 0px 17px -10px rgba(122, 122, 122, 1);\n  -moz-box-shadow: 0px 0px 17px -10px rgba(122, 122, 122, 1);\n  box-shadow: 0px 0px 17px -10px rgba(122, 122, 122, 1);\n}\n\n.titleGroup {\n  display: flex;\n  justify-content: space-between;\n}\n\n.btnGourp {\n  display: flex;\n  justify-content: flex-start;\n}\n\n.btn {\n  display: flex;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__Cu9cT`,
	"titleGroup": `styles_titleGroup__otwbU`,
	"btnGourp": `styles_btnGourp__OpfcE`,
	"btn": `styles_btn__EA4tV`
};
export default ___CSS_LOADER_EXPORT___;
