import { notifyError } from "../Components/notify/notify-component";
import globals from "../Globals.json";
import api from "./api";

export const createBankAccounts = async (objBankAccounts: any) => {
  if (objBankAccounts.uuid) {
    return api
      .put(
        `${globals.api.bankAccounts}/${objBankAccounts.uuid}`,
        objBankAccounts
      )
      .then((res: any) => {
        if (res.status === 201 || res.status === 200) {
          const objCallback = {
            data: res.data.data,
            status: true,
          };
          return objCallback;
        }
      })
      .catch((error: any) => {
        notifyError(error);
      });
  } else {
    return api
      .post(`${globals.api.bankAccounts}`, objBankAccounts)
      .then((res: any) => {
        if (res.status === 201 || res.status === 200) {
          const objCallback = {
            data: res.data.data,
            status: true,
          };
          return objCallback;
        }
      })
      .catch((error: any) => {
        notifyError(error);
      });
  }
};

export const getBankAccounts = () => {
  return api.get(`${globals.api.bankAccounts}?paginate=false`);
};
