export interface ISelect {
  value: number;
  label: string;
}

export const objStatus: ISelect[] = [
  {
    value: 0,
    label: "Todos",
  },
  {
    value: 1,
    label: "Pendente",
  },
  {
    value: 2,
    label: "Finalizado",
  },
  {
    value: 3,
    label: "Estornado",
  },
];

export const objectPeriod: ISelect[] = [
  { value: 0, label: "Todos" },
  { value: 1, label: "Data de Vencimento" },
  { value: 2, label: "Data da Competência" },
];
