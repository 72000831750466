import { ToastContainer } from "react-toastify";
import { IListFinancial } from "../../../../Pages/Cadastro/CadFinanceiroReceita";
import LayoutHookTable from "../../../reactHookForm/LayoutTable";
import DataTable from "react-data-table-component";
import CustomLoader from "../../../data/customLoader";
import { paginationOptions } from "../../../data/PagineteInfo";
import LayoutHookFilter from "./LayoutFilter";
import { SubmitHandler, useForm } from "react-hook-form";
import { InputFilter } from "./InputFilter";
import { useEffect, useMemo, useState } from "react";
import {
  deletefinancialTransactionAsync,
  getBanksAsync,
  getCategoriesAsync,
  getChartAccountAsync,
  getReceiverAsync,
  getfinancialTransactionAsync,
} from "../../../../redux/features/financialTransaction/services";
import Format from "../../../../Services/Format";
import {
  compareAsc,
  isBefore,
  isToday,
  parseISO,
  startOfToday,
} from "date-fns";
import ActiveInactiv from "../../../data/activeInactive";
import { convertDatePTbr } from "../../../../utils";
import { IFinancialTransaction } from "../../../../redux/features/financialTransaction/interface";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import { HiOutlineDotsVertical } from "react-icons/hi";
import ModalBootstrap from "../../../data/Modal";
import { notify, notifyError } from "../../../notify/notify-component";
import CadLauch from "../CadLaunch";
import { ITransacaoFinanceira } from "../../Interface/ITransacao";
import CadUndo from "../CadUndo";
import { useAppSelector } from "../../../../redux/store";
import PaginationWrapper from "../../../data/paginationWrapper";
import FilterBy from "../../../../redux/features/financialTransaction/services/FilterBy";
import { getDueMonthDate } from "../../../../redux/features/financialTransaction/utils";
import tableStyleDefaultFinancial from "../../../../Services/tableStyleDefaultFinancial";

interface IFormInput {}

export function ListaFinanceiro({
  title,
  onEditItem,
  onAddNewCli,
  entryType,
  financialData,
  dispatch,
}: IListFinancial) {
  const { revenue, expense, loading, bank } = useAppSelector(
    ({ financialTransactionReducer }) => financialTransactionReducer.value
  );

  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm<IFormInput>();
  const inputError = [errors];

  const [tableList, setTableList] = useState<IFinancialTransaction[]>([]);
  const [toEditLauch, setToEditLauch] = useState<ITransacaoFinanceira>();
  const [modalConfirmation, setModalConfirmation] = useState<boolean>(false);
  const [uuid, setUuid] = useState<string>("");
  const [showModalLaunch, setShowModalLaunch] = useState<boolean>(false);
  const [showModalUndo, setShowModalUndo] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const [amountInstallments, setAmountInstallments] = useState<
    number | undefined
  >();
  const [discount, setDiscount] = useState<number | undefined>();
  const [interest, setInterest] = useState<number | undefined>();
  const [pendingValue, setPendingValue] = useState<number>(0);

  const onSubmit: SubmitHandler<IFormInput> = async (data: any) => {
    let hasValue = false;
    let filteredList: IFinancialTransaction[] = [
      ...(entryType === 1 ? revenue : expense),
    ];

    if (data?.document) {
      filteredList = FilterBy.document(filteredList, data.document);
      hasValue = true;
    }

    if (data?.initial_value && data?.final_value) {
      filteredList = FilterBy.amount(
        filteredList,
        data?.initial_value,
        data?.final_value
      );
      hasValue = true;
    }

    if (data?.document) {
      filteredList = FilterBy.document(filteredList, data.document);
      hasValue = true;
    }

    if (data?.receiver?.value) {
      filteredList = FilterBy.receiver(filteredList, data.receiver.value);
      hasValue = true;
    }

    if (data?.chart_account_id?.value) {
      filteredList = FilterBy.chartAccount(
        filteredList,
        data?.chart_account_id.value
      );
      hasValue = true;
    }

    if (data?.status?.value !== 0) {
      filteredList = FilterBy?.status(
        filteredList,
        Number(data?.status?.value)
      );
      hasValue = true;
    }

    if (data?.periodo?.value === 1) {
      filteredList = FilterBy.dueDate(
        filteredList,
        data.inicialData,
        data.finalData
      );
      hasValue = true;
    }

    if (data?.periodo?.value === 2) {
      filteredList = FilterBy.dueCreate(
        filteredList,
        data.inicialData,
        data.finalData
      );
      hasValue = true;
    }

    setTableList(hasValue ? filteredList : entryType === 1 ? revenue : expense);
  };

  const columns: any = [
    {
      name: "Código",
      center: true,
      grow: 0.2,
      sortable: true,
      selector: (row: any) => {
        return (
          <div
            style={{
              color: "#00AB00",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => handleEdit(row)}
          >
            {parseInt(row?.id)}
          </div>
        );
      },

      sortFunction: (rowA: any, rowB: any) => rowA.id - rowB.id,
    },
    {
      name: "Documento",
      grow: 0.6,
      center: true,
      sortable: true,
      selector: (row: any) => (
        <span style={{ fontWeight: row?.fontWight }}>{row?.document}</span>
      ),
      sortFunction: (rowA: any, rowB: any) => rowA.document - rowB.document,
    },
    {
      name: "Descrição",
      grow: 0.8,
      sortable: true,
      selector: (row: any) => row?.description?.toLowerCase(),
      sortFunction: (rowA: any, rowB: any) => {
        const descA = rowA?.description?.toLowerCase();
        const descB = rowB?.description?.toLowerCase();
        if (descA < descB) return -1;
        if (descA > descB) return 1;
        return 0;
      },
    },
    {
      name: "Emissão",
      grow: 0.5,
      center: true,
      sortable: true,
      selector: (row: any) =>
        row?.creation_date ? convertDatePTbr(row?.creation_date) : "---",
      sortFunction: (rowA: any, rowB: any) =>
        compareAsc(
          parseISO(rowA?.creation_date),
          parseISO(rowB?.creation_date)
        ),
    },
    {
      name: "Vencimento",
      grow: 0.7,
      center: true,
      sortable: true,
      selector: (row: any) => {
        if (row?.due_date) {
          if (isToday(parseISO(row?.due_date)) && row?.status !== 2) {
            return (
              <ActiveInactiv
                status={false}
                text={convertDatePTbr(row?.due_date)}
                color={"yellow"}
              />
            );
          } else if (
            isBefore(parseISO(row?.due_date), startOfToday()) &&
            row?.status !== 2
          ) {
            return (
              <ActiveInactiv
                status={false}
                text={convertDatePTbr(row?.due_date)}
              />
            );
          } else {
            return (
              <ActiveInactiv
                status={true}
                text={convertDatePTbr(row?.due_date)}
              />
            );
          }
        } else {
          <div></div>;
        }
      },
      sortFunction: (rowA: any, rowB: any) =>
        compareAsc(parseISO(rowA.due_date), parseISO(rowB.due_date)),
    },
    {
      name: "Banco",
      grow: 0.8,
      selector: (row: any) =>
        bank
          .find((item: any) => item.id === row?.bank_account_id)
          ?.description?.toLowerCase(),
    },
    {
      name: "Valor",
      grow: 0.6,
      center: true,
      sortable: true,
      selector: (row: any) => (
        <span style={{ fontWeight: row?.fontWight }}>
          {Format.currency(row?.amount)}
        </span>
      ),
      sortFunction: (rowA: any, rowB: any) => rowA.amount - rowB.amount,
    },

    {
      name: "Valor Pago",
      grow: 0.6,
      center: true,
      sortable: true,
      selector: (row: any) => (
        <span style={{ fontWeight: row?.fontWight }}>
          {Format.currency(row?.amount_paid)}
        </span>
      ),
      sortFunction: (rowA: any, rowB: any) =>
        rowA.amount_paid - rowB.amount_paid,
    },
    {
      name: "Pagamento",
      grow: 0.6,
      center: true,
      sortable: true,
      selector: (row: any) =>
        Format.convertToBRFormat(row?.relationships?.entries[0]?.created_at),
      sortFunction: (rowA: any, rowB: any) => {
        const dateA = rowA?.relationships?.entries[0]?.created_at
          ? parseISO(rowA?.relationships?.entries[0]?.created_at)
          : new Date(0);
        const dateB = rowB?.relationships?.entries[0]?.created_at
          ? parseISO(rowB?.relationships?.entries[0]?.created_at)
          : new Date(0);
        return compareAsc(dateA, dateB);
      },
    },

    {
      name: "Situação",
      grow: 0.6,
      center: true,
      selector: (row: any) => {
        if (row.status && row?.relationships) {
          if (
            row?.relationships?.stockMovement?.situation_id === 2 &&
            row?.relationships?.entries?.length === 0 &&
            row.status === 1
          ) {
            return (
              <span
                onClick={() => handleLaunch(row)}
                style={{
                  color: "green",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                Pendente
              </span>
            );
          }
          if (
            row?.relationships?.stockMovement?.situation_id === 2 &&
            row?.relationships?.entries?.length === 0
          ) {
            return <span style={{ color: "red" }}>Cancelado</span>;
          }

          if (
            row?.relationships?.stockMovement?.situation_id === 2 &&
            row?.relationships?.entries?.length > 0
          ) {
            return (
              <span
                onClick={() => handleUndo(row)}
                style={{
                  color: "red",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                Estornar
              </span>
            );
          }
          if (row.status === 1 || row.status === 3) {
            return (
              <span
                onClick={() => handleLaunch(row)}
                style={{
                  color: "#00AB00",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                {row?.chargeback_date ? "Estornado" : "Pendente"}
              </span>
            );
          } else {
            return (
              <span
                onClick={() => handleUndo(row)}
                style={{
                  color: "red",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                Estornar
              </span>
            );
          }
        } else {
          <div></div>;
        }
      },
      sortFunction: (rowA: any, rowB: any) => {
        const statusOrderA = Format.getStatusOrder(rowA);
        const statusOrderB = Format.getStatusOrder(rowB);
        return statusOrderA - statusOrderB;
      },
    },

    {
      name: "Ação",
      width: "60px",
      center: true,
      cell: (row: any) => (
        <div>
          <Dropdown>
            <Dropdown.Toggle variant="white" id="dropdown-basic" size="sm">
              <HiOutlineDotsVertical />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleEdit(row)}>
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    fontSize: "12px",
                  }}
                >
                  {row?.status === 2 ||
                  row?.relationships?.stockMovement?.situation_id === 2 ? (
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        fontSize: "12px",
                      }}
                    >
                      <FaEye
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Visualizar"
                        className="action"
                        size={16}
                        color="8e8d8d"
                      />
                      Visualizar
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        fontSize: "12px",
                      }}
                    >
                      <FaEdit
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Editar"
                        className="action"
                        size={16}
                        color="8e8d8d"
                      />
                      Editar
                    </div>
                  )}
                </div>
              </Dropdown.Item>

              {row?.chargeback_date && (
                <Dropdown.Item onClick={() => handleModalUuid(row.uuid)}>
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                      fontSize: "12px",
                    }}
                  >
                    <FaTrash
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Deletar"
                      className="action"
                      size={16}
                      color="8e8d8d"
                    />
                    Deletar
                  </div>
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
    },
  ];

  const handleAdd = () => {
    onAddNewCli(true);
  };

  const onClickOpenFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleEdit = (row: IFinancialTransaction) => {
    onEditItem(row);
  };

  const handleClear = () => {
    reset();
    const list = entryType === 1 ? revenue : expense;
    setTableList(getDueMonthDate(list));
  };

  const handleLaunch = (row: IFinancialTransaction) => {
    setShowModalLaunch(true);
    setToEditLauch(row);
  };

  const handleUndo = (row: IFinancialTransaction) => {
    setShowModalUndo(true);
    setToEditLauch(row);
  };

  const handleModalUuid = (uuid: string) => {
    setUuid(uuid);
    setModalConfirmation(true);
  };

  const handleDelete = async (uuid: string) => {
    dispatch(deletefinancialTransactionAsync(uuid)).then((result: any) => {
      if (deletefinancialTransactionAsync.fulfilled.match(result)) {
        notify("A Transação foi excluida.");
        dispatch(getfinancialTransactionAsync(""));
      } else {
        notifyError("Erro ao excluir transação.");
      }
    });
  };

  const handleDeleteItem = () => {
    setModalConfirmation(false);
    handleDelete(uuid);
  };

  useEffect(() => {
    dispatch(getfinancialTransactionAsync(""));
    dispatch(getBanksAsync());
    dispatch(getReceiverAsync());
    dispatch(getChartAccountAsync());
    dispatch(getCategoriesAsync());
  }, [dispatch]);

  useEffect(() => {
    const list = entryType === 1 ? revenue : expense;

    if (tableList) {
      handleSubmit(onSubmit)();
    } else {
      setTableList(getDueMonthDate(list));
    }
  }, [revenue, expense, dispatch]);

  return (
    <>
      <LayoutHookFilter
        title={title}
        onClickAdd={handleAdd}
        onClickClear={handleClear}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        onClickOpenFilter={onClickOpenFilter}
        openFilter={openFilter}
      >
        <InputFilter
          register={register}
          control={control}
          watch={watch}
          setValue={setValue}
          setError={setError}
          errors={inputError}
          reset={reset}
          entryType={entryType}
          openFilter={openFilter}
        />
      </LayoutHookFilter>

      <LayoutHookTable>
        <div>
          <DataTable
            columns={columns}
            data={tableList}
            striped
            pagination
            paginationDefaultPage={1}
            paginationPerPage={25}
            customStyles={tableStyleDefaultFinancial}
            paginationComponentOptions={paginationOptions}
            progressPending={loading}
            progressComponent={<CustomLoader />}
            noDataComponent="Nenhuma informação foi encontrada."
            paginationComponent={(props) => (
              <PaginationWrapper
                rowCount={props.rowCount}
                rowsPerPage={props.rowsPerPage}
                currentPage={props.currentPage}
                onChangePage={(page: any) =>
                  props.onChangePage(page, props.rowCount)
                }
                onChangeRowsPerPage={(
                  currentRowsperPage: any,
                  currentPage: any
                ) => props.onChangeRowsPerPage(currentRowsperPage, currentPage)}
                footerData={entryType === 1 ? revenue : expense || []}
              />
            )}
          />
        </div>
      </LayoutHookTable>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <ModalBootstrap
        state={modalConfirmation}
        setState={setModalConfirmation}
        text={"Tem certeza de que deseja excluir?"}
        handleAction={handleDeleteItem}
      />
      <CadLauch
        show={showModalLaunch}
        onHide={() => setShowModalLaunch(false)}
        pendingValue={pendingValue}
        setPendingValue={setPendingValue}
        amountInstallments={amountInstallments}
        setAmountInstallments={setAmountInstallments}
        discount={discount}
        setDiscount={setDiscount}
        interest={interest}
        setInterest={setInterest}
        Title="Laçamento"
        launchTransacao={toEditLauch}
        EntryType={entryType}
        Label="Forma de Recebimento"
        Placeholder="Recebimento"
        dispatch={dispatch}
      />
      <CadUndo
        show={showModalUndo}
        onHide={() => setShowModalUndo(false)}
        Title="Estornar"
        undoTransacao={toEditLauch}
        EntryType={entryType}
        Label="Forma de Recebimento"
        Placeholder="Recebimento"
        dispatch={dispatch}
      />
    </>
  );
}
