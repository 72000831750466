import { ExtratoProvider } from "./Components/Financeiro/ExtratoBancario/context/extrato-bancario-context";
import { MovimentacaoProvider } from "./Components/Financeiro/MovimentacaoBancaria/context/movimentacao-bancario-context";
import Routes from "./Routes/routes";
import "bootstrap/dist/css/bootstrap.min.css";
import { StoreProvider } from "./context/store-context";
import { AuthProvider } from "./context/Auth";
import { ReduxProvider } from "./redux/features/provider";

export function App() {
  return (
    <>
      <AuthProvider>
        <StoreProvider>
          <ExtratoProvider>
            <MovimentacaoProvider>
              <ReduxProvider>
                <Routes />
              </ReduxProvider>
            </MovimentacaoProvider>
          </ExtratoProvider>
        </StoreProvider>
      </AuthProvider>
    </>
  );
}
