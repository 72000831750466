import { Fragment, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../../../Loading/Loading";
import { NoData } from "../../../../noData";
import { AppDispatch, useAppSelector } from "../../../../../redux/store";
import { useDispatch } from "react-redux";
import {
  getBrPaymentTypesAsync,
  getBrPaymentTypesProvidersAsync,
  getPAymentTypePDVAsync,
  getPaymentMechanismsAsync,
} from "../../../../../redux/features/PaymentTypePDV/services";
import { Btn } from "../../../../data/Btn";
import { TreePDV } from "./Tree";
import ModalPDV from "./ModalPDV";
import { SortOrder } from "react-data-table-component";
import { sortByCreated } from "../../../../../Services/sortByCreated";

export default function Pdv({
  onEditItem,
  OnAddNewChartAccount,
  setKeyActived,
}: any) {
  const { paymentTypeDdv } = useAppSelector(
    ({ paymentTypePDVReducer }) => paymentTypePDVReducer.value
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [openModalCadPDV, setModalCadPDV] = useState<boolean>(false);
  const [listPDV, setListPDV] = useState<any[]>([]);
  const [listEditeItem, setListEditeItem] = useState<any | undefined>();

  const handleEdit = (item: any) => {
    setListEditeItem(item);
    setModalCadPDV(true);
  };

  return (
    <>
      <ModalPDV
        show={openModalCadPDV}
        setShow={setModalCadPDV}
        setLoading={setLoading}
        listPDV={listPDV}
        setListPDV={setListPDV}
        listEditeItem={listEditeItem}
        setListEditeItem={setListEditeItem}
        setKeyActived={setKeyActived}
      />
      <div className="mb-2 mt-4">
        <Btn
          text={"Novo recebimento PDV"}
          type="button"
          variante="primary"
          icon="plus"
          onClick={() => setModalCadPDV(true)}
        />
      </div>
      <div className="mt-4">
        {paymentTypeDdv.length > 0 && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "2fr 1fr 2fr 1fr 2fr 2fr 2fr 1fr 1fr",
              flex: "1",
              fontSize: "13px",
              paddingLeft: "1.9rem",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            <div>Data de Cadastro</div>
            <div>Código</div>
            <div>Descrição</div>
            <div>Grupo</div>
            <div>Tipo</div>
            <div>Tipo de Pagamento</div>
            <div>Operadora Credenciada</div>
            <span style={{ maxWidth: "50px" }}>Status</span>
            <div style={{ padding: "0 15px" }}></div>
          </div>
        )}
        {paymentTypeDdv.length > 0 ? (
          paymentTypeDdv?.map((tree: any) => (
            <Fragment key={tree.id}>
              <TreePDV
                paymentTypes={tree}
                OnAddNewCategorie={OnAddNewChartAccount}
                handleEdit={handleEdit}
              />
            </Fragment>
          ))
        ) : (
          <NoData />
        )}
      </div>
      {loading && <Loading />}
    </>
  );
}
