import "react-toastify/dist/ReactToastify.css";
import LayoutNotFilter from "../../../data/LayoutNotFilter";
import { AppDispatch, useAppSelector } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  getChartAccountsync,
  getPAymentTypesync,
} from "../../../../redux/features/paymentType/services";
import { Tab, Tabs } from "react-bootstrap";
import ExpenseModal from "./expenseModal";

export default function ConsultaFormaPagamento(props: any) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const dispatch = useDispatch<AppDispatch>();
  const [keyActived, setKeyActived] = useState("");

  const [key, setKey] = useState<string>(keyActived ? keyActived : "expense");

  useEffect(() => {
    dispatch(getPAymentTypesync(storeMe.storeActive.id));
    dispatch(getChartAccountsync());
  }, [dispatch]);

  return (
    <LayoutNotFilter title={props.Title + ` de pagamento`}>
      <Tabs
        defaultActiveKey="personData"
        className="mt-2"
        activeKey={key}
        onSelect={(k: any) => setKey(k)}
      >
        <Tab eventKey="expense" title="Despesa ERP" className="mx-3">
          <ExpenseModal setKeyActived={setKeyActived} />
        </Tab>
      </Tabs>
    </LayoutNotFilter>
  );
}
