import { MdFirstPage, MdLastPage, MdNavigateBefore, MdNavigateNext } from 'react-icons/md';

export interface IPagination {
   itensFrom: number;
   itensTo: number;
   totalPages: number;
   page: number;
   lastPage: number;
   setPage: React.Dispatch<React.SetStateAction<number>>;
}

export default function Pagination({
   itensFrom, itensTo, totalPages, page, lastPage, setPage
}: IPagination) {

   return (

      <div className="containerPagination">
         <div className="numberPagination">
            {itensFrom}-{itensTo} de {totalPages}
         </div>
         {page === 0 ? (
            <>
               <button disabled>
                  <MdFirstPage size={25} />
               </button>

               <button disabled>
                  <MdNavigateBefore size={25} />
               </button>
            </>
         ) : (
            <>
               <button onClick={() => setPage(0)}>
                  <MdFirstPage size={25} color="#676767" />
               </button>
               <button onClick={() => setPage(page - 1)}>
                  <MdNavigateBefore size={25} color="#676767" />
               </button>
            </>
         )}
         {page === lastPage - 1 ? (
            <>
               <button disabled>
                  <MdNavigateNext size={25} />
               </button>
               <button disabled>
                  <MdLastPage size={25} />
               </button>
            </>
         ) : (
            <>
               <button onClick={() => setPage(page + 1)}>
                  <MdNavigateNext size={25} color="#676767" />
               </button>
               <button onClick={() => setPage(lastPage - 1)}>
                  <MdLastPage size={25} color="#676767" />
               </button>
            </>
         )}
      </div>

   )
}