import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getStoreMeAsync } from "./services";
import { IProfile, IStore, IUser } from "./interface";

const userParse = localStorage.getItem("user");
const user = userParse && JSON.parse(userParse);

interface InitialState {
  value: storeMeState;
}

interface storeMeState {
  fetchError: string;
  loading: boolean;
  user: IUser;
  storeActive: IStore;
}

const initialState: InitialState = {
  value: {
    fetchError: "",
    loading: false,
    user: user || {},
    storeActive: user?.relationships?.stores[0] || {},
  } as storeMeState,
};

export const storeMe = createSlice({
  name: "storeMe",
  initialState,
  reducers: {
    getIdStore: (state: InitialState) => {
      const storeParse = localStorage?.getItem("acitvedStore");
      const store: IStore = storeParse && JSON?.parse(storeParse);
      state.value.storeActive = store;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(getStoreMeAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getStoreMeAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IUser>) => {
        state.value.loading = false;
        state.value.user = action?.payload;
        state.value.storeActive = action.payload?.relationships?.stores[0];
      }
    );
    builder.addCase(
      getStoreMeAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );
  },
});

export const { getIdStore } = storeMe.actions;

export default storeMe.reducer;
