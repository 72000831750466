// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__5qW9A {
  position: absolute;
  z-index: 99;
  min-height: 100%;
  overflow-y: auto;
  background-color: #110f23;
}

.styles_btnImage__02a4y {
  display: flex;
  align-items: center;
}

.styles_img__Rufci {
  height: 45px;
  padding-left: 8px;
  padding-top: 5px;
}

.styles_title__lR-\\+C {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.4px;
}

.styles_titleCollapsed__kqS6O {
  font-size: 12px;
  text-align: center;
}

.styles_arrow__8v7iQ {
  position: absolute;
  right: 9px;
  top: 15px;
}

.styles_hamburger__gLUY0 {
  padding: 0.5rem 1.5rem 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/menu/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,gBAAA;EACA,yBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,qBAAA;AACF;;AAEA;EACE,eAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;EACA,SAAA;AACF;;AAEA;EACE,wBAAA;AACF","sourcesContent":[".container {\n  position: absolute;\n  z-index: 99;\n  min-height: 100%;\n  overflow-y: auto;\n  background-color: #110f23;\n}\n\n.btnImage {\n  display: flex;\n  align-items: center;\n}\n\n.img {\n  height: 45px;\n  padding-left: 8px;\n  padding-top: 5px;\n}\n\n.title {\n  font-size: 13px;\n  font-weight: 700;\n  letter-spacing: 0.4px;\n}\n\n.titleCollapsed {\n  font-size: 12px;\n  text-align: center;\n}\n\n.arrow {\n  position: absolute;\n  right: 9px;\n  top: 15px;\n}\n\n.hamburger {\n  padding: 0.5rem 1.5rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__5qW9A`,
	"btnImage": `styles_btnImage__02a4y`,
	"img": `styles_img__Rufci`,
	"title": `styles_title__lR-+C`,
	"titleCollapsed": `styles_titleCollapsed__kqS6O`,
	"arrow": `styles_arrow__8v7iQ`,
	"hamburger": `styles_hamburger__gLUY0`
};
export default ___CSS_LOADER_EXPORT___;
