// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_containerFilter__CIlJX {
  background-color: white;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 1px 1px 1px -1px rgba(164, 166, 163, 0.9803921569);
  border-radius: 5px;
}
.styles_containerFilter__CIlJX h3 {
  font-size: 16px;
}
.styles_containerFilter__CIlJX button {
  font-size: 12px;
  padding: 5px 15px;
  letter-spacing: 0.5px;
}

.styles_containerTable__WYg5G {
  background-color: white;
  padding: 15px;
  box-shadow: 1px 1px 1px -1px rgba(164, 166, 163, 0.9803921569);
  border-radius: 5px;
}

.styles_line__Dt-Gi {
  border-bottom: 1px solid rgba(164, 166, 163, 0.9803921569);
  margin: 30px 0;
}

.styles_btn__dGRno {
  font-size: 12px !important;
  padding: 8px 10px !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
}

.styles_BtnPendente__n5uh5 {
  background-color: transparent;
  color: green;
  text-decoration: underline;
  border: none;
  opacity: 0.75;
}

.styles_BtnEstornar__H-bXC {
  background-color: transparent;
  color: red;
  text-decoration: underline;
  border: none;
  opacity: 0.75;
}

.styles_btnDoc__-TR\\+Q {
  background-color: transparent;
  text-decoration: underline;
  border: none;
}

.styles_wrapper__W30y3 {
  position: relative;
  z-index: 99;
}

.styles_tableOne__XxE86 {
  position: relative;
  z-index: 9;
}

.styles_tableTwo__XlO10 {
  margin-top: -52px;
  z-index: 99;
  background-color: black;
}`, "",{"version":3,"sources":["webpack://./src/Components/Cadastro/Produtos/FormProduto/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,8DAAA;EACA,kBAAA;AACF;AACE;EACE,eAAA;AACJ;AAEE;EACE,eAAA;EACA,iBAAA;EACA,qBAAA;AAAJ;;AAIA;EACE,uBAAA;EACA,aAAA;EACA,8DAAA;EACA,kBAAA;AADF;;AAIA;EACE,0DAAA;EACA,cAAA;AADF;;AAIA;EACE,0BAAA;EACA,4BAAA;EACA,2BAAA;EACA,gCAAA;AADF;;AAIA;EACE,6BAAA;EACA,YAAA;EACA,0BAAA;EACA,YAAA;EACA,aAAA;AADF;;AAIA;EACE,6BAAA;EACA,UAAA;EACA,0BAAA;EACA,YAAA;EACA,aAAA;AADF;;AAIA;EACE,6BAAA;EACA,0BAAA;EACA,YAAA;AADF;;AAIA;EACE,kBAAA;EACA,WAAA;AADF;;AAIA;EACE,kBAAA;EACA,UAAA;AADF;;AAIA;EACE,iBAAA;EACA,WAAA;EACA,uBAAA;AADF","sourcesContent":[".containerFilter {\n  background-color: white;\n  padding: 15px;\n  margin-bottom: 10px;\n  box-shadow: 1px 1px 1px -1px #a4a6a3fa;\n  border-radius: 5px;\n\n  h3 {\n    font-size: 16px;\n  }\n\n  button {\n    font-size: 12px;\n    padding: 5px 15px;\n    letter-spacing: 0.5px;\n  }\n}\n\n.containerTable {\n  background-color: white;\n  padding: 15px;\n  box-shadow: 1px 1px 1px -1px #a4a6a3fa;\n  border-radius: 5px;\n}\n\n.line {\n  border-bottom: 1px solid #a4a6a3fa;\n  margin: 30px 0;\n}\n\n.btn {\n  font-size: 12px !important;\n  padding: 8px 10px !important;\n  font-weight: 500 !important;\n  letter-spacing: 0.5px !important;\n}\n\n.BtnPendente {\n  background-color: transparent;\n  color: green;\n  text-decoration: underline;\n  border: none;\n  opacity: 0.75;\n}\n\n.BtnEstornar {\n  background-color: transparent;\n  color: red;\n  text-decoration: underline;\n  border: none;\n  opacity: 0.75;\n}\n\n.btnDoc {\n  background-color: transparent;\n  text-decoration: underline;\n  border: none;\n}\n\n.wrapper {\n  position: relative;\n  z-index: 99;\n}\n\n.tableOne {\n  position: relative;\n  z-index: 9;\n}\n\n.tableTwo {\n  margin-top: -52px;\n  z-index: 99;\n  background-color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerFilter": `styles_containerFilter__CIlJX`,
	"containerTable": `styles_containerTable__WYg5G`,
	"line": `styles_line__Dt-Gi`,
	"btn": `styles_btn__dGRno`,
	"BtnPendente": `styles_BtnPendente__n5uh5`,
	"BtnEstornar": `styles_BtnEstornar__H-bXC`,
	"btnDoc": `styles_btnDoc__-TR+Q`,
	"wrapper": `styles_wrapper__W30y3`,
	"tableOne": `styles_tableOne__XxE86`,
	"tableTwo": `styles_tableTwo__XlO10`
};
export default ___CSS_LOADER_EXPORT___;
