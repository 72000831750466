import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";

import IconCadastroB from "../../images/icons_branco/cadastro_branco.svg";
import IconEstoqueB from "../../images/icons_branco/estoque_branco.svg";
import IconVendasB from "../../images/icons_branco/vendas_branco.svg";
import IconFinanceiroB from "../../images/icons_branco/financeiro_branco.svg";
import IconConfigB from "../../images/icons_branco/config_branco.svg";
import IconRelatorioB from "../../images/icons_branco/Relatorios_branco.svg";
import IconHomeB from "../../images/icons_branco/home_branco.svg";

import styles from "../styles.module.scss";
import { useContext } from "react";

import { Link, useHistory } from "react-router-dom";
import globals from "../../../Globals.json";
import { ExtratoContext } from "../../Financeiro/ExtratoBancario/context/extrato-bancario-context";
import { MovimentacaoContext } from "../../Financeiro/MovimentacaoBancaria/context/movimentacao-bancario-context";
import { ThemeContext } from "../../../context/theme-context";
import {
  FaAngleLeft,
  FaCashRegister,
  FaChartPie,
  FaHome,
  FaUsers,
} from "react-icons/fa";
import { FaBoxesPacking } from "react-icons/fa6";
import { HiBuildingStorefront } from "react-icons/hi2";
import { PiBankFill } from "react-icons/pi";
import { RiMenuUnfoldLine } from "react-icons/ri";
import { CgMenuGridR } from "react-icons/cg";

export function MenuStore() {
  const router = useHistory();
  const regex = /^\/dashboard\/([^/]+)/;
  const match: any = router && router?.location?.pathname?.match(regex);
  const pathname = match ? match[1] : "dashboard";
  const { handleShowExtrato } = useContext(ExtratoContext);
  const { handleShowMovimentacao } = useContext(MovimentacaoContext);
  const { collapsed, handleCollapsed } = useContext(ThemeContext);

  const backgroundColor = pathname === "dashboard" ? "#5467c4" : "";
  const backgroundColorCad = pathname === "cadastro" ? "#5467c4" : "";
  const backgroundColorEst = pathname === "estoque" ? "#5467c4" : "";
  const backgroundColorVen = pathname === "vendas" ? "#5467c4" : "";
  const backgroundColorFin = pathname === "financeiro" ? "#5467c4" : "";
  const backgroundColorCon = pathname === "configuracao" ? "#5467c4" : "";
  const backgroundColorRel = pathname === "relatorio" ? "#5467c4" : "";
  const collapsedMargin = collapsed ? "1rem" : "3.4rem";
  const width = collapsed ? "88px" : "";
  const margin = collapsed ? "0 0.2rem" : "";
  const paddingLeft = collapsed ? "7px" : "";

  return (
    <div className={styles.container}>
      <ProSidebar
        collapsed={collapsed}
        //breakPoint="md"
        style={{ width, margin }}
      >
        <SidebarContent>
          <Menu iconShape="circle">
            <div
              className={styles.btnImage}
              style={{ marginBottom: collapsedMargin }}
            >
              <button
                onClick={handleCollapsed}
                type="button"
                style={{ border: "none", background: "none", paddingLeft }}
              >
                {!collapsed ? (
                  <div className={styles.arrow}>
                    <FaAngleLeft style={{ color: "#f0f0f0" }} size={30} />
                  </div>
                ) : (
                  <div className={styles.hamburger}>
                    <CgMenuGridR size={30} color="white" />
                  </div>
                )}
              </button>
            </div>
            <MenuItem
              style={{ fontSize: "12px", backgroundColor, paddingLeft }}
              icon={<FaHome size={24} />}
            >
              DASHBOARD
              <Link to={globals.paths.home} />
            </MenuItem>
            {collapsed && (
              <div className="title-collapsed" style={{ backgroundColor }}>
                DASHBOARD
              </div>
            )}
            <SubMenu
              placeholder=""
              style={{
                fontSize: "12px",
                backgroundColor: backgroundColorCad,
                paddingLeft,
              }}
              title="CADASTRO"
              icon={<FaUsers size={24} />}
            >
              <span>Pessoa</span>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Clientes
                <Link to={globals.paths.cadClientes} />
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Fornecedores
                <Link to={globals.paths.cadFornecedores} />
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Funcionários
                <Link to={globals.paths.cadFuncionarios} />
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Transportadoras
                <Link to={globals.paths.cadTransportadoras} />
              </MenuItem>
              <span>Produtos</span>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Produtos
                <Link to={globals.paths.CadProdutos} />
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Fichas técnicas
                <Link to={globals.paths.CadFichaTecnica} />
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Produção
                <Link to={globals.paths.CadProducao} />
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Unidades de Medida
                <Link to={globals.paths.CadUnidMedida} />
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Categorias
                <Link to={globals.paths.CadCatProdutos} />
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Perfis Fiscais
                <Link to={globals.paths.CadPerfilFiscal} />
              </MenuItem>

              <span>Sincronização</span>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Tabela de Preços
                <Link to={globals.paths.CadTabelaPreco} />
              </MenuItem>
            </SubMenu>
            {collapsed && (
              <div
                className="title-collapsed"
                style={{ backgroundColor: backgroundColorCad }}
              >
                CADASTRO
              </div>
            )}

            {/* Grupo Estoque */}
            <SubMenu
              placeholder=""
              style={{
                fontSize: "12px",
                backgroundColor: backgroundColorEst,
                paddingLeft,
              }}
              title="ESTOQUE"
              icon={<FaBoxesPacking size={24} />}
            >
              <span>Movimentações</span>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Movimentações
                <Link to={globals.paths.cadMovimentacoes} />
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Transferências
                <Link to={globals.paths.cadTransferencias} />
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Orçamentos
                <Link to={globals.paths.cadOrcamentos} />
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Posição de estoque
                <Link to={globals.paths.cadPosicoes} />
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Ajuste de estoque
                <Link to={globals.paths.cadAjustes} />
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Tipos de movimentações
                <Link to={globals.paths.cadTiposMovimentacoes} />
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Inventário
                <Link to={globals.paths.cadInventario} />
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Contagem de estoque
                <Link to={globals.paths.cadContagem} />
              </MenuItem>
              <span>Arquivos</span>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Exportar inventário
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Importar inventário
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Livro de inventário
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Markup de produtos
              </MenuItem>

              <span>Configurações</span>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Locais de estoque
                <Link to={globals.paths.cadLocaisEstoque} />
              </MenuItem>
            </SubMenu>
            {collapsed && (
              <div
                className="title-collapsed"
                style={{ backgroundColor: backgroundColorEst }}
              >
                ESTOQUE
              </div>
            )}

            {/* Grupos Vendas */}
            <SubMenu
              placeholder=""
              style={{
                fontSize: "12px",
                backgroundColor: backgroundColorVen,
                paddingLeft,
              }}
              title="VENDAS"
              icon={<FaCashRegister size={24} />}
            >
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Faturamento
              </MenuItem>
              <span>Caixa</span>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Listagem de Vendas
                <Link to={globals.paths.CadVendas} />
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Fechamento de Caixa
                {/* <Link to={globals.paths} /> */}
              </MenuItem>
            </SubMenu>
            {collapsed && (
              <div
                className="title-collapsed"
                style={{ backgroundColor: backgroundColorVen }}
              >
                VENDAS
              </div>
            )}
            {/* Grupos Financeiro */}
            <SubMenu
              placeholder=""
              style={{
                fontSize: "12px",
                backgroundColor: backgroundColorFin,
                paddingLeft,
              }}
              title="FINANCEIRO"
              icon={<PiBankFill size={24} />}
            >
              <span>Movimentações</span>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Receitas
                <Link to={globals.paths.cadTransacoesReceitas} />
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Despesas
                <Link to={globals.paths.cadTransacoesDespesas} />
              </MenuItem>
              <span>Relatórios</span>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Fluxo de caixa
              </MenuItem>
              <MenuItem
                style={{ fontSize: "12px" }}
                onClick={handleShowExtrato}
              >
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Extrato bancário
              </MenuItem>
              <MenuItem
                style={{ fontSize: "12px" }}
                onClick={handleShowMovimentacao}
              >
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Movimentação Bancária
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Imprimir recibo
                <Link to={globals.paths.cadImprimirRecibos} />
              </MenuItem>

              <span>Configurações</span>

              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Categorias
                <Link to={globals.paths.cadCategoriaMovimentacao} />
              </MenuItem>

              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Formas de Recebimento
                <Link to={globals.paths.cadFormaRecebimento} />
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Formas de Pagamento
                <Link to={globals.paths.cadFormaPagamento} />
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Contas bancárias
                <Link to={globals.paths.cadBankAccounts} />
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Plano de contas
                <Link to={globals.paths.cadPlanoContas} />
              </MenuItem>
            </SubMenu>
            {collapsed && (
              <div
                className="title-collapsed"
                style={{ backgroundColor: backgroundColorFin }}
              >
                FINANCEIRO
              </div>
            )}
            {/* Grupos CONFIGURAÇÕES */}
            <SubMenu
              placeholder=""
              style={{
                fontSize: "12px",
                backgroundColor: backgroundColorCon,
                paddingLeft,
              }}
              title="CONFIGURAÇÕES"
              icon={<HiBuildingStorefront size={24} />}
            >
              <span>Fiscal</span>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Certificado digital
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Grupos fiscais
              </MenuItem>
              <span>Empresas</span>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Lojas
                <Link to={globals.paths.cadStore} />
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Acesso as lojas
                <Link to={globals.paths.accessStores} />
              </MenuItem>

              <span>Sistema</span>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Preferências
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Usuários
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Perfis de usuários
              </MenuItem>
            </SubMenu>
            {collapsed && (
              <div
                className="title-collapsed"
                style={{ backgroundColor: backgroundColorCon }}
              >
                CONFIGURAÇÕES
              </div>
            )}
            {/* Grupos RELATÓRIOS */}
            <SubMenu
              placeholder=""
              style={{
                fontSize: "12px",
                backgroundColor: backgroundColorRel,
                paddingLeft,
              }}
              title="RELATÓRIOS"
              icon={<FaChartPie size={24} />}
            >
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Vendas
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Financeiros
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Fiscais
              </MenuItem>
              <MenuItem style={{ fontSize: "11px" }}>
                <span
                  className="bullet bullet-dot"
                  style={{ marginRight: 18 }}
                ></span>
                Estoques
              </MenuItem>
            </SubMenu>
            {collapsed && (
              <div
                className="title-collapsed"
                style={{ backgroundColor: backgroundColorRel }}
              >
                RELATÓRIOS
              </div>
            )}
          </Menu>
        </SidebarContent>

        {/* <SidebarFooter style={{ textAlign: "center" }}></SidebarFooter>*/}
      </ProSidebar>
    </div>
  );
}
