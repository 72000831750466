import { Button, Col, Row, Tab, Tabs } from "react-bootstrap";
import { InputHook } from "../../../../../reactHookForm/InputHook";
import { SelectHook } from "../../../../../reactHookForm/SelectHook";
import { Dispatch, SetStateAction, useState } from "react";
import { InputHookCnpj } from "../../../../../reactHookForm/InputHookCnpj";
import { InputHookCep } from "../../../../../reactHookForm/InputHookCep";
import { StateForm } from "../../../../../../interfaces/state-form";
import { InputButton } from "../../../../../reactHookForm/inputButton";

interface IProps {
  register: any;
  control: any;
  watch: any;
  selectOptions: any;
  setValue: any;
  setError: any;
  errors: any;
  setStateForm: Dispatch<SetStateAction<StateForm>>;
  reset?: any;
  data: any;
  setUserActived: any;
  userActived: any;
}

export function InputForm({
  register,
  control,
  watch,
  selectOptions,
  setValue,
  setError,
  errors,
  reset,
  data,
  setStateForm,
  setUserActived,
  userActived,
}: IProps) {
  const [key, setKey] = useState("personData");

  return (
    <>
      <Tabs
        defaultActiveKey="personData"
        className="mt-2"
        activeKey={key}
        onSelect={(k: any) => setKey(k)}
      >
        <Tab eventKey="personData" title="Dados Pessoais" className="mx-3">
          <Row>
            <Col md="3">
              <InputHookCnpj
                register={register}
                watch={watch}
                setValue={setValue}
                setError={setError}
                errors={errors}
                reset={reset}
                setStateForm={setStateForm}
                label="CNPJ"
                mask="99.999.999/9999-99"
                name="cnpj"
                placeholder="Informe o cnpj"
                dataEdit={data}
                defaultValue={data.cnpj}
              />
            </Col>
            <Col md="4">
              <InputHook
                register={register}
                errors={errors}
                label="Nome"
                name="name"
                placeholder="Informe o nome"
                type="text"
              />
            </Col>
            <Col md="3">
              <InputHook
                register={register}
                errors={errors}
                label="E-mail"
                name="email"
                placeholder="Informe o e-mial"
                type="text"
              />
            </Col>
            <Col md="2">
              <InputButton
                register={register}
                errors={errors}
                setValue={setValue}
                stateBtn={userActived}
                setStateBtn={setUserActived}
                isTextTrue="Ativo"
                isTextFalse="Inativo"
                label="Status"
                name="status"
              />
            </Col>
          </Row>
        </Tab>

        <Tab eventKey="adressData" title="Endereço">
          <Row>
            <Col md="4">
              <InputHookCep
                register={register}
                watch={watch}
                setValue={setValue}
                setError={setError}
                errors={errors}
                setStateForm={setStateForm}
                label="Cep"
                name="cep"
                type="number"
                mask="99999-999"
                placeholder="Informe o cep"
                dataEdit={data}
                defaultValue={data?.cep}
              />
            </Col>
            <Col md="4">
              <InputHook
                register={register}
                errors={errors}
                label="Endereço"
                name="address_street"
                type="text"
                placeholder="Informe o endereço"
              />
            </Col>
            <Col md="2">
              <InputHook
                register={register}
                errors={errors}
                label="Número"
                name="address_number"
                type="text"
                placeholder="Informe o número"
              />
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <InputHook
                register={register}
                errors={errors}
                label="Complemento"
                name="address_complement"
                type="text"
                placeholder="Informe o complemento"
              />
            </Col>
            <Col md="4">
              <InputHook
                register={register}
                errors={errors}
                label="Bairro"
                name="address_district"
                type="text"
                placeholder="Informe o bairro"
              />
            </Col>
            <Col md="3">
              <InputHook
                register={register}
                errors={errors}
                label="Cidade"
                name="city"
                type="text"
                placeholder="Informe a cidade"
                disabled
              />
            </Col>
            <Col md="2">
              <InputHook
                register={register}
                errors={errors}
                label="Estado"
                name="state"
                type="text"
                placeholder="Informe o estado"
                disabled
              />
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="contactData" title="Contato">
          <Row>
            <Col md="3">
              <InputHook
                register={register}
                errors={errors}
                type="number"
                label="Telefone"
                name="phone"
                mask="(99) 9999-9999"
                placeholder="Informe o telefone"
              />
            </Col>
            <Col md="3">
              <InputHook
                register={register}
                errors={errors}
                type="number"
                label="Celular"
                name="cellPhone"
                mask="(99) 99999-9999"
                placeholder="Informe o celular"
              />
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </>
  );
}
