import { Button } from "react-bootstrap";
import * as S from "./styles";
import InputMask from "react-input-mask";
import { useEffect } from "react";

interface IProps {
  autoComplete?: "on" | "off";
  type?: "string" | "number" | "date" | "text" | "email" | "password";
  placeholder?: string;
  maxLength?: number;
  label?: string;
  step?: string;
  min?: string;
  max?: string;
  disabled?: boolean;
  isInvalid?: boolean;
  size?: "sm" | "lg";
  as?: any;
  rows?: number;
  name: string;
  mask?: string;
  defaultValue?: any;
  errors?: any;
  register: any;
  stateBtn: any;
  setStateBtn: any;
  isTextTrue: string;
  isTextFalse: string;
  setValue?: any;
}

export function InputButton({
  label,
  type,
  autoComplete,
  placeholder,
  maxLength,
  step,
  min,
  max,
  disabled,
  as,
  rows,
  name,
  mask,
  defaultValue,
  register,
  errors,
  stateBtn,
  setStateBtn,
  isTextTrue,
  isTextFalse,
  setValue,
}: IProps) {
  const errorToDisplay = errors
    ? Object?.keys(errors[0])?.find((key) => key === name)
    : "";

  const handleValue = () => {
    setStateBtn(!stateBtn);

    setValue && setValue(name, stateBtn === true ? 1 : 0);
  };

  useEffect(() => {
    setValue && setValue(name, stateBtn === true ? 1 : 0);
  }, []);

  return (
    <label>
      <div className="label">{label}</div>
      <S.Input
        errorToDisplay={
          errorToDisplay && errors[0][errorToDisplay]?.message.length > 0
        }
      >
        <Button
          style={{ marginRight: "20px" }}
          className={`px-4 py-1 border border-1  ${
            stateBtn
              ? "bg-success text-white"
              : "border border-1 bg-danger text-white"
          }`}
          variant="withe"
          onClick={handleValue}
          type="button"
        >
          {stateBtn ? isTextTrue : isTextFalse}
        </Button>
        <S.FormInput
          {...register(name)}
          name={name}
          as={as}
          type={type}
          autoComplete={autoComplete}
          placeholder={placeholder}
          maxLength={maxLength}
          step={step}
          min={min}
          max={max}
          disabled={disabled}
          rows={rows}
        />

        {errorToDisplay && (
          <S.Error>{errors[0][errorToDisplay]?.message}</S.Error>
        )}
      </S.Input>
    </label>
  );
}
