// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row{
    margin-top: 50px;
}
.controls{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin:0 30px 0 30px; 

}
details {
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: .5em .5em 0;
}

summary {
    display: flex !important;
    align-items: center;
    border-radius: 5px;
    height: 60px;
    background-color: #FFFFFF;
    margin: -.5em -.5em 0;
    padding: .5em 3em;
}
summary i {
    margin-top: -15px;
    margin-left: 10px;
    color: #1E1E2D;
    font-size: 2em;
}

details[open] {
    background-color: #FFFFFF;
    padding: .5em;
}

details[open] summary {
    margin-bottom: .5em;
}

summary::marker {
    content: '+';
}

.card {
    margin-bottom: 20px;
    border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Cadastro/Produtos/FormProduto/styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,oBAAoB;;AAExB;AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,wBAAwB;IACxB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,qBAAqB;IACrB,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".row{\n    margin-top: 50px;\n}\n.controls{\n    display: flex;\n    justify-content: space-between;\n    padding: 20px;\n    margin:0 30px 0 30px; \n\n}\ndetails {\n    background-color: #FFFFFF;\n    border-radius: 5px;\n    padding: .5em .5em 0;\n}\n\nsummary {\n    display: flex !important;\n    align-items: center;\n    border-radius: 5px;\n    height: 60px;\n    background-color: #FFFFFF;\n    margin: -.5em -.5em 0;\n    padding: .5em 3em;\n}\nsummary i {\n    margin-top: -15px;\n    margin-left: 10px;\n    color: #1E1E2D;\n    font-size: 2em;\n}\n\ndetails[open] {\n    background-color: #FFFFFF;\n    padding: .5em;\n}\n\ndetails[open] summary {\n    margin-bottom: .5em;\n}\n\nsummary::marker {\n    content: '+';\n}\n\n.card {\n    margin-bottom: 20px;\n    border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
