// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_icon__NdMe- {
  font-weight: bolder;
  margin-right: 5px;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/Components/data/Btn/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,iBAAA;EACA,eAAA;AACF","sourcesContent":[".icon {\n  font-weight: bolder;\n  margin-right: 5px;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `styles_icon__NdMe-`
};
export default ___CSS_LOADER_EXPORT___;
