import { ChangeStores } from "../ChangeStores";

interface IProps {
  user: any;
}

export function InputClone({ user }: IProps) {
  return (
    <>
      <ChangeStores user={user} />
    </>
  );
}
