import { Form } from "react-bootstrap";
import styled from "styled-components";

interface IProps {
  errorToDisplay: any;
}

export const Input = styled.div<IProps>`
  margin: 0.2rem 1rem;
  display: grid;

  label {
    display: flex;
    gap: 1rem;
    span {
      font-size: 12px;
      width: auto;
    }

    input {
      height: 16px;
      text-align: start;
    }
  }
`;

export const Error = styled.div`
  font-size: 11px;
  color: #dc2229;
  margin-top: 0.2rem;
`;
