import globals from "../Globals.json";
import api from "./api";

export const createQuote = (objQuote: any) => {
  if (objQuote.uuid) {
    return api
      .put(`${globals.api.stockQuotes}/${objQuote.uuid}`, objQuote)
      .then((res: any) => {
        if (res.status === 201 || res.status === 200) {
          const objCallback = {
            data: res.data.data,
            status: true,
          };
          return objCallback;
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  } else {
    return api
      .post(`${globals.api.stockQuotes}`, objQuote)
      .then((res: any) => {
        if (res.status === 201 || res.status === 200) {
          const objCallback = {
            data: res.data.data,
            status: true,
          };
          return objCallback;
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }
};
