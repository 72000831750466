// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-cad-medida {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.actions {
  display: flex;
  justify-content: space-around;
}

.action {
  cursor: pointer;
}

.btn {
  color: #8e8d8d;
}

#excludeMsg {
  margin-top: 20px;
  font-weight: 300;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Cadastro/CadUnidMedida/styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,kBAAA;AACJ;;AAGA;EACE,aAAA;EACA,6BAAA;AAAF;;AAEA;EACE,eAAA;AACF;;AACA;EACE,cAAA;AAEF;;AAAA;EACE,gBAAA;EACA,gBAAA;EACA,eAAA;AAGF","sourcesContent":[".header-cad-medida{\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin-bottom: 5px;\r\n    // margin: -20px 0 20px 0;\r\n}\r\n\r\n.actions {\r\n  display: flex;\r\n  justify-content: space-around;\r\n}\r\n.action {\r\n  cursor: pointer;\r\n}\r\n.btn {\r\n  color: #8e8d8d;\r\n}\r\n#excludeMsg {\r\n  margin-top: 20px;\r\n  font-weight: 300;\r\n  font-size: 14px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
