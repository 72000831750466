import React, { useEffect, useState, useRef } from "react";
import styles from "./styles.module.scss";
import Button from "react-bootstrap/Button";
import DataTable, { SortOrder } from "react-data-table-component";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Badge from "react-bootstrap/Badge";
import Tooltip from "react-bootstrap/Tooltip";
import Format from "../../../../Services/Format";
import { FaEdit } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loading from "../../../Loading/Loading";

import api from "../../../../Services/api";
import Globals from "../../../../Globals.json";

import DateUtils from "../../../../Services/DateUtils";
import LayoutNotFilter from "../../../data/LayoutNotFilter";
import tableStyleDefault from "../../../../Services/tableStyleDefault";
import { NoData } from "../../../noData";
import ActiveInactiv from "../../../data/activeInactive";
import CustomLoader from "../../../data/customLoader";
import { useAppSelector } from "../../../../redux/store";
import { sortByCreated } from "../../../../Services/sortByCreated";

export default function ConsultaTipoMovimentacao(props: any) {
  const ref = useRef<any>();
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [typeMovement, setTypeMovement] = useState<Array<any>>([]);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const [type, setType] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [startDate, setStartDate] = useState<string>(
    Format.date(new Date(), "YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(
    Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD")
  );

  // Lists
  const [listType, setListType] = useState<Array<any>>([]);
  const [listDescription, setListDescription] = useState<Array<any>>([]);
  const [listIsActive, setListIsActive] = useState<Array<any>>([]);

  const handleEdit = (row: any) => {
    if (typeof props.onEditItem === "function" && row !== undefined) {
      props.onEditItem(row);
    }
  };

  const clearFields = (e: any) => {
    ref.current.clear();
    setType("");
    setDescription("");
    setStartDate(Format.date(new Date(), "YYYY-MM-DD"));
    setEndDate(Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD"));
    setListType([]);
    setListDescription([]);
    setListIsActive([]);
    getTypeMovement();
  };

  const handlePageChange = (page: any) => {
    getTypeMovement(page);
  };

  const columns: any = [
    {
      name: "Descrição",
      sortable: true,
      selector: (row: any) => row.description.toLowerCase(),
    },
    {
      name: "Tipo",
      center: true,
      grow: 0.3,
      selector: (row: any) => row.type.toLowerCase(),
    },
    {
      name: "Data de cadastro",
      center: true,
      grow: 0.3,
      selector: (row: any) =>
        Format.date(DateUtils.toDate(row.created_at), "DD/MM/YYYY"),
    },
    {
      name: "Status",
      center: true,
      grow: 0.2,
      selector: (row: any) =>
        row.is_active ? (
          <ActiveInactiv status={row.is_active} text="Ativo" />
        ) : (
          <ActiveInactiv status={row.is_active} text="Inativo" />
        ),
    },

    {
      name: "Ação",
      center: true,
      grow: 0.2,
      cell: (row: any) => (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">Editar</Tooltip>}
        >
          <span className="d-inline-block">
            <Button
              onClick={() => handleEdit(row)}
              variant="contained"
              color="primary"
            >
              <FaEdit size={18} color="8e8d8d" />
            </Button>
          </span>
        </OverlayTrigger>
      ),
      button: true,
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Registros por página",
    rangeSeparatorText: "de",
  };

  const handleAdd = () => {
    props.OnAddNewCli(true);
  };

  const fillProperties = (data: any) => {
    const meta = data.meta;
    const perpage =
      data.data.length < meta.per_page ? data.data.length : meta.per_page;
    setPerPage(perpage);
    setTotalRows(meta.total);
    setLastPage(meta.last_page);
    setTypeMovement(data.data);
  };

  const getTypeMovement = (filter?: string) => {
    const uri =
      filter !== undefined
        ? `${filter}&paginate=false&store_id=${storeMe.storeActive.id}`
        : `paginate=false&store_id=${storeMe.storeActive.id}`;

    setLoading(true);
    api
      .get(Globals.api.movementsTypes + `?${uri}`)
      .then((response) => {
        const data = response.data;

        setTypeMovement(data.data);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTypeMovement();
  }, []);
  return (
    <>
      <LayoutNotFilter title={props.Title} onClickAdd={handleAdd}>
        <div className="mt-3">
          <DataTable
            className={styles.dataTable}
            columns={columns}
            data={sortByCreated(typeMovement) || []}
            striped
            pagination
            paginationDefaultPage={1}
            paginationPerPage={25}
            customStyles={tableStyleDefault}
            paginationComponentOptions={paginationOptions}
            progressPending={loading}
            progressComponent={<CustomLoader />}
            noDataComponent="Nenhuma informação foi encontrada"
          />
        </div>
      </LayoutNotFilter>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
