import { useRef, useState } from "react";
import { StateForm } from "../../interfaces/state-form";

import * as Yup from "yup";

import { InputForm } from "../../Components/data/inputsWeb/Input";
import Spinner from "../../Components/spinner/Spinner";
import * as S from "./styles";
import { YupValidation } from "../../Services/yup-validation";
import { Link, useHistory } from "react-router-dom";
import Globals from "../../Globals.json";
import { forgotSchema } from "../../schemas/forgotSchema";
import api from "../../Services/api";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputHook } from "../../Components/reactHookForm/InputHook";
import { InputHookDefal } from "../../Components/reactHookForm/InputHookDefal";

export const createPasswordSchema = Yup.object().shape({
  password: Yup.string().required("Por favor, insira uma senha."),
  password_confirmation: Yup.string()
    .required("Por favor, confirme a senha.")
    .oneOf([Yup.ref<any>("password"), null], "As senhas devem ser iguais."),
});

interface IFormInput {}
type FormData = Yup.InferType<typeof createPasswordSchema>;

interface ILogin {
  email: string;
}

export default function Recovery() {
  const history = useHistory();
  const token: string = history.location.search.slice(7);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(createPasswordSchema),
  });
  const [stateForm, setStateForm] = useState<StateForm>({
    state: "default",
    message: "",
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data: any) => {
    try {
      setStateForm({ state: "loading" });

      const dataObj = {
        token,
        password: data.password,
        password_confirmation: data.password_confirmation,
      };

      const res = await api.post(Globals.api.returnResetPassword, dataObj);
      //history.push(Globals.paths.login);
      console.log(res);
      setStateForm({ state: "send" });
    } catch (err: any) {
      const erroAxios = err?.response?.data?.errors;
      const erroHandler = erroAxios ? Object.values(erroAxios)[0] : err;

      if (typeof erroHandler === "string") {
        const parseError = JSON.parse(erroHandler);

        setStateForm({
          state: "error",
          message:
            parseError.cause[0].message || "api_error_please_contact_support",
        });

        setStateForm({ state: "default" });
        return;
      }

      setStateForm({ state: "default" });
    }
  };

  const inputError = [errors];

  return (
    <S.Container>
      <S.Login>
        <S.Card>
          <h2>Alterar senha</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            {stateForm.state === "error" && <p>{stateForm.message}</p>}
            <InputHookDefal
              label="Nova senha"
              name="password"
              type="password"
              placeholder="Senha"
              register={register}
              errors={inputError}
            />
            <InputHookDefal
              label="Confirmar senha"
              name="password_confirmation"
              type="password"
              placeholder="Confirmar senha"
              register={register}
              errors={inputError}
            />
            {
              <S.Button>
                {stateForm.state === "loading" ? (
                  <S.PositionSpinner>
                    <Spinner button />
                  </S.PositionSpinner>
                ) : (
                  "Enviar"
                )}
              </S.Button>
            }
            <div className="forgot">
              <span>
                <Link to={Globals.paths.login}>Voltar para login</Link>
              </span>
            </div>
          </form>
        </S.Card>
      </S.Login>
    </S.Container>
  );
}
