import { createContext, useContext, useState } from "react";
import { LoginApi } from "./services";
import { AuthContextType, Store, User } from "./interfaces";
import api from "../../Services/api";
import globals from "./../../Globals.json";

interface IStoreActive {
  storeId: number;
  uuid: string;
  name: string;
}

const AuthContext = createContext({} as AuthContextType);

function AuthProvider({ children }: any) {
  const [userData, setUserData] = useState<any>();
  const [storeActived, setStoreActived] = useState(
    localStorage.getItem("storeActive")
  );

  const { auth } = new LoginApi();

  async function getMe() {
    const dataUser = localStorage?.getItem("user");

    dataUser && setUserData(JSON.parse(dataUser));
  }

  async function signIn(payload: { email: string; password: string }) {
    try {
      await auth(payload);
    } catch (e: any) {
      throw e;
    }
  }

  const handleActiveStore = (uuid: string) => {
    const stores = userData && userData?.relationships.stores;
    const storeSelected = stores?.find((item: Store) => item.uuid === uuid);

    const storeActived = {
      storeId: storeSelected?.id,
      uuid: storeSelected?.uuid,
      name: storeSelected?.company_name,
    };

    localStorage.setItem("storeActive", JSON.stringify(storeActived));

    setTimeout(() => {
      setStoreActived(JSON.stringify(storeActived));
    }, 1500);
  };

  return (
    <AuthContext.Provider
      value={{
        user: userData ? userData : null,
        storeActive: storeActived ? JSON.parse(storeActived) : null,
        signIn,
        handleActiveStore,
        getMe,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth() must be used within a AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };
