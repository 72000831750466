import { Dispatch, SetStateAction, useEffect } from "react";
import * as S from "./styles";
import InputMask from "react-input-mask";
import { Regex } from "../../../utils/Regex";
import { StateForm } from "../../../interfaces/state-form";
import { Services } from "../../../Services/newService";
import { CheckCPF } from "../../../utils";
import { userCPF, userDataByCPF } from "../../../Services/utilitiesService";

interface IProps {
  autoComplete?: "on" | "off";
  type?: "string" | "number" | "date" | "text" | "email" | "password";
  placeholder?: string;
  maxLength?: number;
  label?: string;
  step?: string;
  min?: string;
  max?: string;
  disabled?: boolean;
  isInvalid?: boolean;
  size?: "sm" | "lg";
  as?: any;
  rows?: number;
  name: string;
  mask?: string;
  defaultValue?: any;
  register: any;
  watch: any;
  setValue: any;
  setError: any;
  errors?: any;
  setStateForm: Dispatch<SetStateAction<StateForm>>;
  reset?: any;
  isEditable?: boolean;
  dataEdit?: any;
  path: string;
}

export function InputHookCpf({
  label,
  type,
  autoComplete,
  placeholder,
  maxLength,
  step,
  min,
  max,
  disabled,
  size,
  as,
  rows,
  name,
  mask,
  defaultValue,
  register,
  watch,
  setValue,
  setError,
  errors,
  setStateForm,
  reset,
  isEditable,
  dataEdit,
  path,
}: IProps) {
  let cpfValue = watch("cpf_cnpj") ?? "";

  async function CallCPF(cnpj: any) {
    try {
      if (isEditable) return;
      setStateForm({ state: "loading" });

      const clearCpf = Regex.removeCharacters(cpfValue);

      const existPeople = [];

      const existCnpjCustomer: any = await Services.checkIfCpfExistsPeople(
        clearCpf,
        "customer"
      );

      const existCnpjVendor: any = await Services.checkIfCpfExistsPeople(
        clearCpf,
        "vendor"
      );

      const existCnpjEmployee: any = await Services.checkIfCpfExistsPeople(
        clearCpf,
        "employee"
      );

      const existCnpjCarrier: any = await Services.checkIfCpfExistsPeople(
        clearCpf,
        "carrier"
      );

      if (existCnpjCustomer?.data.data.length > 0) {
        existPeople.push("cliente");
      }

      if (existCnpjVendor?.data.data.length > 0) {
        existPeople.push("fornecedor");
      }
      if (existCnpjEmployee?.data.data.length > 0) {
        existPeople.push("funcionário");
      }
      if (existCnpjCarrier?.data.data.length > 0) {
        existPeople.push("transportadora");
      }

      const people = existPeople.map((item: string) => item);

      if (existPeople?.length > 0) {
        setError("cpf_cnpj", {
          type: "custom",
          message: `CPF cadastrado em ${people}`,
        });
        setStateForm({ state: "default" });
        return;
      }

      const { data } = await userCPF(cnpj || 0);

      if (data.status === true) {
        setValue("name", data?.result?.nome_da_pf);
        let bird = Regex.converterDataParaPadraoUSA(
          data?.result?.data_nascimento
        );

        setValue("birthdate", bird);
      } else {
        !CheckCPF(cpfValue) &&
          setError("cpf_cnpj", {
            type: "custom",
            message: "CPF não encontrado.",
          });
      }

      setStateForm({ state: "default" });
    } catch (err: any) {
      setStateForm({ state: "default" });
      return;
    }
  }

  useEffect(() => {
    if (dataEdit?.cpf_cnpj === cpfValue) return;

    const clearCpf = Regex.removeCharacters(cpfValue);

    if (clearCpf?.length === 11) {
      !CheckCPF(cpfValue)
        ? setError("cpf_cnpj", {
            type: "custom",
            message: "CPF inválido.",
          })
        : setError("cpf_cnpj", {
            type: "custom",
            message: "",
          });

      (async () => {
        await CallCPF(clearCpf);
      })();
    }

    if (clearCpf?.length === 0) {
      setValue("cpf_cnpj", "");
    }
  }, [cpfValue, setError, setValue]);

  const errorToDisplay = errors
    ? Object?.keys(errors[0])?.find((key) => key === name)
    : "";

  return (
    <label>
      <div className="label">{label}</div>
      <S.Input
        errorToDisplay={
          errorToDisplay && errors[0][errorToDisplay]?.message.length > 0
        }
      >
        {mask ? (
          <InputMask
            className="inputMask"
            {...register(name)}
            mask={mask}
            defaultValue={defaultValue ?? " "}
            name={name}
            type="text"
            disabled={disabled}
            autoComplete="off"
            placeholder={placeholder}
            step={step}
            min={min}
            max={max}
          />
        ) : (
          <S.FormInput
            {...register(name)}
            defaultValue={defaultValue ?? " "}
            name={name}
            as={as}
            type={type}
            autoComplete={autoComplete}
            placeholder={placeholder}
            maxLength={maxLength}
            step={step}
            min={min}
            max={max}
            disabled={disabled}
            rows={rows}
          />
        )}
        {errorToDisplay && (
          <S.Error>{errors[0][errorToDisplay]?.message}</S.Error>
        )}
      </S.Input>
    </label>
  );
}
