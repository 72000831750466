import { useEffect, useState } from "react";
import { Row, Form, Col, Card, Button, Table } from "react-bootstrap/";
import { ToastContainer } from "react-toastify";
import Loading from "../../../Loading/Loading";
import "./styles.scss";
import "react-toastify/dist/ReactToastify.css";
import { notify, notifyError } from "../../../notify/notify-component";
import { getStocks, showStocks } from "../../../../Services/stock-service";
import {
  getProductStockQty,
  getStockableProducts,
} from "../../../../Services/product-service";
import {
  createTransfer,
  showTransfer,
} from "../../../../Services/transfers-service";
import Layout from "../../../data/Layout";
import ReactSelect from "../../../data/reactSelect";
import { InputDefault } from "../../../data/inputDefault";
import { IFormTransferenciaEstoque } from "../../../../Pages/Cadastro/CadTransfEstoque";
import { useAppSelector } from "../../../../redux/store";
import ModalBootstrap from "../../../data/Modal";

import * as S from "../../styled";

export function FormTransferenciaEstoque({
  title,
  OnAddNewEstoque,
  editando,
  estoque,
}: IFormTransferenciaEstoque) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [isIntern, setIsIntern] = useState<boolean>(true);
  const [disableActualQty, setDisableActualQty] = useState<boolean>(true);
  const [disableProduct, setDisableProduct] = useState<boolean>(true);

  const [storeDestinationId, setStoreDestinationId] = useState<number>();
  const [stockDestinationId, setStockDestinationId] = useState<number>();
  const [qty, setQty] = useState<number>();
  const [note, setNote] = useState<string>("");
  const [productName, setProductName] = useState<string>("");
  const [aProducts, setAProducts] = useState<Array<any>>([]);
  const [productId, setProductId] = useState<string>("");
  const [stockMeasureUnit, setStockMeasureUnit] = useState<string>("");
  const [uuid, setUuid] = useState<string>("");
  const [productUuid, setProductUuid] = useState<string>("");
  const [actualQty, setActualQty] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalConfirmation, setModalConfirmation] = useState<boolean>(false);
  const [index, setIndex] = useState<string>("");

  const [listProducts, setListProducts] = useState<Array<any>>([]);
  const [listStocks, setListStocks] = useState<Array<any>>([]);
  const [listStocksDestination, setListStocksDestination] = useState<
    Array<any>
  >([]);
  const [listStocksDestinationIntern, setListStocksDestinationIntern] =
    useState<Array<any>>([]);
  const [listStores, setListStores] = useState<Array<any>>([]);

  const fillProducts = async (stock_id: number) => {
    await getStockableProducts().then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

      let list: any = {};
      let listArray: any = [];
      data.map((item: any) => {
        if (item.stock_local_id === stock_id) {
          list = {
            value: item.uuid,
            label: item.purchase_name,
          };
          listArray.push(list);
        }
      });
      setLoading(true);
      setTimeout(() => {
        setListProducts(listArray);
        setLoading(false);
      }, 300);
    });
  };

  const fillStocks = async () => {
    await getStocks().then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

      const filterByStore = data.filter(
        (item: any) => item.store_id === storeMe.storeActive.id
      );

      let list = filterByStore.map((item: any) => ({
        value: item.uuid,
        label: item.name,
        id: item.id,
      }));
      setListStocks(list);
    });
  };

  const fillStocksDestination = async (store_id: number) => {
    await getStocks().then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

      const filterByStore = data.filter(
        (item: any) => item.store_id === storeMe.storeActive.id
      );

      let list = filterByStore.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setLoading(true);
      setTimeout(() => {
        setListStocksDestination(list);
        setLoading(false);
      }, 300);
    });
  };

  const fillStocksDestinationIntern = async () => {
    await getStocks().then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

      const filterByStore = data.filter(
        (item: any) => item.store_id === storeMe.storeActive.id
      );

      let list = filterByStore.map((item: any) => ({
        value: item.id,
        label: item.name,
        uuid: item.uuid,
      }));
      setLoading(true);
      setTimeout(() => {
        setListStocksDestinationIntern(list);
        setLoading(false);
      }, 300);
    });
  };

  const fillStores = async () => {
    const data = storeMe.user.relationships.stores;

    const stores = data.filter(
      (item: any) => item.id !== storeMe.storeActive.id
    );
    let list = stores.map((item: any) => ({
      value: item.id,
      label: item.trading_name,
    }));
    setListStores(list);
  };

  const fillTransfer = async (uuid: string) => {
    await showTransfer(uuid).then((response) => {
      const data = response.data.data;
      if (data.stock_transfer_type_id === 1) {
        setIsIntern(true);
        setStockDestinationId(data.stock_destination_id);
      } else {
        setIsIntern(false);
        setStoreDestinationId(data.store_destination_id);
      }
      setUuid(data.relationships.stock_origin.uuid);
      setNote(data.note);
      let oModel: any = {
        productName: "",
        qty: "",
        measure_unit: "",
      };
      data.relationships.stock_withdrawal_movement.relationships.products.map(
        (obj: any) => {
          oModel.product_id = obj.relationships.product.id;
          oModel.productName = obj.relationships.product.sale_name;
          oModel.qty = obj.qty;
          oModel.measure_unit = obj.relationships.product.stock_measure_unit_id;
        }
      );
      aProducts.push(oModel);
    });
  };

  useEffect(() => {
    fillStocks();
    fillStores();
    fillStocksDestinationIntern();

    if (estoque) {
      fillTransfer(estoque?.uuid);
      handleSelectStockTransferTypeId(
        estoque?.relationships?.stock_origin.uuid
      );
    }
  }, []);

  function addProductToTable() {
    const oModel = {
      product_id: productId,
      productName: productName,
      qty: qty,
      measure_unit: stockMeasureUnit,
    };
    setLoading(true);
    setTimeout(() => {
      aProducts.push(oModel);
      setLoading(false);
    }, 300);
  }
  function handleCancelar() {
    OnAddNewEstoque(false);
  }

  function handleSelectStockTransferTypeId(uuid: string) {
    setUuid(uuid);
    if (!uuid) return;
    showStocks(uuid).then((response: any) => {
      fillProducts(response.data.data.id);
    });
    setDisableProduct(false);
  }

  function handleSelectStockDestinationId(id: string) {
    setStockDestinationId(parseInt(id));
  }
  function handleSelectStore(id: string) {
    setStoreDestinationId(parseInt(id));
    fillStocksDestination(parseInt(id));
  }

  function handleSelectProduct(product_uuid: string) {
    setLoading(true);
    setProductUuid(product_uuid);
    setTimeout(() => {
      getProductStockQty(product_uuid).then((response: any) => {
        setStockMeasureUnit(
          response.data.data.relationships.stock_measure_unit
            ? response.data.data.relationships.stock_measure_unit.name
            : response.data.data.relationships.sale_measure_unit.name
        );
        setActualQty(
          response.data.data.stock_qty ? response.data.data.stock_qty : 0
        );
        setProductId(response.data.data.id);
        setProductName(response.data.data.purchase_name);
      });
      setDisableActualQty(false);
      setLoading(false);
    });
  }

  function deleteProducts(index: string) {
    let array: any = aProducts;
    if (Number(index) >= 0) {
      setLoading(true);
      setTimeout(() => {
        array.splice(Number(index), 1);
        setAProducts(array);
        setLoading(false);
      }, 300);
    }
  }

  async function handleSubmit() {
    const stock = listStocks?.find((item: any) => item?.value === uuid);

    if (stockDestinationId === stock?.id) {
      notifyError("A origem e o destino do estoque não podem ser os mesmos");
      return;
    }
    const objTransfer: any = {
      uuid: estoque?.uuid ? estoque?.uuid : "",
      stock_uuid: uuid,
      store_id: storeMe.storeActive.id,
      stock_transfer_type_id: isIntern ? 1 : 2,
      store_destination_id: storeDestinationId,
      stock_destination_id: stockDestinationId,
      note: note,
      products: aProducts,
    };

    await createTransfer(objTransfer).then((res: any) => {
      setLoading(true);

      if (!res) {
        setTimeout(() => {
          setLoading(false);
        }, 100);
      } else {
        setTimeout(() => {
          handleCancelar();
          setLoading(false);
        }, 100);
      }
    });
  }

  const handleModalId = (index: string) => {
    setIndex(index);
    setModalConfirmation(true);
  };

  const handleItemTable = () => {
    setModalConfirmation(false);
    deleteProducts(index);
  };

  /*

  useEffect(() => {
    setListStocksDestinationIntern(
      listStocksDestinationIntern.filter((item) => item.uuid !== uuid)
    );
  }, [uuid]);

  useEffect(() => {
    setListStocks(listStocks.filter((item) => item.id !== stockDestinationId));
  }, [stockDestinationId]);
*/
  return (
    <>
      <ModalBootstrap
        state={modalConfirmation}
        setState={setModalConfirmation}
        text="Certeza que deseja excluir o produto?"
        handleAction={handleItemTable}
      />
      <Layout
        title={title}
        isEditable={editando}
        onClickSave={handleSubmit}
        onClickEdit={handleSubmit}
        onClickCancel={handleCancelar}
      >
        <Row>
          <Col className="buttonColumns mb-3 mt-3" md="3">
            <label className="mb-1 mx-2">Tipo de Transferência</label>
            <Button
              size="sm"
              type="button"
              variant={isIntern ? "success" : "primary"}
              onClick={() => setIsIntern(!isIntern)}
            >
              {!isIntern ? "EXTERNA" : "INTERNA"}
            </Button>
          </Col>
        </Row>

        <S.Wrapper>
          <ReactSelect
            label="Origem"
            placeholder={"Estoque"}
            isClearable
            options={listStocks}
            state={uuid}
            onChange={(e: any) => handleSelectStockTransferTypeId(e?.value)}
          />

          {!isIntern ? (
            <>
              <ReactSelect
                label="Destino"
                placeholder={"Loja"}
                isClearable
                options={listStores}
                state={storeDestinationId}
                onChange={(e: any) => handleSelectStore(e?.value)}
              />

              <ReactSelect
                label="Destino"
                placeholder={"Destino | Local de Estoque"}
                isClearable
                options={listStocksDestination}
                state={stockDestinationId}
                onChange={(e: any) => handleSelectStockDestinationId(e?.value)}
              />
            </>
          ) : (
            <ReactSelect
              label="Destino"
              placeholder={"Destino | Local de Estoque "}
              isClearable
              options={listStocksDestinationIntern}
              state={stockDestinationId}
              onChange={(e: any) => handleSelectStockDestinationId(e?.value)}
            />
          )}

          <InputDefault
            size="sm"
            label="Observação"
            placeholder="Observação"
            type="text"
            rows={5}
            maxLength={500}
            onChenge={(e: any) => setNote(e.target.value)}
            state={note}
          />
        </S.Wrapper>
        <Row>
          <h3> Adicionar Itens </h3>
        </Row>
        <S.Wrapper>
          <ReactSelect
            label="Produto"
            placeholder={"Produto"}
            options={listProducts}
            state={productUuid}
            onChange={(e: any) => handleSelectProduct(e?.value)}
          />

          <InputDefault
            label="Unid. medida"
            type="text"
            autoComplete="off"
            placeholder={
              disableActualQty
                ? "Selecione o Produto"
                : "Digite a quantidade atual"
            }
            maxLength={50}
            state={stockMeasureUnit}
            disabled={true}
          />

          <InputDefault
            label="Qtd. Atual"
            type="number"
            autoComplete="off"
            placeholder={
              disableActualQty ? "Selecione o Produto" : "Quantidade atual"
            }
            state={actualQty}
            disabled={true}
          />

          <InputDefault
            label="Qtd. Desejada"
            type="number"
            autoComplete="off"
            placeholder="Quantidade desejada"
            state={qty}
            setState={setQty}
          />
        </S.Wrapper>
        <S.BtnAdd>
          <div>
            <Form.Control
              type="button"
              size="sm"
              onClick={addProductToTable}
              value={"Adicionar Item"}
            />
          </div>
        </S.BtnAdd>
        <Row className="container-data">
          &nbsp;
          <br />
          <h3> Itens </h3>
        </Row>
        {aProducts.length === 0 ? (
          <div className="noDataTable"> Nenhum produto adicionado. </div>
        ) : (
          <Table striped hover bordered size="sm" style={{ marginTop: "30px" }}>
            <thead>
              <tr className="trTable">
                <th>Nome do Produto</th>
                <th>Quantidade</th>
                <th>Unidade de Medida</th>
                <th>Remover</th>
              </tr>
            </thead>
            <tbody>
              {aProducts.map((item: any, index: any) => {
                return (
                  <tr key={index}>
                    <td>{item.productName ? item.productName : ""}</td>
                    <td>{item.qty}</td>
                    <td>{item.measure_unit}</td>
                    <td>
                      <button
                        style={{
                          border: "none",
                          background: "none",
                          paddingLeft: "1rem",
                        }}
                        type="button"
                        onClick={() => handleModalId(index)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          fill="#0f0f0f"
                          viewBox="0 0 256 256"
                        >
                          <path d="M216,48H180V36A28,28,0,0,0,152,8H104A28,28,0,0,0,76,36V48H40a12,12,0,0,0,0,24h4V208a20,20,0,0,0,20,20H192a20,20,0,0,0,20-20V72h4a12,12,0,0,0,0-24ZM100,36a4,4,0,0,1,4-4h48a4,4,0,0,1,4,4V48H100Zm88,168H68V72H188ZM116,104v64a12,12,0,0,1-24,0V104a12,12,0,0,1,24,0Zm48,0v64a12,12,0,0,1-24,0V104a12,12,0,0,1,24,0Z"></path>
                        </svg>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </Layout>

      {loading && <Loading />}

      <ToastContainer
        position="top-center"
        autoClose={300}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
}
