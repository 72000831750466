import { Col, Form, Row } from "react-bootstrap";
import styles from "./styles.module.scss";
import { Btn } from "../Btn";
import React, { SetStateAction, useState } from "react";

interface ILayout {
  children: any;
  title?: string;
  check?: boolean;
  onClickCancel?: () => void;
  onClickSave?: () => void;
  onClickEdit?: () => void;
  isEditable?: any;
  statusCheck?: boolean;
  setStatusCheck?: React.Dispatch<SetStateAction<boolean>>;
  onlyView?: boolean;
}

export default function Layout({
  children,
  title,
  check,
  onClickCancel,
  onClickSave,
  isEditable,
  statusCheck,
  setStatusCheck,
  onClickEdit,
  onlyView,
}: ILayout) {
  return (
    <Form className={styles.container}>
      <Row>
        <Col className={styles.titleGroup}>{title && <h3>{title}</h3>}</Col>
      </Row>
      {children}
      <Row className="mt-2 mb-4">
        <Col className={styles.btnGourp}>
          {onClickCancel ? (
            <Btn
              text="Cancelar"
              type="button"
              variante="secondary"
              onClick={onClickCancel}
            />
          ) : (
            <div></div>
          )}
          {onClickEdit && onClickSave && !onlyView && (
            <Btn
              text={isEditable ? "Atualizar" : "Salvar"}
              type="button"
              variante="success"
              onClick={isEditable ? onClickEdit : onClickSave}
            />
          )}
        </Col>
      </Row>
    </Form>
  );
}

/*
 <Form.Check
              className={styles.check}
              type="switch"
              label={statusCheck === 1 ? "Ativo" : "Inativo"}
              checked={statusCheck === 1 ? true : false}
              onChange={(e: any) =>
                e.target.checked === true
                  ? setStatusCheck(1)
                  : setStatusCheck(0)
              }
            />
*/
