import { Button, Col, Modal, Row } from "react-bootstrap";
import { InputDefault } from "../../../../data/inputDefault";
import React, { useState } from "react";
import { useAppSelector } from "../../../../../redux/store";
import api from "../../../../../Services/api";
import Globals from "../../../../../Globals.json";
import { notify, notifyError } from "../../../../notify/notify-component";
import GetEndPoint from "../../../../../Services/GetEndPoint";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { StateForm } from "../../../../../interfaces/state-form";
import { Services } from "../../../../../Services/newService";
import { InputHookCpf } from "../../../../reactHookForm/InputHookCpf";
import { InputHookCnpjPeople } from "../../../../reactHookForm/InputHookCnpjPeople";
import { InputHook } from "../../../../reactHookForm/InputHook";
import { peopleSchemaProduct } from "../../../../../schemas/peopleSchema-product";
import Loading from "../../../../Loading/Loading";
import * as S from "./styled";

interface IProps {
  show: boolean;
  setShow: any;
  setLoading: any;
  setSuplier: any;
}

type FormData = Yup.InferType<typeof peopleSchemaProduct>;

interface IInput {
  cpf_cnpj: string;
  company_name: string;
}

export default function ModalSupplier({
  show,
  setShow,
  setLoading,
  setSuplier,
}: IProps) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [stateForm, setStateForm] = useState<StateForm>({
    state: "default",
    message: "",
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(peopleSchemaProduct),
  });

  const handleClose = () => {
    const newData = {
      cpf_cnpj: null,
      company_name: "",
    };
    //reset(newData);
    setShow(false);
  };

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    setLoading(true);
    setStateForm({ state: "loading" });

    const newData = {
      ...data,
      store_id: storeMe.storeActive.id,
      is_vendor: true,
    };
    try {
      const res = await Services.people(newData, setStateForm);
      if (res?.status === 200) {
        notify(`Fornecedor cadastrado.`);
        GetEndPoint.vendor(setSuplier);
        setLoading(false);
        setStateForm({ state: "send" });
        handleClose();
      }
    } catch (err: any) {
      const erroAxios = err?.response?.data?.errors;
      const erroHandler = erroAxios ? Object.values(erroAxios)[0] : err;

      if (typeof erroHandler === "string") {
        const parseError = JSON.parse(erroHandler);
        notifyError(parseError.cause[0].message || `Erro ao cadastrar.`);
        setLoading(false);
        setStateForm({ state: "error" });
        return;
      }
    }
  };

  const inputError = [errors];

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Novo Fornecedor</h3>
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <S.Wrapper>
              <InputHookCnpjPeople
                register={register}
                watch={watch}
                setValue={setValue}
                setError={setError}
                errors={inputError}
                reset={reset}
                setStateForm={setStateForm}
                label="CNPJ"
                mask="99.999.999/9999-99"
                name="cpf_cnpj"
                placeholder="Cnpj"
                path={"vendor"}
                setArrayContacts={[]}
                setArrayAdress={[]}
              />

              <InputHook
                register={register}
                errors={inputError}
                label="Razão social"
                name="company_name"
                placeholder="Razão social"
                type="text"
                disabled={stateForm.state === "loading"}
              />

              <InputHook
                register={register}
                errors={inputError}
                label="Nome fantasia"
                name="trading_name"
                placeholder="Nome fantasia"
                type="text"
                disabled={stateForm.state === "loading"}
              />

              <InputHook
                register={register}
                errors={inputError}
                label="Email"
                name="email"
                type="text"
                placeholder="Email"
                disabled={stateForm.state === "loading"}
              />
            </S.Wrapper>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={stateForm.state === "loading"}
            >
              Salvar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      {stateForm.state === "loading" && <Loading />}
    </>
  );
}
