import { IListRepresentative } from "../../../../../Pages/dashboard/Admin/CadRepresentative";
import { StateForm } from "../../../../../interfaces/state-form";
import Loading from "../../../../Loading/Loading";
import { useForm, SubmitHandler } from "react-hook-form";
import { useState } from "react";
import LayoutHookFilter from "../../../../reactHookForm/LayoutFilter";
import { InputFilter } from "./InputFilter";
import DataTable, { SortOrder } from "react-data-table-component";
import tableStyleDefault from "../../../../../Services/tableStyleDefault";
import { paginationOptions } from "../../../../data/PagineteInfo";
import Format from "../../../../../Services/Format";
import DateUtils from "../../../../../Services/DateUtils";
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IRepresentative } from "../interface/IRepresentative";
import { FaEdit } from "react-icons/fa";
import LayoutHookTable from "../../../../reactHookForm/LayoutTable";
import { sortByCreated } from "../../../../../Services/sortByCreated";
import { NoData } from "../../../../noData";
import CustomLoader from "../../../../data/customLoader";

interface IFormInput {}

export function ListRepresentative({
  title,
  OnAddNewCli,
  selectOptions,
  onEditItem,
}: IListRepresentative) {
  const { register, handleSubmit, control, watch, reset } =
    useForm<IFormInput>();
  const [stateForm, setStateForm] = useState<StateForm>({
    state: "default",
    message: "",
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data: any) => {
    try {
      setStateForm({ state: "loading" });

      setStateForm({ state: "send" });
    } catch (error: any) {
      const erroAxios = error?.response?.data?.errors;
      const erroHandler = erroAxios ? Object.values(erroAxios)[0] : error;

      if (typeof erroHandler === "string") {
        const parseError = JSON.parse(erroHandler);

        setStateForm({
          state: "error",
          message:
            parseError.cause[0].message || "api_error_please_contact_support",
        });
        setStateForm({ state: "default" });
        return;
      }

      setStateForm({ state: "default" });
    }
  };

  const columns = [
    {
      name: "Nome",
      minWidth: "190px",
      selector: (row: any) => row?.name,
      grow: 2,
    },
    {
      name: "Celular",
      minWidth: "120px",
      selector: (row: any) => Format.formatCelular(row?.cellPhone ?? "") ?? "",
    },
    {
      name: "Telefone",
      minWidth: "110px",
      selector: (row: any) => Format.formatTelefone(row?.phone ?? "") ?? "",
    },
    {
      name: "Localidade",
      minWidth: "40px",
      center: true,
      selector: (row: any) => row?.state,
    },
    {
      name: "CPF/CNPJ",
      minWidth: "140px",
      center: true,
      selector: (row: any) => row?.cnpj,
    },
    {
      name: "Data de cadastro",
      minWidth: "100px",
      center: true,
      selector: (row: any) =>
        Format.date(DateUtils.toDate(row?.created_at), "DD/MM/YYYY"),
    },
    {
      name: "Status",
      minWidth: "80px",
      center: true,
      selector: (row: any) =>
        row?.status === 1 ? (
          <h4>
            <Badge bg="success">Ativo</Badge>
          </h4>
        ) : (
          <h4>
            <Badge bg="danger">Inátivo</Badge>
          </h4>
        ),
    },
    {
      name: "Ação",
      minWidth: "80px",
      cell: (row: any) => (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">Editar</Tooltip>}
        >
          <span className="d-inline-block">
            <Button
              onClick={() => handleEdit(row)}
              variant="contained"
              color="primary"
            >
              <FaEdit size={18} color="8e8d8d" />
            </Button>
          </span>
        </OverlayTrigger>
      ),
      button: true,
    },
  ];

  const handleEdit = (row: IRepresentative) => {
    onEditItem(row);
  };

  return (
    <>
      <LayoutHookFilter
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        title={title}
        onClickAdd={() => OnAddNewCli(true)}
        onClickClear={() => reset()}
      >
        <InputFilter
          register={register}
          control={control}
          watch={watch}
          selectOptions={selectOptions}
        />
      </LayoutHookFilter>

      <LayoutHookTable>
        <DataTable
          columns={columns}
          data={sortByCreated(selectOptions?.representative) || []}
          striped
          pagination
          paginationDefaultPage={1}
          paginationPerPage={25}
          customStyles={tableStyleDefault}
          paginationComponentOptions={paginationOptions}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhuma informação foi encontrada"
        />
      </LayoutHookTable>

      {stateForm.state === "loading" && <Loading />}
    </>
  );
}
