import { memo } from "react";
import Select from "react-select";
import {
  msgStyles,
  NoOptionsMessage,
} from "../../NoOptionsMessage/no-options-message-component";
import * as S from "./styles";
import { Button } from "react-bootstrap";

interface IProps {
  label?: string;
  options: any;
  state?: any;
  setState?: any;
  isClearable?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  type?: "number";
  defaultValue?: any;
  onChange?: any;
  onBlur?: any;
  minWidth?: string | undefined;
  setShow?: any;
  icon?: any;
}

function ReactSelectComplement({
  options,
  state,
  setState,
  isClearable,
  placeholder,
  label,
  isDisabled,
  isInvalid,
  type,
  defaultValue,
  onChange,
  onBlur,
  minWidth,
  setShow,
  icon,
}: IProps) {
  return (
    <label>
      <div className="label">{label}</div>
      <S.Input>
        <S.Wrapper minWidth={minWidth}>
          <div className="select">
            <Select
              value={options.filter((option: any) => option?.value === state)}
              isClearable={isClearable}
              placeholder={
                <div className="placeholderSelect">{placeholder}</div>
              }
              options={options}
              defaultValue={defaultValue}
              components={{ NoOptionsMessage }}
              onChange={
                onChange
                  ? onChange
                  : type
                  ? (e: any) => setState(Number(e?.value))
                  : (e: any) => setState(e?.value)
              }
              isDisabled={isDisabled}
              onBlur={onBlur}
              styles={{
                noOptionsMessage: (base) => ({ ...base, ...msgStyles }),
                control: (base) => ({
                  ...base,
                  border: isInvalid
                    ? "1px solid #F1416C"
                    : "1px solid var(--color-input-border)",
                  boxShadow: "var(--color-input-shadow)",
                  borderRadius: 4,
                  height: 18,
                  display: "flex",
                  alignContent: "center",
                  minHeight: 26.5,
                  textAlign: "left",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary50: "#e4e6ef",
                  primary25: "#e4e6ef",
                  primary75: "#e4e6ef",
                  primary: "#5d98f1e8",
                },
              })}
            />
          </div>
          <Button onClick={setShow} size="sm">
            {icon}
          </Button>
        </S.Wrapper>
      </S.Input>
    </label>
  );
}

export default memo(ReactSelectComplement);
