import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";
import { sortByCreated } from "../../../../Services/sortByCreated";

export const getStoreAsync = createAsyncThunk(
  "stores",
  async (store_id: number, thunkAPI) => {
    try {
      const response = await api.get(
        globals.api.stores + `?paginate=false&store_id=${store_id}`
      );
      return sortByCreated(response.data.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getStateAsync = createAsyncThunk(
  "stores/state",
  async (_, thunkAPI) => {
    try {
      const response = await api.get(`${globals.api.states}?paginate=false`);

      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getInvoicesTypesAsync = createAsyncThunk(
  "stores/invoicesTypes",
  async (_, thunkAPI) => {
    try {
      const response = await api.get(
        `${globals.api.invoicesTypes}?paginate=false`
      );
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCstsAsync = createAsyncThunk("csts", async (_, thunkAPI) => {
  try {
    const response = await api.get(globals.api.csts);
    return response.data.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
