import styled from "styled-components";

interface IProps {
  status: boolean;
}

export const Content = styled.div<IProps>`
  background-color: ${({ status }) => (status ? "#00ab00" : "#ff1a1a")};
  padding: 4px 7px;
  font-size: 11px;
  color: white;
  border-radius: 5px;
  text-align: center;
`;
