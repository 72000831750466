import { useEffect, useState, useContext } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DataTable from "react-data-table-component";
import Format from "../../../../Services/Format";
import { FaEdit } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../../Services/api";
import Globals from "../../../../Globals.json";
import DateUtils from "../../../../Services/DateUtils";
import {
  HiOutlineDocumentDuplicate,
  HiOutlineDotsVertical,
} from "react-icons/hi";
import GetEndPoint from "../../../../Services/GetEndPoint";
import { InputDefault } from "../../../data/inputDefault";
import ReactSelect from "../../../data/reactSelect";
import LayoutFilter from "../../../data/LayoutFilter";
import LayoutTable from "../../../data/LayoutTable";
import tableStyleDefault from "../../../../Services/tableStyleDefault";
import { ProdutoContext } from "../../../../context/product";
import { useAppSelector } from "../../../../redux/store";
import { paginationOptions } from "../../../data/PagineteInfo";
import { NoData } from "../../../noData";
import ActiveInactiv from "../../../data/activeInactive";
import CustomLoader from "../../../data/customLoader";
import { Dropdown } from "react-bootstrap";
import * as S from "./styled";
import { notifyError } from "../../../notify/notify-component";

export default function ListaProdutos(props: any) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const { setMinSale, setCoast } = useContext(ProdutoContext);
  const [productsList, setProductsList] = useState<Array<any>>([]);
  const [name, setName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [typedate, setTypeDate] = useState<number>(0);
  const [category, setCategory] = useState<any>("");
  const [code, setCode] = useState<string>("");
  const [startDate, setStartDate] = useState<string>(
    Format.firstDate("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(
    Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD")
  );
  const [listName, setListName] = useState<Array<any>>([]);
  const [categoriesList, setCategoriesList] = useState<any[]>([]);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const listdate = [
    { value: 0, label: "Todos" },
    { value: 1, label: "Data Cadastro" },
  ];

  const handleEdit = (row: any) => {
    if (typeof props.onEditItem === "function" && row !== undefined) {
      props.onDuplicar(false);
      props.onEditando(true);
      props.onEditItem(row);
      props.onNewProduct();
    }
  };

  const handleDuplicate = (row: any) => {
    if (typeof props.onEditItem === "function" && row !== undefined) {
      props.onDuplicar(true);
      props.onEditando(false);
      props.onEditItem(row);
      props.onNewProduct();
      props.setIfDuplicate(true);
    }
  };

  const handleNew = () => {
    setMinSale("0.01");
    setCoast("0.01");
    props.onNewProduct();
  };

  const isLater = (start: string, end: string) => {
    return start > end;
  };

  const handleDateStart = async (date: string) => {
    if (!date) return;
    if (date.length !== 10) {
      return;
    }
    setStartDate(date);
    let dateValid = isLater(date, endDate);
    if (dateValid) {
      return;
    }
  };

  const handleDateEnd = async (date: string) => {
    if (!date) return;
    if (date.length !== 10) {
      return;
    }
    setEndDate(date);
    let dateValid = isLater(startDate, date);
    if (dateValid) {
      return;
    }
  };

  const clearFields = () => {
    setName("");
    setCode("");
    setCategory("");
    setTypeDate(0);
    setStartDate(Format.firstDate("YYYY-MM-DD"));
    setEndDate(Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD"));
    getData();
  };

  const createFilter = (filterList: Array<string>): string => {
    let filter = "";
    for (let i = 0; i < filterList.length; i++) {
      if (i >= 1) {
        filter += "&";
      }
      filter += `filter${filterList[i]}`;
    }
    return filter;
  };

  const handleSearchList = () => {
    let filterList = [];
    if (name !== "") filterList.push(`[product_name]=${name}`);
    if (category !== "") filterList.push(`[product_category_id]=${category}`);
    if (code) filterList.push(`[code]=${code}`);
    if (typedate === 1) {
      filterList.push(`[created_from]=${startDate}`);
      filterList.push(`[created_until]=${endDate}`);
    }
    if (filterList.length > 0) {
      const filter = createFilter(filterList);
      if (filter !== "") {
        getData(filter);
      }
      return;
    }
    getData();
  };

  const columns: any = [
    {
      name: "Código",
      sortable: true,
      width: "88px",
      center: true,
      selector: (row: any) => Number(row.code),
    },
    {
      name: "Produto",
      minWidth: "190px",
      sortable: true,
      selector: (row: any) => {
        return (
          <div
            title="Editar"
            style={{
              cursor: "pointer",
            }}
            onClick={() => handleEdit(row)}
          >
            {row.sale_name?.toLowerCase()}
          </div>
        );
      },
    },

    {
      name: "Tipo",
      center: true,
      selector: (row: any) =>
        row?.relationships?.product_type?.product_type_description,
    },
    {
      name: "Operação",
      minWidth: "90px",
      center: true,
      selector: (row: any) =>
        row.relationships?.purpose?.product_purpose_description,
    },

    {
      name: "Custo",
      selector: (row: any) =>
        row.cost_price.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
      sortable: true,
      width: "85px",
      center: true,
    },
    {
      name: "Venda",
      selector: (row: any) =>
        row.sale_price.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
      sortable: true,
      width: "85px",
      center: true,
    },
    {
      name: "Categoria",
      center: true,

      sortable: true,
      selector: (row: any) =>
        row.relationships?.category?.name.toLowerCase() ?? "",
    },
    {
      name: "Qtd.",
      selector: (row: any) => row.stock_qty.toFixed(2).replace(".", ","),
      width: "80px",
      sortable: true,
      center: true,
    },
    {
      name: "Unidade",
      width: "94px",
      sortable: true,
      center: true,
      selector: (row: any) =>
        row.relationships?.sale_measure_unit?.name.toLowerCase() ?? "",
    },
    {
      name: "Status",
      width: "84px",
      center: true,
      selector: (row: any) =>
        row.is_active ? (
          <ActiveInactiv status={row.is_active} text="Ativo" />
        ) : (
          <ActiveInactiv status={row.is_active} text="Inativo" />
        ),
    },
    {
      name: "Ação",
      width: "60px",
      center: true,
      cell: (row: any) => (
        <Dropdown>
          <Dropdown.Toggle variant="white" id="dropdown-basic" size="sm">
            <HiOutlineDotsVertical />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleDuplicate(row)}>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  fontSize: "12px",
                }}
              >
                <HiOutlineDocumentDuplicate
                  size={18}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Duplicar Produto"
                  className="action"
                  color="#0095e8"
                />
                Duplicar Produto
              </div>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleEdit(row)}>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  fontSize: "12px",
                }}
              >
                <FaEdit
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Atualizar"
                  className="action"
                  onClick={() => handleEdit(row)}
                  size={16}
                  color="#50cd89"
                />
                Atualizar
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      button: true,
    },
  ];

  /* 
    {
      name: "Ação",
      maxWidth: "30px",
      center: true,
      cell: (row: any) => (
        <div className="actions">
          <HiOutlineDocumentDuplicate
            size={18}
            data-toggle="tooltip"
            data-placement="top"
            title="Duplicar Produto"
            className="action"
            onClick={() => handleDuplicate(row)}
            style={{ marginRight: 15 }}
            color="#0095e8"
          />
          <FaEdit
            data-toggle="tooltip"
            data-placement="top"
            title="Atualizar"
            className="action"
            onClick={() => handleEdit(row)}
            size={16}
            color="#50cd89"
          />
        </div>
      ),
      button: true,
    },
  */

  const getData = (filter?: string) => {
    const uri =
      filter !== undefined
        ? `?${filter}&paginate=false&append=stock_qty&store_id=${storeMe.storeActive.id}`
        : `?paginate=false&append=stock_qty&orderBy=created_at&order=desc&store_id=${storeMe.storeActive.id}`;

    setLoading(true);
    api
      .get(Globals.api.products + `${uri}`)
      .then((response) => {
        const data = response.data;
        setProductsList(data.data);
      })
      .catch((error) => {
        notifyError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickOpenFilter = () => {
    setOpenFilter(!openFilter);
  };

  useEffect(() => {
    getData();
    GetEndPoint.categories(setCategoriesList);
    GetEndPoint.productsPage(setListName);
  }, []);

  let newListCategori = categoriesList.filter(
    (item) => item.store_id === storeMe.storeActive.id
  );
  return (
    <>
      <LayoutFilter
        title="Produtos"
        onClickAdd={handleNew}
        onClickClear={clearFields}
        onClickSearch={handleSearchList}
        onClickOpenFilter={onClickOpenFilter}
        openFilter={openFilter}
      >
        <S.Wrapper>
          {openFilter && (
            <div className="min-screen">
              <ReactSelect
                label="Produto"
                placeholder="Produto"
                state={name}
                options={listName}
                setState={setName}
              />

              <InputDefault
                label="Código"
                placeholder="Código"
                type="text"
                autoComplete="off"
                state={code}
                maxLength={50}
                onChenge={(e: any) => setCode(e.target.value)}
              />

              <ReactSelect
                label="Categoria"
                placeholder="Categoria"
                state={category}
                options={newListCategori}
                setState={setCategory}
              />

              <ReactSelect
                label="Período"
                placeholder="Periodo"
                state={typedate}
                options={listdate}
                isInvalid={false}
                setState={setTypeDate}
              />

              <InputDefault
                label="De"
                type="date"
                disabled={typedate === 0}
                state={startDate}
                onChenge={(e: any) => handleDateStart(e.target.value)}
              />

              <InputDefault
                label="Até"
                type="date"
                disabled={typedate === 0}
                state={endDate}
                onChenge={(e: any) => handleDateEnd(e.target.value)}
              />
            </div>
          )}

          <div className="max-screen">
            <ReactSelect
              label="Produto"
              state={name}
              options={listName}
              setState={setName}
            />

            <InputDefault
              label="Código"
              type="text"
              autoComplete="off"
              state={code}
              maxLength={50}
              onChenge={(e: any) => setCode(e.target.value)}
            />

            <ReactSelect
              label="Categoria"
              state={category}
              options={newListCategori}
              setState={setCategory}
            />

            <ReactSelect
              label="Período"
              state={typedate}
              options={listdate}
              isInvalid={false}
              setState={setTypeDate}
            />

            <InputDefault
              label="De"
              type="date"
              disabled={typedate === 0}
              state={startDate}
              onChenge={(e: any) => handleDateStart(e.target.value)}
            />

            <InputDefault
              label="Até"
              type="date"
              disabled={typedate === 0}
              state={endDate}
              onChenge={(e: any) => handleDateEnd(e.target.value)}
            />
          </div>
        </S.Wrapper>
      </LayoutFilter>
      <LayoutTable>
        <DataTable
          columns={columns}
          data={productsList || []}
          striped
          pagination
          paginationDefaultPage={1}
          paginationPerPage={25}
          customStyles={tableStyleDefault}
          paginationComponentOptions={paginationOptions}
          progressPending={loading}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhuma informação foi encontrada."
        />
      </LayoutTable>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
