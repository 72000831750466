import { useEffect, useRef, useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import DataTable from "react-data-table-component";
import api from "../../../../Services/api";
import Globals from "../../../../Globals.json";
import { ToastContainer } from "react-toastify";
import Format from "../../../../Services/Format";
import DateUtils from "../../../../Services/DateUtils";
import { sortByCreated } from "../../../../Services/sortByCreated";
import styles from "./styles.module.scss";
import LayoutFilter from "../../../data/LayoutFilter";
import tableStyleDefault from "../../../../Services/tableStyleDefault";
import { InputDefault } from "../../../data/inputDefault";
import LayoutTable from "../../../data/LayoutTable";
import { IListProducao } from "../../../../Pages/Cadastro/CadProducao";
import ReactSelect from "../../../data/reactSelect";
import * as S from "./styled";
import CustomLoader from "../../../data/customLoader";
import GetEndPoint from "../../../../Services/GetEndPoint";
import ModalBootstrap from "../../../data/Modal";
import { notify, notifyError } from "../../../notify/notify-component";
import { useAppSelector } from "../../../../redux/store";

export default function ListaProducao({
  OnAddNewCli,
  title,
  onEditItem,
  OnAddNewProducao,
}: IListProducao) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const ref = useRef<any>();
  const [typedate, setTypeDate] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>(
    Format.firstDate("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(
    Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD")
  );
  // Erros
  const [validDateIni, setValidDateIni] = useState<boolean>(false);
  const [validDateEnd, setValidDateEnd] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [productionList, setProductionList] = useState<Array<any>>([]);
  // Lists
  const [listName, setListName] = useState<Array<any>>([]);
  const [creations, setCreations] = useState<Array<any>>([]);
  const [products, setProducts] = useState<Array<any>>([]);
  const [modalConfirmation, setModalConfirmation] = useState<boolean>(false);
  const [uuid, setUuid] = useState<string>("");
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const handleNew = () => {
    OnAddNewCli(true);
  };

  const clearFields = () => {
    ref.current.clear();
    setName("");
    setTypeDate(0);
    setStartDate(Format.date(new Date(), "YYYY-MM-DD"));
    setEndDate(Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD"));
    getData();
  };
  const handleName = (names: any) => {
    if (!names || names.length <= 0) {
      setName("");
      return;
    }
    setName(names[0]);
  };
  const getData = (filter?: string) => {
    const uri =
      filter !== undefined
        ? `${filter}&paginate=false&store_id=${storeMe.storeActive.id}`
        : `paginate=false&store_id=${storeMe.storeActive.id}`;

    setLoading(true);

    api
      .get(Globals.api.production + `?${uri}`)
      .then((response) => {
        const data = response.data;
        setProductionList(
          sortByCreated(
            data.data.filter((x: any) => x.relationships?.creation !== null)
          )
        );
      })
      .catch((error) => {
        notifyError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const isLater = (start: string, end: string) => {
    return start > end;
  };

  const handleDateStart = async (date: string) => {
    if (!date) return;
    if (date.length !== 10) {
      setValidDateIni(false);
      return;
    }
    setStartDate(date);
    let dateValid = isLater(date, endDate);
    setValidDateIni(dateValid);
    if (dateValid) {
      return;
    }
  };

  const handleDateEnd = async (date: string) => {
    if (!date) return;
    if (date.length !== 10) {
      setValidDateIni(false);
      return;
    }
    setEndDate(date);
    let dateValid = isLater(startDate, date);
    setValidDateEnd(dateValid);

    if (dateValid) {
      return;
    }
  };
  const handleReverse = async (uuid: any) => {
    api
      .put(Globals.api.produce + `/${uuid}/chargeback`)
      .then((response) => {
        getData();
        notify("Produção estornada.");
      })
      .catch((error) => {
        notifyError("Erro ao tentar estornar produção.");
      })
      .finally(() => {});
  };
  const searchNames = (name: any) => {
    if (name.length >= 3) {
      api
        .get(
          Globals.api.products + `/autocomplete?name=sale_name&value=${name}`
        )
        .then((response) => {
          const names = response.data.data;
          if (names.length > 0) {
            setListName(names.map((name: any) => name.sale_name));
          }
        })
        .catch((error) => {})
        .finally(() => {});
    }
  };

  const columns: any = [
    {
      name: "Data",
      center: true,
      selector: (row: any) => Format.formatDate(row.created_at),
      grow: 0.2,
    },
    {
      name: "Produto",
      sortable: true,
      selector: (row: any) =>
        row.relationships?.creation?.relationships?.product?.sale_name.toLowerCase(),
    },
    {
      name: "Unid. Medida",
      center: true,
      grow: 0.5,
      selector: (row: any) =>
        row.relationships?.creation?.relationships?.measure_unit?.name.toLowerCase() ??
        "",
    },
    {
      name: "Quantidade",
      center: true,
      sortable: true,
      grow: 0.5,
      selector: (row: any) => Number(row.qty),
    },
    {
      name: "Estoque",
      center: true,
      selector: (row: any) => row.relationships?.stock?.name.toLowerCase(),
      grow: 0.6,
    },
    {
      name: "Custo",
      center: true,
      sortable: true,
      grow: 0.5,
      selector: (row: any) => calcCosts(row),
    },

    {
      name: "Status",
      width: "150px",
      grow: 0.2,
      cell: (row: any) =>
        row.chargeback_dt ? (
          <span>Estornado</span>
        ) : (
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">Estornar</Tooltip>}
          >
            <S.Span onClick={() => handleModal(row.uuid)}>Estornar</S.Span>
          </OverlayTrigger>
        ),
      button: true,

      selector: (row: any) =>
        row.situation_id === 2 ? "Estornado" : "Realizado -",
    },
  ];
  const createFilter = (filterList: Array<string>): string => {
    let filter = "";
    for (let i = 0; i < filterList.length; i++) {
      if (i >= 1) {
        filter += "&";
      }
      filter += `filter${filterList[i]}`;
    }
    return filter;
  };
  const handleSearchList = () => {
    if (validDateIni || validDateEnd) return;
    let filterList = [];
    if (name !== "") filterList.push(`[product_name]=${name}`);

    if (typedate === 1) {
      filterList.push(`[created_from]=${startDate}`);
      filterList.push(`[created_until]=${endDate}`);
    }
    if (filterList.length > 0) {
      const filter = createFilter(filterList);
      if (filter !== "") {
        getData(filter);
      }
      return;
    }
    getData();
  };

  const listdate = [
    {
      value: 0,
      label: "Todos",
    },
    {
      value: 1,
      label: "Data",
    },
  ];

  const calcCosts = (row: any) => {
    const inputs = creations.find(
      (item: any) => item.uuid === row?.relationships?.creation?.uuid
    )?.relationships?.inputs;

    const costs = [];
    for (let i = 0; i < inputs?.length; i++) {
      const cost = products?.find(
        (item: any) => item.id === inputs[i]?.product_id
      )?.cost_price;

      if (cost) {
        costs.push(cost);
      }
    }
    const soma = costs.reduce(
      (acumulador, valorAtual) => acumulador + valorAtual,
      0
    );

    const result = soma / Number(row.qty);
    return result ? Format.currency(result) : "---";
  };

  const paginationOptions = {
    rowsPerPageText: "Registros por página",
    rangeSeparatorText: "de",
  };

  const handleModal = (uuid: string) => {
    setUuid(uuid);
    setModalConfirmation(true);
  };

  const handleEstorno = () => {
    handleReverse(uuid);
    setModalConfirmation(false);
  };

  const onClickOpenFilter = () => {
    setOpenFilter(!openFilter);
  };

  useEffect(() => {
    getData();
    GetEndPoint.productCreationComplet(setCreations);
    GetEndPoint.productsComplet(setProducts);
  }, []);

  return (
    <>
      <ModalBootstrap
        state={modalConfirmation}
        setState={setModalConfirmation}
        text="Certeza que deseja estornar a produção?"
        handleAction={handleEstorno}
      />
      <LayoutFilter
        title={title}
        onClickAdd={handleNew}
        onClickClear={clearFields}
        onClickSearch={handleSearchList}
        onClickOpenFilter={onClickOpenFilter}
        openFilter={openFilter}
      >
        <S.Wrapper>
          {openFilter && (
            <div className="min-screen">
              <ReactSelect
                label="Produto"
                placeholder="Produto"
                state={name}
                options={listName}
                setState={setName}
              />

              <ReactSelect
                label="Período"
                placeholder="Periodo"
                state={typedate}
                options={listdate}
                isInvalid={false}
                setState={setTypeDate}
              />

              <InputDefault
                label="De"
                type="date"
                disabled={typedate === 0}
                state={startDate}
                onChenge={(e: any) => handleDateStart(e.target.value)}
              />

              <InputDefault
                label="Até"
                type="date"
                disabled={typedate === 0}
                state={endDate}
                onChenge={(e: any) => handleDateEnd(e.target.value)}
              />
            </div>
          )}

          <div className="max-screen">
            <ReactSelect
              label="Produto"
              state={name}
              options={listName}
              setState={setName}
            />

            <ReactSelect
              label="Período"
              state={typedate}
              options={listdate}
              isInvalid={false}
              setState={setTypeDate}
            />

            <InputDefault
              label="De"
              type="date"
              disabled={typedate === 0}
              state={startDate}
              onChenge={(e: any) => handleDateStart(e.target.value)}
            />

            <InputDefault
              label="Até"
              type="date"
              disabled={typedate === 0}
              state={endDate}
              onChenge={(e: any) => handleDateEnd(e.target.value)}
            />
          </div>
        </S.Wrapper>
      </LayoutFilter>
      <LayoutTable>
        <DataTable
          className="dataTable"
          columns={columns}
          data={productionList || []}
          striped
          pagination
          paginationDefaultPage={1}
          paginationPerPage={25}
          customStyles={tableStyleDefault}
          paginationComponentOptions={paginationOptions}
          progressPending={loading}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhuma informação foi encontrada."
        />
      </LayoutTable>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
