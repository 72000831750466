import { Button, Col, Modal, Row } from "react-bootstrap";
import { Dispatch, SetStateAction, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../../../../../redux/store";

import { notify, notifyError } from "../../../../../notify/notify-component";
import { InputHook } from "../../../../../reactHookForm/InputHook";
import { IPaymentEntry } from "../../../../../../redux/features/paymentType/interface";
import { IObj } from "../typeOptionsGroup/obj";
import { categoryFinancialSchema } from "../../../../../../redux/features/categorieFinancial/schema";
import {
  createCategoryFinancialAsync,
  getCategorieFinancialAsync,
  updateCategoryFinancialAsync,
} from "../../../../../../redux/features/categorieFinancial/services";
import { CheckboxHook } from "../../../../../reactHookForm/inputCheckbox";
import * as S from "../../../styled";

interface IProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setListPDV: any;
  listPDV: any[];
  listEditeItem: any;
  setListEditeItem: any;
  setKeyActived: any;
}

type FormData = Yup.InferType<typeof categoryFinancialSchema>;

interface IInput {
  is_active: boolean;
  description: string;
  entry_type: number;
  chart_account_id: IObj;
  terminate_days: number;
  tax: number;
  accept_change: boolean;
  visible_pdv: boolean;
}

export default function ModalPDV({
  show,
  setShow,
  setLoading,
  listEditeItem,
  setListEditeItem,
  setKeyActived,
}: IProps) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const dispatch = useDispatch<AppDispatch>();

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(categoryFinancialSchema),
  });

  const handleClose = () => {
    setShow(false);
    setListEditeItem(undefined);
    resetItens();
  };

  const onSubmit: SubmitHandler<IInput> = async (data: IInput) => {
    setLoading(true);
    const newData = {
      ...data,
      description: data.description,
      entry_type: 1,
      store_id: storeMe.storeActive.id,
      is_active: true,
    };

    if (listEditeItem) {
      const newDataUpdate = {
        ...listEditeItem,
        ...newData,
        is_active: data.is_active,
      };
      dispatch(updateCategoryFinancialAsync(newDataUpdate)).then(
        (result: any) => {
          if (updateCategoryFinancialAsync.fulfilled.match(result)) {
            dispatch(getCategorieFinancialAsync(storeMe.storeActive.id));
          } else {
            notifyError("Erro ao atualizar item.");
          }
        }
      );
      resetItens();
      setShow(false);
      setLoading(false);
      setKeyActived("revenue");
      return;
    }

    dispatch(createCategoryFinancialAsync(newData)).then((result: any) => {
      if (createCategoryFinancialAsync.fulfilled.match(result)) {
        dispatch(getCategorieFinancialAsync(storeMe.storeActive.id));
      } else {
        notifyError("Erro ao adicionar item.");
      }
    });
    setKeyActived("revenue");
    setLoading(false);
    resetItens();
    setShow(false);
  };

  const inputError = [errors];

  const resetItens = () => {
    setValue("description", "");
    setValue("is_active", "");

    reset({});
  };

  useEffect(() => {
    if (listEditeItem && show) {
      setValue("description", listEditeItem?.description);
      setValue("is_active", listEditeItem?.is_active);
    } else {
      resetItens();
      setValue("is_active", true);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      style={{ fontSize: "12px" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>
            {listEditeItem ? "Editar " : "Adicionar "} Forma de Recebimento
          </h3>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <S.Wrapper>
            <InputHook
              register={register}
              errors={inputError}
              label="Tipo"
              name="entry_type"
              defaultValue={"Receita"}
              type="text"
              disabled={true}
            />

            <InputHook
              register={register}
              errors={inputError}
              label="Descrição"
              name="description"
              placeholder="Descrição"
              type="text"
            />
          </S.Wrapper>
          <CheckboxHook
            register={register}
            errors={inputError}
            label="Ativo"
            name="is_active"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" type="submit">
            {listEditeItem ? "Atualizar" : "Salvar"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
