import { components } from "react-select";

export const NoOptionsMessage = (pr: any) => {
  return (
    <components.NoOptionsMessage {...pr}>
      <span className="custom-css-class">Nenhum Resultado</span>
    </components.NoOptionsMessage>
  );
};

export const msgStyles = {
  background: "#0d6efd",
  color: "white",
};
