import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getChartAccountsync, getPAymentTypesync } from "./services";
import { sortByCreated } from "../../../Services/sortByCreated";
import { IPaymentEntry } from "./interface";
import { IChartAccount } from "../chartAccount/interface";

interface InitialState {
  value: PaymentTypeState;
}

interface StateForm {
  state: "error" | "loading" | "send" | "default";
  message?: string;
}

interface PaymentTypeState {
  loading: boolean;
  fetchError: string;
  stateForm: StateForm;
  paymentType: IPaymentEntry[];
  expense: IPaymentEntry[];
  chartAccount: IChartAccount[];
  chartAccountExpense: IChartAccount[];
}

const initialState: InitialState = {
  value: {
    loading: false,
    fetchError: "",
    paymentType: [],
    expense: [],
    chartAccount: [],
    chartAccountExpense: [],
    stateForm: { state: "default" },
  } as PaymentTypeState,
};

export const paymentType = createSlice({
  name: "paymentType",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(getPAymentTypesync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getPAymentTypesync.fulfilled,
      (state: InitialState, action: PayloadAction<IPaymentEntry[]>) => {
        state.value.loading = false;

        const dataFilter = action.payload.filter(
          (expense: any) => expense.entry_type === 1
        );

        const dataFilterExpense = action.payload.filter(
          (expense: any) => expense.entry_type === 0
        );

        state.value.paymentType = sortByCreated(dataFilter);
        state.value.expense = sortByCreated(dataFilterExpense);
      }
    );
    builder.addCase(
      getPAymentTypesync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );

    builder.addCase(getChartAccountsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getChartAccountsync.fulfilled,
      (state: InitialState, action: PayloadAction<IPaymentEntry[]>) => {
        state.value.loading = false;

        const dataFilter = action.payload.filter(
          (expense: any) => expense.entry_type === 1
        );

        const dataFilterExpense = action.payload.filter(
          (expense: any) => expense.entry_type === 0
        );

        state.value.chartAccount = sortByCreated(dataFilter);
        state.value.chartAccountExpense = sortByCreated(dataFilterExpense);
      }
    );
    builder.addCase(
      getChartAccountsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );
  },
});

export const {} = paymentType.actions;

export default paymentType.reducer;
