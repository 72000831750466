import { Form } from "react-bootstrap";
import styles from "./styles.module.scss";

interface ILayoutTable {
  children: any;
}

export default function LayoutTableNotShadow({ children }: ILayoutTable) {
  return <Form className={styles.container}>{children}</Form>;
}
