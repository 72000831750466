import { Button, Modal } from "react-bootstrap";
import styles from "./styles.module.scss";
import { Dispatch, SetStateAction } from "react";

interface IProps {
  showModal: boolean;
  handleClose: () => void;
  logoBd: string | null;
  setLogo: Dispatch<SetStateAction<string | null>>;
  logo: string | null;
  handleImageChange: (event: any) => void;
  errors?: any;
  register: any;
}

export function ModalImage({
  showModal,
  handleClose,
  logoBd,
  setLogo,
  logo,
  handleImageChange,
  errors,
  register,
}: IProps) {
  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Modal de exemplo</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modal}>
        <div>
          <div className={styles.wrapperLogo}>
            <div>
              <h4>Logo atual</h4>
              <div className={styles.gridImage}>
                {logoBd && <img src={logoBd} alt="logo" />}
              </div>
              <button className={styles.remove} onChange={() => setLogo(null)}>
                Remover
              </button>
            </div>
            <div>
              <h4>Prévia</h4>
              <div className={styles.gridImage}>
                {logo && <img src={logo} alt="logo" />}
              </div>
              <div className={styles.fileUpload}>
                <button className={styles.change}>Alterar</button>
                <input type="file" name="file" onChange={handleImageChange} />
              </div>
            </div>
          </div>
        </div>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "12px",
            paddingLeft: "1rem",
          }}
        >
          Aviso: Lembre-se de apenas arquivos do tipo [.png] podem ser usados
          como logo.
        </p>
        {/*<input type="file" onChange={(e) => setLogo(e.target.value)} />*/}
      </Modal.Body>
      <Modal.Footer className={styles.btnWrapper}>
        <Button onClick={handleClose} size="sm">
          Fechar
        </Button>
        <Button onClick={handleClose} size="sm">
          Salvar alterações
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
