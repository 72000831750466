import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  IBank,
  IChartAccount,
  IFinancialTransaction,
  IReceiver,
} from "./interface";
import {
  getBanksAsync,
  getCategoriesAsync,
  getChartAccountAsync,
  getReceiverAsync,
  getfinancialTransactionAsync,
} from "./services";
import { getEntryType, getEntryTypeChartAccount } from "./utils";
import { ISelect, objStatus, objectPeriod } from "./services/object";

interface InitialState {
  value: FinancialTransaction;
}

interface StateForm {
  state: "error" | "loading" | "send" | "default";
  message?: string;
}

interface FinancialTransaction {
  loading: boolean;
  fetchError: string;
  stateForm: StateForm;
  revenue: IFinancialTransaction[];
  expense: IFinancialTransaction[];
  bank: IBank[];
  period: ISelect[];
  status: ISelect[];
  receiver: IReceiver[];
  chartAccountRevenue: IChartAccount[];
  chartAccountExpense: IChartAccount[];
  category: any[];
}

const initialState: InitialState = {
  value: {
    loading: false,
    fetchError: "",
    stateForm: { state: "default" },
    revenue: [],
    expense: [],
    bank: [],
    receiver: [],
    chartAccountRevenue: [],
    chartAccountExpense: [],
    category: [],
    period: objectPeriod,
    status: objStatus,
  } as FinancialTransaction,
};

export const financialTransaction = createSlice({
  name: "financialTransaction",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(
      getfinancialTransactionAsync.pending,
      (state: InitialState) => {
        state.value.loading = true;
      }
    );
    builder.addCase(
      getfinancialTransactionAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IFinancialTransaction[]>) => {
        state.value.loading = false;
        state.value.revenue = getEntryType(action.payload, 1);
        state.value.expense = getEntryType(action.payload, 0);
      }
    );
    builder.addCase(
      getfinancialTransactionAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );

    builder.addCase(getBanksAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getBanksAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IBank[]>) => {
        state.value.loading = false;
        state.value.bank = action.payload;
      }
    );
    builder.addCase(
      getBanksAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );

    builder.addCase(getReceiverAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getReceiverAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IReceiver[]>) => {
        state.value.loading = false;
        state.value.receiver = action.payload;
      }
    );
    builder.addCase(
      getReceiverAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );

    builder.addCase(getChartAccountAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getChartAccountAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IChartAccount[]>) => {
        state.value.loading = false;
        state.value.chartAccountRevenue = getEntryTypeChartAccount(
          action.payload,
          1
        );
        state.value.chartAccountExpense = getEntryTypeChartAccount(
          action.payload,
          0
        );
      }
    );
    builder.addCase(
      getChartAccountAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );

    builder.addCase(getCategoriesAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getCategoriesAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IChartAccount[]>) => {
        state.value.loading = false;
        state.value.category = action.payload;
      }
    );
    builder.addCase(
      getCategoriesAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );
  },
});

export const {} = financialTransaction.actions;

export default financialTransaction.reducer;
