import { Button, ButtonGroup, Col } from "react-bootstrap";
import * as S from "./styles";
import { ITypeRecord } from "../../FormPessoa";

interface IProps {
  typeRecord: ITypeRecord;
  setTypeRecord: any;
}

export function TypeRecordGroup({ typeRecord, setTypeRecord }: IProps) {
  return (
    <S.RowGroup typePerson={typeRecord}>
      <Col md="4">
        <ButtonGroup>
          <Button
            onClick={() =>
              setTypeRecord({ ...typeRecord, customer: !typeRecord.customer })
            }
            variant={typeRecord.customer === true ? "primary" : "light"}
            active={typeRecord.customer === true ? true : false}
            size="sm"
          >
            Cliente
          </Button>
          <Button
            onClick={() =>
              setTypeRecord({ ...typeRecord, vendor: !typeRecord.vendor })
            }
            variant={typeRecord.vendor === true ? "primary" : "light"}
            active={typeRecord.vendor === true ? true : false}
            size="sm"
          >
            Fornecedor
          </Button>
          <Button
            onClick={() =>
              setTypeRecord({ ...typeRecord, employee: !typeRecord.employee })
            }
            variant={typeRecord.employee === true ? "primary" : "light"}
            active={typeRecord.employee === true ? true : false}
            size="sm"
          >
            Funcionário
          </Button>
          <Button
            onClick={() =>
              setTypeRecord({ ...typeRecord, carrier: !typeRecord.carrier })
            }
            variant={typeRecord.carrier === true ? "primary" : "light"}
            active={typeRecord.carrier === true ? true : false}
            size="sm"
          >
            Transportadora
          </Button>
        </ButtonGroup>
      </Col>
    </S.RowGroup>
  );
}
