import { SelectHookFlex } from "../../../../reactHookForm/SelectHookFlex";
import Format from "../../../../../Services/Format";
import DateUtils from "../../../../../Services/DateUtils";
import { useAppSelector } from "../../../../../redux/store";
import { InputHookFlex } from "../../../../reactHookForm/InputHookFlex";
import * as S from "./styled";

interface IProps {
  register: any;
  control: any;
  watch: any;
  setValue: any;
  setError: any;
  errors: any;
  reset: any;
  entryType: number;
  openFilter: boolean;
}

export function InputFilter({
  register,
  control,
  watch,
  setValue,
  setError,
  reset,
  entryType,
  openFilter,
}: IProps) {
  const periodoState = watch("periodo");
  const {
    status,
    period,
    chartAccountExpense,
    chartAccountRevenue,
    receiver,
    bank,
    category,
  } = useAppSelector(
    ({ financialTransactionReducer }) => financialTransactionReducer.value
  );

  const listChastAccount =
    entryType === 1 ? chartAccountRevenue : chartAccountExpense;

  return (
    <S.Wrapper>
      {openFilter && (
        <div className="min-screen">
          <label>
            <div className="label">Código</div>
            <InputHookFlex
              register={register}
              name="document"
              placeholder="Código"
              type="text"
            />
          </label>
          <label>
            <span className="label">Favorecido</span>

            <SelectHookFlex
              register={register}
              control={control}
              defaultValue={[]}
              name="receiver"
              placeholder="Favorecido"
              options={receiver.map((item: any) => ({
                label: item.name,
                value: item.id,
              }))}
            />
          </label>
          <label>
            <span className="label">Plano Co.</span>
            <SelectHookFlex
              register={register}
              control={control}
              defaultValue={[]}
              name="chart_account_id"
              placeholder="Plano de conta"
              options={listChastAccount.map((item: any) => ({
                label: item.description,
                value: item.id,
              }))}
            />
          </label>

          <label>
            <span className="label">Situação</span>
            <SelectHookFlex
              register={register}
              control={control}
              defaultValue={status[0]}
              name="status"
              placeholder="Situação"
              options={status}
            />
          </label>

          <label>
            <span className="label">Categoria</span>
            <SelectHookFlex
              register={register}
              control={control}
              defaultValue={[]}
              name="category_id"
              placeholder="Categoria"
              options={category.map((item: any) => ({
                label: item.description,
                value: item.id,
              }))}
            />
          </label>
          <label>
            <span className="label">Conta</span>
            <SelectHookFlex
              register={register}
              control={control}
              defaultValue={[]}
              name="bank_id"
              placeholder="Conta"
              options={bank.map((item: any) => ({
                label: item.description,
                value: item.id,
              }))}
            />
          </label>

          <label>
            <span className="label">Valor</span>
            <InputHookFlex
              register={register}
              name="initial_value"
              placeholder="Inicial"
              type="text"
            />
          </label>
          <label>
            <span className="label"> Valor Final</span>
            <InputHookFlex
              register={register}
              name="final_value"
              placeholder="Final"
              type="text"
            />
          </label>

          <label>
            <span className="label"> Data</span>
            <SelectHookFlex
              register={register}
              control={control}
              defaultValue={period[1]}
              name="periodo"
              placeholder="Período"
              options={period}
              isClearable={false}
            />
          </label>

          {periodoState?.value !== 0 && (
            <>
              <label>
                <span className="label">De</span>
                <InputHookFlex
                  register={register}
                  name="inicialData"
                  placeholder="Inicial"
                  type="date"
                  disabled={periodoState?.value === 0 ? true : false}
                  defaultValue={Format.firstDate("YYYY-MM-DD")}
                />
              </label>

              <label>
                <span className="label">Até</span>
                <InputHookFlex
                  register={register}
                  name="finalData"
                  placeholder="Final"
                  type="date"
                  disabled={periodoState?.value === 0 ? true : false}
                  defaultValue={Format.date(
                    DateUtils.endOfMonth(),
                    "YYYY-MM-DD"
                  )}
                />
              </label>
            </>
          )}
        </div>
      )}

      <div className="max-screen">
        <label className="code">
          Código
          <InputHookFlex register={register} name="document" type="text" />
        </label>

        <label className="receiver">
          Favorecido
          <SelectHookFlex
            register={register}
            control={control}
            defaultValue={[]}
            name="receiver"
            options={receiver.map((item: any) => ({
              label: item.name,
              value: item.id,
            }))}
          />
        </label>

        <label className="chart">
          Plano
          <SelectHookFlex
            register={register}
            control={control}
            defaultValue={[]}
            name="chart_account_id"
            options={listChastAccount.map((item: any) => ({
              label: item.description,
              value: item.id,
            }))}
          />
        </label>

        <label className="status">
          Situação
          <SelectHookFlex
            register={register}
            control={control}
            defaultValue={status[0]}
            name="status"
            options={status}
          />
        </label>

        <label className="date">
          Data
          <SelectHookFlex
            register={register}
            control={control}
            defaultValue={period[1]}
            name="periodo"
            options={period}
            isClearable={false}
          />
        </label>

        <label>
          Valor
          <label className="initial-value">
            <InputHookFlex
              register={register}
              name="initial_value"
              placeholder="Inicial"
              type="number"
            />
            <InputHookFlex
              register={register}
              name="final_value"
              type="number"
              placeholder="Final"
            />
          </label>
        </label>

        <label className="category">
          Categoria
          <SelectHookFlex
            register={register}
            control={control}
            defaultValue={[]}
            name="category_id"
            options={category.map((item: any) => ({
              label: item.description,
              value: item.id,
            }))}
          />
        </label>

        <label className="bank">
          Conta
          <SelectHookFlex
            register={register}
            control={control}
            defaultValue={[]}
            name="bank_id"
            options={bank.map((item: any) => ({
              label: item.description,
              value: item.id,
            }))}
          />
        </label>

        {periodoState?.value !== 0 && (
          <>
            <label className="initial-data">
              De
              <InputHookFlex
                register={register}
                name="inicialData"
                type="date"
                disabled={periodoState?.value === 0}
                defaultValue={Format.firstDate("YYYY-MM-DD")}
              />
            </label>

            <label className="final-data">
              Até
              <InputHookFlex
                register={register}
                name="finalData"
                type="date"
                disabled={periodoState?.value === 0}
                defaultValue={Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD")}
              />
            </label>
          </>
        )}
      </div>
    </S.Wrapper>
  );
}
