// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableContact {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

.rowContatc {
  display: flex;
  height: 25px;
}
.rowContatc div {
  width: 300px;
  border: 2px solid rgba(0, 0, 0, 0.144);
}

.buttonTables button {
  background-color: inherit;
  border: none;
  outline: none;
  margin-right: 10px;
  cursor: pointer;
  width: 30px;
}

.container-data {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.rowInputs {
  padding: 5px;
}

.pre-table-items {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.btnAddToTable {
  margin-top: 15px;
}

.trTable {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #181c32 !important;
}

.buttonOptionsPessoa {
  margin-top: 30px;
  background-color: inherit;
  border: none;
  outline: none;
  margin-right: 10px;
  cursor: pointer;
  width: 30px;
}

.btn-flex-end {
  display: flex;
  justify-content: flex-end;
}

button {
  margin-right: 30px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Estoque/AjusteEstoque/CadAjusteEstoque/styles.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,cAAA;AAAF;;AAGA;EACE,aAAA;EACA,YAAA;AAAF;AAEE;EACE,YAAA;EACA,sCAAA;AAAJ;;AAKE;EACE,yBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;AAFJ;;AAMA;EACE,aAAA;EACA,8BAAA;EACA,uBAAA;AAHF;;AAKA;EACE,YAAA;AAFF;;AAKA;EACE,aAAA;EACA,6BAAA;EACA,mBAAA;EACA,mBAAA;AAFF;;AAMA;EACE,gBAAA;AAHF;;AAKA;EACE,2BAAA;EACA,0BAAA;EACA,yBAAA;AAFF;;AAMA;EACE,gBAAA;EACA,yBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;AAHF;;AAMA;EACE,aAAA;EACA,yBAAA;AAHF;;AAKA;EACE,kBAAA;AAFF","sourcesContent":["\r\n.tableContact {\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 8px 0;\r\n}\r\n\r\n.rowContatc {\r\n  display: flex;\r\n  height: 25px;\r\n\r\n  div {\r\n    width: 300px;\r\n    border: 2px solid rgba(0, 0, 0, 0.144);\r\n  }\r\n}\r\n\r\n.buttonTables {\r\n  button {\r\n    background-color: inherit;\r\n    border: none;\r\n    outline: none;\r\n    margin-right: 10px;\r\n    cursor: pointer;\r\n    width: 30px;\r\n  }\r\n}\r\n\r\n.container-data {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: flex-start;\r\n}\r\n.rowInputs {\r\n  padding: 5px;\r\n}\r\n\r\n.pre-table-items {\r\n  display: flex;\r\n  justify-content: space-evenly;\r\n  align-items: center;\r\n  flex-direction: row;\r\n}\r\n\r\n\r\n.btnAddToTable {\r\n  margin-top: 15px;\r\n}\r\n.trTable {\r\n  font-weight: 600 !important;\r\n  font-size: 14px !important;\r\n  color: #181c32 !important;\r\n}\r\n\r\n\r\n.buttonOptionsPessoa {\r\n  margin-top: 30px;\r\n  background-color: inherit;\r\n  border: none;\r\n  outline: none;\r\n  margin-right: 10px;\r\n  cursor: pointer;\r\n  width: 30px;\r\n}\r\n\r\n.btn-flex-end {\r\n  display: flex;\r\n  justify-content: flex-end;\r\n} \r\nbutton {\r\n  margin-right: 30px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
