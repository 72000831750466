import { defaultThemes } from "react-data-table-component";

const tableStyleDefaultBolder: any = {
  header: {
    style: {
      minHeight: "50px",
      fontWeight: "bold",
    },
  },
  headRow: {
    style: {
      color: "black",
      borderStyle: "solid",
      fontWeight: "bold",
      borderWidth: "1px",
      borderColor: defaultThemes.default.divider.default,

      fontSize: "12px",
    },
  },
  headCells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
  cells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: defaultThemes.default.divider.default,
        textTransform: "capitalize",
        fontWeight: "bold",
        fontSize: "11.5px",
      },
    },
  },
  rows: {
    style: {
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: defaultThemes.default.divider.default,
      fontSize: "11.5px",
      textTransform: "capitalize",
      fontWeight: "bold",
      borderTopStyle: "none",
    },
  },
};

export default tableStyleDefaultBolder;
