import { Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import { AiOutlineSearch, AiOutlinePlus } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { MdKeyboardArrowDown } from "react-icons/md";

interface IBtn {
  text: string;
  variante:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";
  type?: "button" | "submit" | "reset";
  icon?: "search" | "plus" | "trash" | "arrow";
  onClick?: () => void;
}

export function Btn({ text, variante, icon, onClick, type }: IBtn) {
  return (
    <Button
      style={{ padding: "5px 12px" }}
      variant={variante}
      type={type}
      onClick={onClick}
    >
      {icon === "search" && <AiOutlineSearch className={styles.icon} />}
      {icon === "plus" && <AiOutlinePlus className={styles.icon} />}
      {icon === "trash" && <BiTrash className={styles.icon} />}
      {icon === "arrow" && <MdKeyboardArrowDown className={styles.icon} />}
      {text}
    </Button>
  );
}
