import * as Yup from "yup";

export const representativeSchema = Yup.object().shape({
  cnpj: Yup.string().nullable().required("Por favor, informe um cnpj."),
  name: Yup.string().nullable().required("Por favor, informe um nome."),
  email: Yup.string().nullable().required("Por favor, informe o email."),
  cep: Yup.string().nullable().required("Por favor, informe o cep."),
  address_street: Yup.string()
    .nullable()
    .required("Por favor, informe o endereço."),
  address_number: Yup.string()
    .nullable()
    .required("Por favor, informe o número."),
  address_district: Yup.string()
    .nullable()
    .required("Por favor, informe o nome do bairro."),
  state: Yup.string().nullable().required("Por favor, selecione o estado."),
  city: Yup.string().nullable().required("Por favor, informe a cidade."),
  cellPhone: Yup.string().nullable().required("Por favor, informe a celular."),
});
