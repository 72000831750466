import moment from "moment";
import DateUtils from "./DateUtils";
import { format, parseISO, startOfMonth, subDays } from "date-fns";

class Format {
  static getStatusOrder = (row: any) => {
    if (row.status && row?.relationships) {
      if (
        row?.relationships?.stockMovement?.situation_id === 2 &&
        row?.relationships?.entries?.length === 0 &&
        row.status === 1
      ) {
        return 1; // Pendente
      }
      if (
        row?.relationships?.stockMovement?.situation_id === 2 &&
        row?.relationships?.entries?.length === 0
      ) {
        return 2; // Cancelado
      }
      if (
        row?.relationships?.stockMovement?.situation_id === 2 &&
        row?.relationships?.entries?.length > 0
      ) {
        return 3; // Estornar
      }
      if (row.status === 1 || row.status === 3) {
        return row?.chargeback_date ? 4 : 1; // Estornado ou Pendente
      } else {
        return 3; // Estornar
      }
    }
    return 0; // Caso indefinido
  };

  static convertToBRFormat = (isoDate: any) => {
    if (!isoDate) return "---";
    const date = parseISO(isoDate);
    return format(date, "dd/MM/yyyy");
  };

  static currency(value: number) {
    const formatoMoeda = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);

    return formatoMoeda;
  }

  static toDecimal(value: string) {
    return parseFloat(value).toFixed(2);
  }

  // Antigos
  static formatCep(cep: string) {
    let onlyNumbers = cep.replace(/\D/g, "");
    if (onlyNumbers.length === 8) {
      // return onlyNumbers;
      return `${onlyNumbers.substring(0, 5)}-${onlyNumbers.substring(5, 8)}`;
    }
    return "";
  }

  static stringFormated(string: any) {
    return string.replace(/\D/g, "");
  }

  static formatCelular(celular: string) {
    if (celular) {
      let onlyNumbers = celular.replace(/\D/g, "");
      if (onlyNumbers.length === 10) {
        return `(${onlyNumbers.substring(0, 2)}) ${onlyNumbers.substring(
          2,
          6
        )}-${onlyNumbers.substring(6, 10)}`;
      }
      if (onlyNumbers.length === 11) {
        return `(${onlyNumbers.substring(0, 2)}) ${onlyNumbers.substring(
          2,
          3
        )} ${onlyNumbers.substring(3, 7)}-${onlyNumbers.substring(7, 11)}`;
      }
    }

    return "";
  }

  static formatTelefone(tel: string) {
    if (tel) {
      let onlyNumbers = tel.replace(/\D/g, "");
      if (onlyNumbers.length === 10) {
        return `(${onlyNumbers.substring(0, 2)}) ${onlyNumbers.substring(
          2,
          6
        )}-${onlyNumbers.substring(6, 10)}`;
      }
    }

    return "";
  }

  static formatCpf(cpf: string) {
    let onlyNumbers = cpf.replace(/\D/g, "");
    if (onlyNumbers.length === 11) {
      return `${onlyNumbers.substring(0, 3)}.${onlyNumbers.substring(
        3,
        6
      )}.${onlyNumbers.substring(6, 9)}-${onlyNumbers.substring(9, 11)}`;
    }
    return "";
  }

  static formatCnpj(cnpj: string) {
    let onlyNumbers = cnpj.replace(/\D/g, "");
    if (onlyNumbers.length === 14) {
      return `${onlyNumbers.substring(0, 2)}.${onlyNumbers.substring(
        2,
        5
      )}.${onlyNumbers.substring(5, 8)}/${onlyNumbers.substring(
        8,
        12
      )}-${onlyNumbers.substring(12, 14)}`;
    }
    return "";
  }

  static formatData(data: string) {
    let onlyNumbers = data.replace(/\D/g, "");
    if (onlyNumbers.length === 8) {
      return `${onlyNumbers.substring(0, 2)}/${onlyNumbers.substring(
        2,
        4
      )}/${onlyNumbers.substring(4, 8)}`;
    }
    return "";
  }

  static numeric(value: number, decimals: number = 0): string {
    return (
      value?.toLocaleString(undefined, { minimumFractionDigits: decimals }) ?? 0
    );
  }

  // Input (Ex.:): Tue Nov 23 2021 09:14:54 GMT-0300 (Horário Padrão de Brasília)
  // Output: 23/11/2021
  static date(value: Date = new Date(), format: string = "DD/MM/YYYY"): string {
    return this.datetime(value, format);
  }

  static firstDate(format: string = "DD/MM/YYYY") {
    const dataAtual = new Date();
    const primeiroDiaDoMes = startOfMonth(dataAtual);
    return this.datetime(primeiroDiaDoMes, format);
  }

  // Input (Ex.:): Tue Nov 23 2021 09:13:45 GMT-0300 (Horário Padrão de Brasília)
  // Output: 09:13:45
  static time(value: Date = new Date(), format: string = "HH:mm:ss"): string {
    return this.datetime(value, format);
  }

  // Input (Ex.:): Tue Nov 23 2021 09:12:23 GMT-0300 (Horário Padrão de Brasília)
  // Output: 2021-11-23
  static originalDate(value: Date = new Date()): string {
    const day = value.getDate() > 9 ? value.getDate() : `0${value.getDate()}`;
    const month =
      value.getMonth() + 1 > 9
        ? value.getMonth() + 1
        : `0${value.getMonth() + 1}`;
    const year = value.getFullYear();
    return `${year}-${month}-${day}`;
  }

  // Formatação genérica. Sempre que algum retorno der 'Invalid dateX', é necessário incluir um 'else if'
  // para que o parâmetro value tenha o mesmo formato colocado no 'moment'. Por exemplo:
  //
  // value: 11/23/2021 09:08:13
  // formatação: MM/DD/YYYY HH:mm:ss
  //
  // Quando os dois baterem a formatação (derem 'true'), daí pode ser modificado o 'newFormattedDate' da forma que quiser
  // ao entrar no 'if'
  //
  // Já o outro parâmetro de 'dateFormat' será o formato que deseja ser de 'output'. Daí será necessário formatar seu
  // 'switch case' da forma que achar necessário a exibição de sua data

  static genericFormatterToBRString(dateFormat: string, value: string): string {
    let dateFormatted: string[] = [];
    let dateFormatted2: string[] = [];
    let newFormattedDate: string = "";
    let dayMonthYearFormat: boolean = true;

    if (moment(value, "MM/DD/YYYY HH:mm:ss", true).isValid()) {
      dateFormatted = String(value).split(" ");
      dateFormatted2 = String(dateFormatted[0]).split("/");
      newFormattedDate =
        dateFormatted2[1] +
        "/" +
        dateFormatted2[0] +
        "/" +
        dateFormatted2[2] +
        " " +
        dateFormatted[1];
    } else if (moment(value, "YYYY-MM-DDTHH:mm:ss", true).isValid()) {
      dateFormatted = String(value).split("T");
      dateFormatted2 = String(dateFormatted[0]).split("-");
      newFormattedDate =
        dateFormatted2[2] +
        "/" +
        dateFormatted2[1] +
        "/" +
        dateFormatted2[0] +
        " " +
        dateFormatted[1];
    } else if (moment(value, "YYYY-MM", true).isValid()) {
      dateFormatted = String(value).split("-");
      newFormattedDate = dateFormatted[1] + "/" + dateFormatted[0];
      dayMonthYearFormat = false;
    } else if (moment(value, "YYYY-MM-DDTHH:mm:ssZ", true).isValid()) {
      dateFormatted = String(value).split("T");
      dateFormatted2 = String(dateFormatted[0]).split("-");
      newFormattedDate =
        dateFormatted2[2] +
        "/" +
        dateFormatted2[1] +
        "/" +
        dateFormatted2[0] +
        " " +
        dateFormatted[1].replaceAll("Z", "");
    } else {
      return "Invalid dateX";
    }

    if (dayMonthYearFormat) {
      switch (dateFormat) {
        case "DD/MM/YYYY HH:mm:ss":
          break;
        case "DD/MM/YYYY":
          dateFormatted = String(newFormattedDate).split(" ");
          newFormattedDate = dateFormatted[0];
          break;
        case "HH:mm:ss":
          dateFormatted = String(newFormattedDate).split(" ");
          newFormattedDate = dateFormatted[1];
          break;
        case "YYYY-MM":
          dateFormatted = String(value).split("-");
          newFormattedDate = dateFormatted[1] + "/" + dateFormatted[0];
          break;
        default:
          dateFormatted = String(value).split("/");
          newFormattedDate = Format.date(
            DateUtils.toDate(
              dateFormatted[1] + "/" + dateFormatted[0] + "/" + dateFormatted[2]
            ),
            dateFormat ?? "DD/MM/YYYY"
          );
      }
    }
    return newFormattedDate;
  }

  // Input (Ex.:): 23/11/2021 09:03:00
  // Output: 2021-11-23T09:03:00
  static convertDateTimeString(value: string): string {
    const datetimeSplitted = value.split(" ");
    const dateSplitted = datetimeSplitted[0].split("/");
    return `${dateSplitted[2]}-${dateSplitted[1]}-${dateSplitted[0]}T${datetimeSplitted[1]}`;
  }

  // Input (Ex.:): 2021-11-23T11:00:44.387Z
  // Output: 23/11/2021 11:00:44.387
  static convertInternationalDateTimeString(value: string): string {
    const datetimeSplitted = value.split("T");
    const dateSplitted = datetimeSplitted[0].split("-");
    const timeConverted = datetimeSplitted[1].replaceAll("Z", "");
    return `${dateSplitted[2]}/${dateSplitted[1]}/${dateSplitted[0]} ${timeConverted}`;
  }

  // Input (Ex.:): 2021-11-23T08:59:07
  // Output: 2021-11-23
  static convertDateString(value: string): string {
    return value.split("T")[0];
  }

  // Input (Ex.:): 2021-11-23
  // Output: 23/11/2021
  static convertBRDateString(value: string): string {
    const newBRDate = value.split("-");
    return `${newBRDate[2]}/${newBRDate[1]}/${newBRDate[0]}`;
  }

  // Input (Ex.:): 2021-06
  // Output: 06/2021
  // Obs.: Recebe o input em 'ano-mês'
  static convertBRDateMonthYearString(value: string): string {
    const newBRDate = value.split("-");
    return `${newBRDate[1]}/${newBRDate[0]}`;
  }

  // Input (Ex.:): 07/14/1982
  // Output: 14/07/1982
  // Obs.: Para quando dia e mês vierem em formatação invertida
  static convertDayMonthDate(value: string): string {
    const newBRDate = value.split("/");
    return `${newBRDate[1]}/${newBRDate[0]}/${newBRDate[2]}`;
  }

  // Input (Ex.:): Tue Nov 23 2021 08:41:45 GMT-0300 (Horário Padrão de Brasília)
  // Output: 23/11/2021 08:41:45
  static datetime(
    value: Date | string = "",
    format: string = "DD/MM/YYYY HH:mm:ss"
  ): string {
    var date: Date;
    if (typeof value === "string") {
      date = new Date(value);
    } else {
      date = new Date(value.toDateString() + " " + value.toTimeString());
    }
    return moment(date).format(format).toString();
  }

  // Input (Ex.:): 5.99
  // Output: 5,99
  // static formatCurrency(current: number): string {
  //   const currencyFormatted = String(
  //     current.toLocaleString("pt-br", {
  //       style: "currency",
  //       currency: "BRL",
  //     })
  //   );
  //   return currencyFormatted.includes("R$")
  //     ? currencyFormatted.replace("R$", "").trim()
  //     : currencyFormatted.trim();
  // }

  // Input (Ex.:): 5.99
  // Output: 5,99
  static formatCurrency(current: number): string {
    return new Intl.NumberFormat("pt-br", {
      style: "currency",
      currency: "BRL",
    }).format(current);
  }

  static formatDate(date: string) {
    const data = new Date(date);
    const dia = data.getDate().toString().padStart(2, "0"); // Obtém o dia e adiciona o zero à esquerda, se necessário
    const mes = (data.getMonth() + 1).toString().padStart(2, "0"); // Obtém o mês (vale lembrar que Janeiro é 0) e adiciona o zero à esquerda, se necessário
    const ano = data.getFullYear().toString().slice(-2);
    return `${dia}/${mes}/${ano}`;
  }

  static formatDecimal(current: number): string {
    return new Intl.NumberFormat("pt-br", { maximumFractionDigits: 2 }).format(
      current
    );
  }
  // Input (Ex.:): 5,99
  // Output: 5.99
  static formatCurrencyNumber(current: string | number): number {
    let newCurrent: string = String(current);
    if (newCurrent.includes(",")) {
      newCurrent = newCurrent.replace(",", ".");
    }
    return parseFloat(newCurrent);
  }
  static convertPointComma(value: string) {
    return value.replace(".", ",");
  }
  static convertCommaPoint(value: string) {
    return value.replace(",", ".");
  }
  static convertCommaPointToFix2(value: string) {
    return parseFloat(value.replace(",", ".")).toFixed(2).replace(".", ",");
  }
  static removeSymbol(value: string) {
    return value.replace("R$", "");
  }
  static startDatetoEndDateFormatted(startDate: Date, endDate: Date): string {
    return `${this.convertBRDateString(
      this.originalDate(startDate)
    )} à ${this.convertBRDateString(this.originalDate(endDate))}`;
  }

  // Input (Ex.:): 02/09/2021
  // Output: 2021-11-23T11:00:44.387Z
  static convertToOriginalDateTime(value: string): string {
    const BRDate = value.split("/");
    const BRTime = "00:00:00";
    return `${BRDate[2]}-${BRDate[1]}-${BRDate[0]}T${BRTime}.000Z`;
  }

  static convertDate(date: string) {
    let dia = date.split("/")[0];
    let mes = date.split("/")[1];
    let ano = date.split("/")[2];

    return ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2);
  }
}

export default Format;
